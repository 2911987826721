import { COMMENTS_TYPES } from "../../constants/Comments";
import { buildQueryParamsString } from "../string";

export const getMe = () => {
  return "/users/me";
};

export const getAuthToken = (code, refreshToken, isPasswordResetFlow) => {
  if (code !== undefined) {
    return `/auth/token?code=${encodeURIComponent(
      code
    )}&isPasswordResetFlow=${isPasswordResetFlow}`;
  } else {
    return `/auth/token?refreshToken=${encodeURIComponent(
      refreshToken
    )}&isPasswordResetFlow=${isPasswordResetFlow}`;
  }
};

export const acceptTerms = () => {
  return "/users/me/accepttnc";
};

export const getRecentlyViewed = () => {
  return "/users/myrecentlyviewed";
};

export const getRealtimeRevenueAssets = () => {
  return "/assets/realtimerevenue";
};

export const getRealtimeRevenueLastAvailableDate = () => {
  return "/assets/realtimerevenue/lastavailabledate";
};

export const getPPAAndRevenue = () => {
  return "/assets/realtimerevenue/ppaandrevenue";
};

export const getRealTimeRevenueDetailsForAsset = (
  assetId,
  fromDate,
  toDate
) => {
  return `/assets/${assetId}/realtimerevenue/details?fromDate=${fromDate}&toDate=${toDate}`;
};

export const creatOrEditAsset = (assetId = "") => {
  return `/assets/${assetId}`;
};

export const archiveAsset = (assetId) => {
  return `/assets/${assetId}/archive`;
};

export const getPortfolioNamesForAsset = (assetId) => {
  return `/assets/${assetId}/portfolionames`;
};

export const getAssetById = (assetId, isRecentlyViewed) => {
  return `/assets/${assetId}?isRecentlyViewed=${isRecentlyViewed}`;
};

export const getAssetOnboardingChecklist = (assetId) => {
  return `/assets/${assetId}/checklist`;
};

export const setAssetOnboardingComplete = (assetId) => {
  return `/assets/${assetId}/onboarding/complete`;
};

export const getJournalGlCodes = (assetId, isCreatePage, queryParams) => {
  if (isCreatePage) {
    return `/assets/glaccounts/all/${assetId}?${buildQueryParamsString({
      ...queryParams
    })}`;
  } else {
    return `/assets/glaccounts/all/${assetId}?includeInterCompanyAccounts=true`;
  }
};

export const getChartOfAccountById = (id) => {
  return `/assets/glaccounts/${id}`;
};

export const createOrEditChartOfAccount = (assetId, chartAccountId) => {
  return `/assets/glaccounts/${chartAccountId || assetId}`;
};

export const archiveChartOfAccount = (accountId, assetId) => {
  return `/assets/glaccounts/archive/${accountId}/${assetId}`;
};

export const downloadChartOfAccountsSampleTemplate = () => {
  return `/assets/glaccounts/sample`;
};

export const getUploadChartOfAccounts = (assetId) => {
  return `/assets/glaccounts/${assetId}/upload`;
};

export const updateMapSystemAccounts = (assetId) => {
  return `/assets/glaccounts/${assetId}/systemaccount`;
};

export const setConversionPeriod = (assetId, conversionDate) => {
  return `/assets/${assetId}/conversionperiod?${buildQueryParamsString(
    conversionDate
  )}`;
};

export const getAllOrganisations = () => {
  return `/organizations/`;
};

export const getOrgById = (orgId) => {
  return `/organizations/${orgId}`;
};

export const createOrg = () => {
  return `/organizations/`;
};

export const updateOrgById = (orgId) => {
  return `/organizations/${orgId}`;
};

export const archiveOrgById = (orgId) => {
  return `/organizations/${orgId}/archive`;
};

export const getOrganizationMasterData = (orgId) => {
  return `/organization/masterdata/${orgId}`;
};

export const updateOrganizationMasterData = (orgId) => {
  return `/organization/masterdata/${orgId}`;
};

export const uploadOrganizationMasterData = (orgId) => {
  return `/organization/masterdata/${orgId}/upload`;
};

export const downloadSampleOrganizationMasterData = () => {
  return `/organization/masterdata/upload/sample`;
};

export const getTaxData = (taxId) => {
  return `/tax/${taxId}`;
};

export const addTaxToOrganization = () => {
  return `/tax/`;
};

export const editTaxToOrganization = (taxId) => {
  return `/tax/${taxId}`;
};

export const deleteTax = (taxId) => {
  return `/tax/${taxId}`;
};

export const archiveTax = (taxId) => {
  return `/tax/archive/${taxId}`;
};

export const getDataAvailableFromAndToDate = (assetId) => {
  return `/assets/${assetId}/datastatus/dates`;
};

export const uploadAssetMasterData = (assetId) => {
  return `/assets/masterdata/${assetId}/upload`;
};

export const getAssetMasterData = (assetId) => {
  return `/assets/masterdata/${assetId}`;
};

export const updateAssetMasterData = (assetId) => {
  return `/assets/masterdata/${assetId}`;
};

export const getAssetReportingMasterData = (assetId) => {
  return `/assets/masterdata/${assetId}/availability`;
};

export const getListOfYearsForBudgetUpload = (assetId) => {
  return `/assets/budget/${assetId}/years`;
};

export const downloadSampleAssetOrgConsolidationMasterData = () =>
  `/assets/masterdata/upload/consolidation/sample`;

export const downloadSampleAssetMasterData = () =>
  `/assets/masterdata/upload/sample`;

export const getAssetDashboardBudgetKPIData = (
  assetId,
  financialYear,
  budgetType
) => {
  return `/assets/${assetId}/dashboard/kpibudget?financialYear=${financialYear}&budgetType=${budgetType}`;
};

export const getAssetDashboardMasterKPIData = (assetId) => {
  return `/assets/${assetId}/dashboard/kpimaster`;
};

export const getAssetDashboardActualKPIData = (assetId, month, year) => {
  return `/assets/${assetId}/dashboard/kpidata?month=${month}&year=${year}`;
};

export const importAssetTransactionData = (
  assetId,
  fromMonth,
  fromYear,
  toMonth,
  toYear
) => {
  return `/assets/${assetId}/reports/importdata?fromMonth=${fromMonth}&fromYear=${fromYear}&toMonth=${toMonth}&toYear=${toYear}`;
};

export const importNonGLAssetTransactionData = (assetId) => {
  return `/assets/nongltransactiondata/${assetId}/upload`;
};

export const getMyAssets = () => {
  return "/users/myassets";
};

export const getAssetSearch = (assetName) => {
  return `/assets/search?assetName=${assetName}`;
};

export const getBankAccountCodesForAsset = (assetId, isForPayments = false) => {
  if (isForPayments) {
    return `/assets/masterdata/${assetId}/bankaccountcodes?paymentBankAccount=true`;
  }
  return `/assets/masterdata/${assetId}/bankaccountcodes`;
};

export const getReportsBudgetOptions = (assetId) => {
  return `/assets/${assetId}/reports/budgetoptions`;
};

export const generateIncomeStatement = (assetId) => {
  return `/assets/${assetId}/reports/incomestatement`;
};

export const exportIncomeStatement = (assetId) => {
  return `/assets/${assetId}/reports/incomestatement/excel`;
};

export const getIncomeStatementFxDrilldownReport = (assetId) => {
  return `/assets/${assetId}/reports/incomestatement/fx/drilldown`;
};

export const updateIncomeStatementComment = (assetId) => {
  return `/assets/${assetId}/reports/incomestatement/comment`;
};

export const getIncomeStatementTransactions = (assetId) => {
  return `/assets/${assetId}/reports/incomestatement/transactions`;
};

export const getIncomeStatementReportBudgetDetails = (assetId) => {
  return `/assets/${assetId}/reports/incomestatement/budget`;
};

export const getIncomeStatementReportVarianceBudget = (assetId) => {
  return `/assets/${assetId}/reports/incomestatement/variances/budget`;
};

export const getIncomeStatementReportVarianceDetails = (assetId) => {
  return `/assets/${assetId}/reports/incomestatement/variances`;
};

export const generateIncomeStatementOutturnReport = (assetId) => {
  return `/assets/${assetId}/reports/budget/plreport`;
};

export const generateIncomeStatementOutturnReportExcel = (
  assetId,
  budgetName,
  year,
  compareToBudget
) => {
  return `/assets/${assetId}/reports/budget/outturn/excel?budgetName=${budgetName}&year=${year}&compareToBudget=${compareToBudget}`;
};

export const generateBalanceSheet = (assetId) => {
  return `/assets/${assetId}/reports/balancesheet`;
};

export const exportBalanceSheet = (assetId) => {
  return `/assets/${assetId}/reports/balancesheet/excel`;
};

export const updateBalanceSheetComment = (assetId) => {
  return `/assets/${assetId}/reports/balancesheet/comment`;
};

export const getBalanceSheetGenericDrillDownData = (assetId) => {
  return `/assets/${assetId}/reports/balancesheet/transactions`;
};

export const getBalanceSheetTradeDrillDownData = (assetId) => {
  return `/assets/${assetId}/reports/balancesheet/tradecreditorsdebtors/drilldown`;
};

export const getBalanceSheetAccrualDrillDownData = (assetId) => {
  return `/assets/${assetId}/reports/balancesheet/accrualsprepayments/drilldown`;
};

export const getRevenueAccrualDrilldownDetailReport = (assetId) => {
  return `/assets/${assetId}/reports/balancesheet/revenueaccruals/drilldown/details`;
};

export const getTradeDrilldownDetailReport = (assetId) => {
  return `/assets/${assetId}/reports/balancesheet/tradecreditorsdebtors/drilldown/details`;
};

export const updateBalanceSheetTradeDrillDownDataComment = (assetId) => {
  return `/assets/${assetId}/reports/balancesheet/drilldown/comment`;
};

export const getBalanceSheetPrepaymentsDrillDownData = (assetId) => {
  return `/assets/${assetId}/reports/balancesheet/prepayments/drilldown`;
};

export const getBalanceSheetPrepaymentsDrillDownDetailReport = (assetId) => {
  return `/assets/${assetId}/reports/balancesheet/prepayments/drilldown/details`;
};

export const getBalanceSheetCostAccrualsDrillDownDetailReport = (assetId) => {
  return `/assets/${assetId}/reports/balancesheet/costaccruals/drilldown/details`;
};

// POST
export const getBankAccountDrillDownDetailReport = (assetId) => {
  return `/assets/${assetId}/reports/balancesheet/bankaccounts/drilldown/details`;
};

export const generateCashflow = (assetId) => {
  return `/assets/${assetId}/reports/cashflow`;
};

export const exportCashflow = (assetId) => {
  return `/assets/${assetId}/reports/cashflow/excel`;
};

export const generateDetailedCashflow = (assetId) => {
  return `/assets/${assetId}/reports/cashflowdetail`;
};

export const exportDetailedCashflow = (assetId) => {
  return `/assets/${assetId}/reports/cashflowdetail/excel`;
};

export const updateDetailedCashFlowComment = (assetId) => {
  return `/assets/${assetId}/reports/cashflowdetail/comment`;
};

export const getDetailedCashflowTransactions = (assetId) => {
  return `/assets/${assetId}/reports/cashflowdetail/transactions`;
};

export const getDetailedCashflowBudgetTransactions = (assetId) => {
  return `/assets/${assetId}/reports/cashflowdetail/budget`;
};

export const getBudgetYearList = (assetId) => {
  return `/assets/budget/${assetId}/reports/outturn/years`;
};

export const generateCashFlowDetailOutturnReport = (
  assetId,
  budgetName,
  year
) => {
  return `/assets/${assetId}/reports/cashflowdetail/outturn?budgetName=${budgetName}&year=${year}`;
};

export const generateCashFlowDetailOutturnReportExcel = (
  assetId,
  budgetName,
  year,
  compareToBudget
) => {
  return `/assets/${assetId}/reports/cashflowdetail/outturn/excel?budgetName=${budgetName}&year=${year}&compareToBudget=${compareToBudget}`;
};

export const uploadAssetKPIMasterData = (assetId) => {
  return `/assets/${assetId}/kpimaster/upload`;
};

export const uploadAssetKPIBudget = (assetId) => {
  return `/assets/${assetId}/kpibudget/upload`;
};

export const uploadAssetKPIData = (assetId) => {
  return `/assets/${assetId}/kpidata/upload`;
};

export const getAssetDashboardData = (
  assetId,
  budgetType,
  period,
  fromMonth,
  fromYear,
  toMonth,
  toYear,
  organizationId,
  enableOwnershipView
) => {
  let url = `/assets/${assetId}/dashboard?budgetType=${budgetType}&fromMonth=${fromMonth}&fromYear=${fromYear}&toMonth=${toMonth}&toYear=${toYear}&organizationId=${organizationId}&enableOwnershipView=${enableOwnershipView}`;
  if (period !== null) {
    url += `&period=${period}`;
  }
  return url;
};

export const getAssetBudgetOptions = (assetId) => {
  return `/assets/${assetId}/dashboard/budgetoptions`;
};

export const getAssetDashboardPeriodOptions = (assetId) => {
  return `/assets/${assetId}/dashboard/periodoptions`;
};

export const getTableauToken = () => {
  return "/users/tableau/token";
};

export const generateOneViewData = (assetId) => {
  return `/assets/oneview/${assetId}/generatedata`;
};

export const uploadOneViewData = (assetId) => {
  return `/assets/oneview/${assetId}/uploaddata`;
};

export const createJob = () => {
  return `/jobs/`;
};

export const getJobById = (jobId) => {
  return `/jobs/${jobId}`;
};

export const getLatestJob = (jobType, orgId, assetId) => {
  return `/jobs/${jobType}/latest?${buildQueryParamsString({
    orgId,
    assetId
  })}`;
};

export const getLastRunJobDetails = (jobName) => {
  return `/jobs/${jobName}/lastruntime`;
};

export const getAllInvoices = (orgId, assetId) => {
  if (orgId === undefined && assetId === undefined) {
    return `/assets/invoices`;
  } else if (orgId !== undefined && assetId !== undefined) {
    return `/assets/invoices?orgId=${orgId}&assetId=${assetId}`;
  } else if (orgId !== undefined && assetId === undefined) {
    return `/assets/invoices?orgId=${orgId}`;
  } else if (orgId === undefined && assetId !== undefined) {
    return `/assets/invoices?assetId=${assetId}`;
  }
};

export const exportInvoiceReportExcel = (queryParams) =>
  `/assets/invoices/csv?${queryParams}`;

export const getAllInvoicesPaginatedFilters = (
  orgId,
  assetId,
  page,
  sortBy,
  filter,
  isAsc,
  size = 15
) => {
  let url = `/assets/invoices?page=${page}&size=${size}`;
  if (orgId !== undefined && assetId !== undefined) {
    url += `&orgId=${orgId}&assetId=${assetId}`;
  } else if (orgId !== undefined && assetId === undefined) {
    url += `&orgId=${orgId}`;
  }
  if (sortBy !== undefined) {
    url += `&sortBy=${sortBy}&asc=${isAsc}`;
  }
  if (filter !== undefined) {
    url += `&filter=${encodeURIComponent(filter)}`;
  }
  return url;
};

export const getPurchasePaymentsPaginated = (queryParams) =>
  `/assets/payments/paginated?${queryParams}`;

export const getInvoiceDetailsById = (assetId, invoiceId) => {
  return `/assets/${assetId}/invoices/${invoiceId}`;
};

export const changeInvoiceStatusToDisputeOrUndo = (
  assetId,
  invoiceId,
  inDispute
) => {
  return `/assets/${assetId}/invoices/${invoiceId}/dispute?inDispute=${inDispute}`;
};

export const refreshInvoice = (assetId, invoiceId) => {
  return `/assets/${assetId}/invoices/${invoiceId}/refresh`;
};

export const getInvoiceHistory = (assetId, invoiceId) => {
  return `/assets/${assetId}/invoices/${invoiceId}/history`;
};

export const getAssetUserConfiguration = (assetId) =>
  `/assets/${assetId}/userconfiguration`;

export const setAssetUserConfiguration = (assetId) => {
  return `/assets/${assetId}/userconfiguration`;
};

export const getPurchaseOrderHistoryAndComments = ({
  assetId,
  poId,
  historyAndComments
}) =>
  `/assets/${assetId}/purchaseorders/${poId}/historyandcomments?${buildQueryParamsString(
    { historyAndComments }
  )}`;

export const getMilestonesByAssetAndBudgetLineItemIds = (
  assetId,
  budgetLineItemId
) => {
  return `/assets/${assetId}/contracts/${budgetLineItemId}/milestones`;
};

export const getAzureSasTokenUrl = (url) => {
  return `/azure/sastoken?url=${url}`;
};

export const getOperationalBudgetData = (
  assetId,
  invoiceId,
  supplierCode,
  accountCode
) => {
  if (accountCode && supplierCode) {
    return `/assets/${assetId}/invoices/${invoiceId}/operationalbudget?supplierCode=${supplierCode}&accountCode=${accountCode}`;
  }
  return `/assets/${assetId}/invoices/${invoiceId}/operationalbudget?supplierCode=${supplierCode}`;
};

export const getInvoiceSupplierLineItemAccrualData = (
  assetId,
  invoiceId,
  invoiceLineItemId
) => {
  if (invoiceLineItemId) {
    return `/assets/${assetId}/invoices/${invoiceId}/accrualdata?invoiceLineItemId=${invoiceLineItemId}`;
  }
  return `/assets/${assetId}/invoices/${invoiceId}/accrualdata`;
};

export const putInvoiceEnrichmentData = (assetId, invoiceId, type) => {
  if (type === "operational") {
    // enrichInvoiceWithOperationalBudget
    return `/assets/${assetId}/invoices/${invoiceId}/operational`;
  }
  return `/assets/${assetId}/invoices/${invoiceId}/construction/${type}`;
};

export const getBudgetInfo = (assetId, budgetLineItemId, invoiceId) => {
  return `/assets/${assetId}/contracts/${budgetLineItemId}/budget?invoiceId=${invoiceId}`;
};

export const getCostAccrualDataForPOInvoiceLineItem = (
  assetId,
  id,
  lineItemId
) => {
  return `/assets/${assetId}/purchaseorder/${id}/lineitem/${lineItemId}/costaccruals`;
};

export const uploadPurchaseOrderData = (assetId) => {
  return `/assets/${assetId}/purchaseorder/upload`;
};

export const downloadSamplePurchaseOrderData = () => {
  return `/assets/purchaseorder/upload/sample`;
};

export const getPOUtilisedAmount = (
  assetId,
  purchaseOrderID,
  invoiceId,
  invoiceLineItemId
) => {
  if (invoiceLineItemId && invoiceId) {
    return `/assets/${assetId}/purchaseorder/${purchaseOrderID}/poutilisedamount?invoiceId=${invoiceId}&invoiceLineItemId=${invoiceLineItemId}`;
  }
  if (invoiceId) {
    return `/assets/${assetId}/purchaseorder/${purchaseOrderID}/poutilisedamount?invoiceId=${invoiceId}`;
  }
  return `/assets/${assetId}/purchaseorder/${purchaseOrderID}/poutilisedamount`;
};

export const getInvoiceSupplierLineItemAccruedAmountDetails = (assetId) => {
  if (assetId) {
    return `/assets/${assetId}/invoices/accrualdata`;
  }
};

export const getInvoiceOperationalBudgetIncurredAmountDetails = (assetId) => {
  return `/assets/${assetId}/invoices/operationalbudget`;
};

export const getInvoiceAttachments = ({ assetId, invoiceId }) => {
  return `/assets/${assetId}/invoices/${invoiceId}/documents`;
};

export const uploadInvoiceAttachment = ({ assetId, invoiceId }) => {
  return `/assets/${assetId}/invoices/${invoiceId}/documents`;
};

export const deleteInvoiceAttachment = ({
  assetId,
  invoiceId,
  attachmentId
}) => {
  return `/assets/${assetId}/invoices/${invoiceId}/documents/${attachmentId}`;
};

// export const getBackOfficeApprovers = (assetId, filterMe) => {
//   return `/users/backoffice/payment/approvers?assetId=${assetId}&filterMe=${filterMe}`;
// };

// export const getAssetOwnerApprovers = (assetId) => {
//   return `/users/assetowner/payment/approvers?assetId=${assetId}`;
// }; AK

export const getInvoicePayments = ({ assetId, invoiceId }) => {
  return `/assets/${assetId}/invoices/${invoiceId}/payments`;
};

export const getPaymentById = (assetId, invoiceId, paymentId) => {
  return `/assets/${assetId}/invoices/${invoiceId}/payments/${paymentId}`;
};

export const getAllPayments = (orgId, assetId) => {
  if (orgId === undefined && assetId === undefined) {
    return `/assets/payments`;
  } else if (orgId !== undefined && assetId !== undefined) {
    return `/assets/payments?orgId=${orgId}&assetId=${assetId}`;
  } else if (orgId !== undefined && assetId === undefined) {
    return `/assets/payments?orgId=${orgId}`;
  } else if (orgId === undefined && assetId !== undefined) {
    return `/assets/payments?assetId=${assetId}`;
  }
};

export const getPaymentHistory = (assetId, invoiceId, paymentId) => {
  return `/assets/${assetId}/invoices/${invoiceId}/payments/${paymentId}/history`;
};

export const createPayment = (assetId, invoiceId) => {
  return `/assets/${assetId}/invoices/${invoiceId}/payments`;
};

// GET
export const getPaymentOutstanding = (assetId, invoiceId) => {
  return `/assets/${assetId}/invoices/${invoiceId}/payments/outstanding`;
};

// export const createNonInvoicePayment = (assetId) => {
//   return `/assets/${assetId}/payments`;
// }; AK

export const updatePayment = (assetId, invoiceId, paymentId) => {
  return `/assets/${assetId}/invoices/${invoiceId}/payments/${paymentId}`;
};

export const getInvoiceComments = (assetId, invoiceId) => {
  return `/assets/${assetId}/invoices/${invoiceId}/comments`;
};

export const postInvoiceComments = (assetId, invoiceId) => {
  return `/assets/${assetId}/invoices/${invoiceId}/comments`;
};

export const rejectPayment = (assetId, paymentId) => {
  return `/assets/${assetId}/payments/${paymentId}/reject`;
};

export const voidPayment = (assetId, paymentId) => {
  return `/assets/${assetId}/payments/${paymentId}/void`;
};

export const getAssetBankAccountBalanceData = (assetId) => {
  return `/assets/${assetId}/bankaccountbalance`;
};

export const approvePaymentByAssetOwner = (assetId) => {
  return `/assets/${assetId}/payments/approve`;
};

export const validatePurchaseInvoiceInterCompanyPayments = (assetId) =>
  `/assets/${assetId}/purchase/invoices/payments/approve/validation`;

export const getPaymentByIdForListing = (assetId, paymentId) => {
  return `/assets/${assetId}/payments/${paymentId}`;
};

export const getOperationalCostsGLCodesForAsset = (assetId) => {
  return `/assets/masterdata/${assetId}/operationalcostglcodes`;
};

export const getGlCodes = (assetId) => {
  return `/assets/masterdata/${assetId}/glcodes`;
};

export const getInvoiceDocuments = () => {
  return `/documents/`;
};

export const getInvoiceDocumentsPaginatedFilters = ({
  page,
  sortBy,
  filter,
  asc,
  size = 15
}) => {
  let url = `/documents/paginated?page=${page}&size=${size}`;
  if (sortBy !== undefined) {
    url += `&sortBy=${sortBy}&asc=${asc}`;
  }
  if (filter !== undefined) {
    url += `&filter=${filter}`;
  }
  return encodeURI(url);
};

export const getSuppliersForAsset = (assetId) => {
  return `/assets/${assetId}/suppliers`;
};

export const getSuppliers = (orgId, assetId) => {
  if (orgId === undefined && assetId === undefined) {
    return `/suppliers/`;
  } else if (orgId !== undefined && assetId !== undefined) {
    return `/suppliers/?orgId=${orgId}&assetId=${assetId}`;
  } else if (orgId !== undefined && assetId === undefined) {
    return `/suppliers/?orgId=${orgId}`;
  } else if (orgId === undefined && assetId !== undefined) {
    return `/suppliers/?assetId=${assetId}`;
  }
};

export const getTaxCodeListFromAIQ = (assetId) => {
  return `/assets/${assetId}/taxcodes`;
};

export const createInvoice = (assetId) => {
  return `/assets/${assetId}/invoices/`;
};

export const getInvoicesForAsset = (assetId) => {
  return `/assets/${assetId}/invoices/`;
};

export const getPuchaseInvoicesForAsset = (assetId, supplierId, currency) => {
  return `/assets/${assetId}/purchaseinvoices?${buildQueryParamsString({
    supplierId,
    currency
  })}`;
};
export const getMappingDetailsForAllocations = (
  assetId,
  invoiceId,
  supplierId
) => {
  return `/assets/${assetId}/invoices/${invoiceId}/allocation/mappingdata?${buildQueryParamsString(
    {
      supplierId
    }
  )}`;
};

export const getDeletePurchaseInvoice = (assetId, invoiceId, allocationId) => {
  return `/assets/${assetId}/invoices/${invoiceId}/allocation/${allocationId}`;
};

export const deletePurchaseCreditNoteReceipt = (
  assetId,
  purchaseCreditNoteId,
  receiptId
) => {
  return `/assets/${assetId}/purchasecreditnotes/${purchaseCreditNoteId}/receipts/${receiptId}`;
};

export const getPurchaseCreditNoteReceipts = (
  assetId,
  purchaseCreditNoteId
) => {
  return `/assets/${assetId}/purchasecreditnotes/${purchaseCreditNoteId}/receipts`;
};

export const getSaveAllocationsUrl = (assetId, invoiceId) => {
  return `/assets/${assetId}/invoices/${invoiceId}/allocation`;
};

export const updateInvoice = (assetId, invoiceId) => {
  return `/assets/${assetId}/invoices/${invoiceId}`;
};

export const rejectDocument = () => {
  return `/documents/reject`;
};

export const holdDocument = () => {
  return `/documents/onhold`;
};

export const undoOnHoldDocument = () => {
  return `/documents/undoonhold`;
};

export const rejectInvoice = (assetId, invoiceId) => {
  return `/assets/${assetId}/invoices/${invoiceId}/reject`;
};

export const getGLCodesForAssetForNonInvoicePayment = (assetId) => {
  return `/assets/masterdata/${assetId}/glcodes?nonInvoice=${true}`;
};

export const renameInvoiceDocument = (assetId, invoiceId, documentId) => {
  return `/assets/${assetId}/invoices/${invoiceId}/documents/${documentId}/rename`;
};

export const getInvoiceOperationalBudgetData = ({
  assetId,
  invoiceId,
  budgetLineItemId,
  accountId
}) => {
  return `/assets/${assetId}/invoices/${invoiceId}/operationalbudget?${buildQueryParamsString(
    {
      accountId,
      budgetLineItemId
    }
  )}`;
};

export const accrualdata = ({
  assetId,
  invoiceId,
  accountId,
  invoicePeriodFrom,
  invoicePeriodTo,
  showAllAccruals,
  budgetLineItemId,
  financialPeriodEndDate
}) => {
  return `/assets/${assetId}/invoices/${invoiceId}/accrualdata?${buildQueryParamsString(
    {
      accountId,
      invoicePeriodFrom,
      invoicePeriodTo,
      showAllAccruals,
      budgetLineItemId,
      financialPeriodEndDate
    }
  )}`;
};

export const putOperationInvoiceEnrichmentData = (assetId, invoiceId) => {
  return `/assets/${assetId}/invoices/${invoiceId}/operational`;
};

export const assetDataReleaseStatus = (assetId) => {
  return `/assets/${assetId}/datastatus`;
};

export const getAllPaymentsAwaitingPayment = (orgId, assetId) => {
  if (orgId === undefined && assetId === undefined) {
    return `/assets/payments/awaitingpayment`;
  } else if (orgId !== undefined && assetId !== undefined) {
    return `/assets/payments/awaitingpayment?orgId=${orgId}&assetId=${assetId}`;
  } else if (orgId !== undefined && assetId === undefined) {
    return `/assets/payments/awaitingpayment?orgId=${orgId}`;
  } else if (orgId === undefined && assetId !== undefined) {
    return `/assets/payments/awaitingpayment?assetId=${assetId}`;
  }
};

export const getAllPaymentsPaymentProcessed = (orgId, assetId) => {
  if (orgId === undefined && assetId === undefined) {
    return `/assets/payments/paymentprocessed`;
  } else if (orgId !== undefined && assetId !== undefined) {
    return `/assets/payments/paymentprocessed?orgId=${orgId}&assetId=${assetId}`;
  } else if (orgId !== undefined && assetId === undefined) {
    return `/assets/payments/paymentprocessed?orgId=${orgId}`;
  } else if (orgId === undefined && assetId !== undefined) {
    return `/assets/payments/paymentprocessed?assetId=${assetId}`;
  }
};

export const createPayRun = () => {
  return `/payruns/`;
};

export const getPayRuns = (orgId, assetId) => {
  if (orgId === undefined && assetId === undefined) {
    return `/payruns/`;
  } else if (orgId !== undefined && assetId !== undefined) {
    return `/payruns/?orgId=${orgId}&assetId=${assetId}`;
  } else if (orgId !== undefined && assetId === undefined) {
    return `/payruns/?orgId=${orgId}`;
  } else if (orgId === undefined && assetId !== undefined) {
    return `/payruns/?assetId=${assetId}`;
  }
};

export const reversePayRun = (payRunId) => `/payruns/${payRunId}`;

export const getPayRun = (payRunId, orgId, assetId) => {
  if (orgId === undefined && assetId === undefined) {
    return `/payruns/${payRunId}/`;
  } else if (orgId !== undefined && assetId !== undefined) {
    return `/payruns/${payRunId}/?orgId=${orgId}&assetId=${assetId}`;
  } else if (orgId !== undefined && assetId === undefined) {
    return `/payruns/${payRunId}/?orgId=${orgId}`;
  } else if (orgId === undefined && assetId !== undefined) {
    return `/payruns/${payRunId}/?assetId=${assetId}`;
  }
};

export const downloadPayRun = (payRunId) => {
  return `/payruns/${payRunId}/download`;
};

export const exportPayRun = (payRunId, assetAccountId, assetId) => {
  return `/payruns/${payRunId}/excel?${buildQueryParamsString({
    assetAccountId,
    assetId
  })}`;
};

export const getPurchaseOrders = (orgId, assetId) => {
  if (orgId === undefined && assetId === undefined) {
    return `/assets/purchaseorders`;
  } else if (orgId !== undefined && assetId !== undefined) {
    return `/assets/purchaseorders/?orgId=${orgId}&assetId=${assetId}`;
  } else if (orgId !== undefined && assetId === undefined) {
    return `/assets/purchaseorders/?orgId=${orgId}`;
  } else if (orgId === undefined && assetId !== undefined) {
    return `/assets/purchaseorders/?assetId=${assetId}`;
  }
};

export const getPurchasedOrdersPaginated = ({
  orgId,
  assetId,
  page,
  sortBy,
  filter,
  isAsc,
  size = 15
}) =>
  `/assets/purchaseorders/paginated?${buildQueryParamsString({
    orgId,
    assetId,
    page,
    sortBy,
    filter,
    asc: isAsc,
    size
  })}`;

export const bankAccountDetails = (assetId) => {
  return `/assets/${assetId}/bankaccountdetails`;
};

export const getBeneficiaries = (assetId) => {
  return `/assets/${assetId}/beneficiaries`;
};

export const getSupplierBankAccounts = (assetId, invoiceId) => {
  return `/assets/${assetId}/invoices/${invoiceId}/supplierbankaccount`;
};

export const getAssetUsers = (assetId, privilege) => {
  return `/users/assets/${assetId}?${buildQueryParamsString({ privilege })}`;
};

export const getUsersForAllAssetOrOrg = (queryParams) => {
  return `/users?${queryParams}`;
};

//GET
export const getUserByEmail = (email) => {
  return `/users/email?${buildQueryParamsString({ email })}`;
};

export const createPO = (assetId) => {
  return `/assets/${assetId}/purchaseorders`;
};

export const updatePurchaseOrder = (assetId, poId) =>
  `/assets/${assetId}/purchaseorders/${poId}`;

export const getPurchaseOrderComments = (assetId, poId) => {
  return `/assets/${assetId}/purchaseorders/${poId}/comments`;
};

export const postPurchaseOrderComments = ({ assetId, poId }) => {
  return `/assets/${assetId}/purchaseorders/${poId}/comments`;
};

export const getPurchaseOrderAttachments = (assetId, poId) => {
  return `/assets/${assetId}/purchaseorders/${poId}/documents`;
};

export const uploadPurchaseOrderAttachment = ({ assetId, poId }) => {
  return `/assets/${assetId}/purchaseorders/${poId}/documents`;
};

export const deletePurchaseOrderAttachment = ({
  assetId,
  poId,
  attachmentId
}) => {
  return `/assets/${assetId}/purchaseorders/${poId}/documents/${attachmentId}`;
};

export const getPurchaseOrder = (assetId, poId) =>
  `/assets/${assetId}/purchaseorders/${poId}`;

export const getPOAttachments = ({ assetId, poId }) =>
  `/assets/${assetId}/purchaseorders/${poId}/documents`;

export const deleteCommentInPO = ({ assetId, poId }) =>
  `assets/${assetId}/purchaseorders/${poId}/comments`;

export const editCommentInPO = ({ assetId, poId }) =>
  `assets/${assetId}/purchaseorders/${poId}/comments`;

export const getGLCodesForAsset = (assetId) =>
  `/assets/masterdata/${assetId}/glcodes/?categories=${encodeURIComponent(
    "OPERATIONAL COSTS,REVENUE"
  )}`;

export const getAllGLCodesForAsset = (assetId) =>
  `/assets/masterdata/${assetId}/bankrecon/glcodes`;

export const returnPO = (
  assetId,
  poId // Put request
) => `/assets/${assetId}/purchaseorders/${poId}/return`;

export const cancelPO = (
  assetId,
  poId // Put request
) => `/assets/${assetId}/purchaseorders/${poId}/cancel`;

export const editApprovedPO = (
  assetId,
  poId // Put request
) => `/assets/${assetId}/purchaseorders/${poId}`;

export const getPOGoodsReceivedList = (assetId, poId) => {
  return `/assets/${assetId}/purchaseorders/${poId}/goods`;
};

export const createPOGoodsReceived = (assetId, poId) => {
  return `/assets/${assetId}/purchaseorders/${poId}/goods`;
};

export const updatePOGoodsReceived = (assetId, poId, poGoodsReceivedId) => {
  return `/assets/${assetId}/purchaseorders/${poId}/goods/${poGoodsReceivedId}`;
};

export const deletePOGoodsReceived = (assetId, poId, poGoodsReceivedId) => {
  return `/assets/${assetId}/purchaseorders/${poId}/goods/${poGoodsReceivedId}`;
};

export const getPurchaseOrdersForAssetAndSupplier = ({
  assetId,
  currencyCode,
  supplierId
}) => {
  return `/assets/${assetId}/purchaseorders?${buildQueryParamsString({
    currencyCode,
    supplierId
  })}`;
};

export const getPOAvailableAmount = (assetId, poId) => {
  return `/assets/${assetId}/purchaseorders/${poId}/poavailableamount`;
};

export const viewBudgetData = (assetId, poId) =>
  `/assets/${assetId}/purchaseorders/${poId}/budgetview`;

export const downloadPurchaseOrder = (assetId, poId) => {
  return `/assets/${assetId}/purchaseorders/${poId}/pdf`;
};

export const sendPODocumentToSupplier = (assetId, id) => {
  return `/assets/${assetId}/purchaseorders/${id}/pdf/email`;
};

export const getPOEstimatedInvoices = (assetId, poId) => {
  return `/assets/${assetId}/purchaseorders/${poId}/estimatedinvoices`;
};

export const cancelInvoice = (assetId, invoiceId) => {
  return `/assets/${assetId}/invoices/${invoiceId}/cancel`;
};

export const voidInvoice = (assetId, invoiceId) => {
  return `/assets/${assetId}/invoices/${invoiceId}/void`;
};

export const markAsReceivedAction = (assetId, paymentId) =>
  `/assets/${assetId}/payments/${paymentId}/markasreceived`;

export const undoRejectDocument = () => `/documents/undoreject`; // PUT

export const getDocumentDetails = (queryParams) =>
  `/documents/linked?${queryParams}`;

export const resolveDispute = (assetId, invoiceId) =>
  `/assets/${assetId}/invoices/${invoiceId}/resolvedispute`;

export const getAssetBankAccounts = (queryParams) => {
  return `/assets/bankaccounts/?${queryParams}`;
};

export const connectToTrueLayer = (assetId, assetBankAccountId) => {
  return `/assets/bankaccounts/${assetId}/${assetBankAccountId}/connecttotruelayer`;
};

export const syncTransactions = (
  assetId,
  assetBankAccountId,
  fromDate,
  toDate
) => {
  return `/assets/bankaccounts/${assetId}/${assetBankAccountId}/synctransactions?${buildQueryParamsString(
    {
      fromDate,
      toDate
    }
  )}`;
};

export const uploadBankStatement = (assetId, assetBankAccountId) => {
  return `/assets/bankaccounts/${assetId}/${assetBankAccountId}/statement/upload`;
};

export const downloadSampleBankStatement = () => {
  return `/assets/bankaccounts/statement/sample`;
};

export const getBankBalance = (assetId, assetBankAccountId, month, year) => {
  return `/assets/bankaccounts/${assetId}/${assetBankAccountId}/balance?month=${month}&year=${year}`;
};

export const getBankStatementLineItems = (
  assetId,
  assetBankAccountId,
  page,
  sortBy,
  filter,
  isAsc,
  size = 15
) => {
  let url = `/assets/bankaccounts/${assetId}/${assetBankAccountId}/statement/lineitems?page=${page}&size=${size}`;
  if (sortBy !== undefined) {
    url += `&sortBy=${sortBy}&asc=${isAsc}`;
  }
  if (filter !== undefined) {
    url += `&filter=${filter}`;
  }
  return encodeURI(url);
};

export const exportBankStatementLineItems = (
  assetId,
  assetBankAccountId,
  queryParams
) => {
  return `/assets/bankaccounts/${assetId}/${assetBankAccountId}/statement/lineitems/export?${queryParams}`;
};

export const getBankaccountDetailsById = (assetId, assetBankAccountId) => {
  return `/assets/bankaccounts/${assetId}/${assetBankAccountId}/`;
};

export const uploadTrialBalances = (assetId) => {
  return `/assets/${assetId}/trialbalance/upload`;
};

export const downloadSampleTrialBalance = () => {
  return `/assets/trialbalance/sample`;
};

export const getAllJournalsPaginatedFilters = (queryParams) => {
  return `/assets/journals?${queryParams}`;
};

export const getAllCurrencyRates = ({ baseCurrencyCode, exchangeDate }) =>
  `/currency/all?${buildQueryParamsString({
    baseCurrencyCode,
    exchangeDate
  })}`;

export const convertCurrency = ({
  amount,
  fromCurrencyCode,
  toCurrencyCode,
  exchangeDate
}) =>
  `/currency/convert?${buildQueryParamsString({
    amount,
    exchangeDate,
    fromCurrencyCode,
    toCurrencyCode
  })}`;

export const createJournal = (assetId) => {
  return `/assets/${assetId}/journals`;
};

export const updateJournal = (assetId, journalId) => {
  return `/assets/${assetId}/journals/${journalId}`;
};

export const getJournalDetails = (assetId, journalId) => {
  return `/assets/${assetId}/journals/${journalId}`;
};

export const getPotentialExchangeRateForJournal = (
  assetId,
  journalCurrency,
  journalType,
  transactionDate
) => {
  return `/assets/${assetId}/journals/exchangerate?${buildQueryParamsString({
    journalCurrency,
    journalType,
    transactionDate
  })}`;
};

export const getRepeatingJournalDetails = (assetId, journalId) => {
  return `/assets/${assetId}/repeatingjournals/${journalId}`;
};

export const updateJournalDetails = (assetId, journalId) => {
  return `/assets/${assetId}/journals/${journalId}`;
};

export const cancelManualJournal = (assetId, journalId) => {
  return `/assets/${assetId}/journals/${journalId}/cancel`;
};

export const returnManualJournalToDraft = (assetId, journalId) => {
  return `/assets/${assetId}/journals/${journalId}/return`;
};

export const revertManualJournal = (assetId, journalId) => {
  return `/assets/${assetId}/journals/${journalId}/revert`;
};

export const downloadJournalSampleTemplate = () => {
  return `/assets/journals/sample`;
};

export const uploadJournalCSVFile = (
  assetId,
  isOverwritePreviouslyImportedJournal
) => {
  return `/assets/${assetId}/journals/upload?${buildQueryParamsString({
    isOverwritePreviouslyImportedJournal
  })}`;
};

export const getAllRepeatingJournalsPaginatedFilters = (queryParams) => {
  return `/assets/repeatingjournals?${queryParams}`;
};

export const createRepeatingJournal = (assetId) => {
  return `/assets/${assetId}/repeatingjournals`;
};

export const updateRepeatingJournal = (assetId, journalId) => {
  return `/assets/${assetId}/repeatingjournals/${journalId}`;
};

export const cancelRepeatingJournal = (assetId, journalId) => {
  return `/assets/${assetId}/repeatingjournals/${journalId}/cancel`;
};

export const downloadSampleTaggedInvoice = () => {
  return `/assets/taggedinvoices/sample`;
};

export const uploadTaggedInvoices = (assetId) => {
  return `/assets/${assetId}/taggedinvoices/upload`;
};

export const getAllProjectInvoices = (queryParams) =>
  `/assets/taggedinvoices?${queryParams}`;

export const getPSAReport = (queryParams) => {
  return `/assets/projectspendanalysis?${queryParams}`;
};

export const getEVAReport = (queryParams) => `/assets/evareport?${queryParams}`;

export const getMinimumStartDateForContracts = (queryParams) =>
  `/assets/contracts/date?${queryParams}`;

export const getEVAInvoice = ({ assetId, invoiceId, getLinks }) => {
  if (getLinks) {
    return `/assets/${assetId}/taggedinvoices/${invoiceId}?getLinks=true&${getLinks}`;
  } else {
    return `/assets/${assetId}/taggedinvoices/${invoiceId}`;
  }
};

export const getRealisedContingency = ({ assetId }) =>
  `/assets/${assetId}/realizedcontingency`;

export const setRealisedContingencyOpeningBalance = ({
  assetId,
  contingencyOpeningBalance
}) =>
  `/assets/${assetId}/contingencyopeningbalance?contingencyOpeningBalance=${contingencyOpeningBalance}`;

export const getUnRealisedContingencies = ({ assetId }) =>
  `/assets/${assetId}/unrealizedcontingency`;

export const addUpdateUnrealisedContingencies = ({ assetId }) =>
  `/assets/${assetId}/unrealizedcontingency`;

export const updateProjectInvoice = ({ assetId, invoiceId }) =>
  `/assets/${assetId}/taggedinvoices/${invoiceId}`;

export const assignInvoice = ({ assetId, invoiceId }) =>
  `/assets/${assetId}/taggedinvoices/${invoiceId}/assign`;

export const archiveInvoice = ({ assetId, invoiceId }) =>
  `/assets/${assetId}/taggedinvoices/${invoiceId}/archive`;

export const archiveTaggingInvoice = ({ assetId, invoiceId }) =>
  `assets/${assetId}/taggedinvoices/${invoiceId}/status?taggingStatus=ARCHIVED`;

export const unarchiveTaggingInvoice = ({ assetId, invoiceId }) =>
  `/assets/${assetId}/taggedinvoices/${invoiceId}/status?taggingStatus=NOT_TAGGED `;

export const tagOrUpdateTaggingInvoice = ({ assetId, invoiceId }) =>
  `/assets/${assetId}/taggedinvoices/${invoiceId}/tags`;

export const returnToDraftInvoice = ({ assetId, invoiceId }) =>
  `/assets/${assetId}/taggedinvoices/${invoiceId}/return`;

export const getContractsForContact = ({ assetId, contactId }) =>
  `/assets/${assetId}/contracts?${buildQueryParamsString({ contactId })}`;

export const getContractEarnedAndPlannedValues = ({ assetId, contractId }) =>
  `/assets/${assetId}/contract/${contractId}/earned`;

export const updateContractEarnedAndPlannedValues = ({ assetId, contractId }) =>
  `/assets/${assetId}/contract/${contractId}/earned`;

export const getProjectInvoiceAttachments = ({ assetId, invoiceId }) =>
  `/assets/${assetId}/taggedinvoices/${invoiceId}/documents`;

export const uploadProjectInvoiceAttachment = ({ assetId, invoiceId }) =>
  `/assets/${assetId}/taggedinvoices/${invoiceId}/documents`;

export const deleteProjectInvoiceAttachment = ({
  assetId,
  invoiceId,
  documentId
}) =>
  `/assets/${assetId}/taggedinvoices/${invoiceId}/documents/${documentId}
  `;

export const getAllContracts = (queryParams) =>
  `/assets/contracts/all?${queryParams}`;

export const getProjectInvoiceHistoryAndComments = ({ assetId, invoiceId }) =>
  `/assets/${assetId}/taggedinvoices/${invoiceId}/historyandcomments`;

export const addProjectInvoiceComments = ({ assetId, invoiceId }) =>
  `/assets/${assetId}/taggedinvoices/${invoiceId}/comments`;

export const getBankStatementLineItem = (
  assetId,
  assetBankAccountId,
  assetBankAccountStatementLineItemId
) => {
  return `/assets/bankaccounts/${assetId}/${assetBankAccountId}/statement/lineitem/${assetBankAccountStatementLineItemId}/`;
};

export const submitStatementLineItemForApproval = ({
  assetId,
  assetBankAccountId,
  assetBankAccountStatementLineItemId
}) =>
  `/assets/bankaccounts/${assetId}/${assetBankAccountId}/statement/lineitem/${assetBankAccountStatementLineItemId}/`;

export const voidLineItem = ({
  assetId,
  assetBankAccountId,
  assetBankAccountStatementLineItemId
}) =>
  `/assets/bankaccounts/${assetId}/${assetBankAccountId}/statement/lineitem/${assetBankAccountStatementLineItemId}/void`;

// POST
export const unreconcileLineItem = ({
  assetId,
  assetBankAccountId,
  assetBankAccountStatementLineItemId
}) =>
  `/assets/bankaccounts/${assetId}/${assetBankAccountId}/statement/lineitem/${assetBankAccountStatementLineItemId}/unreconcile`;

export const returnToDraft = ({
  assetId,
  assetBankAccountId,
  assetBankAccountStatementLineItemId
}) =>
  `/assets/bankaccounts/${assetId}/${assetBankAccountId}/statement/lineitem/${assetBankAccountStatementLineItemId}/returntodraft`;

export const getBankReconHistoryAndComments = ({
  assetId,
  assetBankAccountId,
  assetBankAccountStatementLineItemId,
  historyAndComments = COMMENTS_TYPES.ALL
}) => {
  return `/assets/bankaccounts/${assetId}/${assetBankAccountId}/statement/lineitem/${assetBankAccountStatementLineItemId}/historyandcomments?${buildQueryParamsString(
    {
      historyAndComments
    }
  )}`;
};

export const addCommentInBankRecon = ({
  assetId,
  assetBankAccountId,
  assetBankAccountStatementLineItemId
}) => {
  return `/assets/bankaccounts/${assetId}/${assetBankAccountId}/statement/lineitem/${assetBankAccountStatementLineItemId}/comments`;
};

export const updateCommentInBankRecon = ({
  assetId,
  assetBankAccountId,
  assetBankAccountStatementLineItemId
}) =>
  `/assets/bankaccounts/${assetId}/${assetBankAccountId}/statement/lineitem/${assetBankAccountStatementLineItemId}/comments`;

export const deleteCommentInBankRecon = ({
  assetId,
  assetBankAccountId,
  assetBankAccountStatementLineItemId
}) =>
  `/assets/bankaccounts/${assetId}/${assetBankAccountId}/statement/lineitem/${assetBankAccountStatementLineItemId}/comments`;

export const getJournalHistoryAndComments = ({
  assetId,
  journalId,
  historyAndComments
}) => {
  return `/assets/${assetId}/journals/${journalId}/historyandcomments?${buildQueryParamsString(
    { historyAndComments }
  )}`;
};

export const addJournalComment = ({ assetId, journalId }) => {
  return `/assets/${assetId}/journals/${journalId}/comments`;
};

export const updateJournalComment = ({ assetId, journalId }) =>
  `/assets/${assetId}/journals/${journalId}/comments`;

export const deleteJournalComment = ({ assetId, journalId }) =>
  `/assets/${assetId}/journals/${journalId}/comments`;

export const getJournalAttachments = ({ assetId, journalId }) => {
  return `/assets/${assetId}/journals/${journalId}/documents`;
};

export const getRepeatingJournalAttachments = (assetId, journalId) => {
  return `/assets/${assetId}/repeatingjournals/${journalId}/documents`;
};

export const getDeleteJournalAttachmentUrl = ({
  assetId,
  journalId,
  attachmentId
}) => {
  return `/assets/${assetId}/journals/${journalId}/documents/${attachmentId}`;
};

export const getUploadJournalAttachmentUrl = (
  { assetId, journalId } // POST
) => `/assets/${assetId}/journals/${journalId}/documents`;

export const getUploadRepeatingJournalAttachmentUrl = (
  { assetId, journalId } // POST
) => `/assets/${assetId}/repeatingjournals/${journalId}/documents`;

export const getDeleteRepeatingJournalAttachmentUrl = (
  assetId,
  journalId,
  id
) => {
  return `/assets/${assetId}/repeatingjournals/${journalId}/documents/${id}`;
};

export const approveLineItem = ({
  assetId,
  assetBankAccountId,
  assetBankAccountStatementLineItemId
}) =>
  `/assets/bankaccounts/${assetId}/${assetBankAccountId}/statement/lineitem/${assetBankAccountStatementLineItemId}/approve`;

export const nonGLPaymentDataFile = (assetId) =>
  `/assets/nonglpaymentdata/${assetId}/upload`; // POST

export const downloadSampleNonGlPayment = () =>
  `/assets/nonglpaymentdata/sample`;

export const getBankStatement = ({
  // GET
  assetBankAccountId,
  assetBankAccountStatementId,
  assetId
}) =>
  `/assets/bankaccounts/${assetId}/${assetBankAccountId}/statement/${assetBankAccountStatementId}`;

export const deleteBankStatement = ({
  // DELETE
  assetBankAccountId,
  assetBankAccountStatementId,
  assetId,
  lineItemsToDelete
}) =>
  `/assets/bankaccounts/${assetId}/${assetBankAccountId}/statement/${assetBankAccountStatementId}?${buildQueryParamsString(
    { lineItemsToDelete }
  )}`;

export const createPortfolio = () => `/portfolios/`; // POST
export const getAllPortfolio = () => `/portfolios/`; // GET
export const getPortfolioById = (portfolioId) => `/portfolios/${portfolioId}`; // GET
export const deletePortfolio = (portfolioId) => `/portfolios/${portfolioId}`; // DELETE
export const updatePortfolio = (portfolioId) => `/portfolios/${portfolioId}`; // PUT

export const generateVarianceReport = (
  assetIds,
  duration,
  period,
  varianceAbove,
  variancePercentAbove,
  showNegativeVarianceOnly,
  orgId
) => {
  return `/reports/variance?${buildQueryParamsString({
    assetIds,
    duration,
    period,
    varianceAbove,
    variancePercentAbove,
    showNegativeVarianceOnly,
    orgId
  })}`;
};

export const exportVarianceReport = (
  assetIds,
  duration,
  period,
  varianceAbove,
  variancePercentAbove,
  showNegativeVarianceOnly,
  orgId
) => {
  return `/reports/variance/export?${buildQueryParamsString({
    assetIds,
    duration,
    period,
    varianceAbove,
    variancePercentAbove,
    showNegativeVarianceOnly,
    orgId
  })}`;
};

export const addCommentsToVarianceReport = () => {
  return `/reports/variance/comment`;
};

export const getAssetsEarliestAndLatestDates = (orgId) => {
  return `/organizations/${orgId}/dataavailabledates`;
};

export const generateDetailedCashflowForPortfolio = () => {
  return `/assets/portfolios/reports/cashflowdetail`;
};

export const getBankAccountCodesForPortfolioAssets = (portfolioId) => {
  return `/portfolios/${portfolioId}/bankaccountcodes`;
};

export const getPortfolioAssetsDataAvailableFromAndToDate = (portfolioId) => {
  return `/portfolios/${portfolioId}/datastatus/dates`;
};

export const generateIncomeStatementReportForPortfolio = () => {
  return `/assets/portfolios/reports/incomestatement`;
};

export const generateCashflowForPortfolio = () => {
  return `/assets/portfolios/reports/cashflow`;
};

// Sales invoices

export const createSalesInvoice = ({ assetId }) =>
  `/assets/${assetId}/sales/invoices`;

export const getContactsList = ({ assetId, orgId, types = [] }) => {
  const queryString = {
    assetId,
    orgId
  };
  if (types?.length > 0) queryString.types = types.join(",");
  return `/organizations/contacts/list?${buildQueryParamsString(queryString)}`;
};

export const getIntercompanyAccounts = ({ orgId }) =>
  `/assets/glaccounts/intercompanyaccounts?${buildQueryParamsString({
    orgId
  })}`;

export const getTaxList = ({ assetId, orgId, type = null, status }) =>
  `/tax?${buildQueryParamsString({ assetId, orgId, type, status })}`;

export const getBalanceAccrued = ({
  assetId,
  accountId,
  contactId,
  budgetLineItemId,
  periodMonth,
  periodYear
}) =>
  `/assets/${assetId}/accruedbalance?${buildQueryParamsString({
    accountId,
    contactId,
    budgetLineItemId,
    periodMonth,
    periodYear
  })}`;

export const getSalesInvoice = ({ assetId, invoiceId, getLinks }) => {
  if (getLinks) {
    return `/assets/${assetId}/sales/invoices/${invoiceId}?getLinks=true&${getLinks}`;
  }
  return `/assets/${assetId}/sales/invoices/${invoiceId}`;
};

export const updateSalesInvoice = ({ assetId, invoiceId }) =>
  `/assets/${assetId}/sales/invoices/${invoiceId}`;

export const addInvoiceComments = ({ assetId, invoiceId }) =>
  `/assets/${assetId}/sales/invoices/${invoiceId}/comments`;

export const updateInvoiceComments = ({ assetId, invoiceId }) =>
  `/assets/${assetId}/sales/invoices/${invoiceId}/comments`;

export const deleteInvoiceComments = ({ assetId, invoiceId }) =>
  `/assets/${assetId}/sales/invoices/${invoiceId}/comments`;

export const getAttachments = ({ assetId, invoiceId }) =>
  `/assets/${assetId}/sales/invoices/${invoiceId}/documents`;

export const uploadAttachment = (
  { assetId, invoiceId } // POST
) => `/assets/${assetId}/sales/invoices/${invoiceId}/documents`;

export const deleteAttachment = ({ assetId, invoiceId, attachmentId }) =>
  `/assets/${assetId}/sales/invoices/${invoiceId}/documents/${attachmentId}`;

export const getAllGlAccounts = (assetId) =>
  `/assets/glaccounts/all/${assetId}`;

export const getAllSalesInvoices = (queryParams) =>
  `/assets/sales/invoices?${queryParams}`;

export const exportSalesInvoiceReportExcel = (queryParams) =>
  `/assets/sales/invoices/csv?${queryParams}`;

export const returnSalesInvoice = ({ assetId, invoiceId }) =>
  `/assets/${assetId}/sales/invoices/${invoiceId}/return`;

export const cancelSalesInvoice = ({ assetId, invoiceId }) =>
  `/assets/${assetId}/sales/invoices/${invoiceId}/cancel`;

export const voidSalesInvoice = ({ assetId, invoiceId }) =>
  `/assets/${assetId}/sales/invoices/${invoiceId}/void`;

export const getApprovedSalesInvoiceLineItemData = ({
  assetId,
  accountId,
  budgetLineItemId,
  customerId,
  type,
  periodMonth,
  periodYear
}) =>
  `/assets/${assetId}/sales/invoices/approved?${buildQueryParamsString({
    accountId,
    budgetLineItemId,
    customerId,
    type,
    periodMonth,
    periodYear
  })}`;

// Get budget line items - for the budget line item dropdown in the sales invoice
export const getBudgetLineItemsList = ({
  assetId,
  financialYear,
  month,
  glAccountId,
  systemAccountsToInclude = {},
  includeAllSystemAccounts
}) =>
  `/budgets/${assetId}/budgetlineitems?${buildQueryParamsString({
    financialYear,
    month,
    glAccountId,
    ...systemAccountsToInclude,
    includeAllSystemAccounts
  })}`;

export const getUniqueBudgetLineItems = ({ assetId }) =>
  `/budgets/${assetId}/budgetlineitems/unique`;

// Get gl codes list - for the gl code dropdown in the sales invoice
export const getGlCodesList = ({
  assetId,
  glSections = [],
  glTypes = [],
  systemAccountsToInclude = {},
  includeAllSystemAccounts
}) => {
  if (glSections.length > 0 && glTypes.length > 0) {
    return `/assets/glaccounts/all/${assetId}?${new URLSearchParams(
      glSections
        .map((i) => ["glSections", i])
        .concat(Object.entries(systemAccountsToInclude).map(([k, v]) => [k, v]))
        .concat(
          includeAllSystemAccounts !== undefined
            ? [["includeAllSystemAccounts", includeAllSystemAccounts]]
            : []
        )
        .concat([["glTypes", glTypes.join(",")]])
    ).toString()}`;
  }
  return glTypes.length > 0
    ? `/assets/glaccounts/all/${assetId}?${buildQueryParamsString({
        glTypes: glTypes.join(","),
        ...systemAccountsToInclude,
        includeAllSystemAccounts
      })}`
    : `/assets/glaccounts/all/${assetId}?${new URLSearchParams(
        glSections
          .map((i) => ["glSections", i])
          .concat(
            Object.entries(systemAccountsToInclude).map(([k, v]) => [k, v])
          )
          .concat(
            includeAllSystemAccounts !== undefined
              ? [["includeAllSystemAccounts", includeAllSystemAccounts]]
              : []
          )
      ).toString()}`;
};

export const getGlCodesListForCoA = (assetId) => {
  return `/assets/glaccounts/${assetId}/coa`;
};

export const getSalesInvoiceHistoryAndComments = ({
  assetId,
  invoiceId,
  historyAndComments = COMMENTS_TYPES.ALL
}) =>
  `/assets/${assetId}/sales/invoices/${invoiceId}/historyandcomments?${buildQueryParamsString(
    {
      historyAndComments
    }
  )}`;

export const createContact = (orgId) => {
  return `/organizations/${orgId}/contacts`;
};

export const createUnverifiedContact = (orgId) => {
  return `/organizations/${orgId}/contacts/unverified`;
};

export const getAssignContactToAssetUrl = (orgId) => {
  return `/organizations/${orgId}/contacts`;
};

export const updateContact = (orgId, contactId) => {
  return `/organizations/${orgId}/contacts/${contactId}`;
};

export const deleteContactDocument = (contactId, documentId) => {
  return `/organizations/contacts/${contactId}/document/${documentId}`;
};

export const getContacts = (queryParams) => {
  return `/organizations/contacts?${queryParams}`;
};

export const getContactDetails = (contactId) => {
  return `/organizations/contacts/${contactId}`;
};

export const getContactActivityDetails = (orgId, contactId, assetId) => {
  buildQueryParamsString({
    assetId
  });
  return `/organizations/${orgId}/contacts/${contactId}/activity?${buildQueryParamsString(
    {
      assetId
    }
  )}`;
};

export const archiveContact = (orgId, contactId) => {
  return `/organizations/${orgId}/contacts/${contactId}/archive`;
};

export const getContactsExcel = (queryParams) => {
  return `/organizations/contacts/excel?${queryParams}`;
};

export const getOrgBudgets = (orgId) => {
  return `/budgets/${orgId}`;
};

export const createOrgBudget = (orgId) => {
  return `/budgets/${orgId}`;
};

export const editOrgBudget = (orgId, budgetId) => {
  return `/budgets/${orgId}/${budgetId}`;
};

export const getOrgBudgetById = (orgId, budgetId) => {
  return `/budgets/${orgId}/${budgetId}`;
};

export const getAssetBudgets = (assetId) => {
  return `/budgets/assets/${assetId}`;
};

export const getAssetClasses = (orgId, assetId) => {
  return `/organizations/fixedassets?${buildQueryParamsString({
    orgId,
    assetId
  })}`;
};

export const getFixedAssetRegisters = (queryParams) => {
  return `/organizations/fixedassetregisters?${queryParams}`;
};

export const importFixedAssetBlock = (assetId) => {
  return `/organizations/assets/${assetId}/fixedassetregisters`;
};

export const getFixedAssetRegisterDepreciationJournals = (registerId) =>
  `/organizations/fixedassetregisters/${registerId}/journals`;

export const downloadSampleFixedAssetBlock = () => {
  return `/organizations/assets/fixedassetregisters/sample`;
};

export const createOrganizationFixedAssetClass = (orgId) => {
  return `/organizations/${orgId}/fixedassets`;
};

// PUT
export const addAdjustmentJournalsToFixedAssetRegister = (
  assetId,
  registerId
) =>
  `/organizations/assets/${assetId}/fixedassetregisters/${registerId}/adjustmentjournals`;

//GET
export const getFixedAssetRegisterAdjustmentJournals = (assetId, registerId) =>
  `/organizations/assets/${assetId}/fixedassetregisters/${registerId}/adjustmentjournals`;

export const assignGLCodesToFixedAssetClass = (
  assetId,
  id,
  plCodeId,
  balanceSheetCodeId,
  glAccountIds
) => {
  return `/organizations/assets/${assetId}/fixedassets/${id}?${buildQueryParamsString(
    { plCodeId, balanceSheetCodeId, glAccountIds }
  )}`;
};

export const updateFixedAssetRegister = (id) => {
  return `/organizations/fixedassetregisters/${id}`;
};

export const getFixedAssetRegister = (id) => {
  return `/organizations/fixedassetregisters/${id}`;
};

export const uploadAssetBudget = (assetId, budgetId) => {
  return `/budgets/${assetId}/upload`;
};

export const importAssetBudget = (assetId, budgetId) => {
  return `/budgets/${assetId}/${budgetId}/upload`;
};

export const downloadAssetBudgetSampleTemplate = () => {
  return `/budgets/sample`;
};

export const getAssetBudget = (assetId, budgetId) => {
  return `/budgets/assets/${assetId}/${budgetId}`;
};

export const updateAssetBudget = (assetId, budgetId) => {
  return `/budgets/assets/${assetId}/${budgetId}`;
};

export const archiveAssetBudget = (assetId, budgetId) => {
  return `/budgets/assets/${assetId}/${budgetId}/archive`;
};

export const getAssetBudgetHistory = (assetId, budgetId) => {
  return `/budgets/assets/${assetId}/${budgetId}/history`;
};

export const validateAssetBudgetInUseState = (assetId, budgetLineItemId) => {
  return `/budgets/assets/${assetId}/${budgetLineItemId}/isinuse`;
};

export const createSalesReceipt = (assetId, invoiceId) =>
  `/assets/${assetId}/sales/invoices/${invoiceId}/receipts`;

export const getOutstandingSalesReceiptData = (assetId, invoiceId) =>
  `/assets/${assetId}/sales/invoices/${invoiceId}/receipts/outstanding`;

export const getSalesReceiptsForSalesInvoice = (assetId, invoiceId) =>
  `/assets/${assetId}/sales/invoices/${invoiceId}/receipts`;

export const deleteSalesReceipt = ({ assetId, invoiceId, receiptId }) =>
  `/assets/${assetId}/sales/invoices/${invoiceId}/receipts/${receiptId}`;

export const getSalesReceiptData = ({ assetId, invoiceId, receiptId }) =>
  `/assets/${assetId}/sales/invoices/${invoiceId}/receipts/${receiptId}`;

export const downloadSampleBudgetOpeningBalancesTemplate = () => {
  return `/budgets/openingbalance/sample`;
};

export const getAssetBudgetOpeningBalances = (assetId, budgetId) => {
  return `/budgets/assets/${assetId}/${budgetId}/openingbalance`;
};

export const updateAssetBudgetOpeningBalances = (assetId, budgetId) => {
  return `/budgets/assets/${assetId}/${budgetId}/openingbalance`;
};

export const uploadAssetBudgetOpeningBalances = (assetId, budgetId) => {
  return `/budgets/${assetId}/${budgetId}/openingbalance/upload`;
};

export const getAssetTransactions = (assetId, fromDate, toDate) => {
  //return `/assets/${assetId}/transactions/dummy60k`;
  return `assets/${assetId}/transactions?${buildQueryParamsString({
    fromDate,
    toDate
  })}`;
};

export const getOutstandingPaymentDetails = ({ assetId, invoiceId }) =>
  `/assets/${assetId}/sales/invoices/${invoiceId}/payments/outstanding`;

export const getPaymentsForSalesInvoice = ({ assetId, invoiceId }) =>
  `/assets/${assetId}/sales/invoices/${invoiceId}/payments`;

export const createSalesPayment = ({ assetId, invoiceId }) =>
  `/assets/${assetId}/sales/invoices/${invoiceId}/payments`;

export const getSalesPaymentHistory = ({ assetId, paymentId, invoiceId }) =>
  `/assets/${assetId}/sales/invoices/${invoiceId}/payments/${paymentId}/history`;

export const getSalesPayment = ({ assetId, paymentId, invoiceId }) =>
  `/assets/${assetId}/sales/invoices/${invoiceId}/payments/${paymentId}`;

export const updateSalesPayment = ({ assetId, paymentId, invoiceId }) =>
  `/assets/${assetId}/sales/invoices/${invoiceId}/payments/${paymentId}`;

export const addCommentForSalesPayment = ({ assetId, paymentId, invoiceId }) =>
  `/assets/${assetId}/sales/invoices/${invoiceId}/payments/${paymentId}/comments`;

export const rejectSalesPayment = ({ assetId, paymentId }) =>
  `/assets/${assetId}/sales/invoices/payments/${paymentId}/reject`;

export const voidSalesPayment = ({ assetId, paymentId }) =>
  `/assets/${assetId}/sales/invoices/payments/${paymentId}/void`;

export const approveSalesPayment = ({ assetId }) =>
  `/assets/${assetId}/sales/invoices/payments/approve`;

export const validateSalesInvoiceInterCompanyPayments = (assetId) =>
  `/assets/${assetId}/sales/invoices/payments/approve/validation`;

export const getAwaitingApprovalPayments = ({ orgId, assetId }) =>
  `/assets/sales/invoices/payments/awaitingapproval?${buildQueryParamsString({
    orgId,
    assetId
  })}`;

export const getAllSalesPaymentsAwaitingPayment = ({ orgId, assetId }) =>
  `/assets/sales/invoices/payments/awaitingpayment?${buildQueryParamsString({
    orgId,
    assetId
  })}`;

export const getSalesPaymentsForAsset = (assetId) =>
  `/assets/${assetId}/payments`;

export const getSalesPaymentsPaginated = (query) =>
  `/assets/sales/invoices/payments/paginated?${query}`;

export const getBankAccountDetailsForAsset = ({
  assetId,
  includeInterCompanyAccounts
}) => {
  return includeInterCompanyAccounts
    ? `/assets/${assetId}/bankaccounts/?${buildQueryParamsString({
        includeInterCompanyAccounts
      })}`
    : `/assets/${assetId}/bankaccounts/`;
};

export const getContanctBankAccountDetails = (contactId) =>
  `/organizations/contacts/${contactId}/bankaccounts`;

export const getAssetsForContacts = (orgId) => {
  return `/organizations/${orgId}/assets`;
};

export const getAllPaymentsForPayRunCreation = ({ orgId, assetId }) =>
  `/payruns/payments/awaitingpayment?${buildQueryParamsString({
    orgId,
    assetId
  })}`;

export const deleteSalesInvoiceAllocation = ({ assetId, allocationId }) =>
  `/assets/${assetId}/sales/invoices/allocations/${allocationId}`;

export const getAllocationsForSalesInvoice = ({ assetId, invoiceId }) =>
  `/assets/${assetId}/sales/invoices/${invoiceId}/allocations`;

export const allocateSalesInvoiceCreditNote = ({ assetId, invoiceId }) =>
  `/assets/${assetId}/sales/invoices/${invoiceId}/allocations`;

export const getSalesInvoiceCreditNoteDetailsForAllocation = ({
  assetId,
  invoiceId
}) => `/assets/${assetId}/sales/invoices/${invoiceId}/allocations/lineitems`;

export const getCreatePurchaseCreditNoteReceipt = (
  assetId,
  purchaseCreditNoteId
) => {
  return `/assets/${assetId}/purchasecreditnotes/${purchaseCreditNoteId}/receipts`;
};

export const setPurchaseOrderInitialNumber = (
  assetId,
  purchaseOrderInitialNumber,
  purchaseOrderPrependedText
) => {
  return `/assets/${assetId}/purchaseorderinitialnumber?${buildQueryParamsString(
    {
      purchaseOrderInitialNumber,
      purchaseOrderPrependedText
    }
  )}`;
};

export const setSalesInvoiceInitialNumber = (
  assetId,
  salesInvoiceInitialNumber
) => {
  return `/assets/${assetId}/salesinvoiceinitialnumber?${buildQueryParamsString(
    {
      salesInvoiceInitialNumber
    }
  )}`;
};

export const setSalesCreditNoteInitialNumber = (
  assetId,
  salesCreditNoteInitialNumber
) => {
  return `/assets/${assetId}/salescreditnoteinitialnumber?${buildQueryParamsString(
    {
      salesCreditNoteInitialNumber
    }
  )}`;
};

export const getTrialBalancesForMonthAndYear = (assetId, month, year) => {
  return `/assets/${assetId}/reports/trialbalance?${buildQueryParamsString({
    month,
    year
  })}`;
};

export const generateTrialBalanceReportExcel = (assetId, month, year) => {
  return `/assets/${assetId}/reports/trialbalance/excel?${buildQueryParamsString(
    {
      month,
      year
    }
  )}`;
};

export const getTrialBalanceTransactions = (assetId, queryParams) => {
  return `/assets/${assetId}/reports/trialbalance/transactions?${queryParams}`;
};

export const getClosingPeriodForFinancialYear = (assetId, financialYear) => {
  return `/assets/${assetId}/closingperiod?${buildQueryParamsString({
    financialYear
  })}`;
};

export const getFinancialYearsForClosingPeriod = (orgId, assetId) => {
  let url = `/assets/closingperiod/financialyears`;
  if (orgId !== undefined && assetId !== undefined) {
    url += `?orgId=${orgId}&assetId=${assetId}`;
  } else if (orgId !== undefined && assetId === undefined) {
    url += `?orgId=${orgId}`;
  }
  return encodeURI(url);
};

export const updateClosingPeriod = (assetId) => {
  return `/assets/${assetId}/closingperiod`;
};

export const getClosingPeriodsForOrg = (orgId, financialYear) => {
  return `/assets/closingperiod?${buildQueryParamsString({
    orgId,
    financialYear
  })}`;
};

export const bulkUpdateClosingPeriods = (
  orgId,
  assetId,
  closingPeriodModule,
  financialYear
) => {
  return `/assets/closingperiod/all?${buildQueryParamsString({
    orgId,
    assetId,
    closingPeriodModule,
    financialYear
  })}`;
};

export const getGLAccountsForSalesInvoices = (assetId) =>
  `/assets/glaccounts/${assetId}/sales`;

export const getDebtorsAgeingReport = (
  orgId,
  assetId,
  isInvoiceDate,
  period
) => {
  let url = `/assets/reports/debtorsageing`;
  if (orgId !== undefined && assetId !== undefined) {
    url += `?orgId=${orgId}&assetId=${assetId}&isInvoiceDate=${isInvoiceDate}&period=${period}`;
  } else if (orgId !== undefined && assetId === undefined) {
    url += `?orgId=${orgId}&isInvoiceDate=${isInvoiceDate}&period=${period}`;
  }
  return encodeURI(url);
};

export const getDebtorsAgeingDrilldownReport = (
  orgId,
  assetId,
  contactId,
  isInvoiceDate,
  period
) => {
  let url = `/assets/reports/debtorsageing/drilldown/${contactId}`;
  if (orgId !== undefined && assetId !== undefined) {
    url += `?orgId=${orgId}&assetId=${assetId}&isInvoiceDate=${isInvoiceDate}&period=${period}`;
  } else if (orgId !== undefined && assetId === undefined) {
    url += `?orgId=${orgId}&isInvoiceDate=${isInvoiceDate}&period=${period}`;
  }
  return encodeURI(url);
};

export const getCreditorsAgeingReport = (
  orgId,
  assetId,
  isInvoiceDate,
  period
) => {
  let url = `/assets/reports/creditorsageing`;
  if (orgId !== undefined && assetId !== undefined) {
    url += `?orgId=${orgId}&assetId=${assetId}&isInvoiceDate=${isInvoiceDate}&period=${period}`;
  } else if (orgId !== undefined && assetId === undefined) {
    url += `?orgId=${orgId}&isInvoiceDate=${isInvoiceDate}&period=${period}`;
  }
  return encodeURI(url);
};

export const getCreditorsAgeingDrilldownReport = (
  orgId,
  assetId,
  contactId,
  isInvoiceDate,
  period
) => {
  let url = `/assets/reports/creditorsageing/drilldown/${contactId}`;
  if (orgId !== undefined && assetId !== undefined) {
    url += `?orgId=${orgId}&assetId=${assetId}&isInvoiceDate=${isInvoiceDate}&period=${period}`;
  } else if (orgId !== undefined && assetId === undefined) {
    url += `?orgId=${orgId}&isInvoiceDate=${isInvoiceDate}&period=${period}`;
  }
  return encodeURI(url);
};

export const getUtilizationTemplates = (assetId) => {
  return `/assets/${assetId}/utilization/template`;
};

export const getUtilizationTemplate = (assetId, templateId) => {
  return `/assets/${assetId}/utilization/template/${templateId}`;
};

export const archiveUtilizationTemplate = (assetId, templateId) => {
  return `/assets/${assetId}/utilization/template/${templateId}/archive`;
};

export const downloadSalesInvoicePDF = ({ assetId, invoiceId }) =>
  `/assets/${assetId}/sales/invoices/${invoiceId}/pdf`;

export const sendSalesInvoiceDocumentToCustomer = (assetId, id) => {
  return `/assets/${assetId}/sales/invoices/${id}/pdf/email`;
};

export const getPostingPeriod = ({ assetId, instrumentDate, module }) =>
  `/assets/${assetId}/postingperiod?${buildQueryParamsString({
    instrumentDate,
    module
  })}`;

export const generateRemittance = (isSalesPayment, isSendMeEnabled) =>
  `/assets/remittance/generate?${buildQueryParamsString({
    isSalesPayment,
    isSendMeEnabled
  })}`;

export const getPaymentsForRemittance = ({ assetId, orgId }) =>
  `/assets/remittance/payments?${buildQueryParamsString({ assetId, orgId })}`;

export const getSalesPaymentsForRemittance = ({ assetId, orgId }) =>
  `/assets/remittance/salespayments?${buildQueryParamsString({
    assetId,
    orgId
  })}`;

export const dailyReportCheck = (orgId, assetId, jobId) => {
  return `/assets/reports/check?${buildQueryParamsString({
    orgId,
    assetId,
    jobId
  })}`;
};

export const getRevenueCheckData = (orgId, assetId, jobId) => {
  return `/assets/reports/revenuecheck?${buildQueryParamsString({
    orgId,
    assetId,
    jobId
  })}`;
};

export const getPlatformUsers = (page, sortBy, filter, isAsc, size = 15) => {
  let url = `/users/?page=${page}&size=${size}`;
  if (sortBy !== undefined) {
    url += `&sortBy=${sortBy}&asc=${isAsc}`;
  }
  if (filter !== undefined) {
    url += `&filter=${filter}`;
  }
  return encodeURI(url);
};

export const getUser = (userId) => {
  return `/users/${userId}`;
};

export const createPlatformUser = () => {
  return `/users/platform`;
};

export const updatePlatformUser = (userId) => {
  return `/users/${userId}`;
};

export const getRoles = () => {
  return `/roles/`;
};

export const updateRole = (roleId) => {
  return `/roles/${roleId}`;
};

export const getPrivileges = () => {
  return `/privileges/`;
};

export const copyInstrument = ({ assetId }) =>
  `/assets/${assetId}/copyinstrument`;

export const voidAndCreateDraftSalesInvoice = ({ assetId, invoiceId }) =>
  `/assets/${assetId}/sales/invoices/${invoiceId}/voidandsavedraft`;

export const voidAndCreateDraftInvoice = ({ assetId, invoiceId }) =>
  `/assets/${assetId}/invoices/${invoiceId}/voidandsavedraft`;

export const getAssetsUnderPrimaryOrg = ({ orgId, includeIntegratedAssets }) =>
  `/organizations/${orgId}/assets?${buildQueryParamsString({
    includeIntegratedAssets
  })}`;

export const getInvoiceHistoryAndComments = ({
  assetId,
  invoiceId,
  historyAndComments = COMMENTS_TYPES.ALL
}) =>
  `/assets/${assetId}/invoices/${invoiceId}/historyandcomments?${buildQueryParamsString(
    {
      historyAndComments
    }
  )}`;

export const addPurchaseInvoiceComment = ({ assetId, invoiceId }) =>
  `/assets/${assetId}/invoices/${invoiceId}/comments`;

export const updatePurchaseInvoiceComment = ({ assetId, invoiceId }) =>
  `/assets/${assetId}/invoices/${invoiceId}/comments`;

export const deletePurchaseInvoiceComment = ({ assetId, invoiceId }) =>
  `/assets/${assetId}/invoices/${invoiceId}/comments`;

export const getEnabledModulesForFAM = () => `/organizations/modules`;

export const getPrepaymentData = ({ assetId, invoiceId }) =>
  `/assets/${assetId}/invoices/${invoiceId}/prepaymentdata`;

export const approvePurchaseInvoice = ({ assetId, invoiceId }) =>
  `/assets/${assetId}/invoices/${invoiceId}/approve`;
export const createContract = (assetId) => {
  return `/assets/${assetId}/contract`;
};

export const updateContract = (assetId, contractId) =>
  `/assets/${assetId}/contract/${contractId}`;

export const getContracts = (queryParams) => {
  return `/assets/contracts?${queryParams}`;
};

export const getContractTypes = () => {
  return `/assets/contracttypes`;
};

export const getContract = (assetId, contractId) => {
  return `/assets/${assetId}/contract/${contractId}`;
};

export const voidContract = (assetId, contractId) => {
  return `/assets/${assetId}/contract/${contractId}/void`;
};

export const voidAndCreateDraftContract = (assetId, contractId) => {
  return `/assets/${assetId}/contract/${contractId}/voidandsavedraft`;
};

export const getContractMilestones = (assetId, contractId) => {
  return `/assets/${assetId}/contract/${contractId}/milestones`;
};

export const createContractMilestone = (assetId, contractId) => {
  return `/assets/${assetId}/contract/${contractId}/milestones`;
};

export const deleteContractMilestone = (assetId, contractId, milestoneId) => {
  return `/assets/${assetId}/contract/${contractId}/milestones/${milestoneId}`;
};

export const addContractDocuments = (assetId, contractId) => {
  return `/assets/${assetId}/contract/${contractId}/document`;
};

export const getContractDocument = (assetId, contractId, documentId) => {
  return `/assets/${assetId}/contract/${contractId}/document/${documentId}`;
};

export const deleteContractDocument = (assetId, contractId, documentId) => {
  return `/assets/${assetId}/contract/${contractId}/document/${documentId}`;
};

export const saveContractDocumentXFDF = (assetId, contractId, documentId) => {
  //return `/assets/${assetId}/contract/${contractId}/document/${documentId}/codify-pdf`;
  return `/assets/${assetId}/contract/${contractId}/document/${documentId}/xfdf`;
};

export const getContractDocumentAttributes = (
  assetId,
  contractId,
  documentId
) => {
  return `/assets/${assetId}/contract/${contractId}/document/${documentId}/attributes`;
};

export const updateContractDocumentAttributes = (
  assetId,
  contractId,
  documentId,
  section
) => {
  return `/assets/${assetId}/contract/${contractId}/document/${documentId}/attributes?${buildQueryParamsString(
    {
      section
    }
  )}`;
};

export const getAssetInvoices = (assetId, invoiceStatus) =>
  `/assets/${assetId}/invoicelist?${buildQueryParamsString({ invoiceStatus })}`;
export const bulkUpdatePaymentsStatus = () => `/assets/payments/bulk`;
export const getPaymentsForAsset = ({
  assetId,
  paymentStatus,
  isApprovePaymentList
}) =>
  `/assets/${assetId}/payments?${buildQueryParamsString({
    paymentStatus,
    isApprovePaymentList
  })}`;

export const createBulkPayments = (assetId) =>
  `/assets/${assetId}/invoices/bulk-payments`;

export const getPaidOutBankStatementLineItems = ({
  assetBankAccountId,
  assetId
}) =>
  `/assets/bankaccounts/${assetId}/${assetBankAccountId}/statement/lineitems/paidout`;
export const getBankPaidInStatementLineItems = ({
  assetBankAccountId,
  assetId
}) =>
  `/assets/bankaccounts/${assetId}/${assetBankAccountId}/statement/lineitems/paidin`;

// GET
export const getCategories = (assetId) =>
  assetId
    ? `/assets/tagging/categories?assetId=${assetId}`
    : `/assets/tagging/categories`;

// POST
export const createCategory = () => `/assets/tagging/categories`;

// PUT
export const updateCategory = (id) => `/assets/tagging/categories/${id}`;

// GET
export const getCategory = (id) => `/assets/tagging/categories/${id}`;

// PUT
export const assignCategoryToAssets = ({ categoryId, assetIds }) =>
  `/assets/tagging/categories/${categoryId}/assign?${assetIds}`;

// PUT
export const addTaggingCategoryAttribute = (categoryId) =>
  `/assets/tagging/categories/${categoryId}/attributes`;

// PUT
export const updateTaggingCategoryAttributeAndValues = ({
  categoryId,
  attributeId
}) => `/assets/tagging/categories/${categoryId}/attributes/${attributeId}`;

// PUT
export const createOrUpdateAssetTag = (assetId, categoryId) =>
  `/assets/${assetId}/tagging/categories/${categoryId}/tags`;

// GET
export const getAssetTags = (assetId, queryParams) =>
  `/assets/${assetId}/tagging/categories/tags${
    queryParams ? `?${queryParams}` : ""
  }`;
export const createUtilisationTemplate = (assetId) => {
  return `/assets/${assetId}/utilization/template`;
};

// GET
export const getBudgetsForAsset = (assetId) =>
  `/budgets/assets/${assetId}/unique`;

// GET
export const getBudgetLineItemsForAssetBudget = (assetId, assetBudgetIds) =>
  `/budgets/assets/${assetId}/budgetlineitems?assetBudgetIds=${assetBudgetIds}`;

// GET
export const getTaggedBudgetLineItems = (assetId, queryParams) => {
  return `/budgets/assets/${assetId}/tagging/budgetlineitems?${queryParams}`;
};

export const getTaggedBudgetLineItemData = (
  assetId,
  budgetLineItem,
  contactId,
  assetBudgetId
) => {
  return `/budgets/assets/${assetId}/tagging/budgetlineitems/periods?${buildQueryParamsString(
    {
      budgetLineItem,
      contactId,
      assetBudgetId
    }
  )}`;
};

// PUT
export const tagBudgetLineItem = (assetId) => {
  return `/budgets/assets/${assetId}/tagging/budgetlineitems`;
};

// GET
export const getAvailablePaymentsForUtilization = (assetId, queryParams) => {
  return `/assets/${assetId}/utilization/availablePayments?${queryParams}`;
};

// POST
export const createUtilizationRequest = (assetId) => {
  return `/assets/${assetId}/utilization/request`;
};

// PUT
export const updateUtilizationRequest = (assetId, requestId) => {
  return `/assets/${assetId}/utilization/request/${requestId}`;
};

// GET
export const getUtilizationRequests = (assetId, queryParams) => {
  return `/assets/${assetId}/utilization/request?${queryParams}`;
};

// POST
export const previewUtilizationRequest = (assetId) => {
  return `/assets/${assetId}/utilization/request/preview`;
};

// GET
export const getUtilizationRequest = (assetId, requestId) => {
  return `/assets/${assetId}/utilization/request/${requestId}`;
};

// POST
export const approveUtilizationRequest = (assetId, requestId) => {
  return `/assets/${assetId}/utilization/request/${requestId}/approve`;
};

export const createContractTask = (assetId, contractId, documentId) => {
  return `/assets/${assetId}/contract/${contractId}/document/${documentId}/tasks`;
};

export const updateTaskStatus = (assetId, taskId, queryParams) => {
  return `/assets/${assetId}/tasks/${taskId}/status?${queryParams}`;
};

export const bulkUpdateTaskStatus = (queryParams) => {
  return `/assets/tasks/status?${queryParams}`;
};

export const getBudgetFinancialYearDateRange = (queryParams) => {
  return `/budgets/budgetedperiod/dates?${queryParams}`;
};

export const getEAMImportDataPaginatedWithFilter = (orgId, queryParams) => {
  return `/assets/eam/${orgId}?${queryParams}`;
};

export const importEAMData = (orgId) => {
  return `/assets/eam/${orgId}/import`;
};

export const approveEAMData = (orgId) => {
  return `/assets/eam/${orgId}/approve`;
};

export const rejectEAMData = (orgId) => {
  return `/assets/eam/${orgId}/reject`;
};

export const confirmChecksInEAMData = (orgId) => {
  return `/assets/eam/${orgId}/confirmchecks`;
};

export const getTasksPaginatedWithFilter = (queryParams) => {
  return `/assets/tasks?${queryParams}`;
};

export const getTask = (taskId, queryParams) => {
  return `/assets/tasks/${taskId}?${queryParams}`;
};

export const createOrUpdateTask = (assetId) => {
  return `/assets/${assetId}/tasks`;
};

export const generateExcelForTasks = (queryParams) => {
  return `/assets/tasks/excel?${queryParams}`;
};

export const getTaskAttachments = ({ assetId, taskId }) => {
  return `/assets/${assetId}/tasks/${taskId}/documents`;
};

export const getDeleteTaskAttachmentUrl = ({
  assetId,
  taskId,
  attachmentId
}) => {
  return `/assets/${assetId}/tasks/${taskId}/documents/${attachmentId}`;
};

export const getUploadTaskAttachmentUrl = ({ assetId, taskId }) => {
  return `/assets/${assetId}/tasks/${taskId}/documents`;
};

export const getTaskHistoryAndComments = ({
  assetId,
  taskId,
  historyAndComments
}) => {
  return `/assets/${assetId}/tasks/${taskId}/historyandcomments?${buildQueryParamsString(
    { historyAndComments }
  )}`;
};

export const addTaskComment = ({ assetId, taskId }) => {
  return `/assets/${assetId}/tasks/${taskId}/comments`;
};

export const uploadTaskData = () => {
  return `/assets/tasks/upload`;
};

export const downloadSampleTaskData = (areRepeatingTasks) => {
  return `/assets/tasks/upload/sample?areRepeatingTasks=${areRepeatingTasks}`;
};

export const getTaggingDashboardSearchSuggestions = (query, orgId, assetId) => {
  return `/search/suggestions?${buildQueryParamsString({
    query,
    orgId,
    assetId
  })}`;
};

export const getTaggingDashboardSearchData = () => {
  return `/search/`;
};

export const getTaggingDashboardGraphMonthlyData = () => {
  return `/search/monthly`;
};

export const fxRevaluationForAsset = (assetId, date) => {
  return `/gl/reevalfx/${assetId}?date=${date}`;
};

export const getFxRevaluationHistory = (assetId) => {
  return `/gl/reevalfx/${assetId}/history`;
};

export const getCompaniesForIntegration = (orgId) =>
  `/assets/${orgId}/companies/`;

export const getPlatformIntegrationDetail = ({ orgId, assetId }) =>
  `/assets/${assetId}/${orgId}/platform/integration`;

export const savePlatformIntegrationCompany = ({
  orgId,
  assetId,
  companyName,
  platformType
}) =>
  `/assets/${assetId}/${orgId}/platform/company?${buildQueryParamsString({
    companyName,
    platformType
  })}`;

export const sendTrialBalanceDataToExternalSystems = ({
  orgId,
  assetId,
  companyId,
  month,
  year
}) =>
  `/assets/${assetId}/${companyId}/${orgId}/trialbalance/transactions?${buildQueryParamsString(
    {
      month,
      year
    }
  )}`;

export const uploadPlatFormIntegrationGlcodes = ({
  orgId,
  platformIntegrationId
}) =>
  `/assets/${orgId}/platformintegration/${platformIntegrationId}/glcodes/upload`;

// GET
export const getPlatformIntegrationGlCodes = ({
  orgId,
  platformIntegrationId
}) => `/assets/${orgId}/platformintegration/${platformIntegrationId}/glcodes`;

// PUT
export const mapSystemAndPlatformGlCodes = ({ orgId, platformIntegrationId }) =>
  `/assets/${orgId}/platformintegration/${platformIntegrationId}/glcodes`;

export const downloadSampleMSDynamicsGlCode = () =>
  `/assets/platformintegration/glcodes/upload/sample`;

//GET
export const getLatestFXRevaluationDate = (assetId) => {
  return `/gl/reevalfx/${assetId}/date`;
};

//GET
export const getApproverLimitRulesets = (assetId) => {
  return `/assets/${assetId}/approverlimitruleset`;
};

//POST
export const createApproverLimitRulesets = (assetId) => {
  return `/assets/${assetId}/approverlimitruleset`;
};

//GET
export const availableAssetCurrenciesForUse = () => {
  return `/famcurrencyexchange/currencies`;
};

//GET
export const getFamCurrencyExchangeUpdateHistory = (month, year) => {
  return `/famcurrencyexchange/history?month=${month}&year=${year}`;
};

//GET
export const getAllFAMCurrencyExchangeRates = (month, year) => {
  return `/famcurrencyexchange/?month=${month}&year=${year}`;
};

//POST
export const updateFamCurrencyExchangeRates = () => {
  return `/famcurrencyexchange/`;
};

export const getNominalLedgerReport = (
  assetId,
  fromDate,
  toDate,
  glAccountIds
) => {
  return `/assets/${assetId}/reports/nominalledger?${buildQueryParamsString({
    fromDate,
    toDate,
    glAccountIds
  })}`;
};

export const generateExcelForNominalLedgerReport = (
  assetId,
  fromDate,
  toDate,
  glAccountIds
) => {
  return `/assets/${assetId}/reports/nominalledger/excel?${buildQueryParamsString(
    {
      fromDate,
      toDate,
      glAccountIds
    }
  )}`;
};

export const getNominalLedgerTransactions = (
  assetId,
  fxCurrency,
  glAccountId,
  transactionDate
) => {
  return `/assets/${assetId}/reports/nominalledger/fx/drilldown?${buildQueryParamsString(
    {
      fxCurrency,
      glAccountId,
      transactionDate
    }
  )}`;
};

export const getDetailedCreditorsReport = (
  assetId,
  contactIds,
  fromDate,
  toDate
) => {
  return `/assets/${assetId}/reports/detailedcreditors?${buildQueryParamsString(
    {
      contactIds,
      fromDate,
      toDate
    }
  )}`;
};

export const getDetailedCreditorTransactions = (
  assetId,
  contactId,
  transactionDate
) => {
  return `/assets/${assetId}/reports/detailedcreditors/fx/drilldown?${buildQueryParamsString(
    {
      contactId,
      transactionDate
    }
  )}`;
};

export const generateExcelForDetailedCreditorsReport = (
  assetId,
  contactIds,
  fromDate,
  toDate
) => {
  return `/assets/${assetId}/reports/detailedcreditors/excel?${buildQueryParamsString(
    {
      contactIds,
      fromDate,
      toDate
    }
  )}`;
};

//Green byte connection and Power generation
//GET
export const getDataSourceDataForOrg = (orgId) => {
  return `organizations/${orgId}/powergeneration/datasource`;
};

//POST
export const configureDataSource = (orgId) => {
  return `organizations/${orgId}/powergeneration/datasource`;
};

//GET
export const getDeviceDataForAsset = (orgId, assetId) => {
  return `/organizations/${orgId}/assets/${assetId}/powergeneration/devices`;
};

//POST
export const configureAssetDeviceData = (orgId, assetId) => {
  return `/organizations/${orgId}/assets/${assetId}/powergeneration/devices`;
};

//POST
export const syncBudget = (assetId, budgetId) => {
  return `/assets/${assetId}/budgets/${budgetId}/sync`;
};

//POST
export const sendActualAndCommitmentData = (assetId, fromPeriod, toPeriod) => {
  return `/assets/${assetId}/budget/actualcommitment/transactions?${buildQueryParamsString(
    {
      fromPeriod,
      toPeriod
    }
  )}`;
};

//GET
export const getActualAndCommitmentDataSyncHistory = (assetId) => {
  return `/assets/${assetId}/budgets/actual/history`;
};

//GET
export const getActualAndCommitmentDataSyncHistoryJSON = (
  assetId,
  id,
  type
) => {
  return `/assets/${assetId}/budgets/actual/history/${id}/json?${buildQueryParamsString(
    {
      type
    }
  )}`;
};

//GET
export const getRevenueAutomationData = (
  assetId,
  financialYear,
  revenueDataPoint
) => {
  return `/assets/${assetId}/revenueautomation/data?${buildQueryParamsString({
    financialYear,
    revenueDataPoint
  })}`;
};

//PUT
export const updateRevenueAutomationData = (
  assetId,
  financialYear,
  revenueDataPoint
) => {
  return `/assets/${assetId}/revenueautomation/data?${buildQueryParamsString({
    financialYear,
    revenueDataPoint
  })}`;
};

//GET
export const downloadSampleRevenueAutomationData = (revenueDataPoint) => {
  return `/assets/revenueautomation/data/sample?${buildQueryParamsString({
    revenueDataPoint
  })}`;
};

//POST
export const uploadRevenueAutomationDataPoints = (
  assetId,
  financialYear,
  revenueDataPoint,
  consumerPriceIndexId
) => {
  return `/assets/${assetId}/revenueautomation/data/upload?${buildQueryParamsString(
    {
      financialYear,
      revenueDataPoint,
      consumerPriceIndexId
    }
  )}`;
};

//GET
export const getConsumerPriceIndexNames = () => {
  return "/assets/revenueautomation/cpi";
};

//GET
export const getConsumerPriceIndexActualData = (cpiId) => {
  return `/assets/revenueautomation/cpi/${cpiId}/actuals`;
};

//POST
export const configureConsumerPriceIndex = () => {
  return `/assets/revenueautomation/cpi/configure`;
};

//GET
export const getAllGLAccountsAssociatedToContractFinanceBlocks = (assetId) => {
  return `/assets/${assetId}/revenueautomation/glcodes`;
};

//GET
export const fetchRevenueScheduleData = (
  assetId,
  accountId,
  contractId,
  period
) => {
  return `/assets/${assetId}/revenueschedule?${buildQueryParamsString({
    accountId,
    contractId,
    period
  })}`;
};

//GET
export const generateRevenueScheduleExcel = (
  assetId,
  accountId,
  contractId,
  period
) => {
  return `/assets/${assetId}/revenueschedule/export?${buildQueryParamsString({
    accountId,
    contractId,
    period
  })}`;
};

//GET
export const getConsumerPriceIndexData = (assetId, cpiIds, financialYear) => {
  return `/assets/${assetId}/revenueautomation/cpi?${buildQueryParamsString({
    cpiIds,
    financialYear
  })}`;
};

//PUT
export const updateConsumerPriceIndexData = (assetId, financialYear) => {
  return `/assets/${assetId}/revenueautomation/cpi?${buildQueryParamsString({
    financialYear
  })}`;
};

//GET
export const getPowerGenerationData = (assetId, deviceIds, financialYear) => {
  return `/assets/${assetId}/revenueautomation/powergeneration?${buildQueryParamsString(
    {
      deviceIds,
      financialYear
    }
  )}`;
};

//PUT
export const updatePowerGenerationData = (assetId, financialYear) => {
  return `/assets/${assetId}/revenueautomation/powergeneration?${buildQueryParamsString(
    {
      financialYear
    }
  )}`;
};

//GET
export const generatePowerGenerationExcel = (
  assetId,
  deviceIds,
  financialYear
) => {
  return `/assets/${assetId}/revenueautomation/powergeneration/excel?${buildQueryParamsString(
    {
      deviceIds,
      financialYear
    }
  )}`;
};

export const getAllCompletedJobsForUser = () => {
  return `/jobs/completed`;
};

export const getPortfolioDashboardDetails = (portfolioId, fromDate, toDate) => {
  return `/portfolios/${portfolioId}/dashboard?${buildQueryParamsString({
    fromDate,
    toDate
  })}`;
};
