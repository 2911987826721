/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import moment from "moment";
import { useState } from "react";
import { Alert, Collapse, Input } from "reactstrap";
import InvoiceFormContext from "./InvoiceFormContext";
import {
  LINE_ITEM_API_ERRORED,
  UPDATE_LINEITEM_ACCRUED_AMOUNT,
  UPDATE_LINEITEM_OPENING_BALANCE
} from "./actionsTypes";
import { Labels } from "../../../../../../constants/Constants";
import { useParams } from "react-router-dom";
import useToggle from "../../../../../../hooks/useToggle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faInfoCircle
} from "@fortawesome/free-solid-svg-icons";
import {
  getDateInDisplayFormat,
  getMonthName,
  serializeDate
} from "../../../../../../utils/date";
import InvoiceStatuses from "../../../../../../constants/InvoiceStatuses";
import { formatAmount } from "../../../../../../utils/number";
import {
  getJournalDescription,
  getJournalLink,
  TooltipMessage
} from "./JournalManagement";
import classNames from "classnames";
import AccrualAmountDrilldown from "./AccrualAmountDrilldown";
import useGetDateRangeForFinancialYear from "../../../../../../hooks/useGetDateRangeForFinancialYear";
import QueryKeys from "../../../../../../constants/QueryKeys";
import { getAccrualdata } from "./operationalFormApi";
import { useQuery } from "react-query";
import { getSymbolFromCurrencyCode } from "../../../../../../utils/currency/currency";
import { PurchaseInvoiceTypes } from "../../../../../../constants/PurchaseInvoiceTypes";
import { toReadableString } from "../../../../../../utils/string";
import useGetPostingPeriod from "../../../../../../hooks/useGetPostingPeriod";
import PostingPeriodModules from "../../../../../../constants/PostingPeriodModules";
import Tooltip from "../../../../../../elements/ToolTips/Tooltip";

export default function AccruedAmounts({
  invoiceDetails,
  currency,
  lineItem,
  accountId,
  getJournalDescriptionBeforeApproval,
  assetDetails,
  isFormReadOnly,
  setIsFormChanged
}) {
  const [expandAccruals, toggleAccruals] = useToggle(false);

  const { dispatch, formatAmount } = useContext(InvoiceFormContext);
  const isAccrualsDropDownDisabled =
    lineItem?.budgetingOption === "poNumber" &&
    [InvoiceStatuses.DRAFT, InvoiceStatuses.AWAITING_APPROVAL].includes(
      invoiceDetails?.invoiceStatus
    );

  const journalPostingPeriodQuery = useGetPostingPeriod({
    assetId: assetDetails.id,
    instrumentDate: invoiceDetails?.invoiceDate
      ? serializeDate(new Date(invoiceDetails.invoiceDate))
      : null,
    module: PostingPeriodModules.JOURNAL
  });

  const params = useParams();
  const { data: assetDates } = useGetDateRangeForFinancialYear(
    assetDetails.id,
    {
      onSuccess: () => {
        setSelectedYear(getYearOptions().pop());
      }
    }
  );

  const [selectedYear, setSelectedYear] = useState(null);

  const accruedAmountsQuery = useQuery(
    [
      QueryKeys.Accrualdata,
      {
        assetId: invoiceDetails.assetId,
        invoiceId: invoiceDetails.id,
        accountId,
        budgetLineItemId:
          lineItem.budgetingOption === "budgetLineItem"
            ? lineItem.budgetLineItem?.value
            : undefined,
        financialPeriodEndDate: selectedYear?.value
      }
    ],
    getAccrualdata,
    {
      enabled: !!(accountId && lineItem.lineItemId),
      onSuccess: (data) => {
        dispatch({
          type: UPDATE_LINEITEM_OPENING_BALANCE,
          payload: {
            openingBalance: data.openingBalance,
            lineItemId: lineItem.lineItemId
          }
        });
      },
      onError: (err) => {
        dispatch({
          type: LINE_ITEM_API_ERRORED,
          payload: {
            apiErrors: err,
            lineItemId: lineItem.lineItemId
          }
        });
      },
      refetchOnMount: false
    }
  );

  const [isOpen, toggle] = useToggle();

  const getYearOptions = () => {
    if (
      !(assetDates?.dataAvailableFromDate || assetDates.financialYearEndDate)
    ) {
      return [];
    }

    const financialYearEndDate = moment(
      "1970-" + assetDetails.financialYearEndDate
    );
    const dataAvailableFromDate = moment(assetDates.dataAvailableFromDate);

    const startYear = dataAvailableFromDate.isBefore(
      financialYearEndDate.set("year", dataAvailableFromDate.get("year"))
    )
      ? dataAvailableFromDate.get("year")
      : dataAvailableFromDate.get("year") + 1;

    const endYear = financialYearEndDate
      .set("year", new Date().getFullYear())
      .isBefore(new Date())
      ? new Date().getFullYear() + 1
      : new Date().getFullYear();

    const options = [];
    for (let start = startYear; start <= endYear; start++) {
      options.push(start);
    }

    return options.map((year) => {
      const date = moment(financialYearEndDate).set("year", year);
      return {
        label: "FY-ending " + date.format("MMM YYYY"),
        value: date.format("YYYY-MM-DD")
      };
    });
  };

  const openAccrualAmountDrilldown = () => {
    const options = getYearOptions();
    setSelectedYear(options.length > 0 ? options[options.length - 1] : null);
    accruedAmountsQuery.refetch();
    toggle();
  };

  const handleAccruedAmountChange = (event) => {
    setIsFormChanged(true);
    event.target.checkValidity();
    dispatch({
      type: UPDATE_LINEITEM_ACCRUED_AMOUNT,
      payload: {
        totalAccruedAmount: event.target.value,
        totalAccruedAmountError: event.target.value
          ? event.target.validationMessage
          : "",
        lineItemId: lineItem.lineItemId
      }
    });
  };

  return (
    <div>
      <div
        className="d-flex justify-content-between cursor-pointer"
        onClick={!isAccrualsDropDownDisabled ? toggleAccruals : null}
      >
        <span className="h4 sub-heading-small d-flex align-items-center m-0 py-3 mr-auto">
          {Labels.Payments.Invoices.Details.accrualWriteback}
          <span className="field-label">
            <TooltipMessage
              message={`${
                Labels.Payments.Invoices.Details.theAccrualWritebackJournals
              } ${
                Labels.Payments.Invoices.Details.associatedWithThis
              } ${toReadableString(invoiceDetails.invoiceType)} ${
                Labels.Payments.Invoices.Details.willBeIn
              } ${toReadableString(assetDetails.automaticJournalStatus)} ${
                Labels.Payments.Invoices.Details.status
              } ${
                Labels.Payments.Invoices.Details
                  .toChangeKindlyUpdateTheGeneralDetailsInSettingsAre
              }`}
            />
          </span>
        </span>
        <button
          className="btn btn-link m-0 p-0 ml-auto"
          disabled={isAccrualsDropDownDisabled}
        >
          {expandAccruals ? (
            <FontAwesomeIcon
              size="2x"
              icon={faAngleUp}
              className="m-0 p-0 mr-1 ml-1 align-middle"
            />
          ) : (
            <FontAwesomeIcon
              size="2x"
              icon={faAngleDown}
              className="m-0 p-0 mr-1 ml-1 align-middle"
            />
          )}
        </button>
      </div>
      <Collapse isOpen={expandAccruals}>
        <div>
          <Alert color="info">
            {[
              InvoiceStatuses.DRAFT,
              InvoiceStatuses.AWAITING_APPROVAL
            ].includes(invoiceDetails.invoiceStatus)
              ? invoiceDetails.invoiceType ===
                PurchaseInvoiceTypes["Purchase Invoice"]
                ? Labels.Payments.Invoices.Details
                    .accrualJournalsInfoBeforeApprovalInvoice
                : Labels.Payments.Invoices.Details
                    .accrualJournalsInfoBeforeApprovalCreditNote
              : Labels.Payments.Invoices.Details
                  .accrualJournalsInfoAfterApproval}
          </Alert>
        </div>
        {!!lineItem.costAccrualJournalPostingData ||
        (!!lineItem.totalAccruedAmount && lineItem.totalAccruedAmount !== 0) ? (
          <div>
            <div className="mb-3 body-primary color-text-primary">
              <table className="w-100">
                <thead>
                  <tr className="column-header-1">
                    <th>{Labels.CommonModals.date}</th>
                    <th>{Labels.Payments.Invoices.Details.postingPeriod}</th>
                    <th>
                      {Labels.Payments.Invoices.JournalManagement.bliGlCode}
                    </th>
                    <th className="text-center">
                      {Labels.Payments.Invoices.JournalManagement.debit}
                    </th>
                    <th className="text-center">
                      {Labels.Payments.Invoices.JournalManagement.credit}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {[
                    InvoiceStatuses.DRAFT,
                    InvoiceStatuses.AWAITING_APPROVAL
                  ].includes(invoiceDetails.invoiceStatus) ? (
                    <>
                      <tr>
                        <td></td>
                        <td></td>
                        <td className="text-left" style={{ maxWidth: "10rem" }}>
                          {getJournalDescriptionBeforeApproval()}
                        </td>
                        <td className="text-center">
                          {lineItem.totalAccruedAmount < 0
                            ? formatAmount(
                                Math.abs(lineItem.totalAccruedAmount)
                              )
                            : "-"}
                        </td>
                        <td className="text-center py-2">
                          {lineItem.totalAccruedAmount > 0
                            ? formatAmount(
                                Math.abs(lineItem.totalAccruedAmount)
                              )
                            : "-"}
                        </td>
                      </tr>
                      <tr className="border-bottom-fv-border-1px">
                        <td>
                          {getDateInDisplayFormat(invoiceDetails.invoiceDate)}
                        </td>
                        <td>
                          <div className="d-flex align-items-end justify-content-start">
                            <span>
                              {journalPostingPeriodQuery.data?.postingPeriod ||
                                "-"}
                            </span>
                          </div>
                        </td>
                        <td
                          id={"cost-accruals-" + lineItem.lineItemId}
                          className="text-left text-truncate"
                        >
                          {Labels.Payments.Invoices.Details.costAccruals}
                        </td>
                        <Tooltip
                          target={"cost-accruals-" + lineItem.lineItemId}
                          placement="bottom-start"
                        >
                          {Labels.Payments.Invoices.Details.nullToNull}
                        </Tooltip>
                        <td className="text-center">
                          {lineItem.totalAccruedAmount > 0
                            ? formatAmount(
                                Math.abs(lineItem.totalAccruedAmount)
                              )
                            : "-"}
                        </td>
                        <td className="text-center py-2">
                          {lineItem.totalAccruedAmount < 0
                            ? formatAmount(
                                Math.abs(lineItem.totalAccruedAmount)
                              )
                            : "-"}
                        </td>
                      </tr>
                    </>
                  ) : (
                    <CostAccrual
                      journal={lineItem.costAccrualJournalPostingData}
                      currency={currency}
                    />
                  )}
                </tbody>
              </table>
            </div>
          </div>
        ) : null}

        {!!lineItem.costAccrualJournalPostingData ||
        !!lineItem.purchaseOrder?.id ||
        !!lineItem.poNumber?.id ? null : (
          <>
            <div className="text-right col d-block field-label">
              {Labels.Payments.Invoices.Details.amountToWriteBack}
            </div>
            <div className="d-flex justify-content-between align-items-center body-primary color-text-primary py-3 pr-3">
              <span>
                {Labels.Payments.Invoices.Details.latestBalance}
                <FontAwesomeIcon
                  id={"latestBalanceInfoMessage" + lineItem.lineItemId}
                  icon={faInfoCircle}
                  className="ml-1"
                />
                <Tooltip
                  fade={false}
                  style={{
                    maxWidth: "unset",
                    textAlign: "start"
                  }}
                  innerClassName="prepayment-journal-schedule-message w-100"
                  popperClassName="w-25"
                  target={"latestBalanceInfoMessage" + lineItem.lineItemId}
                >
                  <div className="w-100">
                    {Labels.Payments.Invoices.Details.latestBalanceInfoMessage}
                  </div>
                </Tooltip>
              </span>
              <button
                className="btn btn-link m-0 p-0 text-underline"
                onClick={openAccrualAmountDrilldown}
                disabled={!lineItem.openingBalance}
              >
                {formatAmount(lineItem.openingBalance)}
              </button>
              <div className="d-flex flex-column">
                <div className="d-flex justify-content-end align-items-center">
                  <span className="pr-1">
                    {getSymbolFromCurrencyCode(invoiceDetails.assetCurrency)}
                  </span>
                  <Input
                    type="number"
                    name="latest-balance"
                    id="latest-balance"
                    disabled={lineItem.openingBalance === 0 || isFormReadOnly}
                    step={0.01}
                    min={
                      lineItem.openingBalance > 0
                        ? 0.01
                        : lineItem.openingBalance
                    }
                    max={
                      lineItem.openingBalance > 0
                        ? lineItem.openingBalance
                        : -0.01
                    }
                    onChange={handleAccruedAmountChange}
                    value={lineItem.totalAccruedAmount}
                  />
                </div>
                <span className="error-message mt-1">
                  {lineItem.totalAccruedAmountError}
                </span>
              </div>
            </div>
          </>
        )}
      </Collapse>
      <div className="border-bottom-fv-border-2px"></div>
      {isOpen ? (
        <AccrualAmountDrilldown
          assetDetails={assetDetails}
          invoiceDetails={invoiceDetails}
          params={params}
          selectedYear={selectedYear}
          setSelectedYear={setSelectedYear}
          getYearOptions={getYearOptions}
          accruedAmountsQuery={accruedAmountsQuery}
          isOpen={isOpen}
          toggle={toggle}
        />
      ) : null}
    </div>
  );
}

function CostAccrual({ journal, currency }) {
  const params = useParams();
  let bliGlCodeRow,
    prepaymentRow = null;

  if (!journal?.journalPostingLineItemDataList) {
    return null;
  }

  if (
    journal.journalPostingLineItemDataList[0].budgetLineItemDescription ===
    "Prepayment"
  ) {
    prepaymentRow = journal.journalPostingLineItemDataList[0];
    bliGlCodeRow = journal.journalPostingLineItemDataList[1];
  } else {
    prepaymentRow = journal.journalPostingLineItemDataList[1];
    bliGlCodeRow = journal.journalPostingLineItemDataList[0];
  }

  const monthYear = `${getMonthName(journal.month)}-${journal.year}`;

  return (
    <>
      <tr>
        <td className="pt-3"></td>
        <td></td>
        <td className="text-left" style={{ maxWidth: "10rem" }}>
          {bliGlCodeRow.budgetLineItemDescription === "Prepayment"
            ? "Prepayment"
            : getJournalDescription(bliGlCodeRow)}
        </td>
        <td className="text-center">
          {formatAmount(bliGlCodeRow.debit, currency)}
        </td>
        <td className="text-center py-2">
          {formatAmount(bliGlCodeRow.credit, currency)}
        </td>
      </tr>
      <tr className={classNames("border-bottom-fv-border-1px")}>
        <td>
          {journal.journalId ? (
            <a
              href={getJournalLink({
                journalId: journal.journalId,
                ...params
              })}
              target="_blank"
              rel="noreferrer"
            >
              {getDateInDisplayFormat(
                new Date(journal.transactionDate?.join("-"))
              )}
            </a>
          ) : (
            <>
              {getDateInDisplayFormat(
                new Date(journal.transactionDate?.join("-"))
              )}
            </>
          )}
        </td>
        <td>{monthYear}</td>
        <td
          id={"journal-description-" + prepaymentRow.accountId}
          className="text-left text-truncate"
        >
          {prepaymentRow.budgetLineItemDescription === "Prepayment"
            ? "Prepayment"
            : getJournalDescription(prepaymentRow)}
        </td>
        <Tooltip
          target={"journal-description-" + prepaymentRow.accountId}
          placement="bottom-start"
        >
          {getJournalDescription(prepaymentRow)}
        </Tooltip>
        <td className="text-center">
          {formatAmount(prepaymentRow.debit, currency)}
        </td>
        <td className="text-center py-2">
          {formatAmount(prepaymentRow.credit, currency)}
        </td>
      </tr>
    </>
  );
}
