import { useQuery } from "react-query";
import QueryKeys from "../../../../../constants/QueryKeys";
import { getPaymentOutstanding } from "../../../../../utils/http/endpoints";
import { httpRequest } from "../../../../../utils/http/httpRequest";
import { resolveAPIPromise } from "../../../../../utils/promises";

export default function useGetOutStandingPaymentLineItems(assetId, invoiceId) {
  return useQuery({
    queryKey: [
      QueryKeys.getOutstandingPaymentLineItems,
      { assetId, invoiceId }
    ],
    queryFn: () =>
      resolveAPIPromise(
        httpRequest({
          method: "get",
          url: getPaymentOutstanding(assetId, invoiceId)
        })
      ),
    enabled: !!(assetId && invoiceId)
  });
}
