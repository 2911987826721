import React, { useContext, useEffect } from "react";
import { Labels } from "../../../../../../constants/Constants";
import { formatAmountWithThousandSeparatorsToPrecision } from "../../../../../../utils/number";
import {
  OTHER_DEDUCTIONS_UPDATE,
  UPDATE_WITHHOLDING_TAXES_FROM_TAX_LIST_API_AND_INVOICE_DATA,
  WITHHOLDING_TAXES_UPDATE
} from "./actionsTypes";
import InvoiceFormContext from "./InvoiceFormContext";
import { Alert } from "reactstrap";
import { getSymbolFromCurrencyCode } from "../../../../../../utils/currency/currency";
import XEInfoTooltip from "../../../../../../elements/XEInfoTooltip";
import useGetTaxList from "../../../../../../hooks/useGetTaxList";
import { useParams } from "react-router-dom";

export default function WithholdingTaxes({
  invoiceDetails,
  currency,
  calWithheldAmount,
  isFormReadOnly,
  isCreditNote
}) {
  const { state, dispatch, setIsFormChanged } = useContext(InvoiceFormContext);
  const { withholdingTaxes, otherDeductions } = state;
  const params = useParams();
  const taxListQuery = useGetTaxList({
    assetId: invoiceDetails.assetId,
    orgId: params.orgId,
    status: "ACTIVE"
  });

  useEffect(() => {
    if (Object.keys(invoiceDetails).length === 0 || !taxListQuery.data) {
      return;
    }

    dispatch({
      type: UPDATE_WITHHOLDING_TAXES_FROM_TAX_LIST_API_AND_INVOICE_DATA,
      payload: {
        whtTaxList: taxListQuery.data.filter((i) => i.isWithholdingTax) || [],
        invoiceDetails: invoiceDetails
      }
    });
  }, [invoiceDetails, dispatch, taxListQuery.data]);

  // Set other deductions also as per invoiceDetails api res
  useEffect(() => {
    // Set otherDeductions value from invoiceDetails if selectedBudgetLineItemId and invoiceDetails.budgetLineItem.id are same
    dispatch({
      type: OTHER_DEDUCTIONS_UPDATE,
      payload: { value: invoiceDetails.otherDeductions }
    });
  }, [invoiceDetails, dispatch]);

  const handleWithholdingTaxChange = (taxItem) => {
    setIsFormChanged(true);
    dispatch({
      type: WITHHOLDING_TAXES_UPDATE,
      payload: taxItem
    });
  };

  // Read value and error message
  const handleOtherDeductionsChange = (evt) => {
    setIsFormChanged(true);
    dispatch({
      type: OTHER_DEDUCTIONS_UPDATE,
      payload: {
        value: evt.target.value,
        validationErrors: evt.target.validationMessage
      }
    });
  };

  // Subscribe for error messages (OnChange will not be triggered if alphabets are entered by user)
  const handleOtherDeductionsKeyUp = (evt) => {
    setIsFormChanged(true);
    dispatch({
      type: OTHER_DEDUCTIONS_UPDATE,
      payload: {
        value: evt.target.value,
        validationErrors: evt.target.validationMessage
      }
    });
  };

  let renderedWithholdingTaxes = withholdingTaxes.map((taxItem, index) => (
    <WithholdingTax
      bcNetAmount={invoiceDetails.bcNetAmount}
      handleWithholdingTaxChange={handleWithholdingTaxChange}
      key={taxItem.id}
      taxItem={taxItem}
      isFormReadOnly={isFormReadOnly}
      currency={currency}
      invoiceDetails={invoiceDetails}
      calWithheldAmount={calWithheldAmount}
      isNegativeInvoice={invoiceDetails.isNegativeInvoice}
    />
  ));

  return (
    <>
      <div className="divider-thick"></div>
      <fieldset disabled={isFormReadOnly || isCreditNote}>
        <div id="withholding-taxes" className="my-3">
          <h3 className="color-dark mb-0">
            {Labels.Payments.Invoices.Details.WithholdingTaxes}
          </h3>
          <div id="withholding-taxes-container">
            <div className="wt-row head-row field-label">
              <div>
                <span>{Labels.Payments.Invoices.Details.Tax}</span>
              </div>
              <div>
                <span>{Labels.Payments.Invoices.Details.Withheld}</span>
              </div>
              <div>
                <span>{Labels.Payments.Invoices.Details.AmountWithheld}</span>
              </div>
            </div>
          </div>
          {renderedWithholdingTaxes}
          {invoiceDetails.isNegativeInvoice ? (
            <div className="mt-4 mb-0">
              <Alert role="alert" color="info">
                {Labels.Payments.Invoices.Details.negativeInvoiceWHTInfoMsg}
              </Alert>
            </div>
          ) : null}
          <div className="other-deductions">
            <label htmlFor="other-deductions">
              {Labels.Payments.Invoices.Details.OtherDeductions}
            </label>
            <span>
              <span className="text-danger font-weight-normal mr-1">
                {otherDeductions.validationErrors}
              </span>
              <span>
                {getSymbolFromCurrencyCode(invoiceDetails.invoiceCurrency)}{" "}
              </span>
              <input
                value={otherDeductions.value}
                className="input-field"
                onChange={handleOtherDeductionsChange}
                onKeyUp={handleOtherDeductionsKeyUp}
                disabled={isFormReadOnly || invoiceDetails.isNegativeInvoice}
                type="number"
                min={0}
                step={0.01}
                id="other-deductions"
              />
              <XEInfoTooltip
                bcAmount={(
                  otherDeductions.value * invoiceDetails.exchangeRate
                ).toFixed(2)}
                invoiceDetails={invoiceDetails}
              />
            </span>
          </div>
        </div>
      </fieldset>
      {isCreditNote && (
        <Alert role="alert" color="info">
          {Labels.Payments.Invoices.Details.notApplicableForWHT}
        </Alert>
      )}
    </>
  );
}

function WithholdingTax({
  handleWithholdingTaxChange,
  taxItem,
  invoiceDetails,
  calWithheldAmount,
  isFormReadOnly,
  isNegativeInvoice
}) {
  const handleCheckboxChange = (evt) => {
    handleWithholdingTaxChange({
      ...taxItem,
      isSelected: evt.target.checked,
      percentage: evt.target.checked
        ? {
            value: taxItem.percentage.value,
            validationErrors: !isNaN(parseFloat(taxItem.percentage.value))
              ? ""
              : Labels.CommonModals.pleaseEnterANumber
          }
        : {
            value: taxItem.percentage.validationErrors
              ? ""
              : taxItem.percentage.value,
            validationErrors: ""
          }
    });
  };

  const handlePercentChange = (evt) => {
    handleWithholdingTaxChange({
      ...taxItem,
      percentage: {
        value: evt.target.value,
        validationErrors: evt.target.validationMessage
      }
    });
  };

  const handlePercentChangeKeyUp = (evt) => {
    handleWithholdingTaxChange({
      ...taxItem,
      percentage: {
        value: taxItem.percentage.value,
        validationErrors: evt.target.validationMessage
      }
    });
  };

  return (
    <div className="wt-row d-flex align-items-center">
      <div className="d-flex align-items-center">
        <input
          type="checkbox"
          checked={taxItem.isSelected}
          id={`wht-tax-${taxItem.id}`}
          disabled={isFormReadOnly || isNegativeInvoice}
          onChange={handleCheckboxChange}
        ></input>
        <label htmlFor={`wht-tax-${taxItem.id}`}>
          &nbsp;&nbsp;{taxItem.name}
        </label>
      </div>
      <div className="contingency-percentage">
        <div className="d-flex align-items-center">
          <input
            type="number"
            min={0}
            className="input-field"
            step={0.01}
            max={100}
            value={taxItem.percentage.value}
            disabled={
              !taxItem.isSelected || isFormReadOnly || isNegativeInvoice
            }
            onChange={handlePercentChange}
            required={taxItem.isSelected}
            onKeyUp={handlePercentChangeKeyUp}
          />
          <label>&nbsp;%</label>
        </div>
        <span className="text-danger d-block my-1">
          {taxItem.percentage.validationErrors}
        </span>
      </div>
      <span>
        {(calWithheldAmount(taxItem) && taxItem.isSelected) ||
        calWithheldAmount(taxItem) === 0
          ? getSymbolFromCurrencyCode(invoiceDetails?.invoiceCurrency) +
            formatAmountWithThousandSeparatorsToPrecision(
              calWithheldAmount(taxItem).toFixed(2)
            )
          : ""}
        {taxItem.isSelected ? (
          <XEInfoTooltip
            invoiceDetails={invoiceDetails}
            bcAmount={(
              calWithheldAmount(taxItem) * invoiceDetails.exchangeRate
            ).toFixed(2)}
          />
        ) : null}
      </span>
    </div>
  );
}
