const initialState = {
  apiErrors: [],
  openedLineItemId: "",
  lineItems: [],
  otherDeductions: {
    value: 0,
    validationErrors: ""
  },
  withholdingTaxes: [],
  invoiceSavedMessage: ""
};

export default initialState;
