import React, {useContext, useEffect, useRef, useState} from "react";
import {formatAmountWithThousandSeparatorsToPrecision} from "../../../../../../utils/number";
import classNames from "classnames";
import IncurredAmountDrillDownModal from "./IncurredAmountDrillDownModal";
import useOperationalBudgetingData from "./useOperationalBudgetingData";
import {Labels} from "../../../../../../constants/Constants";
import InvoiceFormContext from "./InvoiceFormContext";
import InvoiceStatuses from "../../../../../../constants/InvoiceStatuses";
import {PurchaseInvoiceTypesEnum} from "../../../../../../constants/PurchaseInvoiceTypes";

export default function Budgeting({
  assetId,
  invoiceId,
  currency,
  accountId,
  invoiceDetails,
  budgetLineItemId,
  opexLineItem,
  lineItemId,
  lineItem,
  isUpdateBudgetNeeded,
  budgetLineItemDescription
}) {
  const { state } = useContext(InvoiceFormContext);
  const { lineItems } = state;
  const [budgetInfo, setBudgetInfo] = useState({});
  const getOpexLineItem = (lineItem) => {
    if (lineItem.budgetingOption === "budgetLineItem") {
      return lineItem.budgetLineItem?.reportingLineDescription || null;
    } else {
      return lineItem.glAccountCode?.reportingLineDescription || null;
    }
  };
  const { key: budgetInfoDataKey, data: budgetInfoData } =
    useOperationalBudgetingData({
      assetId,
      invoiceId,
      accountId,
      budgetLineItemId,
      lineItemId,
      opexLineItem: getOpexLineItem(lineItem)
    });
  const [isDrillDownModalOpen, setModal] = useState(false);
  const toggleDrillDownModal = () => setModal(!isDrillDownModalOpen);

  const [title, setTitle] = useState("");
  const [budgetAmount, setBudgetAmount] = useState(0);
  const [isMTD, setIsMTD] = useState();

  const userClickedRow = useRef("");
  const incurredAmountRef = useRef(0);
  const isCreditNote =
    invoiceDetails?.invoiceType ===
    PurchaseInvoiceTypesEnum.PURCHASE_CREDIT_NOTE;

  const launchDrillDownModal = (
    selectedBudgetAmount,
    rowName,
    isBudgetLineItem,
    isMTD,
    incurredAmount
  ) => {
    setIsMTD(isMTD);
    setBudgetAmount(selectedBudgetAmount);
    setTitle(rowName);
    userClickedRow.current = isBudgetLineItem ? "budgetLineItem" : "glLineItem";
    incurredAmountRef.current = incurredAmount;
    toggleDrillDownModal();
  };

  const formatAmount = (amount, amountColumn) => {
    if (amount === null || isNaN(parseFloat(amount))) {
      return "-";
    } else if (!budgetLineItemId && amountColumn === "Budget Line Item") {
      return "-";
    }
    return currency + formatAmountWithThousandSeparatorsToPrecision(amount);
  };

  const getIncurredAmount = (
    baseAmount,
    isBudgetLineItem = false,
    discardBaseValue = false
  ) => {
    if (isNaN(parseFloat(baseAmount)) || !isUpdateBudgetNeeded) {
      return baseAmount;
    }

    const baseAmountValue = parseFloat(baseAmount);
    let newIncurredAmount = 0;

    lineItems.forEach((i) => {
      if (isBudgetLineItem) {
        if (
          i.budgetingOption === "budgetLineItem" &&
          i.budgetLineItem?.value === budgetLineItemId
        ) {
          newIncurredAmount += isCreditNote
            ? -i.invoiceLineItem.netAmount
            : i.invoiceLineItem.netAmount;
        }
      } else {
        if (getOpexLineItem(i) === getOpexLineItem(lineItem)) {
          newIncurredAmount += isCreditNote
            ? -i.invoiceLineItem.netAmount
            : i.invoiceLineItem.netAmount;
        }
      }
    });

    return discardBaseValue
      ? newIncurredAmount
      : newIncurredAmount + baseAmountValue;
  };

  const budgetLineItemMtdVariance =
    budgetInfo?.budgetLineItemMTD?.budgetAmount -
    (isUpdateBudgetNeeded
      ? getIncurredAmount(
          budgetInfo?.budgetLineItemMTD?.otherInvoicesIncurredAmount,
          true
        )
      : budgetInfo?.budgetLineItemMTD?.incurredAmount);

  const budgetLineItemYtdVariance =
    budgetInfo?.budgetLineItemYTD?.budgetAmount -
    (isUpdateBudgetNeeded
      ? getIncurredAmount(
          budgetInfo?.budgetLineItemYTD?.otherInvoicesIncurredAmount,
          true
        )
      : budgetInfo?.budgetLineItemYTD?.incurredAmount);

  const opexBudgetMtdVariance =
    budgetInfo?.opexBudgetMTD?.budgetAmount -
    (isUpdateBudgetNeeded
      ? getIncurredAmount(
          budgetInfo?.opexBudgetMTD?.otherInvoicesIncurredAmount
        )
      : budgetInfo?.opexBudgetMTD?.incurredAmount);

  const opexBudgetYtdVariance =
    budgetInfo?.opexBudgetYTD?.budgetAmount -
    (isUpdateBudgetNeeded
      ? getIncurredAmount(
          budgetInfo?.opexBudgetYTD?.otherInvoicesIncurredAmount
        )
      : isUpdateBudgetNeeded
      ? getIncurredAmount(budgetInfo?.opexBudgetYTD?.incurredAmount)
      : budgetInfo?.opexBudgetYTD?.incurredAmount);
  useEffect(() => {
    if (
      invoiceDetails?.invoiceType ===
        PurchaseInvoiceTypesEnum.PURCHASE_CREDIT_NOTE &&
      invoiceDetails?.invoiceStatus === InvoiceStatuses.DRAFT &&
      isUpdateBudgetNeeded
    ) {
      let budgetInfo = { ...budgetInfoData };
      for (let key in budgetInfoData) {
        if (budgetInfo[key]) {
          budgetInfo[key].incurredAmount =
            budgetInfoData[key]?.incurredAmount -
            (invoiceDetails?.netAmount || 0);
        }
      }
      setBudgetInfo(budgetInfo);
    } else {
      setBudgetInfo(budgetInfoData);
    }
  }, [budgetInfoDataKey]);

  return !!budgetLineItemId ? (
    <div className="budgeting text-right mx-0">
      <div className="row budgeting-header">
        <span className="col-3"></span>
        <h4 className="col-3 section-column-header mb-0">
          {Labels.Payments.Invoices.Details.incurred}
        </h4>
        <h4 className="col-3 section-column-header mb-0">
          {Labels.Payments.Invoices.Details.budget}
        </h4>
        <h4 className="col-3 section-column-header mb-0">
          {Labels.Payments.Invoices.Details.variance}
        </h4>
      </div>
      <div className="row budgeting-row">
        <span className="col-3 text-left row-header-2">
          {`${lineItem.budgetLineItem.budgetLineItem} ${Labels.Payments.Invoices.Details.mtd}`}
        </span>
        <span className="col-3 body-primary">
          <span
            className={classNames("btn-link cursor-pointer m-0 p-0", {
              disabled:
                !budgetLineItemId || budgetInfo?.budgetLineItemMTD === null
            })}
            onClick={() =>
              launchDrillDownModal(
                budgetInfo?.budgetLineItemMTD?.budgetAmount,
                `${lineItem.budgetLineItem.budgetLineItem} ${Labels.Payments.Invoices.Details.charges} - ${Labels.Payments.Invoices.Details.monthly}`,
                true,
                true,
                isUpdateBudgetNeeded
                  ? getIncurredAmount(
                      budgetInfo?.budgetLineItemMTD
                        ?.otherInvoicesIncurredAmount,
                      true,
                      true
                    )
                  : budgetInfo?.budgetLineItemMTD?.incurredAmount
              )
            }
          >
            {lineItem.budgetingOption !== "budgetLineItem"
              ? "-"
              : formatAmount(
                  isUpdateBudgetNeeded
                    ? getIncurredAmount(
                        budgetInfo?.budgetLineItemMTD
                          ?.otherInvoicesIncurredAmount,
                        true
                      )
                    : budgetInfo?.budgetLineItemMTD?.incurredAmount,
                  "Budget Line Item"
                )}
          </span>
        </span>
        <span className="col-3 body-primary color-text-primary">
          {lineItem.budgetingOption !== "budgetLineItem"
            ? "-"
            : formatAmount(
                budgetInfo?.budgetLineItemMTD?.budgetAmount,
                "Budget Line Item"
              )}
        </span>
        <span
          className={classNames(
            "col-3 body-primary",
            { "text-success": budgetLineItemMtdVariance >= 0 },
            { "text-danger": budgetLineItemMtdVariance < 0 }
          )}
        >
          {lineItem.budgetingOption !== "budgetLineItem"
            ? "-"
            : formatAmount(budgetInfo?.budgetLineItemMTD?.variance) === "-"
            ? "-"
            : formatAmount(budgetLineItemMtdVariance, "Budget Line Item")}
        </span>
      </div>
      <div className="fv-border"></div>
      <div className="row budgeting-row">
        <span className="col-3 text-left row-header-2">
          {`${lineItem.budgetLineItem.budgetLineItem} ${Labels.Payments.Invoices.Details.annual}`}
        </span>
        <span className="col-3 body-primary">
          <span
            className={classNames("btn-link cursor-pointer m-0 p-0", {
              disabled:
                !budgetLineItemId || budgetInfo?.budgetLineItemMTD === null
            })}
            onClick={() =>
              launchDrillDownModal(
                budgetInfo?.budgetLineItemYTD?.budgetAmount,
                `${lineItem.budgetLineItem.budgetLineItem} ${Labels.Payments.Invoices.Details.charges} - ${Labels.Payments.Invoices.Details.annual}`,
                true,
                false,
                isUpdateBudgetNeeded
                  ? getIncurredAmount(
                      budgetInfo?.budgetLineItemYTD
                        ?.otherInvoicesIncurredAmount,
                      true,
                      true
                    )
                  : budgetInfo?.budgetLineItemYTD?.incurredAmount
              )
            }
          >
            {lineItem.budgetingOption !== "budgetLineItem"
              ? "-"
              : formatAmount(
                  isUpdateBudgetNeeded
                    ? getIncurredAmount(
                        budgetInfo?.budgetLineItemYTD
                          ?.otherInvoicesIncurredAmount,
                        true
                      )
                    : budgetInfo?.budgetLineItemYTD?.incurredAmount,
                  "Budget Line Item"
                )}
          </span>
        </span>
        <span className="col-3 body-primary color-text-primary">
          {lineItem.budgetingOption !== "budgetLineItem"
            ? "-"
            : formatAmount(
                budgetInfo?.budgetLineItemYTD?.budgetAmount,
                "Budget Line Item"
              )}
        </span>
        <span
          className={classNames(
            "col-3 body-primary",
            { "text-success": budgetLineItemYtdVariance >= 0 },
            { "text-danger": budgetLineItemYtdVariance < 0 }
          )}
        >
          {lineItem.budgetingOption !== "budgetLineItem"
            ? "-"
            : formatAmount(budgetLineItemYtdVariance) === "-"
            ? "-"
            : formatAmount(budgetLineItemYtdVariance, "Budget Line Item")}
        </span>
      </div>
      <div className="fv-border"></div>
      <div className="row budgeting-row">
        <span className="col-3 text-left row-header-2">
          {`${lineItem.budgetLineItem.reportingLineDescription} ${Labels.Payments.Invoices.Details.mtd}`}
        </span>
        <span className="col-3 body-primary">
          <span
            className={classNames("btn-link cursor-pointer m-0 p-0", {
              disabled: !accountId
            })}
            onClick={() =>
              launchDrillDownModal(
                budgetInfo?.opexBudgetMTD?.budgetAmount,
                `${lineItem.budgetLineItem.reportingLineDescription} ${Labels.Payments.Invoices.Details.charges} - ${Labels.Payments.Invoices.Details.monthly}`,
                false,
                true,
                isUpdateBudgetNeeded
                  ? getIncurredAmount(
                      budgetInfo?.opexBudgetMTD?.incurredAmount,
                      false,
                      true
                    )
                  : budgetInfo?.opexBudgetMTD?.incurredAmount
              )
            }
          >
            {formatAmount(
              isUpdateBudgetNeeded
                ? getIncurredAmount(
                    budgetInfo?.opexBudgetMTD?.otherInvoicesIncurredAmount
                  )
                : budgetInfo?.opexBudgetMTD?.incurredAmount
            )}
          </span>
        </span>
        <span className="col-3 body-primary color-text-primary">
          {formatAmount(budgetInfo?.opexBudgetMTD?.budgetAmount)}
        </span>
        <span
          className={classNames(
            "col-3 body-primary",
            { "text-success": opexBudgetMtdVariance >= 0 },
            { "text-danger": opexBudgetMtdVariance < 0 }
          )}
        >
          {formatAmount(opexBudgetMtdVariance) === "-"
            ? "-"
            : formatAmount(opexBudgetMtdVariance)}
        </span>
      </div>
      <div className="fv-border"></div>
      <div className="row budgeting-row">
        <span className="col-3 text-left row-header-2">
          {`${lineItem.budgetLineItem.reportingLineDescription} ${Labels.Payments.Invoices.Details.annual}`}
        </span>
        <span className="col-3 body-primary">
          <span
            className={classNames("btn-link cursor-pointer m-0 p-0", {
              disabled: !accountId
            })}
            onClick={() =>
              launchDrillDownModal(
                budgetInfo?.opexBudgetYTD?.budgetAmount,
                `${lineItem.budgetLineItem.reportingLineDescription} ${Labels.Payments.Invoices.Details.charges} - ${Labels.Payments.Invoices.Details.annual}`,
                false,
                false,
                isUpdateBudgetNeeded
                  ? getIncurredAmount(
                      budgetInfo?.opexBudgetYTD?.incurredAmount,
                      false,
                      true
                    )
                  : budgetInfo?.opexBudgetYTD?.incurredAmount
              )
            }
          >
            {formatAmount(
              isUpdateBudgetNeeded
                ? getIncurredAmount(
                    budgetInfo?.opexBudgetYTD?.otherInvoicesIncurredAmount
                  )
                : isUpdateBudgetNeeded
                ? getIncurredAmount(budgetInfo?.opexBudgetYTD?.incurredAmount)
                : budgetInfo?.opexBudgetYTD?.incurredAmount
            )}
          </span>
        </span>
        <span className="col-3 body-primary color-text-primary">
          {formatAmount(budgetInfo?.opexBudgetYTD?.budgetAmount)}
        </span>
        <span
          className={classNames(
            "col-3 body-primary",
            { "text-success": opexBudgetYtdVariance >= 0 },
            { "text-danger": opexBudgetYtdVariance < 0 }
          )}
        >
          {formatAmount(opexBudgetYtdVariance) === "-"
            ? "-"
            : formatAmount(opexBudgetYtdVariance)}
        </span>
      </div>
      <div className="fv-border"></div>
      {isDrillDownModalOpen && (
        <IncurredAmountDrillDownModal
          currency={currency}
          title={title}
          budgetAmount={budgetAmount}
          incurredAmount={incurredAmountRef.current}
          invoiceDetails={invoiceDetails}
          toggle={toggleDrillDownModal}
          isOpen={isDrillDownModalOpen}
          isMTD={isMTD}
          userClickedRow={userClickedRow.current}
          lineItemId={lineItemId}
          isUpdateBudgetNeeded={isUpdateBudgetNeeded}
          accountId={accountId}
          budgetLineItemId={budgetLineItemId}
          budgetLineItemDescription={budgetLineItemDescription}
        />
      )}
    </div>
  ) : null;
}
