import NiceModal, {useModal} from "@ebay/nice-modal-react";
import {useState} from "react";
import {Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {Labels} from "../../../../../../constants/Constants";
import {httpRequest} from "../../../../../../utils/http/httpRequest";
import {configureConsumerPriceIndex} from "../../../../../../utils/http/endpoints";
import DisplayAlerts from "../../../../../../elements/DisplayAlerts";
import {alphaNumericWithoutSpacesRegexp} from "../../../../../../constants/regexp";
import {useQueryClient} from "react-query";
import QueryKeys from "../../../../../../constants/QueryKeys";

export default NiceModal.create(({ cpi, setSuccessfulMsg }) => {
  const modal = useModal();
  const [indexName, setIndexName] = useState(cpi ? cpi.indexName : "");
  const [errors, setErrors] = useState([]);
  const [isNameError, setIsNameError] = useState(false);
  const queryClient = useQueryClient();

  const handleNameChange = (evt) => {
    evt.preventDefault();
    let value = evt.target.value;
    setIndexName(evt.target.value);
    if (alphaNumericWithoutSpacesRegexp.test(value)) {
      setIsNameError(false);
      evt.target.setCustomValidity("");
    } else {
      setIsNameError(true);
      evt.target.setCustomValidity(
        Labels.Insights.RevenueAutomation.cpiNameErrorMsg
      );
    }
  };

  const handleSave = () => {
    httpRequest({
      method: "post",
      url: configureConsumerPriceIndex(),
      data: {
        id: cpi ? cpi.id : null,
        indexName: indexName?.trim()
      }
    })
      .then((res) => {
        modal.remove();
        queryClient.invalidateQueries(QueryKeys.getConsumerPriceIndexNames);
        setSuccessfulMsg(
          cpi
            ? Labels.Insights.RevenueAutomation.cpiUpdateSuccessMsg
            : Labels.Insights.RevenueAutomation.cpiSaveSuccessMsg
        );
      })
      .catch((err) => {
        setErrors(err?.data?.errors);
      });
  };

  return (
    <Modal
      isOpen={true}
      toggle={modal.remove}
      centered={true}
      backdrop={"static"}
      fade={false}
      keyboard={false}
    >
      <ModalHeader className="pb-0" toggle={modal.remove}>
        <div>
          <h2 className="modal-title color-dark">
            {cpi
              ? Labels.Insights.RevenueAutomation.updateCPi
              : Labels.Insights.RevenueAutomation.addCpi}
          </h2>
        </div>
      </ModalHeader>
      <ModalBody>
        {errors.length ? (
          <DisplayAlerts
            className={"mt-0"}
            type={"danger"}
            alerts={errors}
            onAlertDismiss={() => setErrors([])}
          />
        ) : null}
        <Row>
          <Col className="row-header-2 my-auto col-sm-12 col-md-6">
            {Labels.Insights.RevenueAutomation.cpiname}
          </Col>
          <Col>
            <input
              type="text"
              maxLength={250}
              value={indexName}
              onChange={(e) => handleNameChange(e)}
              placeholder={Labels.Insights.RevenueAutomation.cpiname}
            />
            {isNameError ? (
              <span className="error-message">
                {Labels.Insights.RevenueAutomation.cpiNameErrorMsg}
              </span>
            ) : null}
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Row>
          <button
            className="btn btn-primary mr-2"
            disabled={!indexName?.trim()?.length || isNameError}
            onClick={handleSave}
          >
            {cpi ? Labels.CommonModals.update : Labels.CommonModals.save}
          </button>
          <button className="btn btn-secondary" onClick={modal.remove}>
            {Labels.CommonModals.cancel}
          </button>
        </Row>
      </ModalFooter>
    </Modal>
  );
});
