import React, { useState } from "react";
import { Modal, ModalBody, Button, Alert } from "reactstrap";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useQueryClient } from "react-query";
import { Labels } from "../../../../../../constants/Constants";
import DragAndDropFileUpload from "../../../../../../elements/file-upload/DragAndDropFileUpload";
import { DownloadLink } from "../../../../../../elements/download/DownloadFiles";
import {
  downloadSampleOrganizationMasterData,
  uploadOrganizationMasterData
} from "../../../../../../utils/http/endpoints";
import QueryKeys from "../../../../../../constants/QueryKeys";
import { httpRequest } from "../../../../../../utils/http/httpRequest";
import DisplayNote from "../../../../../../elements/DisplayNote";

export default NiceModal.create(({ setSuccessMsg, orgId, showWarning }) => {
  const queryClient = useQueryClient();
  const modal = useModal();
  const [errors, setErrors] = useState([]);
  const [attachment, setAttachment] = useState([]);
  const sampleCSVDownloadOptions = {
    url: downloadSampleOrganizationMasterData(),
    responseType: "blob"
  };

  const uploadOptions = {
    accept: ".csv",
    maxFiles: 1,
    minSize: 0,
    maxSize: 10485760
  };

  const resetErrors = () => {
    setErrors([]);
  };

  const errorCodeDesc = {
    "file-invalid-type": Labels.Journals.fileTypeError,
    "file-too-large": Labels.CommonModals.attachmentSizeError
  };

  const onError = (errorList) => {
    const errors = {};
    errorList.forEach((errorObj) => {
      errorObj.errors.forEach((error) => {
        errors[error.code] = {
          errorMessage: errorCodeDesc[error.code] || error.message
        };
      });
    });
    setErrors([...Object.values(errors)]);
  };

  const onFilesSelection = (files = []) => {
    setAttachment(files);
  };

  const handleImport = () => {
    resetErrors();
    const endpoint = uploadOrganizationMasterData(orgId);
    const formData = new FormData();
    formData.append("orgMasterDataFile", attachment[0]);
    httpRequest({
      method: "post",
      url: endpoint,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
      .then((response) => {
        if (response.headers["content-type"] === "application/force-download") {
          setErrors([
            {
              errorMessage:
                Labels.Organisations.reportingMasterData.Import.logFileMsg
            }
          ]);
          let fileName =
            response.headers["content-disposition"].split("filename=")[1];
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        } else {
          queryClient.invalidateQueries(QueryKeys.getOrganizationMasterData);
          setSuccessMsg(
            Labels.Organisations.reportingMasterData.importSuccessMsg
          );
          modal.remove();
        }
      })
      .catch((error) => {
        console.warn(error);
        if (Array.isArray(error.data.errors)) {
          setErrors(error.data.errors);
        } else {
          setErrors(error.data);
        }
      });
  };

  return (
    <>
      <div>
        <Modal
          isOpen={modal.visible}
          className="journals-import"
          centered={true}
          toggle={modal.remove}
          fade={false}
        >
          <div className="modal-header">
            <h2 className="color-dark modal-title">
              {Labels.Organisations.reportingMasterData.Import.title}
            </h2>

            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={modal.remove}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <ModalBody>
            {errors.length ? (
              <div className="mb-2">
                <Alert color="danger" toggle={resetErrors}>
                  <ul className="error-list">
                    {errors.map((error, index) => {
                      return <li key={index}>{error.errorMessage}</li>;
                    })}
                  </ul>
                </Alert>
              </div>
            ) : null}
            <>
              <h4>
                {
                  Labels.Organisations.reportingMasterData.Import.Steps.one
                    .heading
                }
              </h4>
              <p className="step-content">
                {Labels.Journals.Import.Steps.one.content}
              </p>
              <DownloadLink {...sampleCSVDownloadOptions} onError={onError}>
                <span className="body-secondary step-content d-inline-flex">
                  {Labels.CommonModals.downloadCsvTpl}
                </span>
              </DownloadLink>
            </>
            <>
              <h4 className="step-content">
                {
                  Labels.Organisations.reportingMasterData.Import.Steps.two
                    .heading
                }
              </h4>
              <p className="step-content">
                {
                  Labels.Organisations.reportingMasterData.Import.Steps.two
                    .content
                }
              </p>
            </>{" "}
            <>
              <div className="step-content">
                <DragAndDropFileUpload
                  onFileSelection={onFilesSelection}
                  onError={onError}
                  label={Labels.CommonModals.uploadFileLabel}
                  button={true}
                  uploadConfig={uploadOptions}
                />
              </div>
            </>
            <div className="float-left">
              {showWarning ? (
                <DisplayNote
                  message={
                    Labels.Organisations.reportingMasterData.Import
                      .reImportingWarning
                  }
                  type="warning"
                  className="my-2 align-items-center"
                />
              ) : undefined}
            </div>
            <div className="step-content text-center">
              <Button
                color="primary"
                disabled={!attachment.length > 0}
                onClick={handleImport}
              >
                {Labels.Journals.import}
              </Button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
});
