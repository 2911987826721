import NiceModal, { useModal } from "@ebay/nice-modal-react";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Labels } from "../../../../constants/Constants";
import DisplayAlerts from "../../../../elements/DisplayAlerts";
import { serializeDate } from "../../../../utils/date";
import { rejectEAMData } from "../../../../utils/http/endpoints";
import { httpRequest } from "../../../../utils/http/httpRequest";

export default NiceModal.create(
  ({ orgId, assetId, refetch, reset, filters }) => {
    const [comment, setComment] = useState("");
    const modal = useModal();

    const putRejectionReason = async () => {
      return httpRequest({
        method: "post",
        url: rejectEAMData(orgId),
        data: {
          assets: [assetId],
          period: serializeDate(filters.period),
          rejctReason: comment
        }
      })
        .then((res) => Promise.resolve(res?.data))
        .catch((err) => Promise.reject(err));
    };

    const mutation = useMutation(putRejectionReason, {
      onSuccess: () => {
        reset();
        modal.remove();
        setComment("");
        refetch();
      }
    });

    const handleSubmit = (evt) => {
      evt.preventDefault();
      mutation.mutate();
    };

    return (
      <Modal
        isOpen={modal.visible}
        toggle={modal.remove}
        backdrop="static"
        keyboard={false}
        fade={false}
        centered
      >
        <ModalHeader
          className="pb-0"
          toggle={() => {
            modal.remove();
            setComment("");
          }}
        >
          <div>
            <h2 className="color-dark modal-title">
              {Labels.Organisations.importData.rejectData}
            </h2>
          </div>
        </ModalHeader>
        <ModalBody>
          {mutation.isError ? (
            <DisplayAlerts
              className="mt-4"
              alerts={mutation.error}
              type="danger"
            />
          ) : null}
          <div className="comments-box m-0 border-0 shadow-none p-0">
            <label htmlFor="comments">
              {Labels.Payments.Invoices.comments}
            </label>
            <textarea
              id="comments"
              className="form-control"
              rows={10}
              maxLength={512}
              value={comment}
              onChange={(event) => {
                setComment(event.target.value);
              }}
            ></textarea>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={handleSubmit}
            disabled={!comment || mutation.isLoading}
          >
            {mutation.isLoading
              ? Labels.Payments.Invoices.submitting
              : Labels.Payments.Invoices.submit}
          </Button>
          <Button color="secondary" onClick={modal.remove}>
            {Labels.CommonModals.cancel}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
);
