import React, { useState, useEffect, useReducer, useRef } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import InvoicePayments from "./InvoicePayments";
import ReceiptAllocations from "./PurchaseCreditNote/ReceiptAllocations";
import {
  getAssetById,
  getInvoiceDetailsById,
  updateInvoice
} from "../../../../../utils/http/endpoints";
import { Col, Container, Row } from "reactstrap";
import { Labels, Privileges } from "../../../../../constants/Constants";
import DocsViewer from "../../../../../elements/DocsViewer";
import RouteLeavingGuard from "../../../../../elements/modals/RouteLeavingGuard";
import InvoiceStatuses from "../../../../../constants/InvoiceStatuses";
import { useMutation, useQuery, useQueryClient } from "react-query";
import QueryKeys from "../../../../../constants/QueryKeys";
import Auth from "../../../../../auth/Auth";
import InvoiceControlBar from "./InvoiceControlBar";
import { getSymbolFromCurrencyCode } from "../../../../../utils/currency/currency";
import moment, { isDate } from "moment";
import reducer from "./operational-operational/reducer";
import initialState from "./operational-operational/initialState";
import { formatAmountWithThousandSeparatorsToPrecision } from "../../../../../utils/number";
import InvoiceFormContext from "./operational-operational/InvoiceFormContext";
import useQueryParams from "../../../../../hooks/useQueryParams";
import { InvoiceActions } from "../../../../../constants/InvoiceActionsMap";
import useNavigationContext, {
  navigationContexts
} from "../../../../../hooks/useNavigationContext";
import { Helmet } from "react-helmet";
import useGetAssetList from "../../../../../hooks/useGetAssetList";
import InvoiceAlerts from "./InvoiceAlerts";
import { PurchaseInvoiceTypesEnum } from "../../../../../constants/PurchaseInvoiceTypes";
import useGetPostingPeriod from "../../../../../hooks/useGetPostingPeriod";
import { serializeDate } from "../../../../../utils/date";
import PostingPeriodModules from "../../../../../constants/PostingPeriodModules";
import {
  RESET_INVOICE_SAVED_MESSAGE,
  SET_INVOICE_SAVED_MESSAGE
} from "./operational-operational/actionsTypes";
import { resolveAPIPromise } from "../../../../../utils/promises";
import { httpRequest } from "../../../../../utils/http/httpRequest";
import InvoiceDetails from "./InvoiceDetails";
import { useDisclosure } from "@mantine/hooks";
import classNames from "classnames";
import HistoryCommentsAndAttachmentsContainer from "../../../../../elements/history-and-attachments/HistoryCommentsAndAttachmentsContainer";
import useGetPrepaymentsData from "./useGetPrepaymentsData";
import { isJournalEmpty } from "./operational-operational/JournalManagement";
import { fixedEncodeURIComponent } from "../../../../../utils/string";

export default function InvoiceEnrichment(props) {
  const [invoiceDocs, setInvoiceDocs] = useState([]);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [isFormReadOnly, setIsFormReadOnly] = useState(false);
  const history = useHistory();
  const [payments, setPayments] = useState(null);
  const queryClient = useQueryClient();
  const queryParams = useQueryParams();
  const queryParamsWithoutPage = useState(() => {
    const params = new URLSearchParams(queryParams.toString());
    params.delete("page");
    return params;
  });

  const [isSuccessMsgVisible, setSuccessMsgVisible] = useState(false);
  const onSuccessDismiss = () => setSuccessMsgVisible(false);
  const [formErrors, setFormErrors] = useState([]);
  const onErrorsDismiss = () => setFormErrors([]);
  const assetList = useGetAssetList(false);
  const updateAmountsOnlyRef = useRef(null);

  const [state, dispatch] = useReducer(reducer, initialState);
  const formatAmount = (number, useInvoiceCurrency) => {
    const _currency = useInvoiceCurrency
      ? getSymbolFromCurrencyCode(invoiceDetails?.invoiceCurrency)
      : currency;
    if (!isNaN(parseFloat(number))) {
      return (
        _currency +
        " " +
        formatAmountWithThousandSeparatorsToPrecision(
          parseFloat(number).toFixed(2)
        )
      );
    } else {
      return "-";
    }
  };

  const { lineItems } = state;

  const currentActionNameRef = useRef("");
  const isUserConfirmedForVoidActionRef = useRef(false);
  const isCreditNote = () =>
    invoiceDetails?.invoiceType ===
    PurchaseInvoiceTypesEnum.PURCHASE_CREDIT_NOTE;

  const moveToNextInvoice = () => {
    if (currentActionNameRef.current !== InvoiceActions.save) {
      if (!getLinkedInvoiceData(Labels.CommonModals.next).isDisabled) {
        currentActionNameRef.current = null;
        if (!isFormChanged) {
          setFormErrors([]);
          setSuccessMsgVisible(false);
        }
        history.replace(getLinkedInvoiceData(Labels.CommonModals.next).to);
      } else {
        handleBackToListingClick();
      }

      return true;
    } else {
      return false;
    }
  };

  const { data: assetDetails } = useQuery(
    [QueryKeys.ASSET_DETAILS, props.match.params.assetId],
    () =>
      httpRequest({
        method: "get",
        url: getAssetById(
          props.match.params.assetId,
          navigation.pageContext === navigationContexts.underAnAsset
        )
      })
        .then((response) => response.data)
        .catch((error) => error.data),
    {
      placeholderData: {},
      refetchOnWindowFocus: false
    }
  );

  const navigation = useNavigationContext();
  const { data: invoiceDetails } = useQuery(
    [
      QueryKeys.INVOICE_DETAILS,
      +props.match.params.assetId,
      +props.match.params.invoiceId,
      queryParamsWithoutPage.toString()
    ],
    () => {
      let sortAndFiltersParams = new URLSearchParams(queryParams.toString());

      if (
        navigation.pageContext === navigationContexts.underAnOrg &&
        !queryParams.get("filter")?.includes("assetId")
      ) {
        sortAndFiltersParams.set(
          "filter",
          (queryParams.get("filter") || "") +
            (queryParams.get("filter") ? "," : "") +
            "assetId:" +
            assetList.map((i) => i.value).join("|")
        );
      } else if (
        navigation.pageContext === navigationContexts.underAnAsset &&
        !queryParams.get("filter")?.includes("assetId")
      ) {
        sortAndFiltersParams.set(
          "filter",
          (queryParams.get("filter") || "") +
            (queryParams.get("filter") ? "," : "") +
            "assetId:" +
            props.match.params.assetId
        );
      }

      return httpRequest({
        method: "get",
        url:
          getInvoiceDetailsById(
            props.match.params.assetId,
            props.match.params.invoiceId
          ) +
          "?" +
          sortAndFiltersParams.toString()
      })
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error.data.errors));
    },
    {
      onSuccess: (data) => {
        // Disable form if invoice is not in draft or awaiting approval state and user not have edit invoice privillege
        if (
          ![InvoiceStatuses.DRAFT, InvoiceStatuses.AWAITING_APPROVAL].includes(
            data.invoiceStatus
          ) ||
          !Auth.getUserPrivileges().includes(Privileges.EDIT_INVOICE)
        ) {
          setIsFormReadOnly(true);
        } else {
          setIsFormReadOnly(false);
        }
      },
      onError: (error) => {
        if (error[0].errorCode === "400") {
          setFormErrors([]);
          onSuccessDismiss();
          isUserConfirmedForVoidActionRef.current = false;
          setIsFormChanged(false);
          dispatch({
            type: RESET_INVOICE_SAVED_MESSAGE
          });
          moveToNextInvoice();
        }
      },
      enabled: !!assetList?.length,
      placeholderData: {},
      refetchOnWindowFocus: false
    }
  );

  const [selectedLineItemMonths, setSelectedLineItemMonths] = useState([]);
  const selectAllPrepaymentJournals = (data) => {
    const allSelectedLineItemMonths = [
      ...data.prepaymentJournalDataList.map((lineItem) => [
        ...lineItem.journalPostingDataList
          .filter((i) => i.journalPostingLineItemDataList?.length > 0)
          .filter(isJournalEmpty)
          .map((journalMonth) =>
            [
              lineItem.invoiceLineItemId,
              journalMonth.month,
              journalMonth.year
            ].join("-")
          )
      ])
    ];
    setSelectedLineItemMonths(allSelectedLineItemMonths.flat());
  };
  const prepaymentdataQuery = useGetPrepaymentsData({
    assetId: invoiceDetails.assetId,
    invoiceId: invoiceDetails.id,
    onSuccess: selectAllPrepaymentJournals,
    enabled: [
      InvoiceStatuses.DRAFT,
      InvoiceStatuses.AWAITING_APPROVAL
    ].includes(invoiceDetails.invoiceStatus)
  });

  const postingPeriodQuery = useGetPostingPeriod({
    assetId: props.match.params.assetId,
    instrumentDate: invoiceDetails?.invoiceDate
      ? serializeDate(new Date(invoiceDetails.invoiceDate))
      : null,
    module: PostingPeriodModules.AP
  });

  const refreshInvoiceData = () => {
    // TODO: Invalidate specific query
    queryClient.invalidateQueries(QueryKeys.INVOICE_DETAILS);
    queryClient.invalidateQueries([QueryKeys.getInvoiceHistoryAndComments]);
  };

  const processInvoiceDocs = (documents) => {
    const docs = [];
    documents.forEach((doc) => {
      // If file format is not supported then return null
      let fileFormat = null;

      try {
        fileFormat = doc.cloudStorageUrl.match(/\.[a-z]+$/i)[0].toLowerCase();
      } catch (error) {
        console.error("No file format found");
      }

      // Components expects an array of documents
      docs.push({
        fileName: doc.fileName,
        fileURL: doc.cloudStorageUrl,
        fileFormat,
        fromEmailAddress: doc.uploaded ? null : doc.fromEmailAddress
      });
    });
    setInvoiceDocs(docs);
  };

  useEffect(() => {
    if (Object.keys(invoiceDetails || {}).length) {
      processInvoiceDocs(invoiceDetails.documents || []);
    }
  }, [invoiceDetails]);

  const handleBackToListingClick = (event) => {
    if (event) {
      event.preventDefault();
    }

    const currentNumber = invoiceDetails?.currentNumber || 0;
    const assetId = +props.match.params.assetId;
    const invoiceId = +props.match.params.invoiceId;
    if (props.match.params.orgId === undefined) {
      history.push({
        pathname: `/allassets/payments/invoices`,
        search: queryParams.toString(),
        state: {
          fromEnrichmentPage: true,
          assetId,
          invoiceId,
          currentNumber
        }
      });
    } else if (props.location.pathname.match(/orgs/)) {
      history.push({
        pathname: `/orgs/org/${props.match.params.orgId}/payruns/invoices`,
        search: queryParams.toString(),
        state: {
          fromEnrichmentPage: true,
          assetId,
          invoiceId,
          currentNumber
        }
      });
    } else if (props.location.pathname.match(/assets/)) {
      history.push({
        pathname: `/org/${props.match.params.orgId}/assets/asset/${props.match.params.assetId}/payruns/invoices`,
        search: queryParams.toString(),
        state: {
          fromEnrichmentPage: true,
          assetId,
          invoiceId,
          currentNumber
        }
      });
    }
  };

  const currency = assetDetails.currency
    ? getSymbolFromCurrencyCode(assetDetails.currency)
    : "";

  const isLineItemCardHasErrors = (lineItemId) => {
    const lineItem = lineItems.find((i) => i.lineItemId === lineItemId);

    const validations = [
      isJournalPeriodRangeValid(lineItem.journalPeriodRange),
      lineItem.budgetingOption === "budgetLineItem"
        ? lineItem.budgetLineItem
        : lineItem.glAccountCode,
      lineItem.budgetingOption === "poNumber"
        ? lineItem.poNumber &&
          lineItem.poInvoiceScheduleId &&
          lineItem.poInvoiceLineItemId &&
          (lineItem.poInvoiceLineItemId ===
            lineItem.persistedPoNumber?.poInvoiceLineItemId &&
          lineItem.poInvoiceScheduleId ===
            lineItem.persistedPoNumber?.poInvoiceScheduleId
            ? true
            : lineItem.poAvailableAmount >= lineItem.netAmount)
        : true,
      lineItem.budgetingOption !== "",
      !lineItem.totalAccruedAmountError
    ];
    return !validations.every((validation) => Boolean(validation));
  };

  const { url } = useRouteMatch();
  const getLinkedInvoiceData = (direction = "") => {
    return {
      to: {
        pathname: url
          .replace(
            invoiceDetails.assetId,
            invoiceDetails[direction.toLowerCase()]?.assetId
          )
          .replace(
            invoiceDetails.id,
            invoiceDetails[direction.toLowerCase()]?.invoiceId
          ),
        search: queryParams.toString()
      },
      isDisabled: !!(
        !invoiceDetails || !invoiceDetails[direction.toLowerCase()]
      )
    };
  };

  const currentNumber = invoiceDetails?.currentNumber;
  useEffect(() => {
    if (currentNumber) {
      setFormErrors([]);
      setSuccessMsgVisible(false);
      dispatch({
        type: SET_INVOICE_SAVED_MESSAGE,
        payload: {
          userAction: ""
        }
      });
    }
  }, [currentNumber]);

  const [activeTab, setActiveTab] = useState(
    Labels.Payments.Invoices.Payments.details
  );

  const handleTabChange = (event, tabName) => {
    if (tabName) {
      return setActiveTab(tabName);
    }

    setActiveTab(
      event.target.dataset.tabName || event.currentTarget.dataset.tabName
    );
  };

  const [isLineItemDataEdited, lineItemDataEditStatusHandlers] =
    useDisclosure(false);
  const editInvoiceMutation = useMutation(
    (data) =>
      resolveAPIPromise(
        httpRequest({
          method: "put",
          url: updateInvoice(
            props.match.params.assetId,
            props.match.params.invoiceId
          ),
          data
        })
      ),
    {
      onSuccess: (data) => {
        updateAmountsOnlyRef.current = true;
        queryClient.invalidateQueries([
          QueryKeys.getOutstandingPaymentLineItems,
          {
            assetId: props.match.params.assetId,
            invoiceId: props.match.params.invoiceId
          }
        ]);
        refreshInvoiceData();
        lineItemDataEditStatusHandlers.close();
      }
    }
  );
  const saveInvoiceDetails = (isDetails, data) => {
    // If isDetails is false then given data is invoice line items & coming from line items component
    const putData = {
      assetId: invoiceDetails.assetId,
      creditNoteAssociatedInvoiceId:
        invoiceDetails.creditNoteAssociatedInvoiceId || null,
      draftDocument: invoiceDetails.documents.find(
        (document) => document.uploaded === false
      ),
      exchangeRate: invoiceDetails.exchangeRate,
      invoiceCurrency: invoiceDetails.invoiceCurrency,
      invoiceDate: moment(invoiceDetails.invoiceDate).format("YYYY-MM-DD"),
      invoiceDueDate: invoiceDetails.invoiceDueDate
        ? moment(invoiceDetails.invoiceDueDate).format("YYYY-MM-DD")
        : null,
      invoiceLineItems: isDetails
        ? invoiceDetails.invoiceLineItems
        : invoiceDetails.invoiceLineItems.map((lineItem) =>
            lineItem.id === data.id ? data : lineItem
          ),
      invoiceNumber: invoiceDetails.invoiceNumber,
      invoiceType: invoiceDetails.invoiceType,
      supplierId: invoiceDetails.supplierId,
      version: invoiceDetails.version,
      description: fixedEncodeURIComponent(data.description),
      ...data
    };
    return editInvoiceMutation.mutate(putData);
  };

  const value = {
    state,
    dispatch,
    setIsFormChanged,
    formatAmount,
    saveInvoiceDetails,
    editInvoiceMutation,
    activeTab,
    handleTabChange,
    isLineItemDataEdited,
    lineItemDataEditStatusHandlers,
    updateAmountsOnlyRef,
    postingPeriodQuery
  };

  if (!invoiceDetails?.id) {
    return null;
  }

  return (
    <InvoiceFormContext.Provider value={value}>
      <Helmet>
        <title>
          {sessionStorage.getItem("Auth.tenantName")
            ? `${sessionStorage.getItem("Auth.tenantName")} - `
            : ""}
          {`${
            isCreditNote()
              ? Labels.Payments.Invoices.purchaseCreditNote
              : Labels.Payments.Invoices.purchaseInvoice
          } | ${invoiceDetails?.supplierName || ""}`}
        </title>
      </Helmet>
      <Container fluid>
        <div className="animated fadeIn invoice-enrichment">
          <div className="control-bar-container z-index-1030">
            <div>
              <InvoiceControlBar
                invoiceDetails={invoiceDetails}
                currency={currency}
                isSuccessMsgVisible={isSuccessMsgVisible}
                setSuccessMsgVisible={setSuccessMsgVisible}
                handleBackToListingClick={handleBackToListingClick}
                refreshInvoiceData={refreshInvoiceData}
                onSuccessDismiss={onSuccessDismiss}
                setFormErrors={setFormErrors}
                formErrors={formErrors}
                onErrorsDismiss={onErrorsDismiss}
                isFormChanged={isFormChanged}
                payments={payments}
                currentActionNameRef={currentActionNameRef}
                moveToNextInvoice={moveToNextInvoice}
                dispatch={dispatch}
                isUserConfirmedForVoidActionRef={
                  isUserConfirmedForVoidActionRef
                }
                // prevInvoiceStatusRef={prevInvoiceStatusRef}
                isLineItemCardsHasErrors={lineItems.some((lineItem) =>
                  isLineItemCardHasErrors(lineItem.lineItemId)
                )}
                getLinkedInvoiceData={getLinkedInvoiceData}
                primaryOrgId={
                  assetDetails.assetOrganizations?.find(
                    (asset) => asset?.isPrimaryOrganization
                  )?.organizationId
                }
                prepaymentdataQuery={prepaymentdataQuery}
                setSelectedLineItemMonths={setSelectedLineItemMonths}
                selectedLineItemMonths={selectedLineItemMonths}
                selectAllPrepaymentJournals={selectAllPrepaymentJournals}
              />
              <div className="control-bar-border"></div>
            </div>
          </div>
          <Row className="mb-5">
            <InvoiceAlerts
              isSuccessMsgVisible={isSuccessMsgVisible}
              onSuccessDismiss={onSuccessDismiss}
              formErrors={formErrors}
              onErrorsDismiss={onErrorsDismiss}
              prepaymentdataQuery={prepaymentdataQuery}
              assetDetails={assetDetails}
            />
            <Col lg="6" className="invoice-doc-container">
              <div className="d-flex flex-column">
                <div
                  className={classNames("fv-box p-0 mt-3", {
                    "order-1":
                      invoiceDetails.invoiceStatus !== InvoiceStatuses.DRAFT
                  })}
                >
                  {invoiceDetails?.documents?.length === 0 ? (
                    <span className="d-flex align-items-center justify-content-center">
                      {
                        Labels.Payments.Invoices.Details
                          .noInvoiceDocumentsToDisplay
                      }
                    </span>
                  ) : (
                    <DocsViewer
                      documents={invoiceDocs}
                      viewerHeight="750px"
                      containerId="invoice-tab"
                      showEmailBody
                      emailCloudStorageUrl={
                        invoiceDetails?.documents?.find((doc) => !doc.uploaded)
                          ?.cloudStorageUrlForEmail
                      }
                      fromEmailAddress={
                        invoiceDetails.documents.filter(
                          (doc) => !doc.uploaded
                        )[0].fromEmailAddress
                      }
                    />
                  )}
                </div>
                <div className={"mt-3 body-primary color-text-primary"}>
                  <HistoryCommentsAndAttachmentsContainer
                    module={"purchases"}
                  />
                </div>
              </div>
            </Col>
            <Col lg="6" className="pl-0">
              <div className={"invoice-editor"}>
                <InvoiceDetails
                  setIsFormChanged={setIsFormChanged}
                  isFormChanged={isFormChanged}
                  invoiceDetails={invoiceDetails}
                  payments={payments}
                  assetDetails={assetDetails}
                  isFormReadOnly={isFormReadOnly}
                  setIsFormReadOnly={setIsFormReadOnly}
                  refreshInvoiceData={refreshInvoiceData}
                  isSuccessMsgVisible={isSuccessMsgVisible}
                  setSuccessMsgVisible={setSuccessMsgVisible}
                  onSuccessDismiss={onSuccessDismiss}
                  setFormErrors={setFormErrors}
                  formErrors={formErrors}
                  onErrorsDismiss={onErrorsDismiss}
                  currency={currency}
                  isLineItemCardHasErrors={isLineItemCardHasErrors}
                  postingPeriodQuery={postingPeriodQuery}
                  {...props}
                />
              </div>
              {isCreditNote() ? (
                <ReceiptAllocations
                  invoiceDetails={invoiceDetails}
                  refreshInvoiceData={refreshInvoiceData}
                  {...props}
                />
              ) : (
                <InvoicePayments
                  invoiceDetails={invoiceDetails}
                  payments={payments}
                  setPayments={setPayments}
                  setIsFormReadOnly={setIsFormReadOnly}
                  refreshInvoiceData={refreshInvoiceData}
                  {...props}
                />
              )}
            </Col>
          </Row>
        </div>
        {isFormChanged ? (
          <RouteLeavingGuard
            when={isFormChanged}
            navigate={(path) => {
              setIsFormChanged(false);
              setFormErrors([]);
              setSuccessMsgVisible(false);
              history.replace(path);
            }}
            shouldBlockNavigation={(location) => {
              if (isFormChanged) {
                return true;
              }
              return false;
            }}
          />
        ) : null}
      </Container>
    </InvoiceFormContext.Provider>
  );
}
export function isJournalPeriodRangeValid(dateRange) {
  // Both start/end date should be null/undefined or valid dates
  return (
    dateRange.every((date) => !date) || dateRange.every((date) => isDate(date))
  );
}
