import NiceModal, { useModal } from "@ebay/nice-modal-react";
import HotTable, { HotColumn } from "@handsontable/react";
import { Alert, Modal, ModalBody } from "reactstrap";
import { Labels } from "../../../../../constants/Constants";

export default NiceModal.create(
  ({ varianceBudgetData, title, duration, period, isConsolidationEnabled }) => {
    const modal = useModal();

    let readOnlySetting = {
      readOnly: true
    };
    const varianceBudgetDrilldownDataSchema = {
      glName: null,
      budgetLineItemDescription: null,
      contactName: null,
      amount: null
    };
    return (
      <Modal
        keyboard={false}
        backdrop="static"
        centered
        fade={false}
        style={{ maxWidth: "80vw" }}
        isOpen={modal.visible}
        toggle={modal.remove}
      >
        <div className="modal-header">
          <h2 className="color-dark modal-title">{`${title} ${
            duration > 1 ? "from" : "as on"
          } ${period}`}</h2>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={modal.remove}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody className="mt-0 pt-0">
          <div className="income-statement-transactions-table">
            {varianceBudgetData?.length > 0 ? (
              <HotTable
                data={varianceBudgetData}
                dataSchema={varianceBudgetDrilldownDataSchema}
                fillHandle={false}
                height="64vh"
                stretchH="all"
                disableVisualSelection={false}
                manualColumnResize={false}
                licenseKey={process.env.REACT_APP_HOT_TABLE_LICENSE_KEY}
                renderAllRows={true}
              >
                {isConsolidationEnabled ? (
                  <HotColumn
                    title={Labels.Assets.IncomeStatement.globalCode}
                    data="globalCode"
                    className="text-break"
                    settings={readOnlySetting}
                    width={160}
                  />
                ) : undefined}
                {isConsolidationEnabled ? (
                  <HotColumn
                    title={Labels.Assets.IncomeStatement.globalDescription}
                    data="globalDescription"
                    className="text-break"
                    settings={readOnlySetting}
                    width={180}
                  />
                ) : undefined}
                <HotColumn
                  title={Labels.Assets.IncomeStatement.glName}
                  className="text-break"
                  data="glName"
                  settings={readOnlySetting}
                  width={200}
                />
                <HotColumn
                  title={
                    Labels.Assets.IncomeStatement.budgetLineItemDescription
                  }
                  placeholder={Labels.CommonModals.NA}
                  data="budgetLineItemDescription"
                  settings={readOnlySetting}
                  width={200}
                />
                <HotColumn
                  title={Labels.Assets.IncomeStatement.contactName}
                  data="contactName"
                  settings={readOnlySetting}
                  width={200}
                />
                <HotColumn
                  title={Labels.Assets.IncomeStatement.amount}
                  className="text-right"
                  data="pnlAmount"
                  settings={readOnlySetting}
                  width={160}
                />
              </HotTable>
            ) : undefined}
            {varianceBudgetData?.length === 0 ? (
              <Alert color="info" className="mt-3">
                {Labels.Assets.IncomeStatement.noDataAvailable}
              </Alert>
            ) : undefined}
          </div>
        </ModalBody>
      </Modal>
    );
  }
);
