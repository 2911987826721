import React, { useEffect, useState } from "react";
import { toTitleCase } from "../../../../../utils/string";
import { Labels, Privileges } from "../../../../../constants/Constants";
import { httpRequest } from "../../../../../utils/http/httpRequest";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import {
  convertDateFromUTCToLocal,
  getCurrentPeriodDateLabel,
  getDateInDisplayFormat
} from "../../../../../utils/date";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { getSymbolFromCurrencyCode } from "../../../../../utils/currency/currency";
import { formatAmountWithThousandSeparatorsToPrecision } from "../../../../../utils/number";
import PaymentStatuses from "../../../../../constants/PaymentStatuses";
import Auth from "../../../../../auth/Auth";
import PaymentRejectionModal from "../../Payments/PurchaseInvoices/PaymentRejectionModal";
import useToggle from "../../../../../hooks/useToggle";
import { markAsReceivedAction } from "../../../../../utils/http/endpoints";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import PaymentsVoidActionReasonModal from "./operational-operational/PaymentsVoidActionReasonModal";
import PostingPeriodModules from "../../../../../constants/PostingPeriodModules";
import useGetPostingPeriod from "../../../../../hooks/useGetPostingPeriod";

export default function InvoicePaymentsRow({
  payment,
  invoiceDetails,
  handleEditPayment,
  handleViewPayment,
  handleViewPaymentHistory,
  refreshPayments,
  setIsFormReadOnly,
  refreshInvoiceData,
  setApiErrors,
  setAreAlertsVisible,
  outStandingPaymentsQuery
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  const [postingPeriodData, setPostingPeriodData] = useState({});
  const [isRejectModalOpen, toggleRejectModal] = useToggle();
  const [isVoidModalOpen, toggleVoidModal] = useToggle();

  const [interCompanyPaymentDate, setInterCompanyPaymentDate] = useState(null);
  const isInterCompanyPayment =
    payment.interCompanyAccountId && payment.interCompanyPaymentDate;

  const isInterCompanyError = () => {
    return !!isInterCompanyPayment && postingPeriodData?.postingPeriod
      ? postingPeriodData.postingPeriod !==
          getCurrentPeriodDateLabel(interCompanyPaymentDate)
      : false;
  };

  const isPostingPeriodQueryEnabled =
    !!isInterCompanyPayment &&
    !payment.paymentId &&
    [PaymentStatuses.DRAFT, PaymentStatuses.PAID].includes(payment.status);

  const {
    data: postingPeriodQueryData,
    isError: isPostingPeriodError,
    error: postingPeriodError,
    refetch
  } = useGetPostingPeriod({
    assetId: isPostingPeriodQueryEnabled ? invoiceDetails.assetId : null,
    instrumentDate: convertDateFromUTCToLocal(
      payment.interCompanyPaymentDate,
      "YYYY-MM-DD"
    ),
    module: PostingPeriodModules.AP,
    isBlocking: false,
    options: {
      refetchOnWindowFocus: true
    }
  });

  useEffect(() => {
    if (
      [PaymentStatuses.DRAFT, PaymentStatuses.PAID].includes(payment.status) &&
      postingPeriodQueryData
    ) {
      setPostingPeriodData(postingPeriodQueryData);
    }
  }, [postingPeriodQueryData]);

  const processPayment = (action) => {
    if (isInterCompanyError() || isPostingPeriodError) {
      setAreAlertsVisible(true);
      setApiErrors(
        isPostingPeriodError
          ? postingPeriodError
          : [
              {
                errorMessage: `${getDateInDisplayFormat(
                  interCompanyPaymentDate
                )} ${
                  Labels.Payments.Invoices.Payments.interCompanyTransferError
                }`
              }
            ]
      );
    } else {
      setAreAlertsVisible(false);
      setApiErrors([]);
      httpRequest({
        method: "post",
        url: action.uri,
        data: action
      })
        .then((response) => {
          refreshPayments();
          refreshInvoiceData();
          // If payment is voided, make form editable again
          if (action.actionName === "Void Payment") {
            setIsFormReadOnly(false);
          }
        })
        .catch((error) => {
          setApiErrors(error.data.errors);
        });
    }
  };

  const { assetId, orgId } = useParams();
  const markAsReceivedActionMutation = useMutation(
    ({ paymentId }) =>
      httpRequest({
        method: "put",
        url: markAsReceivedAction(assetId, paymentId)
      }),
    {
      onSuccess: () => {
        refreshPayments();
        refreshInvoiceData();
      },
      onError: (error) => {
        setApiErrors(error.data.errors);
      }
    }
  );

  const isEditPaymentEnabled = () => {
    return [PaymentStatuses.DRAFT].includes(payment.status) &&
      Auth.getUserPrivileges().includes(Privileges.EDIT_PAYMENT)
      ? true
      : false;
  };

  const getPaymentDateLabel = (payment) => {
    if (payment.paymentDate) {
      return (
        <a
          href={`#/org/${orgId}/assets/asset/${assetId}/accounting/bankaccounts/${payment.bankAccountId}/transactions/${payment.bankAccountStatementLineItemId}`}
          target="_blank"
          className="link-primary"
        >
          {convertDateFromUTCToLocal(payment.paymentDate, "DD MMM YYYY")}
        </a>
      );
    } else if (isInterCompanyPayment) {
      return (
        <a
          onClick={() => {
            isEditPaymentEnabled()
              ? handleEditPayment(payment)
              : handleViewPayment(payment);
          }}
          className="link-primary"
        >
          {convertDateFromUTCToLocal(
            payment.interCompanyPaymentDate,
            "DD MMM YYYY"
          )}
        </a>
      );
    } else {
      return <>{"-"}</>;
    }
  };

  const getPaymentTypeLabel = () => {
    return isInterCompanyPayment
      ? Labels.Payments.Invoices.Payments.interCompany
      : Labels.Payments.Invoices.Payments.bankReconciliation;
  };

  useEffect(() => {
    setInterCompanyPaymentDate(
      payment.interCompanyPaymentDate
        ? convertDateFromUTCToLocal(payment.interCompanyPaymentDate)
        : null
    );
    if (isPostingPeriodQueryEnabled) {
      refetch();
    }
  }, [invoiceDetails, payment]);

  const handleVoidAction = () => {
    if (
      [PaymentStatuses.PAID].includes(payment.status) &&
      (isInterCompanyError() || isPostingPeriodError)
    ) {
      setAreAlertsVisible(true);
      setApiErrors(
        isPostingPeriodError
          ? postingPeriodError
          : [
              {
                errorMessage: `${getDateInDisplayFormat(
                  interCompanyPaymentDate
                )} ${
                  Labels.Payments.Invoices.Payments
                    .voidInterCompanyTransferError
                }`
              }
            ]
      );
    } else {
      toggleVoidModal();
      setAreAlertsVisible(false);
      setApiErrors([]);
    }
  };

  return (
    <tr key={payment.id} className="bordered-row">
      <td width="28%">{getPaymentDateLabel(payment)}</td>
      <td width="28%">{getPaymentTypeLabel()}</td>
      <td width="20%">{toTitleCase(payment.status.replace(/_/g, " "))}</td>
      <td width="20%" className="text-right px-0">
        {getSymbolFromCurrencyCode(invoiceDetails.invoiceCurrency)}
        {formatAmountWithThousandSeparatorsToPrecision(payment.amount)}
      </td>
      <td width="8%" className="text-right px-0">
        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
          <DropdownToggle
            tag="span"
            data-testid="hamburger-menu"
            data-toggle="dropdown"
            aria-expanded={dropdownOpen}
            className="cursor-pointer"
          >
            <FontAwesomeIcon icon={faEllipsisV} />
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem
              disabled={
                !Auth.getUserPrivileges().includes(
                  Privileges.VIEW_PAYMENT_HISTORY
                )
              }
              onClick={() => handleViewPaymentHistory(payment)}
            >
              {Labels.Payments.Invoices.Payments.viewPaymentHistory}
            </DropdownItem>
            {isEditPaymentEnabled() ? (
              <DropdownItem onClick={() => handleEditPayment(payment)}>
                {Labels.Payments.Invoices.Payments.editPayment}
              </DropdownItem>
            ) : (
              <DropdownItem onClick={() => handleViewPayment(payment)}>
                {Labels.Payments.Invoices.Payments.viewPayment}
              </DropdownItem>
            )}
            {payment?.actions
              ?.filter(
                (i) =>
                  ![
                    "Reject Payment",
                    "Approve Payments",
                    "Void Payment"
                  ].includes(i.actionName)
              )
              ?.map((action) => {
                return (
                  <DropdownItem
                    key={action.actionName}
                    onClick={() => processPayment(action)}
                  >
                    {action.actionName}
                  </DropdownItem>
                );
              })}

            {payment?.actions?.some(
              (i) => i.actionName === Labels.Payments.Invoices.rejectPayment
            ) ? (
              <DropdownItem onClick={toggleRejectModal}>
                {Labels.Payments.Invoices.rejectPayment}
              </DropdownItem>
            ) : null}

            {payment?.actions?.some(
              (i) => i.actionName === Labels.Payments.Invoices.voidPayment
            ) ? (
              <DropdownItem onClick={handleVoidAction}>
                {Labels.Payments.Invoices.voidPayment}
              </DropdownItem>
            ) : null}

            {payment.status === PaymentStatuses.AWAITING_RECEIPT ? (
              <DropdownItem
                onClick={() =>
                  markAsReceivedActionMutation.mutate({
                    paymentId: payment.id
                  })
                }
              >
                {Labels.Payments.Invoices.markAsReceived}
              </DropdownItem>
            ) : null}
          </DropdownMenu>
        </Dropdown>
      </td>

      {isRejectModalOpen ? (
        <PaymentRejectionModal
          isOpen={isRejectModalOpen}
          toggle={toggleRejectModal}
          refreshPayments={refreshPayments}
          paymentId={payment.id}
          isFormChanged={false}
        />
      ) : null}
      {isVoidModalOpen ? (
        <PaymentsVoidActionReasonModal
          isOpen={isVoidModalOpen}
          toggle={toggleVoidModal}
          paymentAssetId={invoiceDetails?.assetId}
          paymentId={payment.id}
          refreshPayment={refreshPayments}
          refreshInvoiceData={refreshInvoiceData}
          outStandingPaymentsQuery={outStandingPaymentsQuery}
        />
      ) : null}
    </tr>
  );
}
