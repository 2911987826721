import React from "react";
import {
  Badge,
  Card,
  CardText,
  Col,
  Container,
  Row,
  Modal,
  ModalBody,
  ModalHeader
} from "reactstrap";
import classNames from "classnames";
import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Labels } from "../../../../../constants/Constants";
import { getFixedAssetRegisterStatusClass } from "../../../../../utils/string";
import { getSymbolFromCurrencyCode } from "../../../../../utils/currency/currency";
import useToggle from "../../../../../hooks/useToggle";
import ConditionalRenderer from "../../../../../elements/ConditionalRenderer";
import { formatAmountWithThousandSeparatorsToPrecision } from "../../../../../utils/number";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import DisplayAlerts from "../../../../../elements/DisplayAlerts";
import Tooltip from "../../../../../elements/ToolTips/Tooltip";

export default NiceModal.create(({ register, errors }) => {
  const modal = useModal();
  const [isDetailsOpen, toggleDetails] = useToggle();

  const currency = getSymbolFromCurrencyCode(register?.assetCurrency);

  return (
    <div>
      <Modal
        size="xl"
        className="custom-modal-width"
        centered
        isOpen={modal.visible}
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader className="pb-0" toggle={modal.remove}></ModalHeader>
        <ModalBody className="pt-0 mt-2">
          {errors.length > 0 ? (
            <DisplayAlerts alerts={errors} type="danger" />
          ) : null}
          {errors.length === 0 && register?.id ? (
            <>
              <React.Fragment>
                <div
                  className={`card-table payment-purchase-invoice-header-fixed table-fixed-asset-register-noassets`}
                >
                  <Row
                    className="d-none d-md-flex small-gutters"
                    style={{ padding: "10px" }}
                  >
                    <Col
                      xs={12}
                      md
                      className={`mb-0 d-flex flex-column justify-content-end align-items-start`}
                    >
                      <p className="fw-600 pl-4">
                        {Labels.Organisations.fixedAssetRegister.assetClass}
                      </p>
                    </Col>
                    <Col
                      xs={12}
                      md
                      className="mb-0 d-flex flex-column justify-content-end align-items-center"
                    >
                      <p className="fw-600">
                        {Labels.Organisations.fixedAssetRegister.invoiceNumber}
                      </p>
                    </Col>
                    <Col
                      xs={12}
                      md
                      className="mb-0 d-flex flex-column justify-content-end align-items-center"
                    >
                      <p className="fw-600">
                        {Labels.Organisations.fixedAssetRegister.purchaseDate}
                      </p>
                    </Col>
                    <Col
                      xs={12}
                      md
                      className="mb-0 d-flex flex-column justify-content-end align-items-center"
                    >
                      <p className="fw-600 text-center ml-3">
                        {Labels.Organisations.fixedAssetRegister.depStartDate}
                      </p>
                    </Col>
                    <Col
                      xs={12}
                      md
                      className="text-center mb-0 d-flex flex-column justify-content-end align-items"
                    >
                      <p className="fw-600 text-right mr-2">
                        {Labels.Organisations.fixedAssetRegister.cost}
                      </p>
                    </Col>
                    <Col
                      xs={12}
                      md
                      className="mb-0 d-flex flex-column justify-content-end align-items-end"
                    >
                      <p className="fw-600 text-right">
                        {Labels.Organisations.fixedAssetRegister.depAmount}
                      </p>
                    </Col>
                    <Col
                      xs={12}
                      md
                      className="mb-0 d-flex flex-column justify-content-end align-items-end"
                    >
                      <p className="fw-600 text-right">
                        {
                          Labels.Organisations.fixedAssetRegister
                            .depreciationForCurrentFY
                        }
                      </p>
                    </Col>
                    <Col
                      xs={12}
                      md
                      className="mb-0 d-flex flex-column justify-content-end align-items-end"
                    >
                      <p className="fw-600 text-right">
                        {Labels.Organisations.fixedAssetRegister.netBookValue}
                      </p>
                    </Col>
                    <Col
                      xs={12}
                      md
                      className="text-center mb-0 d-flex flex-column justify-content-end align-items-center"
                    >
                      <p className="fw-600">
                        {Labels.Organisations.fixedAssetRegister.status}
                      </p>
                    </Col>
                  </Row>
                </div>
              </React.Fragment>
              <React.Fragment>
                <Card
                  className={classNames(
                    "card-table elev--1 body-primary mb-2",
                    "table-fixed-asset-register-noassets"
                  )}
                >
                  <Row className="small-gutters">
                    <Col xs={12} md className="border-down-sm">
                      <Container fluid>
                        <Row className="mb-2 mt-2">
                          <Col>
                            <span className="d-flex align-items-center">
                              <button
                                className="btn btn-link m-0 p-0"
                                onClick={toggleDetails}
                              >
                                {!isDetailsOpen ? (
                                  <FontAwesomeIcon
                                    size="2x"
                                    icon={faAngleDown}
                                    className="m-0 p-0 mr-1 ml-1 align-middle"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    size="2x"
                                    icon={faAngleRight}
                                    className="m-0 p-0 mr-1 ml-1 align-middle"
                                  />
                                )}
                              </button>
                              <CardText
                                id="fixedAssetClassName"
                                className="cut-content row-header-1"
                              >
                                {register.fixedAssetClassName}
                              </CardText>
                              <Tooltip target="fixedAssetClassName">
                                {register.fixedAssetClassName}
                              </Tooltip>
                            </span>
                          </Col>
                        </Row>
                      </Container>
                    </Col>
                    <Col
                      xs={12}
                      md
                      className="mb-2 mb-md-0 d-flex flex-row flex-md-column justify-content-between justify-content-md-center align-items-center color-text-primary"
                    >
                      <span className="color-text-primary d-md-none">
                        {Labels.Organisations.fixedAssetRegister.invoiceNumber}
                      </span>
                      <CardText
                        className="body-primary cut-content color-text-primary"
                        id="invoiceNumber"
                      >
                        {register.invoiceNumber}
                      </CardText>
                      <Tooltip target="invoiceNumber">
                        {register.invoiceNumber}
                      </Tooltip>
                    </Col>
                    <Col
                      xs={12}
                      md
                      className="mb-2 mb-md-0 d-flex flex-row flex-md-column justify-content-between justify-content-md-center align-items-center color-text-primary"
                    >
                      <span className="color-text-primary d-md-none">
                        {Labels.Organisations.fixedAssetRegister.purchaseDate}
                      </span>
                      <CardText className="body-primary cut-content color-text-primary">
                        {register.purchaseDate}
                      </CardText>
                    </Col>
                    <Col
                      xs={12}
                      md
                      className="mb-2 mb-md-0 d-flex flex-row flex-md-column justify-content-between justify-content-md-center align-items-center color-text-primary"
                    >
                      <span className="color-text-primary d-md-none">
                        {
                          Labels.Organisations.fixedAssetRegister
                            .depreciationStartDate
                        }
                      </span>
                      <CardText className="body-primary cut-content color-text-primary ml-3">
                        {register.depreciationStartDate}
                      </CardText>
                    </Col>
                    <Col
                      xs={12}
                      md
                      className="mb-2 mb-md-0 d-flex flex-row flex-md-column justify-content-between justify-content-md-center align-items-end color-text-primary"
                    >
                      <span className="color-text-primary d-md-none">
                        {Labels.Organisations.fixedAssetRegister.cost}
                      </span>
                      <CardText className="body-primary cut-content color-text-primary mr-2">
                        {register.cost
                          ? `${currency}${formatAmountWithThousandSeparatorsToPrecision(
                              register.cost
                            )}`
                          : "-"}
                      </CardText>
                    </Col>
                    <Col
                      xs={12}
                      md
                      className="d-none d-md-flex mb-2 mb-md-0 flex-row flex-md-column justify-content-between justify-content-md-center align-items-end"
                    >
                      <span className="color-text-primary d-md-none">
                        {
                          Labels.Organisations.fixedAssetRegister
                            .depreciationAmount
                        }
                      </span>
                      <CardText className="body-primary cut-content color-text-primary">
                        {register.depreciationAmount
                          ? `${currency}${formatAmountWithThousandSeparatorsToPrecision(
                              register.depreciationAmount
                            )}`
                          : "-"}
                      </CardText>
                    </Col>
                    <Col
                      xs={12}
                      md
                      className="d-none d-md-flex mb-2 mb-md-0 flex-row flex-md-column justify-content-between justify-content-md-center align-items-end"
                    >
                      <span className="color-text-primary d-md-none">
                        {
                          Labels.Organisations.fixedAssetRegister
                            .depreciationForCurrentFY
                        }
                      </span>
                      <CardText className="body-primary cut-content color-text-primary">
                        {register.depreciationAmountForFY
                          ? `${currency}${formatAmountWithThousandSeparatorsToPrecision(
                              register.depreciationAmountForFY
                            )}`
                          : "-"}
                      </CardText>
                    </Col>
                    <Col
                      xs={12}
                      md
                      className="d-none d-md-flex mb-2 mb-md-0 flex-row flex-md-column justify-content-between justify-content-md-center align-items-end"
                    >
                      <span className="color-text-primary d-md-none">
                        {Labels.Organisations.fixedAssetRegister.netBookValue}
                      </span>
                      <CardText className="body-primary cut-content color-text-primary">
                        {register.netBookValue
                          ? `${currency}${formatAmountWithThousandSeparatorsToPrecision(
                              register.netBookValue
                            )}`
                          : "-"}
                      </CardText>
                    </Col>
                    <Col
                      xs={12}
                      md
                      className="mb-2 mb-md-0 d-flex flex-row flex-md-column justify-content-between justify-content-md-center align-items-center"
                    >
                      <span className="color-text-primary d-md-none">
                        {Labels.Organisations.fixedAssetRegister.status}
                      </span>
                      <Badge
                        className={`text-nowrap ${getFixedAssetRegisterStatusClass(
                          register?.status
                        )}`}
                      >
                        {register.status}
                      </Badge>
                    </Col>
                  </Row>
                  <ConditionalRenderer conditions={[!isDetailsOpen]}>
                    <Row
                      className="invoice-card-details-container"
                      style={{
                        marginLeft: "0px",
                        marginRight: "0px"
                      }}
                    >
                      <div className="invoice-card-details-wrapper w-100 column-header-1">
                        <div className="journal-entry ">
                          <div className="journal-line-items col p-0 ">
                            <div className="row header py-0 mb-2">
                              <div className={`row col-8 p-0 pl-1`}>
                                <div className="col-3 label">
                                  {
                                    Labels.Organisations.fixedAssetRegister
                                      .assetDescription
                                  }
                                </div>
                                <div className="col-3 label">
                                  {
                                    Labels.Organisations.fixedAssetRegister
                                      .balanceSheetCodeDescription
                                  }
                                </div>
                                <div className="col-2 label">
                                  {
                                    Labels.Organisations.fixedAssetRegister
                                      .pAndLCodeDescription
                                  }
                                </div>
                                <div className="col-3 label pl-5">
                                  {
                                    Labels.Organisations.fixedAssetRegister
                                      .grossBlockCostCodeDescription
                                  }
                                </div>
                              </div>
                              <div className="row col-4 p-0 ml-4">
                                <div className="col-4 label">
                                  {
                                    Labels.Organisations.fixedAssetRegister
                                      .depreciationMethod
                                  }
                                </div>
                                <div className="col-4 label text-right pr-1 pl-0">
                                  {
                                    Labels.Organisations.fixedAssetRegister
                                      .usefulLife
                                  }
                                </div>
                                <div className="col-4 label text-right ">
                                  {Labels.Organisations.fixedAssetRegister.rate}
                                </div>
                              </div>
                            </div>
                            <div className="journal-line-item col row mr-0 small-text pr-0 color-text-secondary mb-2">
                              <div className={`row col-8 p-0 pl-1`}>
                                <div className="col-3 ">
                                  {register.description}
                                </div>
                                <div className="col-3">
                                  {register.balanceSheetCodeAndDesc}
                                </div>
                                <div className="col-2">
                                  {register.plCodeAndDesc}
                                </div>
                                <div className="col-3 pl-5">
                                  {register.glCodeAndDesc}
                                </div>
                              </div>
                              <div className="row col-4 p-0 ml-4">
                                <div className="col-4">
                                  {register.depreciationMethod}
                                </div>
                                <div className="col-4 text-right pr-1 pl-0">
                                  {register.effectiveLife || "-"}
                                </div>
                                <div className="col-4 text-right">
                                  {register.rate || "-"}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Row>
                  </ConditionalRenderer>
                </Card>
              </React.Fragment>
            </>
          ) : null}
        </ModalBody>
      </Modal>
    </div>
  );
});
