import React from "react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useState } from "react";
import { Labels } from "../../../constants/Constants";
import useTaskStatusUpdate from "./Tasks/useTaskStatusUpdate";
import DisplayAlerts from "../../../elements/DisplayAlerts";
import useBulkTaskStatusUpdate from "./Tasks/useBulkTaskStatusUpdate";

export default NiceModal.create(
  ({
    assetId,
    taskId,
    isRepeat,
    queryParams,
    onComplete,
    canUpdateBulkStatus = false
  }) => {
    const modal = useModal();
    const [comment, setComment] = useState("");

    const complete = (isSuccess, comment = "") => {
      if (isSuccess) {
        setComment("");
        modal.remove();
        onComplete(isSuccess, {
          comment
        });
      }
    };

    const mutationOptions = {
      onSuccess: (res) => {
        if (res.status === 200) {
          complete(true);
        }
      },
      onError: () => {
        complete(false);
      }
    };

    const mutation = canUpdateBulkStatus
      ? useBulkTaskStatusUpdate(mutationOptions)
      : useTaskStatusUpdate(mutationOptions);

    const handleSubmit = (evt) => {
      evt.preventDefault();
      mutation.mutate({ comment, queryParams, assetId, taskId });
    };

    return (
      <Modal
        isOpen={modal.visible}
        toggle={modal.remove}
        backdrop="static"
        keyboard={false}
        fade={false}
        centered
      >
        <ModalHeader className="pb-0" toggle={modal.remove}>
          <h2 className="color-dark modal-title">{Labels.Tasks.archiveTask}</h2>
        </ModalHeader>
        <ModalBody>
          {mutation.isError && (
            <DisplayAlerts
              className="mt-0"
              alerts={mutation.error}
              type="danger"
              onAlertDismiss={() => mutation.reset()}
            />
          )}
          <div className="comments-box m-0 border-0 shadow-none p-0">
            <label htmlFor="comments">
              {Labels.Payments.Invoices.comments}
            </label>
            <textarea
              id="comments"
              className="p-2"
              rows={10}
              maxLength={512}
              value={comment}
              onChange={(event) => {
                setComment(event.target.value);
              }}
            />
          </div>
          {isRepeat && (
            <p className="my-2">
              <span className="row-header-2">{Labels.CommonModals.note}</span>
              {Labels.Tasks.archiveModalNoteMessage}
            </p>
          )}
        </ModalBody>
        <ModalFooter className="p-2">
          <Button
            color="primary"
            onClick={handleSubmit}
            disabled={!comment || mutation.isLoading}
          >
            {mutation.isLoading ? Labels.Tasks.submitting : Labels.Tasks.submit}
          </Button>
          <Button color="secondary" onClick={modal.remove}>
            {Labels.CommonModals.cancel}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
);
