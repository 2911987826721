import React, { useContext } from "react";
import { Modal, ModalBody, Alert, ModalHeader } from "reactstrap";
import InvoiceFormContext from "./InvoiceFormContext";
import { Labels } from "../../../../../../constants/Constants";
import { formatAmount } from "../../../../../../utils/number";
import { getDateInDisplayFormat } from "../../../../../../utils/date";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useRouteMatch } from "react-router-dom";
import { UPDATE_JOURNAL_RANGE } from "./actionsTypes";

export default function POInvoiceSelectorModal({
  isOpen,
  toggle,
  poInvoicesListData,
  poInvoiceScheduleId,
  poInvoiceLineItemId,
  prevPoInvoiceLineItemId,
  lineItemId,
  handleInvoiceSelection,
  currency,
  poUrl,
  lineItemNetAmount
}) {
  const routeMatch = useRouteMatch();

  const {
    state: { lineItems },
    dispatch
  } = useContext(InvoiceFormContext);

  const invoicesLineItemsConsumedByCurrentInvoice = lineItems
    .filter((i) => i.lineItemId !== lineItemId)
    .map((i) => i.poInvoiceLineItemId);

  const invoicesConsumedByCurrentInvoice = lineItems
    .filter((i) => i.lineItemId !== lineItemId)
    .map((i) => i.poInvoiceScheduleId);

  const availableInvoiceLineItems = poInvoicesListData.estimatedInvoices
    .map((i) => i.lineItems)
    .flat()
    .filter((lineItem) => {
      if (
        invoicesLineItemsConsumedByCurrentInvoice.includes(lineItem.id) &&
        invoicesConsumedByCurrentInvoice.includes(lineItem.invoiceId)
      ) {
        // If this invoice is selected any other line item in current invoice
        return false;
      } else if (
        // invoiceLineItemId should be null & this check should't be done for prev values
        lineItem.invoiceLineItemId &&
        lineItem.id !== prevPoInvoiceLineItemId
      ) {
        // If this invoice is selected any other invoice and
        // it should be not equal to the previously selected(persisted invoice) invoice for this line item
        return false;
      }

      return true;
    });

  const totalNoOfLineItems = poInvoicesListData.estimatedInvoices.reduce(
    (acc, invoice) => acc + invoice.lineItems.length,
    0
  );

  const handleSelectOnClick = (lineItem) => {
    dispatch({
      type: UPDATE_JOURNAL_RANGE,
      payload: {
        lineItemId,
        journalPeriodRange: [
          new Date(lineItem.lineItemPeriodFrom),
          new Date(lineItem.lineItemPeriodTo)
        ]
      }
    });

    handleInvoiceSelection({
      poInvoiceLineItemId: lineItem.id,
      poInvoiceScheduleId: lineItem.invoiceId,
      glAccountId: lineItem.glAccountId
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      backdrop="static"
      keyboard={false}
      size="xl"
      centered
    >
      <ModalHeader className="pb-0" toggle={toggle}>
        <div>
          <h2 className="color-dark modal-title">
            {
              Labels.Payments.Invoices.Details
                .poLineItemToInvoiceLineItemAssociation
            }
          </h2>
        </div>
      </ModalHeader>
      <ModalBody className="m-0 body-primary color-text-primary">
        <div>
          {/* - Show error if all invoices are consumed or - If available
              invoices list is zero (if this current invoice consumes other
              available invoices) */}
          {!availableInvoiceLineItems.length && !poInvoiceScheduleId ? (
            <Alert color="danger" role="alert">
              {Labels.Payments.Invoices.Details.allInvoicesConsumedError1}
              <a href={poUrl} target="_blank" rel="noopener noreferrer">
                {Labels.Payments.Invoices.Details.purchaseOrder}
              </a>
              {Labels.Payments.Invoices.Details.allInvoicesConsumedError2}
            </Alert>
          ) : null}
        </div>

        <div>
          <div className="mb-2">
            {`${Labels.Payments.Invoices.Details.thisPoHas} ${totalNoOfLineItems} ${Labels.Payments.Invoices.Details.estimatedInvoicesSelectALineItemToAssociateWithTheCurrentInvoice}`}
          </div>
          <div className="mb-2">
            {Labels.Payments.Invoices.Details.purchaseOrder} -{" "}
            <a
              href={poUrl}
              target="_blank"
              rel="noopener noreferrer"
            >{`${poInvoicesListData.poDescription} - ${poInvoicesListData.id}`}</a>
          </div>
        </div>
        {poInvoicesListData.estimatedInvoices.map((invoice, index) => (
          <React.Fragment key={invoice.id}>
            <div className="po-invoice-selections-modal body-primary color-text-primary">
              <div>
                <h3 className="mb-3">{`Invoice ${index + 1}`}</h3>
                <div className="invoice-data border-bottom-fv-border-1px mb-3 pb-3">
                  <div>
                    <span className="column-header-1 color-dark">
                      {Labels.Payments.Invoices.Details.estimatedInvoiceDate}
                    </span>
                    <span>{getDateInDisplayFormat(invoice.invoiceDate)}</span>
                  </div>

                  <div>
                    <span className="column-header-1 color-dark">
                      {Labels.Payments.Invoices.Details.estimatedInvoicePayDate}
                    </span>
                    <span>
                      {getDateInDisplayFormat(new Date(invoice.invoicePayDate))}
                    </span>
                  </div>

                  <div>
                    <span className="column-header-1 color-dark">
                      {Labels.Payments.Invoices.Details.estInvoiceValue}
                    </span>
                    <span className="text-right">
                      {formatAmount(invoice.totalInvoiceValue, currency)}
                    </span>
                  </div>

                  <div>
                    <span className="column-header-1 color-dark">
                      {Labels.Payments.Invoices.Details.actualInvoiceAmount}
                    </span>
                    <span className="text-right">
                      {formatAmount(invoice.actualInvoiceAmount, currency)}
                    </span>
                  </div>
                </div>

                <h3 className="mb-3">
                  {Labels.Payments.Invoices.Details.lineItems}
                </h3>

                <div className="line-item-rows">
                  <span className="column-header-1 color-dark">
                    {Labels.Payments.Invoices.Details.productDescDot}
                  </span>
                  <span className="column-header-1 color-dark">
                    {Labels.Payments.Invoices.Details.budgetLineDescDot}
                  </span>
                  <span className="column-header-1 color-dark">
                    {Labels.Payments.Invoices.Details.gLCodeDescDot}
                  </span>
                  <span className="column-header-1 color-dark">
                    {Labels.Payments.Invoices.Details.netAmount}
                  </span>
                  <span className="column-header-1 color-dark">
                    {Labels.Payments.Invoices.Details.grossAmount}
                  </span>
                  <span className="column-header-1 color-dark">
                    {Labels.Payments.Invoices.Details.linkedInvoiceNo}
                  </span>
                  <span className=""></span>

                  {invoice.lineItems.map((lineItem) => (
                    <React.Fragment key={invoice.id + lineItem.id}>
                      <span>{lineItem.productDescription}</span>
                      <span>{lineItem.budgetLineItemDescription || "-"}</span>
                      <span>{lineItem.glCodeDescription || "-"}</span>
                      <span
                        className={classNames({
                          "text-danger":
                            lineItemNetAmount !== lineItem.netAmount &&
                            !lineItem.linkedInvoiceId
                        })}
                      >
                        {formatAmount(lineItem.netAmount, currency)}
                      </span>
                      <span className="">
                        {formatAmount(lineItem.grossAmount, currency)}
                      </span>
                      <span className="">
                        {lineItem.linkedInvoiceId ? (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            className="link-primary"
                            href={
                              "/#" +
                              routeMatch.url.replace(
                                routeMatch.params?.invoiceId,
                                lineItem.linkedInvoiceId
                              )
                            }
                          >
                            {lineItem.linkedInvoiceId}
                          </a>
                        ) : poInvoiceScheduleId === lineItem.invoiceId &&
                          poInvoiceLineItemId === lineItem.id ? (
                          routeMatch.params?.invoiceId
                        ) : (
                          Labels.CommonModals.NA
                        )}
                      </span>
                      <span className="text-right">
                        {poInvoiceScheduleId === lineItem.invoiceId &&
                        poInvoiceLineItemId === lineItem.id ? (
                          <FontAwesomeIcon
                            icon={faCheck}
                            size="2x"
                            className={"text-success"}
                          />
                        ) : (
                          <button
                            color="primary"
                            onClick={() => handleSelectOnClick(lineItem)}
                            className={classNames("btn btn-primary m-0", {
                              "d-none":
                                availableInvoiceLineItems.findIndex(
                                  (j) =>
                                    j.id === lineItem.id &&
                                    j.invoiceId === lineItem.invoiceId
                                ) === -1
                            })}
                            disabled={lineItemNetAmount !== lineItem.netAmount}
                          >
                            {Labels.CommonModals.select}
                          </button>
                        )}
                      </span>
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>

            <div
              className={classNames("divider-thick mt-3 mb-3", {
                "d-none": poInvoicesListData.estimatedInvoices.length < 1
              })}
            />
          </React.Fragment>
        ))}

        <div>
          <p>
            <span className="row-header-2">{Labels.CommonModals.note}</span>
            {Labels.Payments.Invoices.Details.poSelectionLineItemNote}
          </p>
        </div>
      </ModalBody>
    </Modal>
  );
}
