import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Alert,
  ModalHeader
} from "reactstrap";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import PaymentActionSaveWarningModal from "./PaymentActionSaveWarningModal";
import { Labels } from "../../../../../constants/Constants";
import { httpRequest } from "../../../../../utils/http/httpRequest";
import { rejectPayment } from "../../../../../utils/http/endpoints";

export default function PaymentRejectionModal({
  isOpen,
  toggle,
  refreshPayments,
  paymentId,
  isFormChanged,
  paymentAssetId,
  refreshPayment,
  togglePaymentsTable
}) {
  let { assetId } = useParams();
  const [comment, setComment] = useState("");
  const [formErrors, setFormErrors] = useState([]);

  const [userAction, setUserAction] = useState("");
  const putRejectionReason = async () => {
    return httpRequest({
      method: "put",
      url: rejectPayment(assetId ? assetId : paymentAssetId, paymentId),
      data: {
        comment
      }
    });
  };
  const mutation = useMutation(putRejectionReason, {
    onSuccess: () => {
      if (refreshPayment) {
        refreshPayment(paymentId);
      }
      if (refreshPayments) {
        refreshPayments();
      }
      toggle();
      if (togglePaymentsTable) {
        togglePaymentsTable(true);
      }
    },
    onError: (err) => {
      setFormErrors(err.data.errors);
    }
  });

  const handleSubmit = (evt) => {
    evt.preventDefault();
    mutation.mutate();
  };

  if (isFormChanged && userAction === "") {
    return (
      <PaymentActionSaveWarningModal
        isLoading={mutation.isLoading}
        handleYes={() => setUserAction("YES")}
        isOpen={isOpen}
        isRejectModal={true}
        toggle={toggle}
      />
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      backdrop="static"
      keyboard={false}
      centered
    >
      <ModalHeader className="pb-0" toggle={toggle}>
        <h2 className="color-dark modal-title">
          {Labels.Payments.Invoices.rejectPayment}
        </h2>
      </ModalHeader>
      <ModalBody>
        <Alert
          color="danger"
          className=""
          isOpen={formErrors?.length > 0}
          toggle={() => setFormErrors([])}
        >
          <ul className="error-list">
            {formErrors.map((error, index) => {
              return <li key={index}>{error.errorMessage}</li>;
            })}
          </ul>
        </Alert>
        <div className="comments-box m-0 border-0 shadow-none p-0">
          <label htmlFor="comments">{Labels.Payments.Invoices.comments}</label>
          <textarea
            id="comments"
            className="form-control"
            rows={10}
            maxLength={512}
            value={comment}
            onChange={(event) => {
              setComment(event.target.value);
            }}
          ></textarea>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={handleSubmit}
          disabled={comment === "" || mutation.isLoading}
        >
          {mutation.isLoading
            ? Labels.Payments.Invoices.submitting
            : Labels.Payments.Invoices.submit}
        </Button>
        <Button color="secondary" onClick={toggle}>
          {Labels.CommonModals.cancel}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
