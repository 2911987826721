/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import { SelectBox as Select } from "../../../../../elements/select/Select";
import { toTitleCase } from "../../../../../utils/string";
import {
  createPayment,
  updatePayment
} from "../../../../../utils/http/endpoints";
import { useParams } from "react-router-dom";
import {
  Alert,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap";
import RouteLeavingGuard from "../../../../../elements/modals/RouteLeavingGuard";
import { useHistory } from "react-router-dom";
import ConfirmDialog from "../../../../../elements/modals/ConfirmDialog";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button
} from "reactstrap";
import useToggle from "../../../../../hooks/useToggle";
import PaymentStatuses from "../../../../../constants/PaymentStatuses";
import { useMutation } from "react-query";
import PaymentRejectionModal from "../../Payments/PurchaseInvoices/PaymentRejectionModal";
import PaymentActionSaveWarningModal from "../../Payments/PurchaseInvoices/PaymentActionSaveWarningModal";
import { Labels, Privileges } from "../../../../../constants/Constants";
import Auth from "../../../../../auth/Auth";
import { getSymbolFromCurrencyCode } from "../../../../../utils/currency/currency";
import PaymentsVoidActionReasonModal from "./operational-operational/PaymentsVoidActionReasonModal";
import { httpRequest } from "../../../../../utils/http/httpRequest";
import useGetBankAccountDetailsForAsset from "../../../../../hooks/useGetBankAccountDetailsForAsset";
import useGetContanctBankAccountDetails from "../../../../../hooks/useGetContanctBankAccountDetails";
import { getInterCompanyPaymentWorkflowMessage } from "../../../../../utils/utils";
import CustomDatePicker from "../../../../../elements/form-fields/inputs/CustomDatePicker";
import {
  serializeDate,
  getCurrentPeriodDateLabel,
  getDateInDisplayFormat
} from "../../../../../utils/date";
import { isDate } from "moment";
import PostingPeriodModules from "../../../../../constants/PostingPeriodModules";
import { integersUptoTwoDecimalsRegexp } from "../../../../../constants/regexp";
import useGetTaxList from "../../../../../hooks/useGetTaxList";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatAmountWithThousandSeparatorsToPrecision } from "../../../../../utils/number";
import useGetPostingPeriod from "../../../../../hooks/useGetPostingPeriod";
import Tooltip from "../../../../../elements/ToolTips/Tooltip";

export default function InvoicePayment(props) {
  const { refreshPayment } = props;
  const [isSaveSuccessMsgVisible, setSaveSuccessMsgVisible] = useState(false);
  const [areAlertsVisible, setAreAlertsVisible] = useState(false);
  const [canClearFormErrors, setCanClearFormErrors] = useState(true);
  const [errors, setErrors] = useState([]);
  const onSaveSuccessDismiss = () => setSaveSuccessMsgVisible(false);
  const clearErrors = () => {
    setAreAlertsVisible(false);
    setErrors([]);
    setCanClearFormErrors(true);
  };

  const [amountToBePaid, setAmountToBePaid] = useState(0);
  const params = useParams();
  const [selectedFromBankAccount, setSelectedFromBank] = useState(null);
  const isFromAccountInterCompanyGlCode = !!selectedFromBankAccount?.glCode;
  const [postingPeriodData, setPostingPeriodData] = useState({});
  const [selectedSupplierBankAccount, setSelectedSupplierBankAccount] =
    useState(null);
  const [isDirectDebitPayment, setIsDirectDebitPayment] = useState(false);
  const [paymentDate, setPaymentDate] = useState(
    props.selectedPayment.paymentDate
      ? props.selectedPayment.paymentDate
      : new Date()
  );
  const history = useHistory();
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [isMovingAwayFromForm, setIsMovingAwayFromForm] = useState(false);

  const [isDropdownOpen, toggleDropdown] = useToggle();
  const [isRejectModalOpen, toggleRejectModal] = useToggle();
  const [isVoidModalOpen, toggleVoidModal] = useToggle();
  const [isSaveWarningModalOpen, toggleSaveWarningModal] = useToggle();
  const fromBankAccountsQuery = useGetBankAccountDetailsForAsset({
    assetId: params.assetId,
    includeInterCompanyAccounts: true,
    returnGroupedData: true
  });
  const toBankAccountsQuery = useGetContanctBankAccountDetails({
    contactId: props.invoiceDetails.supplierId
  });

  useEffect(() => {
    if (props.formMode === "CREATE") {
      setAmountToBePaid(
        (
          props.invoiceDetails.amountPayable - props.invoiceDetails.amountPaid
        ).toFixed(2)
      );
    } else {
      setAmountToBePaid(props.selectedPayment.amount.toFixed(2));
      if (props.selectedPayment.directDebitPayment) {
        setIsDirectDebitPayment(true);
      }
      const _selectedBank = fromBankAccountsQuery.data
        ?.map((optionGroup) => optionGroup.options)
        .flat()
        .find((option) => {
          if (props.selectedPayment?.interCompanyAccountId) {
            return option.value === props.selectedPayment.interCompanyAccountId;
          }
          return (
            option?.bankAccountCode === props.selectedPayment.bankAccountCode
          );
        });
      if (_selectedBank) {
        setSelectedFromBank(_selectedBank);
      }

      const selectedSupplierBankAc = toBankAccountsQuery.data?.find(
        (bank) => bank.value === props.selectedPayment.supplierBankAccountId
      );

      if (selectedSupplierBankAc) {
        setSelectedSupplierBankAccount(selectedSupplierBankAc);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.selectedPayment,
    props.payments,
    props.formMode,
    setIsDirectDebitPayment,
    props.invoiceDetails.amountPayable,
    fromBankAccountsQuery.data,
    toBankAccountsQuery.data
  ]);

  const handleFromBankAccountChange = (bank) => {
    setIsFormChanged(true);
    setSelectedFromBank(bank);
    setPostingPeriodData({});
  };

  const handleSupplierBankAccount = (bank) => {
    setIsFormChanged(true);
    setSelectedSupplierBankAccount(bank);
  };

  const handleIsDirectDebitPaymentChange = (event) => {
    setIsDirectDebitPayment(event.target.checked);
    setIsFormChanged(true);
  };

  const handlePaymentDate = (value) => {
    setPaymentDate(value);
    setIsFormChanged(true);
    setPostingPeriodData({});
  };

  const areLineItemsValid = () => {
    let isLineItemChecked =
      [...props.selectedLineItems].filter((lineItem) => lineItem.isChecked)
        .length > 0;
    let isLineItemAmountValid =
      [...props.selectedLineItems].find(
        (lineItem) =>
          lineItem.isChecked &&
          (lineItem.amount > lineItem.grossAmount || isNaN(lineItem.amount))
      ) === undefined;
    return isLineItemChecked && isLineItemAmountValid;
  };

  const isFormValid = () => {
    return (
      selectedFromBankAccount &&
      areLineItemsValid() &&
      amountToBePaid !== 0 &&
      (isDirectDebitPayment || selectedFromBankAccount?.glCode
        ? true
        : selectedSupplierBankAccount)
    );
  };

  const handleSubmit = (nextStatus) => {
    setErrors([]);
    setAreAlertsVisible(false);
    setSaveSuccessMsgVisible(false);
    let postData = {
      amount: amountToBePaid,
      bankAccountCode: isFromAccountInterCompanyGlCode
        ? undefined
        : selectedFromBankAccount.bankAccountCode,
      interCompanyAccountId: isFromAccountInterCompanyGlCode
        ? selectedFromBankAccount.value
        : null,
      paymentDate:
        props.formMode === "EDIT"
          ? isDate(paymentDate)
            ? serializeDate(paymentDate)
            : paymentDate
          : serializeDate(paymentDate),
      supplierBankAccountId:
        isDirectDebitPayment || isFromAccountInterCompanyGlCode
          ? undefined
          : selectedSupplierBankAccount?.value,
      status: nextStatus || props.selectedPayment?.status,
      version: props.selectedPayment?.version,
      directDebitPayment: isDirectDebitPayment,
      purchasePaymentLineItems: props.selectedLineItems.filter(
        (lineItem) => lineItem.isChecked && lineItem.amount !== 0
      )
    };

    let submitUrl = createPayment(
      props.match.params.assetId,
      props.match.params.invoiceId
    );
    let submitMethod = "post";
    if (props.formMode === "EDIT") {
      submitUrl = updatePayment(
        props.match.params.assetId,
        props.match.params.invoiceId,
        props.selectedPayment.id
      );
      submitMethod = "put";
    }

    httpRequest({
      method: submitMethod,
      url: submitUrl,
      data: postData
    })
      .then((response) => {
        props.refreshPayments();
        setIsFormChanged(false);
        props.setIsCreatePaymentFormVisible(false);
        props.togglePaymentsTable(true);
        props.refreshInvoiceData();
        props.outStandingPaymentsQuery?.refetch();
      })
      .catch((error) => {
        console.log(error);
        setErrors(error.data.errors);
        setAreAlertsVisible(true);
      });
  };

  const { mutate: performAction, isLoading: actionIsLoading } = useMutation(
    (action) => {
      return httpRequest({
        method: "post",
        url: action.uri,
        data: action
      });
    },
    {
      onError: (err) => {
        setErrors(err.data.errors);
      },
      onSuccess: () => {
        setErrors([]);
        props.refreshInvoiceData();
        refreshPayment(props?.selectedPayment?.id);
        props.refreshPayments();
        props.togglePaymentsTable(true);
      }
    }
  );

  const handleCancel = () => {
    props.setIsCreatePaymentFormVisible(false);
  };

  const currentAction = useRef(null);

  // Show this action if form mode is create or edit and user have privillige SUBMIT_PAYMENT_FOR_APPROVAL
  const saveAndSubmitForApproval =
    ["CREATE", "EDIT"].includes(props.formMode) &&
    Auth.getUserPrivileges().includes(Privileges.SUBMIT_PAYMENT_FOR_APPROVAL);

  const interCompanyPaymentWorkflowMessage =
    getInterCompanyPaymentWorkflowMessage({
      isDirectDebitPayment,
      isInterCompanyAccount: isFromAccountInterCompanyGlCode,
      paymentStatus: props.selectedPayment.status
    });

  const isPostingPeriodQueryEnabled =
    !!selectedFromBankAccount?.glCode && !!paymentDate;

  const {
    data: postingPeriodQueryData,
    isError: isPostingPeriodError,
    error: postingPeriodError,
    isLoading: isPostingPeriodLoading,
    refetch
  } = useGetPostingPeriod({
    assetId: isPostingPeriodQueryEnabled ? props.invoiceDetails.assetId : null,
    instrumentDate: isDate(paymentDate)
      ? serializeDate(paymentDate)
      : paymentDate,
    module: PostingPeriodModules.AP,
    isBlocking: false,
    options: {
      refetchOnWindowFocus: true
    }
  });

  useEffect(() => {
    if (isPostingPeriodError && ["CREATE", "EDIT"].includes(props.formMode)) {
      setAreAlertsVisible(true);
      setErrors(postingPeriodError);
      setCanClearFormErrors(false);
    } else {
      clearErrors();
    }
  }, [postingPeriodError]);

  useEffect(() => {
    if (postingPeriodQueryData) {
      setPostingPeriodData(postingPeriodQueryData);
      clearErrors();
    }
  }, [postingPeriodQueryData]);

  useEffect(() => {
    if (isPostingPeriodQueryEnabled) {
      refetch();
    }
  }, [selectedFromBankAccount, paymentDate]);

  const isInterCompanyError = () =>
    ["CREATE", "EDIT"].includes(props.formMode) &&
    !!isFromAccountInterCompanyGlCode &&
    postingPeriodData?.postingPeriod
      ? postingPeriodData.postingPeriod !==
        getCurrentPeriodDateLabel(paymentDate)
      : false;

  const invoiceCurrecny = getSymbolFromCurrencyCode(
    props?.invoiceDetails?.invoiceCurrency
  );

  const { data: taxCodesList } = useGetTaxList({
    assetId: params.assetId,
    orgId: params.orgId,
    status: "ACTIVE"
  });

  const getTaxRate = (taxId) => {
    return taxCodesList?.filter((taxCode) => taxCode.id === taxId).pop()
      ?.taxRate;
  };

  const handleLineItemCheck = (event, id) => {
    setIsFormChanged(true);
    props.setSelectedLineItems(
      [...props.selectedLineItems].map((lineItem) => {
        if (lineItem.invoiceLineItemId === id) {
          lineItem.isChecked = event.target.checked;
        }
        return { ...lineItem };
      })
    );
    setAmountToBePaid(
      [...props.selectedLineItems].reduce((prevVal, curVal) => {
        if (curVal.isChecked) {
          prevVal += curVal.amount;
        }
        return prevVal;
      }, 0)
    );
  };

  const handleEditPaymentAmount = (paymentAmount, id) => {
    let totalToBePaidAmount = 0;
    let updatedLineItems = [...props.selectedLineItems].map((lineItem) => {
      if (lineItem.invoiceLineItemId === id) {
        if (integersUptoTwoDecimalsRegexp.test(paymentAmount)) {
          lineItem.amount = parseFloat(paymentAmount);
        }
      }
      if (lineItem.isChecked) {
        totalToBePaidAmount += isNaN(parseFloat(lineItem.amount))
          ? 0
          : parseFloat(lineItem.amount);
      }
      return { ...lineItem };
    });
    setIsFormChanged(true);
    props.setSelectedLineItems(updatedLineItems);
    setAmountToBePaid(totalToBePaidAmount);
  };

  const getTitle = (formMode) => {
    let title = "";
    if (formMode === "CREATE") {
      title = Labels.Payments.Invoices.Payments.createPayment;
    } else if (formMode === "EDIT") {
      title = Labels.Payments.Invoices.Payments.editPayment;
    } else if (formMode === "VIEW") {
      title = Labels.Payments.Invoices.Payments.viewPayment;
    }
    return title;
  };

  return (
    <Modal
      isOpen={props.isCreatePaymentFormVisible}
      centered
      scrollable
      backdrop="static"
      size="xl"
      keyboard={false}
      fade={false}
    >
      <ModalHeader
        className="pb-0"
        toggle={() => {
          if (isFormChanged) {
            setIsMovingAwayFromForm(true);
          } else {
            handleCancel();
          }
        }}
      >
        <h2 className="color-dark modal-title">{getTitle(props.formMode)}</h2>
      </ModalHeader>
      <ModalBody className="p-0 m-0 body-primary color-text-primary">
        <React.Fragment>
          <Alert
            color="danger"
            className="mt-3 mb-0 mx-3"
            isOpen={areAlertsVisible}
            toggle={canClearFormErrors ? clearErrors : null}
          >
            <ul className="error-list">
              {errors.map((error, index) => {
                return <li key={index}>{error.errorMessage}</li>;
              })}
            </ul>
          </Alert>

          {isInterCompanyError() ? (
            <Alert
              color="danger"
              className="mt-3 mx-3 mb-0"
            >{`${getDateInDisplayFormat(paymentDate)} ${
              Labels.Payments.Invoices.Payments.interCompanyTransferError
            }`}</Alert>
          ) : null}

          <Alert
            color="success"
            className="mt-3 mx-3 mb-0"
            isOpen={isSaveSuccessMsgVisible}
            toggle={onSaveSuccessDismiss}
          >
            {Labels.Payments.Invoices.Payments.PaymentCreationSuccessful}
          </Alert>
          <form id="create-payment-form">
            <fieldset disabled={props.formMode === "VIEW" ? true : false}>
              <div className="form-row">
                <label htmlFor="payment-account">
                  {Labels.Payments.Invoices.Payments.fromAccount}
                </label>
                <div className="payment-account">
                  <Select
                    id="payment-account"
                    value={selectedFromBankAccount}
                    onChange={handleFromBankAccountChange}
                    multi={false}
                    options={fromBankAccountsQuery.data || []}
                    isDisabled={props.formMode === "VIEW" ? true : false}
                  />
                </div>
              </div>
              {isDirectDebitPayment ||
              isFromAccountInterCompanyGlCode ? null : (
                <div className="form-row">
                  <label htmlFor="payment-account">
                    {Labels.Payments.Invoices.Payments.supplierBankAC}
                  </label>
                  <div className="payment-account">
                    <Select
                      id="payment-account"
                      value={selectedSupplierBankAccount}
                      onChange={handleSupplierBankAccount}
                      multi={false}
                      isLoading={toBankAccountsQuery.isLoading}
                      options={toBankAccountsQuery.data || []}
                      isDisabled={props.formMode === "VIEW" ? true : false}
                    />
                  </div>
                </div>
              )}
              {isFromAccountInterCompanyGlCode ? (
                <div className="form-row">
                  <label htmlFor="payment-date">
                    {Labels.Payments.Invoices.Payments.paymentDate}
                  </label>
                  <div className="payment-date">
                    <CustomDatePicker
                      id="payment-date"
                      minDate={new Date(props.invoiceDetails.invoiceDate)}
                      maxDate={new Date()}
                      selected={
                        paymentDate ? new Date(paymentDate) : new Date()
                      }
                      onChange={handlePaymentDate}
                      dateFormat="dd/MM/yyyy"
                      disabled={props.formMode === "VIEW"}
                    />
                  </div>
                </div>
              ) : null}

              <div className="form-row d-flex align-items-center pt-2">
                <label className="m-0" htmlFor="direct-debit-checkbox">
                  {Labels.Payments.Invoices.Payments.payByDirectDebit}
                </label>
                <div className="payment-account">
                  <input
                    type="checkbox"
                    id="direct-debit-checkbox"
                    checked={isDirectDebitPayment}
                    onChange={handleIsDirectDebitPaymentChange}
                  />
                </div>
              </div>

              {props.selectedLineItems.length ? (
                <div>
                  <div className="line-items-table">
                    <Row className="mx-3 pt-3 border-bottom">
                      <Col className="p-0">
                        <label>
                          {Labels.Payments.Invoices.Payments.lineItems}
                        </label>
                      </Col>
                      <Col>
                        <label className="text-center">
                          {Labels.Payments.Invoices.Payments.taxRate}
                        </label>
                      </Col>
                      <Col>
                        <label className="text-right">
                          {Labels.Payments.Invoices.Payments.netAmount}
                          {` (${invoiceCurrecny})`}
                        </label>
                      </Col>
                      <Col>
                        <label className="text-right">
                          {Labels.Payments.Invoices.Payments.taxAmount}
                          {` (${invoiceCurrecny})`}
                        </label>
                      </Col>
                      <Col>
                        <label className="text-right">
                          {Labels.Payments.Invoices.Payments.grossAmount}
                          {` (${invoiceCurrecny})`}
                        </label>
                      </Col>
                      <Col className="p-0">
                        <label className="text-right">
                          {Labels.Payments.Invoices.Payments.paymentAmount}
                          {` (${invoiceCurrecny})`}
                          <FontAwesomeIcon
                            icon={faInfoCircle}
                            className="mx-1"
                            id="paymentAmountMessage"
                          />
                          <Tooltip target={"paymentAmountMessage"}>
                            {
                              Labels.Payments.Invoices.Payments
                                .paymentAmountInfoMsg
                            }
                          </Tooltip>
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    {props.selectedLineItems.map((lineItem) => {
                      return (
                        <div className="line-items-table">
                          <Row className="mx-3 py-2 border-bottom">
                            <Col className="p-0 align-self-center">
                              <div className="d-flex align-items-center">
                                <input
                                  type={"checkbox"}
                                  checked={lineItem.isChecked}
                                  onChange={(event) =>
                                    handleLineItemCheck(
                                      event,
                                      lineItem.invoiceLineItemId
                                    )
                                  }
                                />
                                <label className="m-0 ml-2 fw-400">
                                  {lineItem.invoiceLineItemDescription}
                                </label>
                              </div>
                            </Col>
                            <Col className="align-self-center">
                              <label className="text-center m-0 fw-400">
                                {getTaxRate(lineItem.taxId)}
                                {"%"}
                              </label>
                            </Col>
                            <Col className="align-self-center">
                              <label className="text-right m-0 fw-400">
                                {lineItem.netAmount}
                              </label>
                            </Col>
                            <Col className="align-self-center">
                              <label className="text-right m-0 fw-400">
                                {lineItem.taxAmount}
                              </label>
                            </Col>
                            <Col className="align-self-center">
                              <label className="text-right m-0 fw-400">
                                {lineItem.grossAmount}
                              </label>
                            </Col>
                            <Col className="p-0 align-self-center">
                              <div className="d-flex align-items-center justify-content-end">
                                <input
                                  type={"number"}
                                  value={lineItem.amount}
                                  required
                                  min={
                                    lineItem.grossAmount > 0
                                      ? 0
                                      : lineItem.grossAmount
                                  }
                                  step={0.01}
                                  max={
                                    lineItem.grossAmount > 0
                                      ? lineItem.grossAmount
                                      : 0
                                  }
                                  disabled={!lineItem.isChecked}
                                  onChange={(event) =>
                                    handleEditPaymentAmount(
                                      event.target.value,
                                      lineItem.invoiceLineItemId
                                    )
                                  }
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null}

              {props.formMode !== "CREATE" && (
                <div className="form-row pb-2">
                  <label htmlFor="to-be-paid">
                    {Labels.Payments.Invoices.Payments.paymentStatus}
                  </label>
                  <span className="ml-2 pl-1">
                    {toTitleCase(
                      props.selectedPayment.status.replace(/_/g, " ")
                    )}
                  </span>
                </div>
              )}
              <div className="form-row">
                <label htmlFor="to-be-paid">
                  {Labels.Payments.Invoices.Payments.paymentAmount}
                </label>
                <div>
                  <span className="payment-amount-currency ml-2 pl-1">
                    {getSymbolFromCurrencyCode(
                      props.invoiceDetails.invoiceCurrency
                    )}{" "}
                    {formatAmountWithThousandSeparatorsToPrecision(
                      amountToBePaid,
                      true
                    )}
                  </span>
                </div>
              </div>
            </fieldset>

            {interCompanyPaymentWorkflowMessage ? (
              <p className="mb-2 ml-3">
                <span className="row-header-2">{Labels.CommonModals.note}</span>
                {interCompanyPaymentWorkflowMessage}
              </p>
            ) : null}
          </form>
          <RouteLeavingGuard
            when={isFormChanged}
            navigate={(path) => history.push(path)}
            shouldBlockNavigation={(location) => {
              if (isFormChanged) {
                return true;
              }
              return false;
            }}
          />
          <ConfirmDialog
            isOpen={isMovingAwayFromForm && isFormChanged}
            confirmAction={handleCancel}
            toggle={() => setIsMovingAwayFromForm(!isMovingAwayFromForm)}
            confirmTitle={Labels.CommonModals.confirmTitle}
            confirmMessage={Labels.CommonModals.confirmMessage}
          />
          {isRejectModalOpen ? (
            <PaymentRejectionModal
              togglePaymentsTable={props.togglePaymentsTable}
              isOpen={isRejectModalOpen}
              toggle={toggleRejectModal}
              refreshPayment={refreshPayment}
              refreshPayments={props.refreshPayments}
              paymentId={props?.selectedPayment?.id}
              isFormChanged={isFormChanged}
            />
          ) : null}
          {isSaveWarningModalOpen ? (
            <PaymentActionSaveWarningModal
              isLoading={actionIsLoading}
              toggle={toggleSaveWarningModal}
              isOpen={isSaveWarningModalOpen}
              handleYes={() => performAction(currentAction.current)}
            />
          ) : null}
          {isVoidModalOpen ? (
            <PaymentsVoidActionReasonModal
              isOpen={isVoidModalOpen}
              toggle={toggleVoidModal}
              paymentAssetId={props.match.params.assetId}
              paymentId={props?.selectedPayment?.id}
              refreshPayment={() => {
                refreshPayment(props?.selectedPayment?.id);
                props.refreshPayments();
              }}
            />
          ) : null}
        </React.Fragment>
      </ModalBody>
      <ModalFooter>
        <div className="ip-buttons clearfix">
          {isDirectDebitPayment ||
          props.selectedPayment?.status === "AWAITING_APPROVAL" ? (
            <div className="d-flex justify-content-end">
              <Button
                color="primary"
                className="btn btn-primary ip-save-button"
                type="button"
                disabled={
                  !isFormValid() ||
                  !isFormChanged ||
                  props.selectedPayment.directDebitPayment ||
                  isInterCompanyError() ||
                  isPostingPeriodLoading ||
                  isPostingPeriodError
                }
                onClick={() => handleSubmit(PaymentStatuses.DRAFT)}
              >
                {Labels.Payments.Invoices.Payments.Save}
              </Button>
              <Button
                className="btn btn-secondary ml-3"
                type="button"
                onClick={() => {
                  if (isFormChanged) {
                    setIsMovingAwayFromForm(true);
                  } else {
                    handleCancel();
                  }
                }}
              >
                {Labels.Payments.Invoices.Payments.Cancel}
              </Button>
            </div>
          ) : (
            <div className="d-flex justify-content-end">
              <div className="create-payments-dropdown-button">
                <ButtonDropdown isOpen={isDropdownOpen} toggle={toggleDropdown}>
                  <Button
                    color="primary"
                    className="btn btn-primary ip-save-button"
                    type="button"
                    disabled={
                      !isFormValid() ||
                      !isFormChanged ||
                      props.formMode === "VIEW" ||
                      isPostingPeriodLoading ||
                      isInterCompanyError() ||
                      isPostingPeriodError
                    }
                    onClick={() => handleSubmit(PaymentStatuses.DRAFT)}
                  >
                    {Labels.Payments.Invoices.Payments.Save}
                  </Button>
                  <DropdownToggle
                    color="primary"
                    split
                    disabled={
                      !Boolean(
                        props?.selectedPayment?.actions?.filter(
                          (i) => i.actionName !== "Approve Payments"
                        ).length
                      ) && !saveAndSubmitForApproval
                    }
                  />
                  <DropdownMenu>
                    {saveAndSubmitForApproval ? (
                      <DropdownItem
                        disabled={
                          !isFormValid() ||
                          !isFormChanged ||
                          isInterCompanyError() ||
                          isPostingPeriodLoading ||
                          isPostingPeriodError
                        }
                        onClick={() =>
                          handleSubmit(PaymentStatuses.AWAITING_APPROVAL)
                        }
                      >
                        {
                          Labels.Payments.Invoices.Payments
                            .saveAndSubmitForApproval
                        }
                      </DropdownItem>
                    ) : null}
                  </DropdownMenu>
                </ButtonDropdown>
              </div>
              <button
                className="btn btn-secondary ml-2"
                type="button"
                onClick={() => {
                  if (isFormChanged) {
                    setIsMovingAwayFromForm(true);
                  } else {
                    handleCancel();
                  }
                }}
              >
                {Labels.Payments.Invoices.Payments.Cancel}
              </button>
            </div>
          )}
        </div>
      </ModalFooter>
    </Modal>
  );
}
