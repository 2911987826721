/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-undef */
import React, { useState } from "react";
import { Labels } from "../../../../../../constants/Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import CreateReceipt from "./CreateReceipt";
import {
  deletePurchaseCreditNoteReceipt,
  getPurchaseCreditNoteReceipts
} from "../../../../../../utils/http/endpoints";
import { useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import QueryKeys from "../../../../../../constants/QueryKeys";
import { formatAmountWithThousandSeparatorsToPrecision } from "../../../../../../utils/number";
import { getSymbolFromCurrencyCode } from "../../../../../../utils/currency/currency";
import { Alert } from "reactstrap";
import AllocationRecords from "../AllocationRecords";
import { Privileges } from "../../../../../../constants/Privileges";
import Auth from "../../../../../../auth/Auth";
import InvoiceStatuses from "../../../../../../constants/InvoiceStatuses";
import { convertDateFromUTCToLocal } from "../../../../../../utils/date";
import { PurchaseInvoiceTypesEnum } from "../../../../../../constants/PurchaseInvoiceTypes";
import ConfirmDialog from "../../../../../../elements/modals/ConfirmDialog";
import { nonBlockingHttpRequest } from "../../../../../../utils/http/nonBlockingHttpRequest";
import { httpRequest } from "../../../../../../utils/http/httpRequest";

export default function ReceiptAllocations(props) {
  const [isCreateReceiptFormVisible, setIsCreateReceiptFormVisible] =
    useState(false);
  const [receiptToBeDeleted, setReceiptToBeDeleted] = useState();
  const invoiceDetails = props.invoiceDetails;
  const params = useParams();
  const queryClient = useQueryClient();

  const [apiErrors, setApiErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const handleCreateReceipts = (event) => {
    event.preventDefault();
    resetMessages();
    setIsCreateReceiptFormVisible(true);
  };

  const resetMessages = () => {
    setSuccessMessage("");
    setApiErrors([]);
  };
  const resetVisiblity = (data) => {
    setIsCreateReceiptFormVisible(false);
    resetMessages();
    if (data?.successMessage) {
      setSuccessMessage(successMessage);
    }
  };
  const { data: receiptRecords } = useQuery({
    queryKey: [
      QueryKeys.getPurchaseCreditNoteReceipts,
      params?.assetId,
      params?.invoiceId
    ],
    queryFn: () =>
      nonBlockingHttpRequest({
        method: "get",
        url: getPurchaseCreditNoteReceipts(params?.assetId, params?.invoiceId)
      }),
    enabled: !!(params?.assetId && params?.invoiceId)
  });

  const getReceiptDateLabel = (receipt) => {
    if (receipt.receiptDate) {
      return (
        <a
          href={`#/org/${params.orgId}/assets/asset/${params.assetId}/accounting/bankaccounts/${receipt.bankAccountId}/transactions/${receipt.bankAccountStatementLineItemId}`}
          target="_blank"
          className="link-primary"
        >
          {convertDateFromUTCToLocal(receipt.receiptDate, "DD MMM YYYY")}
        </a>
      );
    } else {
      return <>{"-"}</>;
    }
  };

  const deleteReceiptMutation = useMutation((receipt) => {
    setApiErrors([]);
    setSuccessMessage();
    httpRequest({
      method: "delete",
      url: deletePurchaseCreditNoteReceipt(
        params?.assetId,
        params?.invoiceId,
        receipt?.id
      )
    })
      .then((res) => {
        queryClient.invalidateQueries([
          QueryKeys.getPurchaseCreditNoteReceipts,
          params?.assetId,
          params?.invoiceId
        ]);
        setSuccessMessage(
          Labels.Payments.CreditNotes.successfulDeleteReceiptMessage
        );
        props.refreshInvoiceData();
      })
      .catch((error) => {
        if (typeof error == "string") {
          setApiErrors([{ errorMessage: error }]);
        } else {
          setApiErrors(error.data.errors);
        }
        document.body.scrollIntoView({ behavior: "smooth", block: "start" });
      });
  });
  let isCreateReceiptAllowed = false;
  if (
    invoiceDetails.invoiceStatus === InvoiceStatuses.AWAITING_RECEIPT &&
    Auth.getUserPrivileges().includes(
      Privileges.CREATE_PURCHASE_CREDIT_NOTE_RECEIPT
    )
  ) {
    isCreateReceiptAllowed = true;
  }
  let isAllocationsAllowed = false;
  if (invoiceDetails.invoiceStatus === InvoiceStatuses.AWAITING_RECEIPT) {
    isAllocationsAllowed = true;
  }
  return (
    <>
      <div className="fv-box mt-3">
        <Alert
          color="danger"
          isOpen={apiErrors?.length > 0}
          toggle={() => setApiErrors([])}
        >
          <ul className="error-list">
            {apiErrors?.map((error, index) => {
              return <li key={index}>{error.errorMessage}</li>;
            })}
          </ul>
        </Alert>
        <Alert
          color="success"
          isOpen={!!successMessage}
          toggle={() => setSuccessMessage()}
        >
          {successMessage}
        </Alert>
        <div className="invoice-payments-container">
          {isCreateReceiptFormVisible ? (
            <CreateReceipt
              refreshInvoiceData={props.refreshInvoiceData}
              invoiceDetails={invoiceDetails}
              onGoBack={resetVisiblity}
              receiptRecords={receiptRecords?.data}
            />
          ) : (
            <>
              <>
                <div className="clearfix border-bottom-fv-border-2px">
                  <div className="float-left">
                    <h3 className="color-dark">
                      {Labels.Payments.CreditNotes.title}
                    </h3>
                  </div>
                  <div className="float-right">
                    <div
                      className={`create-payment ${
                        !isCreateReceiptAllowed ? "cursor-not-allowed" : ""
                      }`}
                    >
                      <a
                        href="#"
                        onClick={(event) => handleCreateReceipts(event)}
                        className={`prepend-plus ${
                          !isCreateReceiptAllowed
                            ? "disabled-link"
                            : "link-primary"
                        }`}
                      >
                        {Labels.Payments.CreditNotes.create}
                      </a>
                    </div>
                  </div>
                </div>
                <div className="payments-table-container small-bottom-bordered-table-container">
                  <table className="table table-borderless records-table">
                    <thead>
                      <tr>
                        <th width="30%" className="px-0">
                          {Labels.Payments.CreditNotes.Headers.receiptDate}
                        </th>
                        <th className="text-center">
                          {Labels.CommonModals.status}
                        </th>
                        <th className="text-right px-0" width="15%">
                          {Labels.Payments.CreditNotes.Headers.totalAmount}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {receiptRecords?.data?.map((receipt, index) => {
                        return (
                          <tr key={receipt.id} className="bordered-row">
                            <td>{getReceiptDateLabel(receipt)}</td>
                            <td className="text-center">
                              {InvoiceStatuses[receipt.status]}
                            </td>
                            <td className="text-right">
                              {getSymbolFromCurrencyCode(
                                invoiceDetails.invoiceCurrency
                              )}
                              {formatAmountWithThousandSeparatorsToPrecision(
                                receipt.receiptAmount
                              )}
                            </td>
                            <td className="text-right">
                              <FontAwesomeIcon
                                icon={faTrash}
                                className="fa-trash-alt cursor-pointer"
                                onClick={() => setReceiptToBeDeleted(receipt)}
                              />
                            </td>
                          </tr>
                        );
                      })}
                      {receiptRecords?.data?.length === 0 && (
                        <tr>
                          <td colSpan="5" className="text-center">
                            {Labels.Payments.CreditNotes.emptyReciptRecords}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </>
            </>
          )}
          {!!receiptToBeDeleted && (
            <ConfirmDialog
              confirmTitle={Labels.CommonModals.confirm}
              confirmMessage={
                Labels.Payments.CreditNotes.confirmReceiptDeleteMessage
              }
              confirmAction={() =>
                deleteReceiptMutation.mutate(receiptToBeDeleted)
              }
              confirmButtonName={Labels.CommonModals.yes}
              cancelButtonName={Labels.CommonModals.no}
              toggle={() => setReceiptToBeDeleted(undefined)}
              isOpen={true}
            />
          )}
        </div>
      </div>
      <div className="fv-box mt-3">
        <AllocationRecords
          invoiceDetails={props.invoiceDetails}
          setApiErrors={setApiErrors}
          setSuccessMessage={setSuccessMessage}
          type={PurchaseInvoiceTypesEnum.PURCHASE_CREDIT_NOTE}
          isAllocationsAllowed={isAllocationsAllowed}
          refreshInvoiceData={props.refreshInvoiceData}
        />
      </div>
    </>
  );
}
