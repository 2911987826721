import React, { useState } from "react";
import { useMutation } from "react-query";
import { Modal, ModalBody, ModalFooter, Button, ModalHeader } from "reactstrap";
import { Labels } from "../../../../../../constants/Constants";
import DisplayAlerts from "../../../../../../elements/DisplayAlerts";
import { voidPayment } from "../../../../../../utils/http/endpoints";
import { httpRequest } from "../../../../../../utils/http/httpRequest";

export default function PaymentsVoidActionReasonModal({
  isOpen,
  toggle,
  paymentAssetId,
  refreshPayment,
  paymentId,
  refreshInvoiceData,
  outStandingPaymentsQuery
}) {
  const [comment, setComment] = useState("");

  const putVoidReason = async () =>
    httpRequest({
      method: "put",
      url: voidPayment(paymentAssetId, paymentId),
      data: {
        comment
      }
    })
      .then((res) => Promise.resolve(res.data))
      .catch((err) => Promise.reject(err.data.errors));

  const mutation = useMutation(putVoidReason, {
    onSuccess: () => {
      toggle();
      refreshPayment();
      refreshInvoiceData();
      outStandingPaymentsQuery?.refetch();
    }
  });

  const handleSubmit = (evt) => {
    evt.preventDefault();
    mutation.mutate();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      backdrop="static"
      keyboard={false}
      fade={false}
      centered
    >
      <ModalHeader className="pb-0" toggle={toggle}>
        <h2 className="color-dark modal-title">
          {Labels.Payments.Invoices.Details.paymentVoidActionWarnMessage}
        </h2>
      </ModalHeader>
      <ModalBody>
        {mutation.isError ? (
          <DisplayAlerts
            alerts={mutation.error}
            type="danger"
            className="mt-0"
          />
        ) : null}
        <div className="comments-box m-0 border-0 shadow-none p-0">
          <label htmlFor="comments">{Labels.Payments.Invoices.comments}</label>
          <textarea
            id="comments"
            className="form-control"
            rows={10}
            maxLength={512}
            value={comment}
            onChange={(event) => {
              setComment(event.target.value);
            }}
          ></textarea>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={handleSubmit}
          disabled={mutation.isLoading}
        >
          {mutation.isLoading
            ? Labels.Payments.Invoices.submitting
            : Labels.Payments.Invoices.submit}
        </Button>
        <Button color="secondary" onClick={toggle}>
          {Labels.CommonModals.cancel}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
