export const UPDATE_WITHHOLDING_TAXES_FROM_TAX_LIST_API_AND_INVOICE_DATA =
  "UPDATE_WITHHOLDING_TAXES_FROM_TAX_LIST_API_AND_INVOICE_DATA";
export const WITHHOLDING_TAXES_UPDATE = "WITHHOLDING_TAXES_UPDATE";
export const WITHHOLDING_TAXES_UPDATE_TAX = "WITHHOLDING_TAXES_UPDATE_TAX ";
export const WITHHOLDING_TAXES_TOGGLE_TAX = "WITHHOLDING_TAXES_TOGGLE_TAX";
export const OTHER_DEDUCTIONS_UPDATE = "OTHER_DEDUCTIONS_UPDATE";
export const UPDATED_BUDGET_LINE_ITEM = "UPDATED_BUDGET_LINE_ITEM";
export const UPDATED_PO_NUMBER = "UPDATED_PO_NUMBER";
export const UPDATED_GL_ACCOUNT_CODE = "UPDATED_GL_ACCOUNT_CODE";
export const TOGGLE_LINEITEM_CARD = "TOGGLE_LINEITEM_CARD";
export const LINE_ITEM_API_ERRORED = "LINE_ITEM_API_ERRORED"; // This is dispatched when api calls withing line item fails
export const INVOICE_API_ERRORED = "INVOICE_API_ERRORED";
export const UPDATE_LINEITEMS_FROM_API_RESPONSE =
  "UPDATE_LINEITEMS_FROM_API_RESPONSE";
export const SET_INVOICE_SAVED_MESSAGE = "SET_INVOICE_SAVED_MESSAGE";
export const RESET_INVOICE_SAVED_MESSAGE = "RESET_INVOICE_SAVED_MESSAGE";
export const UPDATE_BUDGETING_OPTION = "UPDATE_BUDGETING_OPTION";
export const CHANGE_SHOULD_UPDATE_BUDGET = "CHANGE_SHOULD_UPDATE_BUDGET";
export const CHANGE_PO_INVOICE_SCHEDULED = "CHANGE_PO_INVOICE_SCHEDULED";
export const UPDATE_PO_AVAILABLE_AMOUNT = "UPDATE_PO_AVAILABLE_AMOUNT";
export const TOGGLE_LINEITEM_SHOW_ALL_ACCRUALS =
  "TOGGLE_LINEITEM_SHOW_ALL_ACCRUALS";
export const UPDATE_PREPAYMENT_JOURNAL_SCHEDULE =
  "UPDATE_PREPAYMENT_JOURNAL_SCHEDULE";
export const UPDATE_LINEITEM_DATA = "UPDATE_LINEITEM_DATA";
export const UPDATE_JOURNAL_RANGE = "UPDATE_JOURNAL_RANGE";
export const TOGGLE_PREPAYMENT_ERROR = "TOGGLE_PREPAYMENT_ERROR";
export const UPDATE_LINEITEM_ACCRUED_AMOUNT = "UPDATE_LINEITEM_ACCRUED_AMOUNT";
export const UPDATE_LINEITEM_OPENING_BALANCE =
  "UPDATE_LINEITEM_OPENING_BALANCE";
