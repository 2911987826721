/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

export const FormInputWarning = ({ formState = {} }) => {
  const resultingErrors = formState?.getErrors() || [];
  // to get error container and field element
  const getElements = (resultingError) => {
    if (!resultingError) {
      return document.body;
    } else {
      if (resultingError.errorContainerClass) {
        const el = document.querySelector(
          `.${resultingError.errorContainerClass}`
        );
        el.classList.add("form-error-container");
        return { parentElement: el };
      } else {
        const className = resultingError?.name?.replaceAll(".", "-");
        const el =
          document.querySelector(
            `.${formState.getErrorClassForField(className)}`
          ) || document.querySelector(`.${className}`);
        if (el?.parentElement) {
          return { fieldEl: el, parentElement: el?.parentElement };
        } else {
          return {
            fieldEl: resultingError?.ref,
            parentElement: resultingError?.ref?.parentElement
          };
        }
      }
    }
  };
  return resultingErrors.map((error) => {
    const { parentElement, fieldEl } = getElements(error);
    return (
      <ErrorContainer
        formErrorContainer={parentElement}
        key={error?.name}
        message={error?.message}
        fieldEl={fieldEl}
      />
    );
  });
};

const ErrorContainer = ({ formErrorContainer, message, fieldEl }) => {
  useEffect(() => {
    if (message && fieldEl?.classList) {
      fieldEl.classList.add("form-field-border");
    }
    return () =>
      fieldEl?.classList && fieldEl.classList.remove("form-field-border");
  }, [message]);
  return ReactDOM.createPortal(
    message && formErrorContainer && (
      <span className="error-message redText px-0 pt-1 col-12">{message}</span>
    ),
    formErrorContainer || document.body
  );
};

FormInputWarning.propTypes = {
  formState: PropTypes.object.isRequired
};
