export const labelsList = {
  Login: {
    horizonFinance: "Horizon Finance",
    horizonFinanceDescription:
      "Scale faster and with more confidence across the full project life cycle.",
    loginToHorizonFinance: "Login to Horizon Finance",
    dontHaveAnAccount: "Don't have an account?",
    privacyPolicy: "Privacy Policy",
    safety: "Safety & Compliance"
  },
  CommonModals: {
    description: "Description",
    mw: "MW",
    all: "All",
    type: "Type",
    status: "Status",
    name: "Name",
    date: "Date",
    returnedToDraft: "Returned to Draft",
    errorTitle: "Error",
    tryAgainMessage: "Please try again.",
    firewallErrorMessage:
      "This action was blocked for security purposes. Please remove special characters from the file name/line item description to continue.",
    dataSavedMessage: "Data was saved successfully.",
    cancel: "Cancel",
    send: "Send",
    viewHistory: "View History",
    ok: "Ok",
    confirm: "Confirm",
    yes: "Yes",
    no: "No",
    reset: "Reset",
    close: "Close",
    save: "Save",
    saveAsDraft: "Save As Draft",
    or: "or",
    import: "Import",
    email: "Email",
    next: "Next",
    prev: "Prev",
    continue: "Continue",
    update: "Update",
    saveAndContinue: "Save & Continue",
    saveAndClose: "Save & Close",
    voidLoaderMessage: "Void in progress...",
    deleteFileConfirmMessage: "Are you sure you want to delete this file?",
    deleteConfirmMessage: "Are you sure you want to delete this file?",
    deleteConfirmTitle: "Delete",
    confirmTitle: "Are you sure you want to leave?",
    confirmMessage: "You can continue editing or discard your changes.",
    confirmButtonName: "Discard",
    cancelButtonName: "Continue editing",
    pleaseEnterANumber: "Please enter a number.",
    pleaseFillInThisField: "Please fill in this field.",
    nothingToDisplay: "Nothing to display.",
    unknownError: "An unknown error has occurred",
    NA: "N/A",
    areYouSure: "Are you sure?",
    returnToDraft: "Return Transaction to Draft",
    edit: "Edit",
    archive: "Archive",
    selectOneOfTheFollowing: "Select one of the following",
    journalPeriodRangeError: "Please select journal period end date.",
    lineItemWarnMessage: "Invoice line item information needs to be filled.",
    accruedAmountError: "Accrued amount should be between ",
    add: "Add",
    addComment: "Add Comment",
    create: "Create",
    remove: "Remove",
    optional: " (Optional)",
    select: "Select",
    options: "option(s)",
    selected: "Selected",
    attachmentSizeError: "The size of the file uploaded is larger than 10MB.",
    current: "Current",
    reject: "Reject",
    reason: "Reason",
    transactions: "Transactions",
    documents: "Documents",
    continueEditing: "Continue Editing",
    invoice: "Invoice",
    invoices: "Invoices",
    purchaseOrder: "Purchase Order",
    fincoviAM: "Fincovi AM",
    horizonFinance: "Horizon Finance",
    baseCurrency: "Base currency",
    xeDotCom: "XE.com",
    comment: "Comment",
    by: "by",
    to: "to",
    addedTheComment: "added the comment",
    hasEdited: "has edited",
    hasDeletedTheComment: "has deleted the comment",
    acceptedFilesLabel: "Accepted file types:",
    downloadCsvTpl: "Download CSV template",
    uploadFileLabel: "Upload or drag and drop files here",
    assetName: "Entity Name",
    selectFilesToUpload: "Select files to upload",
    other: "Other",
    csvExcel: "CSV/Excel",
    delete: "Delete",
    total: "Total",
    submit: "Submit",
    payments: "Payments",
    pleaseSelectAFile: "Please select a file",
    successfullyImportedPayments: "Successfully imported payments",
    today: "Today",
    loadMore: "Load More",
    or: "- or -",
    attachment: "Attachment",
    pleaseUploadOnlyOneFile: "Please upload only one file",
    uploadingFile: "Uploading file",
    loading: "Loading",
    selectAll: "Select All",
    noRecordsFound: "No records found.",
    noAttachmentsAvailable: "No attachments available.",
    inValid: "Invalid.",
    clickHere: "Click here",
    notSet: "Not set",
    allowMaxTwoDecimals: "Number with maximum two decimals allowed.",
    approvalInProgress: "Approval in progress...",
    thisMayTakeAMoment: "This may take a moment",
    thisBankStatementHasBeenDeleted: "This bank statement has been deleted.",
    asOn: "as on",
    GenericValidationMessages: {
      pleaseEnterAValidNumberUptoFourDecimals:
        "Please enter a valid number upto 4 decimals."
    },
    copy: "Copy",
    net: "Net:",
    gross: "Gross:",
    createADraftCopy: "Create a Draft Copy",
    createADraftCopyModal:
      "Copy of the instrument will be created in draft state.",
    preview: "Preview",
    export: "Export",
    noDataFound: "No data found.",
    supportEmail: "support@fincovi.com",
    message: "Message",
    note: "Note: ",
    beta: "Beta",
    run: "Run",
    addRow: "Add Row",
    back: "Back",
    accessIsDenied: "Access is denied",
    from: "from",
    to: "to",
    contactUs: "Contact Us"
  },
  Terms: {
    acceptButton: "Accept & Continue",
    acceptCheckBox: "I have read and agree to the terms"
  },
  TopNav: {
    home: "Home",
    assets: "Entities",
    accountsPayable: "Accounts Payable",
    groups: "Groups",
    portfolio: "Portfolio",
    portfolios: "Portfolios",
    isem: "I-SEM",
    isemBeta: "I-SEM (Beta)",
    signOut: "Sign Out",
    orgs: "Organisations",
    userManagement: "User Management"
  },
  AssetCard: {
    sizeOfProject: "Size of Project",
    tags: "Tags",
    assetType: "Entity Type",
    fincoviLogoText: "Full Service Entity",
    assetSettings: {
      approver: "Approver",
      clickHere: "click here",
      ruleset: "Ruleset",
      additionalSettings: "Additional Settings",
      settingsSaveMessage: "Entity settings saved successfully.",
      canCreatorApprove: "Can creator approve?",
      yes: "Yes",
      no: "No",
      approvalLimit: "Approval Limit",
      assignRulesetToApprover: "Assign Ruleset to Approver",
      canCreatorApproveTooltipMsg:
        "Can the creator of an Instrument (Invoice, Purchase Order, Payment) approve it?",
      poApproversTooltipMessage:
        "Users selected will be able approve a Purchase Order.",
      goodsReceiversTooltipMessage:
        "Users selected will be able to receive goods against an approved Purchase Order.",
      supplierVerifiersTooltipMessage:
        "Users selected will be able to review unverified Suppliers",
      assignRulesetInfoMsg: "Use Rulesets to assign approval limits to users.",
      poReviewersTooltipMessage:
        "Users selected will be able to review a Purchase Order.",
      noRulesetsDataInAssetInfoMsg: {
        line1: "Rulesets have not been created for this entity. Please ",
        line2: " to create."
      }
    }
  },
  GroupCard: {
    entity: "Entity",
    entities: "Entities",
    tags: "Tags",
    assets: "Entities"
  },
  HistoryTile: {
    changed: "Changed",
    added: "Added",
    from: "from",
    for: "for",
    withAComment: "with a comment",
    to: "to"
  },
  Home: {
    mw: "MW",
    recentlyViewed: "Recently Viewed",
    noAssetsLabel: "You haven't viewed any entities recently."
  },
  UserManagement: {
    userManagement: "User Management",
    createUser: "Create User",
    editUser: "Edit User",
    archiveUser: "Archive User",
    emailAddress: "Email Address",
    archivedUserInfoMsg:
      "The entered email was archived previously and cannot be reused. Please try another email.",
    sameFAMUserInfoMsg:
      "The email is already in use. Please try another email.",
    diffFAMUserInfoMsg:
      "A user with this email already exists. Do you want to add this user to the current FAM?",
    diffFAMExternalUserInfoMsg:
      "This email is already registered as an external user. Please try another email.",
    userId: "User Id",
    firstName: "First Name",
    lastName: "Last Name",
    organisation: "Organisation",
    assets: "Entities",
    roles: "Roles",
    users: "Users",
    isExternalUser: "Is External User",
    noUsersFound: "No users available.",
    userCreatedSuccessMsg: "User created successfully.",
    userUpdatedSuccessMsg: "User updated successfully.",
    roleName: "Role Name",
    roleDescription: "Role Description",
    createRole: "Create Role",
    editRole: "Edit Role",
    cloneRole: "Clone Role",
    assignPrivileges: "Assign Privileges",
    roleCreatedSuccessMsg: "Role created successfully.",
    roleUpdatedSuccessMsg: "Role saved successfully.",
    roleClonedSuccessMsg: "Role cloned successfully.",
    archiveUserMsg:
      "Please note this user will be archived in all fams and cannot become active again. Are you sure you want to continue?",
    assetAccessDenied:
      "You do not have access to the entity you were looking for.",
    orgAccessDenied:
      "You do not have access to the organisation you were looking for."
  },
  Checks: {
    completeness: "Completeness",
    accounting: "Accounting",
    contract: "Contract",
    contracts: "Contracts",
    assetName: "Entity Name",
    balanceSheetCheck: "Balance Sheet Check",
    totalAssets: "Total Assets",
    totalEquityAndLiabilities: "Total Equity and Liabilities",
    cashFlowCheck: "Cash Flow Check",
    closingCash: "Closing Cash",
    sumOfBankAccounts: "Sum of Bank Accounts",
    trialBalanceCheck: "Trial Balance Check",
    value: "Value",
    nominalTransactionUploadCheck: "Nominal Transaction Upload",
    period: "Period",
    debit: "Debit",
    credit: "Credit",
    priorPeriodTransactionsCheck: "Prior Period Transactions",
    onboardingBalance: "Onboarding Balance",
    sumOfNPATToDate: "Sum of NPAT to date",
    expectedRetainedEarnings: "Expected Retained Earnings",
    actualRetainedEarnings: "Actual Retained Earnings",
    missingData: "Missing Data",
    dataCheck: "Data Check",
    depreciationCheck: "Depreciation",
    depreciationCharge: "Depreciation Charge",
    historicalCost: "Historical Cost",
    effectiveRate: "Effective Rate",
    debtorsCreditorsCheck: "Debtors / Creditors",
    debtors: "Debtors",
    creditors: "Creditors",
    revenue: "Revenue",
    turbineOM: "Turbine O&M",
    lease: "Lease",
    valuePerContract: "Value per Contract",
    valuePerFinancialStatements: "Value per Financial Statements",
    varianceAmount: "Variance Amount",
    variancePercent: "Variance %",
    balanceSheet: "Balance Sheet",
    indirectCashFlow: "Indirect Cash Flow",
    directCashflow: "Direct Cash Flow",
    trialBalance: "Trial Balance",
    agedCreditors: "Aged Creditors",
    detailedCreditors: "Detailed Creditors",
    tradeCreditors: "Trade Creditors",
    agedDebtors: "Aged Debtors",
    tradeDebtors: "Trade Debtors",
    transactionReport: "Transaction Report",
    sumOfTransactions: "Sum of transactions",
    glCodesMismatchMsg: "Mismatch in values of",
    nominalLedger: "Nominal Ledger",
    largeTransactions: "Large or Unusual Transactions",
    largeReversals: "Large or Unusual Reversals",
    debtorsCreditors: "Debtors/Creditors",
    standardDeviationAnalysis: "Standard Deviation Analaysis",
    depreciationConsistency: "Depreciation Consistency",
    interestChargeConsistency: "Interest Charge Consistency",
    timeOfPosting: "Time of Posting",
    flagIfNotNil: "Nil Accounts",
    transactionId: "Transaction Id",
    type: "Type",
    postingDate: "Posting Date",
    description: "Description",
    glName: "GL Name",
    assetDescription: "Entity Description",
    netAmount: "Net Amount",
    source: "Source",
    expectedValue: "Expected Value",
    actualValue: "Actual Value",
    balance: "Balance",
    date: "Date",
    amount: "Amount",
    glCode: "GL Code",
    standardDeviation: "Standard Deviation",
    varianceFromMean: "Variance from Mean",
    policyRate: "Policy Rate",
    actualRate: "Actual Rate",
    additions: "Additions",
    variance: "Variance (Non-Contract)",
    averageValue: "Average Value (12m)",
    interestConsistency: "Interest Consistency",
    reportInProgressMsg:
      "Report generation is in progress. You will be notified when it is ready.",
    orgNote:
      "Displaying only those entities that have atleast one failed check in the selected period.",
    belowChecksGenerated:
      "Checks are generated for the below Organisations/Entities and the corresponding months:",
    orgLevelNoAssetsMsg:
      "Nothing to display. All checks across entities are complied in the selected period.",
    totalActualRevenue: "Total Actual Revenue",
    totalCalculatedRevenue: "Total Calculated Revenue",
    revenueChecksGraph: {
      title: "Revenue Performance - Accuracy",
      yAxisTitle: "Period(Months)",
      actualRevenue: "Actual Revenue",
      budgetRevenue: "Budget Revenue",
      calculatedRevenue: "Calculated Revenue"
    },
    createEvent: "Create Event",
    eventName: "Event Name",
    eventSuccessMsg: "Event created successfully.",
    details: "Details"
  },
  RealTimeRevenue: {
    assetName: "Entity Name",
    region: "Region",
    sizeMW: "Size MW",
    type: "Type",
    cod: "COD",
    owner: "Owner",
    asset: "Entity",
    country: "Country",
    meteredGeneration: "Metered Generation",
    estimatedCapacityFactor: "Estimated Capacity Factor",
    dispatchDown: "Dispatch Down",
    total: "Total",
    and: "&",
    constraint: "Constraint",
    curtailment: "Curtailment",
    mwh: "MWh",
    mw: "MW",
    estimatedCapacity: "Estimated Capacity",
    factor: "Factor",
    constraintDataLastUpdatedOn:
      "Constraint & Curtailment data last updated on",
    from: "From",
    to: "To",
    refresh: "Refresh",
    viewYourPPA: "View your PPA and Revenue Reconciliation in Realtime",
    viewPPAConfirmMessage:
      "Adding your PPA will enable seeing daily revenue estimates for your entites. Please email <a href='mailto:sales@skyspecs.com'>sales@skyspecs.com</a> for further details.",
    // viewPPAConfirmMessage:
    //   "Adding your PPA will enable us to show you daily revenue estimates for your entities. Please confirm you would like a call back from us to discuss next steps.",
    constraintDataNotAvailable: "Constraint & Curtailment data not available.",
    knowMore: "Know more",
    updateFrequency: "Update Frequency",
    understand: "I Understand",
    updateFrequencyModalText:
      "This website contains data sourced from the SEMO publicly available market data reports and as such are subject to the same accuracy, frequency and delays associated with their releases.",
    updatedDaily: "Updated daily",
    oneBusinessDayDelay: "1 business day delay",
    oneToTwoBusinessDaysDelay: "1-2 business day(s) delay",
    date: "Date",
    confirmCallBack: "Yes, please call me"
  },
  Organisations: {
    organisations: "Organisations",
    noOrganisations: "No Organisations available.",
    organisationPluralOptionally: "Organization(s)",
    name: "Name",
    currency: "Org. Currency",
    enableConsolidationAtOrg: "Enable Consolidation at Org",
    enableConsolidationAtOrgInfoMsg:
      "Enable the option to upload reporting master data at organisation level. The setting can't be edited once organisation is created",
    numOfAssets: "No. of Entities",
    capacity: "Capacity",
    address: "Address",
    createOrganisation: "Create Organisation",
    editOrganisation: "Edit Organisation",
    save: "Save",
    organisationSavedMsg: "Organisation saved successfully.",
    orgName: "Name",
    Validations: {
      currency: "Please select a currency"
    },
    archiveConfirmMessage:
      "Archiving an organisation will archive all entities associated to the organisation. Do you want to continue?",
    edit: "Edit",
    archive: "Archive",
    Tax: {
      title: "Tax",
      taxes: "Taxes",
      noTaxes: "No taxes available.",
      viewtax: "View Tax",
      active: "Active",
      archived: "Archived",
      name: "Name",
      taxName: "Tax Name",
      description: "Description",
      taxRate: "Tax Rate(%)",
      applicableCountry: "Applicable Country",
      createTax: "Create Tax",
      editTax: "Edit Tax",
      goodsOrService: "Goods/Services",
      type: "Tax Type",
      euTradeAndRevCharge: "EU Trade and Rev. Charge",
      reverseCharge: "Reverse Charge / PVA",
      withHoldingTax: "Withholding Tax",
      reportingRate: "Reporting Rate",
      partialReclaimRate: "Partial Reclaim Rate",
      exlcudeFromVAT: "Exclude from VAT Return",
      applicable: "Applicable",
      goods: "Goods",
      services: "Services",
      purchaseType: "Purchase",
      salesType: "Sales",
      successfulCreateMsg: "Tax created successfully.",
      successfulEditMsg: "Tax updated successfully.",
      archivedMsg: "Tax archived successfully.",
      allowMaxTwoDecimals: "Number with maximum two decimals allowed."
    },
    Budgets: {
      budgets: "Budgets",
      createBudget: "Create Budget",
      editBudget: "Edit Budget",
      isDefault: "Is Default",
      viewEdit: "View/Edit",
      archive: "Archive",
      name: "Name",
      glCode: "GL Code",
      glName: "GL Name",
      budgetLineDescription: "Budget Line Description",
      contact: "Contact",
      contracted: "Contracted",
      cashAmount: "Cash",
      pnlAmount: "P&L",
      accrued: "Accrued",
      importBudget: "Import Budget",
      selectAction: "Select Action",
      syncBudget: "Sync Budget",
      openingBalances: "Opening Balances",
      setOpeningBalances: "Set Opening Balances",
      importOpeningBalances: "Import Opening Balances",
      viewHistory: "View History",
      closeHistory: "Close History",
      history: "History",
      fyEnding: "FY Ending",
      hideCashAmounts: "Hide Cash Amounts",
      forTheMonthOf: "for the month of",
      archiveBudgetMsg: "Are you sure you want to archive this budget?",
      archiveErrorMsg: " is in use. Can not be archived.",
      currentOpeningBalancePnL: "Balance Sheet",
      balanceSheetDefault: "Balance Sheet Default",
      currentOpeningBalanceCash: "Cash",
      prepayments: "Prepayments",
      revenueAccruals: "Revenue Accruals",
      accruals: "Accruals",
      notInUseHistoryMsg:
        "History is available only for a budget which is in use.",
      importOpeningBalancesWarningMsg:
        "Re importing opening balances will overwrite existing opening balances.",
      syncSuccessMsg: "Budget data is synced successfully.",
      dataMissingInCoraMsg:
        "Sync failed for the following BLI(s) since they are not present in CORA but available in the current budget",
      dataMissingInAssetBudgetMsg:
        "Sync failed for the following BLI(s) since they are present in CORA but not in the current budget",
      plsUpdateAndSyncAgain:
        "All other BLI(s) synced successfully. If needed please update current budget or CORA and sync again."
    },
    intercompanyAccounts: {
      label: "Intercompany Accounts",
      glCode: "GL Code",
      asset1: "Entity 1",
      asset2: "Entity 2"
    },
    fixedAssetRegister: {
      label: "Fixed Asset Register",
      assetClass: "Asset Class",
      register: "Register",
      depreciationMethod: "Depreciation Method",
      rate: "Rate(%)",
      depreciationRate: "Depreciation Rate(%)",
      disposalNetValue: "Disposal Net Value",
      effectiveLife: "Effective Life(Months)",
      createFixedAssetClass: "Create Fixed Asset Class",
      straightLineValidationError: "Rate has to be a positive integer",
      name: "Name",
      create: "Create",
      save: "Save",
      cancel: "Cancel",
      pAndLCode: "P&L Code",
      balanceSheetCode: "Balance Sheet Code",
      assignGLCodes: "Assign GL Codes",
      accumulatedDepreciationBalanceSheetCode:
        "Accumulated Depreciation Balance Sheet Code",
      depreciationExpensePLCode: "Depreciation Expense P&L Code",
      grossBlockCodes: "Gross Block Codes",
      balanceSheetCodeTooltip:
        "This is the Accumulated Depreciation BS code that is assigned to the entity class. It is specific to this entity",
      pLCodeTooltip:
        "This is the depreciation expense P&L code that is assigned to the entity class. It is specific to this entity",
      grossBlockCodesTooltip:
        "User can select multiple gross block codes. The selected entity class will be applied to all the line items of purchase instruments that have same code in cash budgeting area",
      assign: "Assign",
      assetClassCreatedSuccessMsg: "Asset Class created successfully.",
      assignGLCodeSuccessMsg: "GL Codes assigned successfully.",
      updateGLCodeSuccessMsg: "GL Codes updated successfully.",
      assetName: "Entity Name",
      depreciationStartDate: "Depreciation Start Date",
      depreciationStartDateInfoMsg:
        "Select a date from which depreciation schedule will be calculated.",
      status: "Status",
      description: "Description",
      refNumber: "Ref Number",
      purchaseDate: "Purchase Date",
      JournalsReflectFrom: "Journals Reflect From",
      journalsReflectFromInfoMsg:
        "Select a date from which depreciation journals should reflect in the platform. This does not impact depreciation schedule. However only future journals corresponding to this period will reflect in the platform. For example if depreciation start date is Jan 1, 2023 and  Journals Start Date is Sep-2023, then depreciation schedule will be calculated from Jan 1, 2023. However only those journals that correspond to all future periods of Sep-2023 will reflect in the platform.",
      cost: "Cost",
      depreciationAmount: "Depreciation Amount",
      netBookValue: "Net Book Value",
      void: "Void",
      createDisposalEntry: "Create Disposal Entry",
      depreciationJournals: "Depreciation Journals",
      disposalJournals: "Disposal Journals",
      disposalJournalsSuccessMsg: "Disposal journals created successfully.",
      update: "Update",
      registerFixedAsset: "Register Fixed Asset",
      registeredSuccessMsg: "Registered Successfully.",
      updateSuccessMsg: "Fixed Asset Register updated successfully.",
      editNote:
        "Updating details will impact all the future depreciation journals.",
      voidWarningNote: "Are you sure you want to void the register?",
      import: "Import",
      importTitle: "Import Fixed Asset Block(s)",
      importContent: {
        line1:
          "This import functionality allows user to import the required fixed entity block(s).",
        line2: "Download the csv template and prepare data accordingly."
      },
      importSuccessMsg: "Fixed Asset Block(s) are imported successfully.",
      reImportingWarning:
        "Re-importing fixed entity block will overwrite existing data.",
      assetDescription: "Entity Description",
      balanceSheetCodeDescription: "BalanceSheet Code Description",
      grossBlockCostCodeDescription: "Gross Block Cost Code Description",
      pAndLCodeDescription: "P&L Code Description",
      usefulLife: "Useful Life (Months)",
      invoiceNumber: "Invoice Number",
      depreciationForCurrentFY: "Dep for Current FY",
      depAmount: "Dep Amount",
      depStartDate: "Dep Start Date"
    },
    reportingMasterData: {
      reportingMasterData: "Reporting Master Data",
      Import: {
        title: "Import Reporting Master Data",
        Steps: {
          one: {
            heading: "Step 1. Download template",
            content:
              "Our standard template can be downloaded from the link below."
          },
          two: {
            heading: "Step 2. Copy Reporting Master Data to the template",
            content:
              "Kindly input details in the downloaded template according to the fields in the file."
          }
        },
        reImportingWarning:
          "Re-importing reporting master data at the organisation level will replace existing data in the corresponding entities.",
        updatingNote:
          "Updating reporting master data at the organisation level will replace existing data in the corresponding entities.",
        logFileMsg:
          "There are errors while updating reporting master data. Refer to the downloaded log file for details."
      },
      noDataAvailableToShow: "No data available.",
      importSuccessMsg: "Reporting Master Data imported successfully.",
      updateSuccessMsg: "Reporting Master Data updated successfully."
    },
    importData: {
      importData: "Import Data",
      period: "Period",
      dataType: "Data Type",
      status: "Status",
      generate: "Generate",
      entity: "Entity",
      latestUploadedDate: "Latest Uploaded Date",
      balanceSheet: "Balance Sheet",
      indirectCashFlow: "Indirect Cash Flow",
      reserves: "Reserves",
      selectAction: "Select Action",
      rejectData: "Reject Data"
    }
  },
  Assets: {
    allAssets: "All Entities",
    createAsset: "Create Entity",
    archiveAsset: "Archive Entity",
    siteName: "Site Name",
    formalBusinessName: "Formal Company Name",
    externalRefID: "External Ref ID",
    newAssetCreated: "New entity created sucessfully.",
    entityType: "Entity Type",
    configuration: "Configuration",
    enableModules: "Enable Modules",
    spv: "SPV",
    holdingCompany: "Holding Company",
    integrated: "Integrated",
    fullService: "Full Service",
    address: "Address",
    siteAddress: "Site Address",
    setAsCompanyAddress: "Set as company address",
    setAsCompanyAddressConfirmMsg:
      "This will replace the site address with the entity address. Do you want to continue?",
    addressLine1: "Address Line 1",
    addressLine2: "Address Line 2",
    addressLine3: "Address Line 3",
    invoices: "Invoice(s)",
    state: "State",
    areaCode: "Area Code",
    country: "Country",
    siteCoOrdinates: "Site Coordinates",
    latitude: "Latitude",
    longitude: "Longitude",
    phase: "Phase",
    capacity: "Installed Capacity",
    currency: "Currency",
    financialYearEnd: "Financial Year End",
    mec: "Maximum Export Capacity (MEC)",
    oem: "OEM",
    model: "Model",
    numOfDevices: "No. of Devices",
    deviceNum: "Device No.",
    deviceId: "Device ID",
    deviceSize: "Device Size",
    takingOverDate: "Taking Over Date",
    financialCloseDate: "Financial Close Date",
    canCreatorApprove: "Can Creator Approve",
    creatorApprovalYes: "Yes",
    creatorApprovalNo: "No",
    expectedEndDate: "Expected End Date",
    operationCloseDate: "Operation Close Date",
    timeZone: "Time Zone",
    ownership: "Ownership",
    ownershipMinValueValidationMessage: "ownership % should be greater than 0",
    ownershipValidationMessage: "Sum of ownership % should be equal to 100.",
    countryForTaxPurpose: "Country for Tax Purposes",
    marketID: "Market Identification No.",
    taxID: "Tax ID",
    currenciesToBeUsedWithAsset: "Currencies to be used with the entity",
    companyDetails: "Company Details",
    successfulMessageOnSave: "Entity is updated successfully.",
    projectDetails: "Project Details",
    site: "Site",
    financialDetails: "Financial Details",
    technology: "Technology",
    assetType: "Entity Type",
    mw: "MW",
    noDataLabel: "You have no entities to view.",
    assetOnBoarding: "Entity Onboarding",
    assetOnBoardingNotCompleteMsg: "incomplete.",
    assets: "Entities",
    dashboard: "Dashboard",
    payments: "Payments",
    utilisations: "Utilisations",
    salesInvoices: "Sales Invoices",
    sales: "Sales",
    ageingReport: "Ageing Report",
    purchaseInvoices: "Purchase Invoices",
    purchases: "Purchases",
    items: "Items",
    cash: "Cash",
    ebitda: "EBITDA",
    revenue: "Revenue",
    purchaseOrders: "Purchase Orders",
    payruns: "Pay Runs",
    reports: "Reports",
    financials: "Financials",
    compliance: "Compliance",
    workflows: "Workflows",
    tasks: "Tasks",
    incomeStatement: "Income Statement",
    balanceSheet: "Balance Sheet",
    indirectCashFlow: "Indirect Cash Flow",
    directCashFlow: "Direct Cash Flow",
    trialBalance: "Trial Balance",
    nominalLedger: "Nominal Ledger",
    detailedCreditors: "Detailed Creditors",
    checks: "Checks",
    completeness: "Completeness",
    revenue: "Revenue",
    exportData: "Export Data",
    actualSpendData: "Actual Spend Data",
    dataSentSuccessMsg: "Data sent successfully.",
    financialStatementChecks: "Financial Statements Checks",
    risks: "Risks",
    constructionBudget: "Construction Budget",
    contingency: "Contingency",
    assetRegister: "Entity Register",
    retention: "Retention",
    utilisationRequests: "Utilisation Requests",
    settings: "Settings",
    comments: "Comments",
    primary: "Primary",
    setAsPrimary: "Set as Primary",
    archiveAlertMessage: "This action cannot be reversed",
    archiveWarningMessage:
      "This entity will be deactivated and no longer be available.",
    archiveWarningMessageForPortifolios:
      "This entity will also be removed from the following portfolios:",
    wrongAssetName: "Incorrect entity",
    enableModulesToastMsg: {
      scenario1:
        "Entity will be configured as full service entity along with contract module.",
      scenario2:
        "Entity will be configured as full service entity along with projects module.",
      scenario3:
        "Entity will be configured as full service entity along with tagging module.",
      scenario4: "entity will be configured as full service entity.",
      scenario5:
        "Entity will be configured as full service entity along with the selected modules.",
      scenario6: "Entity will be configured only for contract codification.",
      scenario7:
        "Entity will be configured as integrated entity along with Projects module.",
      scenario8:
        "Entity will be configured as integrated entity along with Tagging module.",
      scenario9: "Entity will be configured as integrated entity.",
      scenario10:
        "Entity will be configured as integrated entity along with the selected modules."
    },
    Dashboard: {
      production: "Production",
      cash: "Cash",
      revenue: "Revenue",
      operatingCosts: "Operating Costs",
      viewDetails: "View Details",
      actual: "Actual",
      budget: "Budget",
      variance: "Variance",
      billableGeneration: "Billable Generation",
      grossGeneration: "Gross Generation",
      gridCurtailment: "Grid Curtailment",
      gridConstraint: "Grid Constraint",
      availability: "Availability",
      scada: "SCADA",
      systemLosses: "System Losses",
      meteredGeneration: "Metered Generation",
      dlaf: "DLAF",
      tlaf: "TLAF",
      meteredGenerationLossFactored: "Metered Generation Loss Factored",
      adjustments: "Adjustments",
      ebitda: "EBITDA",
      workingCapitalAdjustments: "Working Capital Adjustments",
      corporationTax: "Corporation Tax",
      purchaseOfAssets: "Purchase of Assets",
      cfads: "CFADS",
      seniorDebt: "Senior Debt",
      relatedPartyLoans: "Related Party Loans",
      otherDebt: "Other Debt",
      cad: "CAD",
      distributions: "Distributions",
      movementInCash: "Movement in Cash",
      totalOperatingCosts: "Total Operating Costs",
      totalIncome: "Total Income",
      estLostIncome: "Est. Lost Income",
      theoreticalMaxRevenue: "Theoretical Max. Revenue",
      avgCapturedPrice: "Avg Captured Price",
      cashReceiptedInPeriod: "Cash Receipted in Period",
      operationMaintenance: "Operation & Maintenance",
      property: "Property",
      esg: "ESG",
      professionalFees: "Professional Fees",
      boPExtraordinaryCosts: "BoP/Extraordinary Costs",
      otherCosts: "Other Costs",
      avgCost: "Avg Cost",
      cashSpendInPeriod: "Cash Spend in Period",
      mwh: "MWh",
      to: "to",
      noDataAvailable: "No data available.",
      operational: "Operational",
      construction: "Construction"
    },
    Filters: {
      none: "None",
      defaultFormat: "Default Format",
      default: "Default",
      actual: "Actual",
      forecast: "Forecast",
      variances: "Variances",
      monthEnding: "Month Ending",
      monthRange: "Month Range",
      year: "Year",
      calendarYear: "Calendar Year",
      financialYear: "Financial Year",
      varianceAbove: "Variance above",
      variancePercentAbove: "Variance % above",
      period: "Period",
      dateRange: "Date Range",
      glName: "GL Name",
      supplier: "Supplier",
      postedBy: "Posted By",
      compareTo: "Compare To",
      compareWith: "Compare With",
      organization: "Organisation",
      allOrganizations: "All Organisation(s)",
      from: "From",
      to: "To",
      openingPeriod: "Opening Period",
      budget: "Budget",
      generate: "Generate",
      export: "Export",
      reset: "Reset",
      advancedOptionsDropDown: "Advanced Options",
      moreOptionsDropDown: "More Options",
      assets: "Entities",
      thousands: "'000",
      showNegativeVarianceOnly: "Show Negative Variances Only",
      percentageShare: "% Share",
      compareToBudget: "Compare to Budget",
      showYTD: "YTD",
      oneMonth: "1 Month",
      threeMonths: "3 Months",
      sixMonths: "6 Months",
      twelveMonths: "12 Months",
      previousMonth: "Previous Month",
      previousQuarter: "Previous Quarter",
      previousYear: "Previous Year",
      ytd: "YTD",
      lastYearEnd: "Last year end",
      previousOnePeriod: "Previous 1 Period",
      previousTwoPeriods: "Previous 2 Periods",
      previousThreePeriods: "Previous 3 Periods",
      previousFourPeriods: "Previous 4 Periods",
      previousFivePeriods: "Previous 5 Periods",
      previousSixPeriods: "Previous 6 Periods",
      previousSevenPeriods: "Previous 7 Periods",
      previousEightPeriods: "Previous 8 Periods",
      previousNinePeriods: "Previous 9 Periods",
      previousTenPeriods: "Previous 10 Periods",
      previousElevenPeriods: "Previous 11 Periods",
      budgetNone: "None",
      budgetSponsor: "Sponsor",
      budgetDefault: "Default",
      bankAccounts: "Bank Accounts",
      bankAccountCount: "Bank Account(s)",
      selected: "Selected",
      dataNotAvailable: "You do not have any data to view.",
      ageingBy: "Ageing By",
      invoiceDate: "Invoice Date",
      postingPeriod: "Posting Period",
      assignedTo: "Assigned To"
    },
    IncomeStatement: {
      budgetShort: "(B)",
      budget: "(Budget)",
      variance: "Variance",
      variancePercent: "Variance (%)",
      ytdVariance: "YTD (Variance)",
      ytdVariancePercent: "YTD (Variance %)",
      ytd: "YTD",
      ytdBudget: "YTD (Budget)",
      ytdBudgetShort: "YTD (B)",
      revenue: "Revenue",
      costsOfSales: "Costs of Sales",
      operationalCosts: "Operational Costs",
      summary: "Summary",
      backToIncomeStatement: "Back",
      noDetailsAvailable: "No details available.",
      transactionDate: "Transaction Date",
      supplier: "Supplier",
      supplierCode: "Supplier Code",
      supplierName: "Supplier Name",
      contactName: "Contact Name",
      periodPosted: "Period Posted",
      amount: "Amount",
      unrealisedAmount: "Unrealised Amount",
      date: "Date",
      fxRate: "FX Rate",
      unpaidBalance: "Unpaid Balance",
      fxRevaluation: "FX Revaluation",
      runningTotal: "Running Total",
      instrumentType: "Instrument Type",
      instrumentRefNo: "Instrument Ref No.",
      extRefNo: "Ext Ref No.",
      instrumentDate: "Instrument Date",
      instrumentApproved: "Instrument Approved",
      instrumentAmount: "Instrument Amount",
      transactionDescription: "Transaction Description",
      transactionType: "Transaction Type",
      globalCode: "Global Code",
      globalDescription: "Global Description",
      glCode: "GL Code",
      glName: "GL Name",
      invoices: "Invoices",
      attachments: "Attachments",
      to: "to",
      assetName: "Entity Name",
      budgetLineItem: "Budget Line Item",
      budgetLineItemPO: "Budget Line Item/PO",
      budgetLineItemDescription: "Budget Line Item Description",
      reportingLineItem: "Reporting Line Item",
      budgetAmount: "Budget Amount",
      budgetPOAmount: "Budget/PO Amount",
      actualAmount: "Actual Amount",
      comments: "Comments",
      varianceComments: "Variance Comments",
      noDataAvailable: "No data available.",
      total: "Total"
    },
    BalanceSheet: {
      note: "Note:",
      variance: "Variance",
      assets: "Assets",
      nonCurrentAssets: "Non-Current Assets",
      currentAssets: "Current Assets",
      equity: "Equity",
      currentLiabilities: "Current Liabilities",
      nonCurrentLiabilities: "Non-Current Liabilities",
      amount: "Amount",
      units: "Units(MW)",
      price: "Price",
      transactionType: "Transaction Type",
      date: "Date",
      salesPeriod: "Sales Period",
      contactName: "Contact Name",
      glName: "GL Name",
      glCode: "GL Code",
      glDescription: "GL Description",
      transactionDate: "Transaction Date",
      payeeName: "Payee Name",
      budgetLineItemDescription: "Budget Line Item Description",
      reconciliationDate: "Reconciliation Date ",
      dateOfAccrual: "Date of Accrual",
      descriptionOfExpense: "Description of Expense",
      postingPeriod: "Posting Period",
      contact: "Contact",
      budgetLineItem: "Budget Line Item",
      showDetailedView: "Show Detailed View",
      hideDetailedView: "Hide Detailed View",
      viewNotes: "View Notes",
      accountCode: "Account Code",
      debit: "Debit",
      credit: "Credit",
      notes: "Notes",
      description: "Description",
      journalDate: "Journal Date",
      journalDescription: "Journal Description",
      openingBalance: "Opening Balance",
      total: "Total",
      backToBalanceSheet: "Back",
      noBackupDataAvailable: "Balance sheet details not available.",
      supplierName: "Supplier Name",
      invoiceNumber: "Invoice No",
      invoiceDate: "Invoice Date",
      dueDate: "Due Date",
      older: "Older",
      lineItemNetAmount: "Line Item Net Amount",
      startingDate: "Starting Date",
      endingDate: "Ending Date",
      totalDays: "Total Days",
      daysTillPreviousFY: "Days till previous FY",
      daysInCurrentFY: "Days in current FY",
      daysTillPreviousFYTooltipMsg:
        "Days between End date of Previous Financial year & Invoice date",
      daysInCurrentFYTooltipMsg:
        "Days between current date, Start of the current Financial Year & Invoice date",
      additions: "Additions",
      writeback: "Writeback",
      closingBalance: "Closing Balance",
      status: "Status",
      depreciationJournals: "Depreciation Journals"
    },
    Cashflow: {
      cashFlowFromOperatingActivities: "Cash Flow from Operating Activities",
      cashFlowFromInvestingActivities: "Cash Flow from Investing Activities",
      cashFlowFromFinancingActivities: "Cash Flow from Financing Activities",
      bankAccounts: "Bank Accounts"
    },
    DetailedCashFlow: {
      budgetShort: "(B)",
      budget: "(Budget)",
      actual: "(Actual)",
      budgetText: "Budget",
      actualText: "Actual",
      backToMain: "Back to Main",
      variance: "Variance",
      ytdVariance: "YTD (Variance)",
      ytd: "YTD",
      ytdBudget: "YTD (Budget)",
      ytdBudgetShort: "YTD (B)",
      revenue: "Revenue",
      operationalCosts: "Operational Costs",
      table: "Table",
      cashBridge: "Cash Bridge",
      distributions: "Distributions",
      cashFlowFromOperatingActivities: "Cash Flow from Operating Activities",
      cashFlowFromInvestingActivities: "Cash Flow from Investing Activities",
      cashFlowFromFinancingActivities: "Cash Flow from Financing Activities",
      taxesAndPurchaseOfAssets: "Taxes & Purchase of Assets",
      debtRepayments: "Debt Repayments",
      noDetailsAvailable: "No details available.",
      back: "Back",
      bankAccount: "Bank Account",
      globalCode: "Global Code",
      globalDescription: "Global Description",
      glCode: "GL Code",
      glName: "GL Name",
      contributingItem: "Contributing Item",
      amount: "Amount",
      reconciliation: "Reconciliation",
      unReconciliation: "Un-reconciliation",
      fxRevaluation: "FX Revaluation",
      budgetLineItemDescription: "BLI Description",
      contactName: "Contact Name"
    },
    TrialBalance: {
      glCode: "GL Code",
      glName: "GL Name",
      glDescription: "GL Description",
      glSection: "GL Section",
      transactionType: "Transaction Type",
      transactionDate: "Transaction Date",
      amount: "Amount",
      backToTrialbalance: "Back",
      trialBalance: "Trial Balance",
      noTransactions: "No Transactions available.",
      openingBalance: "Opening Balance:",
      balance: "Balance",
      ytd: "YTD",
      mtd: "MTD"
    },
    NominalLedgerReport: {
      currency: "Currency",
      externalRefNumber: "External Ref",
      transactionId: "Transaction Id",
      transactionDate: "Transaction Date",
      postingPeriod: "Posting Period",
      postedBy: "Posted By",
      transactionType: "Transaction Type",
      contact: "Contact",
      description: "Description",
      amountBaseCurrency: "Amount",
      glCode: "GL Code",
      glName: "GL Name",
      runningBalanceBaseCurrency: "Running Balance",
      fxRate: "FX Rate",
      amountForeignCurrency: "Amount(FX)",
      runningBalanceForeignCurrency: "Running Balance(FX)",
      openingBalance: "Opening Balance",
      closingBalance: "Closing Balance",
      fxRevaluationOn: "Fx Revaluation on"
    },
    ExportData: {
      period: "Period",
      dataSentSuccessMsg: "Commitment and actual Spend Data sent successfully.",
      partialSuccessMsgPart1:
        "Commitment and Actual Spend Data wasn't sent for expenses associated to budget line description(s) ",
      partialSuccessMsgPart2:
        " not available in CORA.<br /><br /> Data was successfully sent for other budget line descriptions.",
      since: " since",
      itIs: " it is",
      theyAre: " they are",
      historyDataNotAvailable: "History not available.",
      user: "User",
      sentOn: "Sent On",
      periodRange: "Period Range",
      status: "Status",
      sentSuccessfully: "Sent successfully",
      history: "History",
      actual: "Actual",
      commitment: "Commitment"
    },
    PurchaseOrders: {
      poCancelNoteMessage: "Existing PO accrual journals will be voided.",
      saveConfirmationStatement: "Do you want to continue?",
      updatingPo: "Updating PO...",
      purchaseOrderAccrualSchedule: "PO Accrual Schedule",
      writeBackJournalMessage:
        "PO accrual writeback journal due to linking of a PI with this PO.",
      reversedJournalMessage:
        "Reversal of posted accrual journal due to linking of a PI with this PO.",
      ReverseWriteBackJournalMessage:
        "Reverse accrual writeback journal due to voiding a PI linked with PO.",
      noJournalsAvailable: "There are no PO accrual journals to be posted.",
      Import: {
        title: "Import Purchase Orders",
        note: "POs imported with Awaiting GRN status will trigger PO accrual journals immediately after import.",
        Steps: {
          one: {
            heading: "Step 1. Download template",
            content:
              "Our standard template can be downloaded from the link below. Kindly input purchase order details in the downloaded template as per fields in the file."
          },
          two: {
            heading: "Step 2. Import template"
          }
        },
        logFileMsg:
          "There are errors while uploading purchase orders file. Refer to the log file for details."
      },
      fileTypeError: "Kindly import CSV file(s) as specified.",
      noPurchaseOrdersFound: "No purchase orders available.",
      deleteLineItem: "Delete line item",
      noPurchaseOrdersFoundForMe: {
        line1: "There are no purchase orders assigned that need action. ",
        line2: " to see all purchase orders."
      },
      submitForReview: "Submit For Review",
      download: "Download",
      me: "Me",
      import: "Import",
      poImportLoaderMessage: "Importing Purchase Order(s)...",
      entity: "Entity",
      assetOrEntityPlaceholdermessage: "Select Entity",
      supplierPlaceholdermessage: "Select Existing Supplier",
      pOCurrencyPlaceholdermessage: "Select Purchase Order Currency",
      assetName: "Entity Name",
      poNumber: "PO Number",
      supplier: "Supplier",
      supplierName: "Supplier Name",
      approvedDate: "Approved Date",
      assignedTo: "Assigned To",
      totalAmount: "Total Amount",
      approvedInvoiceAmount: "Approved Invoice Amount",
      availableAmount: "Available Amount",
      remainingAmount: "Remaining Amount",
      receivedAmount: "Received Amount",
      draftInvoiceAmount: "Draft Invoice Amount",
      status: "Status",
      createPurchaseOrder: "Create Purchase Order",
      supplierPhoneNumber: "Supplier Phone Number",
      supplierEmailAddress: "Supplier Email Address",
      description: "Description",
      supplierAddress: "Supplier Address",
      CreateNewSupplier: "Create New Supplier",
      save: "Save",
      supplierAddedSuccessMessage: "Supplier added successfully.",
      workStartDatestimated: "Est. Work Start Date",
      estInvoicePeriod: "Est. Invoice Period",
      estInvoicePeriodTooltipInfo:
        "This sets the start and end date for the estimated invoice period",
      workEndDateEstimated: "Est. Work End Date",
      currency: "Currency",
      vatRate: "VAT Rate",
      specialInstrcutions: "Special Instructions",
      details: "Details",
      willGoodgAndServicesToDeliveredToSite:
        "Will goods and services be delivered to site?",
      goodsRecivedBy: "Goods Received by",
      quoteFileIsDeleted: "Quote file is deleted.",
      whoWillGoodsBeReceivedBy: "Who will goods be received by?",
      whoCanVerifySuppliers: "Who can verify Suppliers?",
      whoCanReviewPO: "Who can review Purchase Orders?",
      whoCanApprovePO: "Who can approve Purchase Orders?",
      purchaseOrderReviewerLabel: "Select Purchase Order Reviewers",
      purchaseOrderApproverLabel: "Select Purchase Order Approvers",
      purchaseOrderGoodsBeReceivedBy: "Select Goods Or Service Receivers",
      purchaseOrderReviewerOptionsLabel: "Reviewer(s)",
      purchaseOrderApproverOptionsLabel: "Approver(s)",
      purchaseOrderGoodsReceiverOptionsLabel: "Goods Receiver(s)",
      supplierVerifiersOptionsLabel: "Verifier(s)",
      reviewersTooltioInfoMsg:
        "Selected users will be allowed to review and submit the purchase order for approval",
      approversTooltipMessage:
        "Selected users will be allowed to approve the purchase order",
      goodsOrServicesTooltipInfoMsg:
        "Selected users will be allowed to receive goods or services for the purchase order",
      addInvoice: "Add Invoice",
      invoiceDate: "Invoice Date",
      budgetLineItemTooltip:
        "The budget line items are based on Est. Invoice date.",
      invoicePayDate: "Invoice Pay Date",
      estInvoiceValue: "Est. Invoice Value",
      doWithholdingTaxesApply: "Do Withholding Taxes Apply?",
      actualInvoiceAmount: "Actual Invoice Amount",
      createPurchaseOrder: "Create Purchase Order",
      purchaseOrdersCreatedSuccessfully:
        "Purchase order(s) created successfully.",
      purchaseOrderSchedule: "Purchase Order Schedule",
      purchaseOrderAmount: "Purchase Order Amount",
      invoices: "Invoices",
      estimatedInvoices: "Estimated Invoices",
      poAmount: "PO Amount",
      lineItemDescription: "Line Item Description",
      lineItem: "Line Item",
      budgetLineItem: "Budget Line Item",
      lineItemPeriod: "Line Item Period",
      poAccrualSchedule: "PO Accrual Schedule",
      gLCode: "GL Code",
      netAmount: "Net Amount",
      taxCode: " Tax Code",
      taxRate: "Tax Rate(%)",
      grossAmount: "Gross Amount",
      addLineItem: "Add Line Item",
      totalEstInvoiceAmount: "Total Est. Invoice Amount",
      totalPurchaseOrderAmount: "Total Purchase Order Amount",
      purchaseOrder: "Purchase Order",
      purchaseOrders: "Purchase Order(s)",
      goodsOrServicesReceived: "Goods or Services Received",
      receiveGoodsOrServices: "Receive Goods or Services",
      backToReceipts: "Back to Receipts",
      editGoodsReceipt: "Edit Goods Receipt",
      deleteGoodsReceipt: "Delete Goods Receipt",
      dateReceived: "Date Received",
      goodsReceivedSuccessfully: "Good and Services receieved successfully",
      valueOfGoodsOrServicesReceived: "Value of goods or services received",
      value: "Value",
      comments: "Comments",
      totalReceipts: "Total Receipts",
      noReceiptsToDisplay: "No receipts available.",
      poEntityValidationMsg: "Please select an entity",
      poSupplierValidationMsg: "Please select a supplier",
      poCurrencyValidationMsg: "Please select purchase order currency",
      poApproversValidationMsg: "Please select purchase order approver",
      poReceiversValidationMsg: "Please select who will receive goods",
      poReviewersValidationMsg: "Please select purchase order reviewer",
      poSuccessMessageSave: "Purchase order details saved successfully.",
      noApproversErrorMsg:
        "There are no approvers who can approve this purchase order. Configure users as approvers and try again.",
      totalEstInvoiceAndPOAmountValueValidationMessage:
        "Total Purchase Order Amount cannot be greater than Approved Purchase Order Amount",
      totalEstInvoiceAndReceivedAmountValidationMessage:
        "Total Purchase Order Amount cannot be less than Received Amount",
      poNetAmountChangeWarningMessage:
        "Existing PO accrual journals will be voided and a new set of PO accrual journals will be posted.",
      poAmountReductionWarningMessage:
        "Est. invoice value will reduce the value of an approved total purchase order amount and cannot be reversed. Are you sure?",
      poVoidActionWarnMessage:
        "Are you sure you want to void the purchase order?",
      deleteInvoice: "Delete invoice",
      view: "View",
      return: "Return",
      poUnsavedWarnMessage:
        "All unsaved changes will be lost after this action.",
      returnReason: "Return reason",
      returnPurchaseOrder: "Return Purchase Order to Draft",
      cancelPurchaseOrder: "Cancel Purchase Order",
      changedPurchaseOrderDetails: "Changed purchase order details.",
      anAttachment: "an attachment.",
      purchaseOrderCreated: "Purchase order created",
      cancellationReasonMessage:
        "On cancelling the purchase order the following will happen.",
      cancellationReason: "Cancellation reason",
      cancellationActionErrorMessage:
        "The purchase order can be cancelled only when est. Invoice values matches the actual invoice.",
      changesTo: "Changes To",
      received: "Received",
      invoiced: "Invoiced",
      receipted: "Receipted",
      purchaseOrderStatus: "Purchase Order Status",
      quote: "Quote",
      history: "History",
      attachments: "Attachments",
      receiveGoodsServices: "Receive Goods/Services",
      thisIsTheTotalOfGoodsReceiptedItCantBeGreaterThanPoAmount:
        "This is the total of goods receipted. It cant be greater than purchase order amount",
      sumTotalOfApprovedPurchaseInvoicesLinkedToThisPo:
        "Sum total of approved purchase invoices linked to this purchase order",
      sumTotalOfDraftPurchaseInvoicesLinkedToThisPo:
        "Sum total of draft purchase invoices linked to this purchase order",
      totalAmountMinusInvoicedAmount: "Total Amount minus Invoiced Amount",
      linkedPurchaseInvoices: "Linked Purchase Invoices",
      invoiceNo: "Invoice No",
      invoicedAmount: "Invoiced Amount",
      addInformationOnInstrument: "Additional Information on Instrument",
      emailContent: "Email Content",
      send: "Send",
      to: "To",
      from: "From",
      cc: "CC",
      sendMeCopy: "Send me a copy",
      emailSeparationNote: "Separate multiple email IDs with a (,)",
      recipentEmailErrorMsg: "Recipient email ID is missing",
      invalidEmailsErrorMsg: "Invalid email ID(s)",
      preview: "Preview",
      PONumberErrorMsg: "Purchase Order number in the email is incorrect",
      emailContentErrorMsg: "Email content cannot be blank.",
      quoteFileTypeError:
        "Accepted file types: jpg, jpeg, png, pdf, docx, xlsx, csv",
      emailSuccessMsg: "Email has been successfully sent.",
      email: {
        step1: "Hi",
        step2: "Here is your Purchase Order",
        step3: `This is an autogenerated email. Kindly don’t reply to this email.

Thanks,
The SkySpecs Team`
      },
      emailNote: "Purchase Order will be attached with the email.",
      ytd: "YTD",
      mtd: "MTD",
      estimatedInvoice: "Estimated Invoice",
      invoicedPoAccrualScheduleNoteMessage:
        "Below is the proposed PO Accrual Schedule. Past period journals are posted and can be accessed from their respective links. Future period journals will be posted at the end of corresponding period.",
      poAccrualScheduleNoteMessage:
        "Below is the PO Accrual Schedule. Posted journals can be accessed from their respective links.",
      period: "Period",
      bliGlCode: "BLI/GL Code",
      debit: "Debit",
      credit: "Credit",
      reportingLineItem: "Reporting Line Item",
      glName: "GL Name",
      contractedOrBudgted: "Contracted/Budgted",
      lineItemAmount: "Line Item Amount",
      available: "Available",
      budgeted: "Budgeted",
      incurredOpeningBalance: "Incurred Opening Balance",
      approvedPOs: "Approved POs",
      approvedPurchases: "Approved Purchases",
      bankReconciliationTransactions: "Bank Reconciliation Transactions",
      totalConsumed: "Total Consumed",
      purchaseOrderNumber: "Purchase Order Number",
      invoiceNumber: "Invoice Number",
      transactionDate: "Transaction Date",
      incurredAmount: "Incurred Amount",
      noDetailsAvailable: "No details available.",
      viewBudget: "View Budget",
      lineItemAmountInfoMsg: "Value entered in the purchase order",
      budgetedInfoMsg:
        "This is the value from the budget for the period selected",
      incurredOpeningBalanceInfoMsg:
        "Incurred cash opening balance against this budget line item",
      approvedPOsYtdInfoMsg:
        "All Purchase Orders approved in the current year that use this budget line item",
      approvedPOsMtdInfoMsg:
        "All Purchase Orders approved in the current month that use this budget line item",
      approvedPurchasesYtdInfoMsg:
        "All Invoices approved in the current year that use this budget line item",
      approvedPurchasesMtdInfoMsg:
        "All Invoices approved in the current month that use this budget line item",
      bankReconciliationTransactionsYtdInfoMsg:
        "All bank reconcilations where a transaction have been created that uses this budget line item(in the current year)",
      bankReconciliationTransactionsMtdInfoMsg:
        "All bank reconcilations where a transaction have been created that uses this budget line item(in the current month)",
      totalConsumedInfoMsg: "Sum total of all incurred columns",
      approvedPurchaseOrderAmountMTD: "Approved Purchase Orders Amount MTD",
      approvedPurchaseOrderAmountYTD: "Approved Purchase Orders Amount YTD",
      approvedPurchaseInvoiecsMTD: "Approved Purchase Invoices Amount MTD",
      approvedPurchaseInvoiecsYTD: "Approved Purchase Invoices Amount YTD",
      bankReconciliationTransactionsAmountYTD:
        "Bank Reconciliation Transactions Amount YTD",
      bankReconciliationTransactionsAmountMTD:
        "Bank Reconciliation Transactions Amount MTD",
      overBudgetedLineItems: "Over Budgeted Line Items",
      overBudgetedPopUp: {
        text1: "The below line items are over budgeted.",
        text2: "Do you want to continue?"
      },
      viewDetails: "View Details"
    },
    Payments: {
      noPaymentsFound: "No payments available.",
      purchasePayments: "Purchase Payments",
      salesPayments: "Sales Payments",
      supplierName: "Supplier Name",
      customerName: "Customer Name",
      assetName: "Entity Name",
      invoiceDate: "Invoice Date",
      invoiceNumber: "Invoice Number",
      paymentStatus: "Payment Status",
      assignedTo: "Assigned To",
      grossAmount: "Gross Amount",
      amountDue: "Amount Due",
      netAmount: "Net Amount",
      paymentAmount: "Payment amount",
      approvedAmount: "Approved Amount",
      suggestedAmount: "Suggested Amount",
      approvePayments: "Approve Payments",
      generatePayrun: "Generate Payrun",
      createNonInvoicePayment: "Create Non Invoice Payment",
      approvePaymentsTooltipText:
        "If paid today, then value in Base Currency is",
      onInvoiceDate: "On Invoice Date",
      potentialLoss: "Potential Loss",
      potentialGain: "Potential Gain",
      createPayments: "Create Payments",
      noInvoicesAreAvailableForCreatingPayments:
        "No invoices are available for creating payments.",
      noPaymentsAreAvailable: "No payments are available.",
      paymentsAreSuccessfullyCreatedAndSubmittedForApproval:
        "Payments are successfully created and submitted for approval.",
      paymentsAreCreatedSuccessfully: "Payments are created successfully.",
      paymentsAreSubmittedForApproval: "Payments are submitted for approval",
      noPaymentsToApprove: "No payments to approve",
      noPaymentsAvailableToGeneratePayruns:
        "No payments available to generate payruns"
    },
    PayRuns: {
      noPayruns: "No payruns available.",
      payrunName: "Payrun Name",
      payrun: "Payrun",
      payrunDate: "Payrun Date",
      generatedBy: "Generated By",
      assetName: "Entity Name",
      account: "Account",
      totalPaymentAmount: "Total Payment Amount",
      viewDetails: "View Details",
      viewCompletedPayruns: "View Completed Pay Runs",
      payee: "Payee",
      extRefNo: "Ext Ref No.",
      refNo: "Ref No.",
      invoiceDate: "Invoice Date",
      reconciliationStatus: "Reconciliation Status",
      reconciled: "Reconciled",
      unReconciled: "Unreconciled",
      amount: "Amount",
      exchangeRateTooltipText1:
        "Total payment amount is based on exchange rate as on",
      exchangeRateTooltipText2:
        "And it consists of payments in currencies other than",
      exchangeRateTooltipText3:
        "View details to see the currencies and corresponding payments.",
      reversePayrun: "Reverse Payrun",
      viewPayrun: "View Payrun",
      downloadPayrun: "Download Payrun",
      exportPayrun: "Export Payrun",
      fileFormat: "File Format",
      dateOfPayment: "Date of Payment",
      cancel: "Cancel",
      payrunReversalSuccessMessage: "Payrun reversed successfully."
    },
    AgeingReport: {
      note: "Note:",
      creditors: "Creditors",
      debtors: "Debtors",
      contactName: "Contact Name",
      older: "Older",
      assetName: "Entity Name",
      invoiceDate: "Invoice Date",
      dueDate: "Due Date",
      refNo: "Ref No.",
      extRefNo: "Ext Ref No.",
      total: "Total",
      back: "Back",
      debtorsAgeingReportFor: "Debtors Ageing Report for",
      creditorsAgeingReportFor: "Creditors Ageing Report for",
      postingPeriod: "Posting Period"
    },
    Settings: {
      FxRevaluation: {
        ran: " ran ",
        for: " for "
      },
      importData: "Import Data",
      generalDetails: "General Details",
      createAsset: "Create Entity",
      closingPeriod: "Closing Period",
      reportingMasterData: "Reporting Master Data",
      integration: {
        label: "Integration",
        configureDataSource: "Configure Data Source",
        dataSource: "Data Source",
        clientId: "Client ID",
        clientIdInfoMessage: "Enter company name as configured at Greenbyte.",
        clientSecretKey: "Client Secret Key",
        secretKey: "Secret Key",
        secretKeyInfoMessage: "Enter API Key as configured at Greenbyte.",
        saveAndTestConnection: "Save and Test Connection",
        dataSourceConnectionSuccessMessage:
          "SCADA Data Source configured successfully. Configure device IDs at entity level.",
        noOfDevices: "No. of Devices",
        deviceIds: "Device IDs",
        deviceId: "Device Id",
        devices: "Devices",
        save: "Save",
        devicesIdsSaveSuccessMsg: [
          "Details saved successfully.",
          "Data source is not configured at Organisation level. ",
          "Click here",
          " to configure.",
          "Device Ids are not configured. Kindly configure."
        ],
        frequency: "Frequency",
        fyEnding: "FY Ending",
        period: "Period",
        totalInKwh: "Total(kwh)",
        periodRange: "Period Range",
        monthRange: "Month Range",
        selectDevice: "Select Device",
        kindlyRetry: "Unable to save details. Kindly retry.",
        deviceDetails: "Device Details",
        step: "Step",
        previous: "Previous",
        month: "Month",
        data: "Data",
        configureCompany: "Configure Company",
        mapGlCodes: "Map GL Codes",
        systemGlCodes: "GL Codes (System)",
        platformGlCodes: "GL Codes (MS dynamics)",
        companyName: "Company Name",
        glCodes: "GL Codes",
        glCodesInfoMsg: "Import list of GL Codes as maintained in MS Dynamics",
        trialBalance: "Trial Balance",
        next: "Next",
        send: "Send",
        sendData: "Send Data",
        sendingData: "Sending Data...",
        selectPlatform: "Select Platform",
        companyName: "Company Name",
        invalidCompanyNameErrorMsg: "Invalid company name.",
        trialBalanceLastDataSentInfoMsg:
          "The Trial Balance data was last sent on "
      },
      AssetOnboarding: {
        Checklist: {
          mainInfoTextLine1ForFullService:
            "The checklist(Step 1 to Step 9) below acts as a guide to setup the entity. To ensure that the entity has been setup correctly, users should address each of the steps.",
          mainInfoTextLine1ForIntegrated:
            "The checklist(Step 1 to Step 5) below acts as a guide to setup the entity. To ensure that the entity has been setup correctly, users should address each of the steps.",
          mainInfoTextLine2:
            "Once entity onboarding is complete, then you cannot change the opening period (Step 3) and conversion period (Step 6).",
          mainInfoTextLine3:
            "While the entity onboarding is not complete, then it is possible to reset the Chart of accounts, System Accounts, Opening balances and Conversion Period, before completing the onboarding. However resetting would cause dependents to be reset as well. For example :  Re-importing Chart of accounts will reset System Accounts, Opening Balances and Conversion Period.",
          isImportReportingMasterDataConfiguredLabel:
            "Import Reporting Master Data",
          isCreditorsBalanceCheckMatchingLabel: "Creditors Balance Check",
          isDebtorsBalanceCheckMatchingLabel: "Debtors Balance Check",
          isDefaultReportingFormatConfiguredLabel:
            "Configure Default Financial Format",
          isImportChartOfAccountsConfiguredLabel: "Import Chart of Accounts",
          isOrgGLAccountMapToAssetGLAccountLabel:
            "Map Org. Chart of Accounts to Entity Chart of Accounts",
          isSetAssetCurrencyLabel: "Set Entity Currency",
          isSetConversionPeriodConfiguredLabel: "Set Conversion Period",
          isSetSalesInvoiceNumberLabel: "Set Sales Invoice No.",
          isBankAccountConfiguredLabel: "Configure Bank Accounts",
          isJournalsImportedLabel: "Import Opening Journals",
          isOpeningPeriodAndBalanceConfiguredLabel:
            "Set Opening period and Import Opening Balances",
          isSystemAccountConfiguredLabel: "Set System Accounts",
          isFixedAssetBlockImportedLabel: "Import Fixed Asset Block",
          BalanceLabels: {
            TradeBalance: {
              isCreditorsBalanceCheckMatching: "Trade Creditors Balance",
              isDebtorsBalanceCheckMatching: "Trade Debtors Balance"
            },
            PendingBalance: {
              isCreditorsBalanceCheckMatching:
                "Pending Purchase Invoice Balance",
              isDebtorsBalanceCheckMatching: "Pending Sales Invoice Balance"
            }
          },
          SmallDescriptions: {
            Completed: {
              isCreditorsBalanceCheckLabel: "",
              isDebtorsBalanceCheckConfiguredLabel:
                "Trade Debtors and pending Sales Invoices are matching.",
              isDefaultReportingFormatConfiguredLabel: "",
              isImportChartOfAccountsConfiguredLabel:
                "Chart of accounts is imported",
              isOrgGLAccountMapToAssetGLAccountLabel: "",
              isSetAssetCurrencyLabel: "",
              isSetConversionPeriodConfiguredLabel:
                "Conversion period is now set. Please create pending Purchase Invoices, payments so that the accounts payable = Invoices pending payment.",
              isSetSalesInvoiceNumberLabel: "Initial Sales Invoice No. set to ",
              isOpeningPeriodAndBalanceConfiguredLabel: "",
              isSystemAccountConfiguredLabel: "System accounts are now mapped.",
              isImportReportingMasterDataConfiguredLabel:
                "Reporting Master data is now imported, you can generate financial statements."
            },
            Incompleted: {
              isCreditorsBalanceCheckLabel:
                "Sum of  Gross Amount Unpaid Purchase Invoice(Debit Notes) should equal the Trade Creditors Balance.",
              isDebtorsBalanceCheckConfiguredLabel: "",
              isDefaultReportingFormatConfiguredLabel:
                "Cannot be done unless chart of accounts is completed.",
              isImportChartOfAccountsConfiguredLabel:
                "Importing chart of accounts is the first step to onboarding a new entity.",
              isOrgGLAccountMapToAssetGLAccountLabel: "",
              isSetAssetCurrencyLabel: "",
              isSetConversionPeriodConfiguredLabel:
                "Conversion period is to be set so that Journals, AP/AR workflows can be used.",
              isSetSalesInvoiceNumberLabel:
                "Sales Invoice No. needs to be set before Sales Invoices can be created",
              isOpeningPeriodAndBalanceConfiguredLabel:
                "Cannot be done unless chart of accounts are imported.",
              isSystemAccountConfiguredLabel: "",
              isImportReportingMasterDataConfiguredLabel:
                "Importing of reporting master data is required to generate financial statements."
            },
            isCreditorsBalanceCheckMatching: "View Purchase Invoices",
            isDebtorsBalanceCheckMatching: "View Sales Invoices"
          },

          Help: {
            isCreditorsBalanceCheck:
              "The total accounts payable should be equal to pending purchase invoices. Entity onboarding can be completed once done.",
            isImportReportingMasterDataConfigured:
              "Importing the reporting master data sets up the reporting format for the entity",
            isDebtorsBalanceCheckConfigured:
              "Unpaid Purchase Invoice(Debit Notes) should equal the Trade Creditors Balance.  Once these balance",
            isDefaultReportingFormatConfigured:
              "Configuring a reporting format allows generation of financial statements.",
            isImportChartOfAccountsConfigured: "Chart of accounts is required.",
            isJournalsImported:
              "If journals are to be posted in the period of their date then Approve journals prior to setting conversion period else they will be posted to the period of conversion.",
            isOrgGLAccountMapToAssetGLAccount: "",
            isSetAssetCurrency: "",
            isSetConversionPeriodConfigured:
              "Conversion Period is date from which AP/AR process can be used i.e., Invoices can be created after onboarding only for dates after Conversion Date. Once conversion period is set, journals prior to conversion start date cannot be imported or posted, conversion period can be reset if required.",
            isSetSalesInvoiceNumber: "Set the initial sales invoice number",
            isOpeningPeriodAndBalanceConfigured: {
              text: "Opening period is the date from which information about the entity is stored in the platform. Opening balances identify the balance against each GLCode as start of Opening Period. The balances are considered true as on Historical date. After setting up opening period and importing opening balances , the entity is setup for:",
              subTexts: [
                "Importing Transactions",
                "Generating Financial statements",
                "Performing Bank Reconciliation"
              ]
            },
            isBankAccountConfigured:
              "All the Imported Bank GL codes have to be configured prior to entity on-boarding",
            isSystemAccountConfigured:
              "Map the system accounts from chart of accounts for use in AP/AR workflows. Once mapped these are locked and cannot be modified.",
            isFixedAssetBlockImported:
              "Import Fixed entity block(s) and register them. Automatic depreciation journals will be created after entity onboarding is marked complete."
          },
          assetOnboardingCompleteBtnTitle: "Set Entity Onboarding Complete"
        },
        title: "Entity Onboarding",
        onBoardingCheckList: "Onboarding Checklist",
        conversionPeriod: "Conversion Period",
        conversionPeriodDesc: {
          step1: `Conversion Period is the month from which the entity is being managed on the platform i.e., if Conversion period is set to April XXXX.  Then 1st April XXXX( i.e., month ending March ) is the date that will be used for all workflows and calculations relating to Conversion Period.`,
          step2:
            "The conversion period can be the month after the opening period. The opening period is currently set to ",
          step3: ", so the conversion period can be only on or after "
        },
        sales: "Sales",
        seedingNumber: "Seeding Number",
        salesInvoices: "Sales Invoices",
        purchaseInvoices: "Purchase Invoices",
        purchases: "Purchases",
        salesInvoiceSeedingNumber: "Sales Invoice Seeding No.",
        salesInvoiceSeedingNumberHelpText:
          "This is the number from which Sales Invoices will start.",
        salesCreditNoteSeedingNumber: "Sales Credit Note Seeding No.",
        salesCreditNoteSeedingNumberHelpText:
          "This is the number from which Sales Credit Notes will start.",
        purchase: "Purchase",
        purchaseOrders: "Purchase Orders",
        POSeedingNumber: "Purchase Order Seeding No.",
        salesInvoice: "Sales Invoice",
        salesCreditNote: "Sales Credit Note",
        seedingNumberSaveSuccessMsg: "Seeding No. saved successfully.",
        poSeedingNumberSaveSuccessMsg:
          "Purchase Order Seeding No. saved successfully.",
        poSeedingNumberHelpText:
          "This is the number from which Purchase Order numbers will start.",
        prependedTextPlaceHolder: "Enter Prepended text",
        note: "Note:",
        POSeedingNumberNote:
          "Seeding number cannot be changed once a Purchase Order has been approved.",
        samplePOSeedingNumber: "First Purchase Order will be:",
        openingPeriod: "Opening Period",
        setConversionPeriod: "Set Conversion Period",
        assetSetupWalkThrough: "Entity Setup Walkthrough",
        currentlySetTo: "Currently set to",
        integrated: "Integrated",
        service: "Full Servcice",
        assetOnboardingChecklist: "Entity Onboarding Checklist",
        onboardingCompleteMsg:
          "This entity has been onboarded. You can now use the AP/AR flow for dates after the start of the opening period.",
        inCompleteOpeningPeriod:
          "Opening Period and Import Opening Balances are not set.",
        messageToappendClickhereLink:
          " to set Opening Period and Import Opening Balances.",
        tasksCompleted: "tasks completed",
        refresh: "Refresh Checklist",
        modules: "modules",
        module: "module",
        contractCodification: "contract codification",
        onboardingCompleteMsgFullServiceLine1:
          "Entity has been onboarded as full service entity along with the",
        onboardingCompleteMsgFullServiceLine2:
          "You can use the AP/AR flow for dates after the start of the opening period.",
        onboardingCompleteMsgIntegratedLine:
          "Entity has been onboarded as integrated entity along with the",
        onboardingCompleteMsgFullServiceLine:
          "Entity has been onboarded as full service entity along with the",
        onboardingCompleteMsgScenarios: {
          scenario1:
            "Entity has been onboarded as full service entity along with contract codification module. You can use the AP/AR flow for dates after the start of the opening period.",
          scenario2:
            "Entity has been onboarded as full service entity along with projects module. You can use the AP/AR flow for dates after the start of the opening period.",
          scenario3:
            "Entity has been onboarded as full service entity along with tagging module. You can use the AP/AR flow for dates after the start of the opening period.",
          scenario4:
            "Entity has been onboarded as full service entity. You can use the AP/AR flow for dates after the start of the opening period.",

          scenario6: "This screen is not applicable.",
          scenario7:
            "Entity has been onboarded as integrated entity along with Projects module.",
          scenario8:
            "Entity has been onboarded as integrated entity along with Tagging module.",
          scenario9: "Entity has been onboarded as integrated entity."
        }
      },
      Rulesets: {
        rulesets: "Rulesets",
        addRulest: "Add Ruleset",
        save: "Save",
        noRulesetsMsg: "No rulesets available.",
        rulesetSaveSuccessMsg: "Ruleset(s) data saved successfully."
      },
      dashboard: "Dashboard",
      asset: "Entity",
      assetDashboard: "Entity Dashboard",
      directCashflow: "Direct Cash Flow",
      year: "Year",
      month: "Month",
      update: "Update",
      saveData: "Save Data",
      submit: "Submit",
      import: "Import",
      viewMasterData: "View Master Data",
      viewDifferenceWithchartOfAccountsButtonTitle: "View Diff. with COA",
      differenceWithchartOfAccountsButtonTitle: "Diff. with COA",
      getReleaseStatus: "Get Release Status",
      date: "Date",
      period: "Period",
      status: "Status",
      budgetData: "Budget Data",
      actualData: "Actual Data",
      masterData: "Master Data",
      value: "Value",
      kpi: "KPI",
      glCode: "GL Code",
      glName: "GL Name",
      glDescription: "GL Description",
      globalCode: "Global Code",
      globalDescription: "Global Description",
      indrectCashFlowCategory: "Indirect Cash Flow Category",
      directCashFlowCategory: "Direct Cash Flow Category",
      aggregateOfGlobalCodes: "Aggregate of Global Codes",
      coa: "COA",
      reportingMasterData: "Reporting Master Data",
      glSectionMatched: "GL Section Matched",
      coaGLSection: "COA GL Section",
      reportingGlSection: "Reporting GL Section",
      reportingLineDescription: "Reporting Line Description",
      cashFlowCategory: "Cash Flow Category",
      dashboardCategory: "Dashboard Category",
      dashboardDetailedCashFlowCategory: "Dashboard Direct Cash Flow Category",
      glSection: "GL Section",
      nonInvoice: "Non-Invoice",
      noDataAvailable: "Data is not available.",
      noMasterData: "No data available.",
      noDiff: "No Diff. with COA.",
      releaseData: "Release Data",
      dataReleaseSuccessMsg: "Data released successfully.",
      releaseDataWarningMsg:
        "Are you sure the checks for the selected period have been generated and thoroughly verified prior to release of data?",
      fxRevaluation: "FX Revaluation",
      viewHistory: "View History",
      fxRevaluationWarningMessages: [
        "An FX revaluation was done on ",
        "Running a revaluation on ",
        " will impact Trade Creditors/Debtors and Ageing Report balances after ",
        "Revaluate again on ",
        " to bring the balances into sync."
      ],
      history: "History",
      fxRevaluationInfoMsgPart1: "FX Revaluation has been triggered for ",
      fxRevaluationInfoMsgPart2: " and this might take sometime.",
      importOpeningBalances: "Import Opening Balances",
      setOpeningBalancePageTitle:
        "Set Opening Period and Import Opening Balances",
      OpeningPeriodDesc: {
        step1: `Opening Period is the month for which the opening balances for all GL-accounts were set.
          This is the date from which all financial statements are available on the platform.`,
        step2: `Opening balances imported are true as on last day of the opening period.`,
        step3: `i.e if opening period is set to Jan XXXX , then the opening balances imported are true as on Jan 31st XXXX`,
        step4:
          "Include only Balance Sheet data and not Income Statement Data. If uploading a Trial Balance for the Opening Period, convert all Income Statement items to the Equity."
      },
      purchaseOrders: "Purchase Orders",
      actualDataFile: "Actual Data File",
      budgetFile: "Budget Data File",
      masterDataFile: "Master Data File",
      importMasterData: "Import Master Data",
      purchaseOrdersDataFile: "Purchase Orders Data File",
      budgetYear: "Budget Year",
      budgetType: "Budget Type",
      Headings: {
        value: "Value"
      },
      supplierName: "Supplier Name",
      invoiceDate: "Invoice Date",
      invoiceId: "Invoice Id",
      bankAC: "Bank A/C",
      accountDetails: "Account Details",
      iban: "IBAN",
      bic: "BIC",
      oin: "OIN",
      currency: "Currency",
      openingStatementBalance: "Opening Statement Balance",
      ibanError: "IBAN entered is not valid.",
      bicError: "BIC entered is not valid.",
      oinError: "OIN can have only alphabets and numbers.",
      balance: "Balance",
      save: "Save",
      bankACSavedMessage: "Bank A/C details saved successfully.",
      noReleaseDone: "No release done.",
      supplier: "Supplier",
      displayName: "Display Name",
      uom: "UOM",
      calculationType: "Calculation Type",
      formula: "Formula",
      order: "Order",
      logoSizeError: "Size too large.",
      logoDimensionError: "Dimensions are greater than 250 x 100.",
      logoTypeError: "Image should only be a jpg/png.",
      invalidLogoError: "Invalid file format.",
      logo: "Logo",
      logoHelperText:
        "Image should be a jpg/png, less than 200KB in size and not more than 250 pixels (width) x 100 pixels (height).",
      ClosingPeriod: {
        closeAll: "Close All",
        noClosingPeriods: "No closing periods avialble.",
        closeAllAP: "Close All AP",
        closeAllAR: "Close All AR",
        closeAllJournal: "Close All Journal",
        financialYear: "Financial Year",
        generate: "Generate",
        viewHistory: "View History",
        period: "Period",
        ap: "AP",
        ar: "AR",
        journal: "Journal",
        reopen: "Reopen",
        open: "Open",
        close: "Close",
        closed: "Closed",
        isClosedPeriodMsg: "is a closed period",
        thisWillReopenMsg: "This will reopen the period for",
        forTheAssetMsg: "for the entity",
        commentsModalInfoMsg:
          "is a closed period. This will reopen the period for",
        for: "for",
        assetName: "Entity Name",
        back: "Back",
        closePeriodTooltipMsg: "Click to close this period",
        reOpenPeriodTooltipMsg: "Click to reopen this period"
      },
      reportingMasterData: {
        reportingMasterData: "Reporting Master Data",
        Import: {
          title: "Import Reporting Master Data",
          Steps: {
            one: {
              heading: "Step 1. Download template",
              content:
                "Our standard template can be downloaded from the link below."
            },
            two: {
              heading: "Step 2. Copy Reporting Master Data to the template",
              content:
                "Kindly input details in the downloaded template according to the fields in the file."
            }
          },
          reImportingWarning:
            "Re-importing reporting master data will replace existing data in the entity.",
          logFileMsg:
            "There are errors while uploading reporting master data. Refer to the log file for details."
        },
        noDataAvailableToShow: "No data available.",
        importSuccessMsg: "Reporting Master Data imported successfully.",
        updateSuccessMsg: "Reporting Master Data updated successfully."
      }
    },
    NoteMessages: {
      takingOverDate: "This is the date that the entity went operational",
      financialCurrencyDetailsNote:
        "Choose the currency your company files taxes and reports in.",
      currenciesToBeUsedWithAsset:
        "These currencies can be used for transactions in the entity and can't be deleted"
    },
    enablePoAutomation: "Enable PO Automation",
    enablePoAutomationInfo:
      "User will have ability to import POs. System will post accrual journals automatically. Once enabled, user will not be able to disable it.",
    automaticJournalStatus: "Automatic Journal Status",
    automaticJournalStatusInfo:
      "Prepayment and accrual writebacks from AP module, reverse accruals from AR module, depreciation journals from fixed entity register will be created with selected status"
  },
  Payments: {
    paid: "Paid",
    received: "Received",
    due: "Due",
    inbox: "Inbox",
    createInvoice: "Create Invoice",
    invoices: "Invoices",
    payments: "Payments",
    CreditNotes: {
      title: "Receipts",
      create: "Create Receipt",
      allocateTo: "Allocate to Purchase Invoice",
      emptyReciptRecords: "No receipts available.",
      Headers: {
        receiptDate: "Receipt Date",
        totalAmount: "Total Amount"
      },
      backTo: "Back to Receipts & Allocations",
      receiptAmt: "Receipt Amount",
      bankAccount: "Bank Account",
      minimumReceiptAmountError: "Receipt amount should be greater than 0",
      confirmReceiptDeleteMessage:
        "Are you sure you want to delete this receipt?",
      successfulReceiptMessage: "Receipt created successfully.",
      successfulDeleteReceiptMessage: "Receipt deleted successfully."
    },
    Allocations: {
      title: "Allocations",
      comments: "Comments",
      emptyRecords: "No allocations available.",
      maxAmountAllowedWarning: "Maximum amount allowed for allocation is ",
      allocatedAmt: "Allocated Amount",
      successfulDeleteMessage: "Allocation deleted successfully.",
      confirmAllocationDeleteMessage:
        "Are you sure you want to delete this allocation?",
      confirmAutoAllocatedAllocationDeleteMessage:
        "Are you sure you want to delete this allocation? This action will void the purchase credit note.",
      Headers: {
        lineItems: "Line Items",
        allocationAmt: "Allocation Amount",
        availableAmt: "Available Amount"
      },
      Invoice: {
        allocateTo: "Allocate to Purchase Credit Note",
        backTo: "Back to Allocations",
        entityToAllocateTo: "Purchase Credit Note"
      },
      CreditNote: {
        allocateTo: "Allocate to Purchase Invoice",
        backTo: "Back to Receipts & Allocations",
        entityToAllocateTo: "Purchase Invoice"
      },
      Validations: {
        maxAllocationAmount: "Maximum value should be"
      }
    },
    Invoices: {
      assetName: "Entity Name",
      supplierName: "Supplier Name",
      purchaseOrderDescription: "Purchase Order Description",
      supplier: "Supplier",
      grossAmount: "Gross Amount",
      netAmount: "Net Amount",
      netPayable: "Net Payable",
      due: "Due",
      amountDue: "Amount Due",
      amount: "Amount",
      invoiceDate: "Invoice Date",
      invoiceFilterDates: "Invoice From Date - To Date",
      dateRange: "Date Range",
      importedFilterDates: "Imported From Date - To Date",
      invoiceStatus: "Invoice Status",
      type: "Type",
      documentStatus: "Document Status",
      paymentStatus: "Payment Status",
      wht: "WHT",
      actions: "Actions",
      viewInvoiceDetails: "View Invoice Details",
      enrichInvoice: "Enrich/Update Invoice",
      cancelInvoice: "Cancel Invoice",
      resolveDispute: "Resolve Dispute",
      voidInvoice: "Void Invoice",
      comments: "Comments",
      submit: "Submit",
      submitting: "Submitting...",
      nonInvoicePayment: "Non Invoice Payment",
      no: "No",
      generate: "Generate",
      noInvoicesFound: "No invoices available.",
      noDocumentsFound: "No documents available.",
      loadMore: "Load More",
      salesTax: "Sales Tax",
      netPayableAfterDeductions: "Net Payable After Deductions",
      netInvoiceAmount: "Net Invoice Amount",
      attachmentName: "Name",
      from: "From",
      importedDate: "Imported Date",
      status: "Status",
      sales: "Sales",
      purchase: "Purchase",
      purchaseInvoice: "Purchase Invoice",
      purchaseInvoices: "Purchase Invoices",
      purchases: "Purchases",
      payments: "Payments",
      receipts: "Receipts",
      rejectionReason: "Rejection reason",
      rejectPayment: "Reject Payment",
      voidPayment: "Void Payment",
      markAsReceived: "Mark as Received",
      rejectInvoice: "Reject Invoice",
      rejectDocument: "Reject Document",
      onHoldReason: "On Hold Reason",
      noRejectionMessage: "No rejection reason was given",
      invoiceCancellationReason: "Reason for Invoice Cancellation",
      cancelInvoice: "Cancel Invoice",
      Details: {
        Invoices: "Invoices",
        History: "History",
        paymentHistory: "Payment History",
        Attachments: "Attachments",
        Comments: "Comments",
        Payments: "Payments",
        InvoiceDetails: "Invoice Details",
        PurchaseInvoiceDetails: "Purchase Invoice Details",
        InvoiceStatus: "Invoice Status",
        InvoiceDate: "Invoice Date",
        BCNetAmount: "BC Net Amount",
        netAmount: "Net Amount",
        Supplier: "Supplier",
        externalReferencePatternMissmatchError:
          'Special characters ":", "|" and "," are not allowed',
        BCGrossAmount: "BC Gross Amount",
        grossAmount: "Gross Amount",
        linkedInvoiceNo: "Linked Invoice No.",
        GLCode: "GL Code",
        ExchangeRate: "Exchange Rate",
        Name: "Name",
        Description: "Description",
        productDescDot: "Product Desc.",
        budgetLineDescDot: "Budget Line Desc.",
        gLCodeDescDot: "GL Code Desc.",
        Percentage: "Percentage",
        Amount: "Amount",
        TotalInvoiceAmount: "Total Invoice Amount",
        totalPaidIn: "Total Paid In",
        totalPaidOut: "Total Paid Out",
        WithholdingTaxes: "Withholding Taxes",
        notApplicableForWHT:
          "Withholding taxes and other deductions are not applicable to a purchase credit note",
        Tax: "Tax",
        Withheld: "% Withheld",
        AmountWithheld: "Amount Withheld",
        OtherDeductions: "Other Deductions",
        NetPayableAmount: "Net Payable Amount",
        Delete: "Delete",
        back: "Back",
        Save: "Save",
        Cancel: "Cancel",
        InvoiceSavedMessage: "Invoice details saved successfully.",
        InvoiceSavedSendForApprovalMessage:
          "Invoice details saved and the invoice is sent for approval.",
        InvoiceSavedAndApprovedMessage:
          "Invoice details saved and the invoice is approved.",
        budgeting: "Budgeting",
        cashBudgeting: "Cash Budgeting",
        budgetLineItem: "Budget Line Item",
        noBudgetAvailable: "No budget available",
        incurred: "Incurred",
        budget: "Budget",
        variance: "Variance",
        purchaseOrder: "Purchase Order",
        amount: "Amount",
        dueAmount: "Due Amount",
        negativeInvoiceWHTInfoMsg:
          "Witholding taxes and other deductions are not applicable to an invoice with negative net amount",
        currencyDifferenceRolloverText:
          "Base Currency Gross Amount differs from Base Currency due to FX translation.",
        confirmTitle: "Discard Changes",
        confirmMessage:
          "You have modified this invoice. You can discard your changes, or cancel to continue editing.",
        cancelButtonName: "Continue Editing",
        journalManagement: "Journal Management",
        accruedAmount: "Accrued Amount",
        amountToWriteBack: "Amount to writeback",
        postingPeriod: "Posting Period",
        costAccruals: "Cost Accruals",
        nullToNull: "null - null",
        accruedAmountDrilldown: "Accrued amount drilldown",
        accrualWriteback: "Accrual Writeback",
        journalDescription: "Journal Description",
        journalLineItemDescription: "Journal Line Item Description",
        journalDate: "Journal Date",
        writtenBackAmount: "Written back amount",
        totalWrittenBackAmount: "Total written back amount",
        mtd: "MTD",
        annual: "Annual",
        charges: "charges",
        noInvoiceDocumentsToDisplay: "No invoice documents available.",
        invoicePeriodFrom: "Invoice period from",
        lineItemPeriodRange: "Line Item Period Range",
        invoicePeriodTo: "Invoice period to",
        openingBalance: "Opening Balance",
        latestBalance: "Latest Balance",
        availableAmount: "Available amount",
        availableBudget: "Available budget",
        incurredOpeningBalance: "Incurred Opening Balance",
        opexLine: "Opex Line",
        reportingLineItem: "Reporting Line Item",
        invoiceID: "Invoice ID",
        extRefNo: "Ext Ref No",
        remainingAvailableAmount: "Remaining available amount",
        remainingAvailableBudget: "Remaining available budget",
        totalIncurred: "Total incurred",
        invoiceDetails: "Invoice",
        type: "Type",
        date: "Date",
        supplier: "Supplier",
        invoiceNo: "Invoice No.",
        dueDate: "Due Date",
        invoiceCurrency: "Invoice Currency",
        exchangeRate: "Exchange Rate",
        exchangeRateIsMissing: "Exchange rate is missing",
        exchangeRatePreOnboardingTooltip:
          "This exchange rate will be used for converting the invoice to entity currency",
        exchangeRateCreateInvoiceTooltipText:
          "The exchange rate used is based on the ",
        lineItems: "Line Items",
        glName: "GL Name",
        taxCode: "Tax Code",
        taxRate: "Tax Rate(%)",
        select: "Select",
        lineItemGrossAmount: "Line Item Gross Amount",
        addLineItem: "Add Line Item",
        invocieAmount: "Invoice Amount",
        baseCurrency: "Base Currency",
        taxAmount: "Tax Amount",
        saveAndContinue: "Save & Continue",
        saveAndClose: "Save & Close",
        updateAndContinue: "Update & Continue",
        updateAndClose: "Update & Close",
        cancel: "Cancel",
        invoiceType: "Invoice Type",
        invocieTaxAmount: "Invoice Tax Amount",
        bcTaxAmount: "BC Tax Amount",
        showDetails: "Show Details",
        hideDetails: "Hide Details",
        taxAmountHasBeChanged: "Tax amount has been changed",
        putOnHold: "Put On Hold",
        removeHold: "Remove Hold",
        reject: "Reject",
        undoReject: "Undo Reject",
        viewDocument: "View Document",
        invoiceEditResetWarnMessage:
          "Changes will result in resetting of budgeting and/or journaling info associated to the invoice.",
        invoiceUnsavedWarnMessage:
          "All unsaved changes will be lost after this action.",
        invoiceVoidActionMessage: "Void Purchase Invoice",
        invoiceVoidAndCreateDraftActionWarnMessage:
          "Void Purchase Invoice and Create Draft",
        pcnVoidAndCreateDraftActionWarnMessage:
          "Void Purchase Credit Note and Create Draft",
        creditNoteVoidActionWarnMessage: "Void Purchase Credit Note",
        paymentVoidActionWarnMessage: "Void Payment",
        invoiceVoidActionWarnWithActionPaymentMessage:
          "Payment associated to this invoice will also be voided.",
        poLinkedInvoiceVoidNoteMessage:
          "All the previously posted journals will be cancelled/reversed. PO accrual schedule will be recalculated and new accrual journals will be posted. User may have to approve journals.",
        asset: "Entity",
        noOpexLineItemError: " does not have an Opex Line Item associated.",
        selectPOAssociation: "Select Purchase Order Association",
        poLineItemToInvoiceLineItemAssociation:
          "Purchase Order Line Item to Invoice Line Item association",
        thisPoHas: "This purchase order has",
        estimatedInvoicesSelectALineItemToAssociateWithTheCurrentInvoice:
          "Estimated Invoices: Select a line item to associate with the current invoice",
        estInvoiceValue: "Est. Invoice Value",
        actualInvoiceAmount: "Actual Invoice Amount",
        poAvailableAmount: "PO Available Amount",
        poAvailableAmountValidation:
          "Purchase Order Available Amount is less than Invoice Line Item Amount",
        allInvoicesConsumedError1:
          "All invoices in the Est. invoice schedule have been linked already, open this ",
        allInvoicesConsumedError2: " to add additional Est. invoices",
        negativeInvoicePOAssociationInfoMsg:
          "Negative Line Item cannot be associated to a Purchase Order",
        NoPurchaseCreditNoteToPO:
          "Purchase Credit Note cannot be associated to a Purchase Order",
        paymentDate: "Payment Date",
        backToInbox: "Back To Inbox",
        backToInvoices: "Back To Invoices",
        backToInvoiceEnrichment: "Back To Invoice Enrichment",
        invoiceEnrichment: "Invoice Enrichment",
        purchaseInvoiceEnrichment: "Purchase Invoice Enrichment",
        selectedEstInvoiceVarianceWarningMessage:
          "Warning: Est. Line item value of purchase order and Invoice Line Item Amount do not match. This will result in a cash flow mismatch. Either update the PO or the Line Item in the invoice",
        viewEmailContent: "View email content",
        CreditNote: {
          title: "Purchase Credit Note",
          selectPI: "Purchase Invoice (Optional)",
          extRefNo: "Ext Ref No.",
          creditNoteNumber: "Purchase Credit Note No.",
          currency: "Credit Note Currency",
          selectedInvoiceTooltip:
            "The current credit note will be used for allocating to the selected Purchase Invoice.",
          creditNote: "Credit Note",
          purchaseCreditNoteDetails: "Purchase Credit Note Details"
        },
        highNetAmountValidation: "Net amount is greater than due amount",
        estimatedInvoicePayDate: "Est. Invoice Pay Date",
        estimatedInvoiceDate: "Est. Invoice Date",
        poSelectionLineItemNote:
          "Only those purchase order line items that exactly match with PI line items can be selected.",
        prepaymentJournalSchedule: "Prepayment Journal Schedule",
        pleaseReviewTheBudgetingAndJournalManagementInformation:
          "Please review the budgeting and journal management information.",
        prepaymentJournals: "Prepayment Journals",
        prepaymentJournalsInfoBeforeApprovalInvoice:
          "Proposed prepayment Journals when the invoice is approved",
        prepaymentJournalsInfoBeforeApprovalCreditNote:
          "Proposed prepayment Journals when the purchase credit note is approved",
        prepaymentJournalsInfoAfterApproval: "Prepayment Journal Schedule.",
        accrualJournalsInfoBeforeApprovalInvoice:
          "Proposed accrual journal when the invoice is approved",
        accrualJournalsInfoBeforeApprovalCreditNote:
          "Proposed accrual journal when the purchase credit note is approved",
        accrualJournalsInfoAfterApproval: "Accrual Journal Schedule.",
        prepaymentJournalScheduleMessage:
          "This will be used for calculation of amount that should be posted in each prepayment journal for this line item. Monthly selection will split the line item amount across the months selected in line item period. Daily selection will split the line item amount in the proportion of number of days in each month selected in line item period. Eg: Line item net amount is $3000. Line item period selected is Jan 2020 to Mar 2020. Monthly selection will post $1000 for Jan, Feb and Mar periods. Daily selection will post $ 1033.33 for Jan, $ 933.33 for Feb and $ 1033.33 for Mar.",
        lineItemPeriodRangeMessage:
          "Prepayment Journals will depend on the period range selected here and the posting period of the invoice. Note: This is an optional field to fill.",
        latestBalanceInfoMessage:
          "This is the latest cost accrual balance of the BLI-GL Code selected in Budgeting section of the line item.",

        toChangeKindlyUpdateTheGeneralDetailsInSettingsAre:
          "To change kindly update the general details in settings area.",
        theAccrualWritebackJournals: "The accrual writeback journals",
        thePrepaymentJournals: "The prepayment journals",
        associatedWithThis: "associated with this",
        invoice: "invoice",
        creditNote: "credit note",
        willBeIn: "will be in",
        status: "status.",
        daily: "Daily",
        monthly: "Monthly"
      },

      Attachments: {
        attachments: "Attachments",
        addAttachment: "Add Attachment",
        name: "Name",
        dateAdded: "Date Added",
        actions: "Actions",
        attachmentSavedSuccessful: "Attachment uploaded successfully.",
        deleteItemFromExternalSourceInfo:
          "Imported documents, cannot be deleted."
      },
      Payments: {
        payments: "Payments",
        createPayment: "Create Payment",
        purchasePayment: "Purchase Payment",
        lineItems: "Line Items",
        taxRate: "Tax Rate",
        netAmount: "Net Amount",
        taxAmount: "Tax Amount",
        grossAmount: "Gross Amount",
        paymentAmountInfoMsg:
          "Payment amount cannot be greater than gross amount of the line item",
        createNoNInvoicePayment: "Create Non-Invoice Payment",
        paymentDate: "Payment Date",
        paymentDateTooltipMessage: {
          step1: "Why is the Payment date inactive?",
          step2:
            "A payment date is required only if making an Intercompany payment. Else the bank transaction date i.e the date of debit is used as the Payment date."
        },
        type: "Type",
        status: "Status",
        paymentStatus: "Payment Status",
        amount: "Amount",
        assignedTo: "Assigned To",
        paymentAmount: "Payment Amount",
        PaymentAccount: "Payment Account",
        Comments: "Comments",
        commentsHere: "Comments here",
        Save: "Save",
        saveAndSubmitForApproval: "Save & Submit for Approval",
        Cancel: "Cancel",
        InvoiceDetails: "Invoice Details",
        InvoiceDate: "Invoice Date",
        NetPayableAmount: "Net Payable Amount",
        PaymentCreationSuccessful: "Payment was saved successfully.",
        interCompanyTransferError:
          "falls within a closed period. An Intercompany payment cannot be made if the period is closed. Reopen the period or change the payment date.",
        voidInterCompanyTransferError:
          "falls within a closed period. An Intercompany payment cannot be voided if the period is closed. Reopen the period and try to void.",
        noPaymentsTo: "No payments available to ",
        noPayments: "No payments available.",
        noApprovedPayments: "No approved payments available.",
        approvePayments: "Approve Payments",
        validationError:
          "An Intercompany payment cannot be made if the period is closed. Reopen the period or change the payment date.",
        voidValidationError:
          "An Intercompany payment cannot be voided if the period is closed. Reopen the period and try to void.",
        backToPayments: "Back to Payments",
        viewPaymentHistory: "View Payment History",
        editNonInvoicePayment: "Edit Non-Invoice Payment",
        editPayment: "Edit Payment",
        viewNonInvoicePayment: "View Non-Invoice Payment",
        viewPayment: "View Payment",
        interCompany: "Intercompany",
        bankReconciliation: "Bank Reconciliation",
        confirmMessage:
          "You have modified this payment form. You can discard your changes, or cancel to continue editing.",
        createPaymentNotAllowedInfoMsg:
          "A new payment can be created, only when Invoice status is 'Awaiting Payment' and no other payments are in process.",
        negativeInvoicePaymentsInfoMsg:
          "Payments related to Invoices with negative net amount are auto generated",
        payByDirectDebit: "Pay By Direct Debit",
        directDebit: "Direct Debit",
        asset: "Entity",
        description: "Description",
        details: "Details",
        budgeting: "Budgeting",
        journalManagement: "Journal Management",
        glCode: "GL Code",
        customer: "Customer",
        supplier: "Supplier",
        fromBankAccount: "From Bank Account",
        fromAccount: "From Account",
        contactName: "Contact Name",
        contactBankAccount: "Contact Bank Account",
        supplierBankAC: " Supplier Bank A/C",
        supplierAccount: " Supplier Account",
        payeeBankAccount: "Payee Bank Account",
        opexLine: "Opex Line",
        submittedBy: "Submitted By",
        suggestedAmount: "Suggested Amount",
        approveAmount: "Approve Amount",
        accountDetails: "Account Details",
        bankAccount: "Bank Account",
        toBankAccount: "To Bank Account",
        balance: "Balance",
        balanceUpdatedOn: "Balance Updated On",
        estbalanceAfterPayment: "Est. Balance After Payment",
        bankBalanceInfoMessage:
          "This bank balance is indicative only and is correct as of last updated date.",
        approve: "Approve",
        generatePayrun: "Generate Payrun",
        addPayrunName: "Add payrun name",
        invalidPayrunNameLengthMsg:
          "Payrun name should not exceed 75 characters.",
        invalidPayrunNameMsg: "Please enter a valid payrun name.",
        cancel: "Cancel",
        approvedAmountRangeOverflowValidationMessage:
          "Approved amount cannot be greater than Invoice amount due.",
        approvedAmountRangeUnderflowValidationMessage:
          "The amount approved must be more than zero.",
        sepaNote: "Note: Generating a SEPA payment file cannot be undone.",
        generatePayrunTitle: "Select payments and generate payrun",
        createNewPayrun: "Create New Payrun",
        payrunSuccess: "Payrun generated successfully.",
        invoiceExtRefNo: "Invoice Ext Ref No.",
        extRefNo: "Ext Ref No.",
        refNo: "Ref No"
      },

      Comments: {
        postComment: "Post Comment",
        clear: "Clear"
      },
      History: {
        changedInvoiceDetails: "Changed invoice details.",
        anAttachment: "an attachment.",
        invoiceCreated: "Invoice Created"
      },
      JournalManagement: {
        journalManagement: "Journal Management",
        prepaymentSchedule: "Prepayment Schedule",
        period: "Period",
        bliGlCode: "BLI/GL Code",
        dr: "DR",
        cr: "CR",
        debit: "Debit",
        status: "Status",
        credit: "Credit",
        note: "Note:",
        prepaymentScheduleNote:
          "All the past journals to reflect immediately. All the future journals to be posted according to the schedule.",
        showAllAccruals: "Show all accruals",
        noJournalsFoundConfirmApprove:
          "No journals found. Please confirm to approve the invoice.",
        journalCreationInProgressMessage:
          "Please wait while journals are being created.",
        invoiceApprovalInProgressMessage:
          "Please wait while invoice is being approved",
        clickHereToReopen: "Click here to reopen.",
        closedPostingPeriodErrorMessage:
          "There are no open periods in the future to post the prepayment journals."
      },
      approveInvoice: "Approve Invoice",
      createPayment: "Create Payment",
      submitPaymentsForApproval: "Submit payments for approval",
      approvePayment: "Approve Payment",
      budgeting: "Budgeting",
      budgetLineItem: "Budget Line Item",
      reportingLineItem: "Reporting Line Item",
      lineItemDescription: "Line Item Description",
      glName: "GL Name",
      creditNotes: "Credit Notes",
      allocations: "Allocations",
      withholdingTaxes: "Withholding taxes",
      creationDate: "Creation Date",
      purchaseCreditNote: "Purchase Credit Note"
    },
    AccountsReceivables: {
      noLineItemsAvailable: "No line items available",
      apar: "AP/AR",
      generatePayrun: "Generate Payrun",
      generateRemittance: "Generate Remittance",
      sendEmail: "Send Email",
      emailIsMissing: "Email is missing.",
      clickToAdd: "Click to add.",
      contactName: "Contact Name",
      emailID: "Email ID",
      invoiceNo: "Invoice No",
      fromBankAC: "From Bank A/C",
      toBankAC: "To Bank A/C",
      paymentAmount: "Payment Amount",
      selectAction: "Select Action",
      approvePayments: "Approve Payments",
      salesInvoices: "Sales Invoices",
      sales: "Sales",
      invoices: "Invoices",
      status: "Status",
      type: "Type",
      assetName: "Entity",
      customerName: "Customer",
      invoiceFromDateToDate: "Invoice From Date - To Date",
      commentsHistory: "Comments & History",
      currency: "Currency",
      createNewInvoice: "Create Invoice",
      refNo: "Ref No.",
      netAmount: "Net Amount",
      dueAmount: "Amount Due",
      grossAmount: "Gross Amount",
      invoiceDate: "Invoice Date",
      invoiceStatus: "Invoice Status",
      receiptedAmount: "Receipted Amount",
      paidAmount: "Paid Amount",
      createSalesInvoice: "Create Sales Invoice",
      createSalesReceipt: "Create Sales Receipt",
      salesReceipt: "Sales Receipt",
      createSalesPayment: "Create Sales Payment",
      editSalesPayment: "Edit Sales Payment",
      salesPayment: "Sales Payment",
      createSalesCreditNote: "Create Sales Credit Note",
      createContractForDifference: "Create Contract for Difference",
      editSalesCreditNote: "Edit Sales Credit Note",
      editContractForDifference: "Edit Contract for Difference",
      amountIsTaxInclusive: "Amount is tax inclusive",
      totalCantBeMoreThan: "Total cant be more than ",
      receiptIsBeingCreatedForAnExcessAmountOf:
        "Receipt is being created for an excess amount of ",
      adjustmentAmount: "Adjustment Amount",
      editSalesInvoice: "Edit Sales Invoice",
      salesPeriod: "Sales Period",
      exchangeRate: "Exchange Rate",
      lineItems: "Line Items",
      description: "Description",
      budgetLineItem: "Budget Line Item",
      gLCode: "GL Code",
      units: "Units",
      tax: "Tax",
      taxRate: "Tax Rate(%)",
      total: "Total",
      availableAmount: "Available Amount",
      addLineItem: "Add Line Item",
      journalManagement: "Journal Management",
      balanceAccrued: "Balance Accrued",
      price: "Price",
      amount: "Amount",
      enteredNow: "Entered Now",
      reversalAmountsWarnMessage: "Please correct below fields.",
      accrualReversal: "Accrual Reversal",
      currentRevenue: "Current Revenue",
      previouslyInvoiced: "Previously Invoiced",
      sum: "SUM",
      totalCurrentRevenue: "Total Current Revenue",
      action: "Action",
      addAttachmentsOptional: "Add Attachment (Optional)",
      addAttachment: "Add Attachment",
      browse: "Browse",
      addcomment: "Add comment",
      dropFilesHere: "Drop files here",
      browseFiles: "Browse files",
      successMessage: "Invoice data saved successfully",
      attachments: "Attachments",
      addAttachment: "Add Attachment",
      name: "Name",
      dateAdded: "Date Added",
      viewDetails: "View Details",
      view: "View",
      edit: "Edit",
      viewInvoiceDetails: "View Invoice Details",
      delete: "Delete",
      salesInvoice: "Sales Invoice",
      void: "Void",
      cancel: "Cancel",
      return: "Return",
      toDraft: "to Draft",
      andCreateDraft: "and Create Draft",
      receiptedAllocatedDetails: "Receipted & Allocated Details",
      paidAllocatedDetails: "Paid & Allocated Details",
      invociedDetails: "Invoiced Details",
      outstandingDetails: "Outstanding Details",
      totalInvocied: "Total Invoiced",
      totalReceiptedAllocated: "Total Receipted & Allocated",
      totalPaidAllocated: "Total Paid & Allocated",
      totalOutstanding: "Total Outstanding",
      noAttachments: "No Attachments are available.",
      interCompanyTransferError:
        "falls within a closed period. An Intercompany payment cannot be made if the period is closed. Reopen the period or change the payment date.",
      voidInterCompanyTransferError:
        "falls within a closed period. An Intercompany payment cannot be voided if the period is closed. Reopen the period and try to void.",
      receipts: "Receipts",
      payments: "Payments",
      allocations: "Allocations",
      deleteAllocationConfirmation:
        "Are you sure you want to delete this allocation?",
      receiptsAllocations: "Receipts & Allocations",
      deleteAllocation: "Delete Allocation",
      paymentsAllocations: "Payments & Allocations",
      noInvoices: "No invoices available.",
      reason: "Reason",
      date: "Date",
      creationDate: "Creation Date",
      avgPrice: "Avg. Price",
      totalAmount: "Total Amount",
      comments: "Comments",
      createReceipt: "Create Receipt",
      createPayment: "Create Payment",
      allocateToCreditNote: "Allocate to Credit Note",
      allocateToSalesInvoice: "Allocate to Sales Invoice",
      commentsHistoryAndAttachments: "Comments, History and Attachments",
      commentsAndHistory: "Comments And History",
      all: "All",
      history: "History",
      noReceiptsOrPayments: "No receipts or payments were created.",
      noAllocationsMessage: "No allocations were created.",
      negativeCFDCreatePaymentNote:
        "Payment amount is the sum of the selected line items total and adjustment amount. Note: The sign is reversed and shown.",
      salesCreditNoteCreatePaymentNote:
        "Payment amount is the sum of the selected line items total and adjustment amount.",
      fromBankAccount: "From Bank Account",
      fromAccount: "From Account",
      customerBankAccount: "Customer Bank Account",
      lineItemsOfCreditNote: "Credit Note Line Items",
      lineItemsOfSalesInvoice: "Line Items of Sales Invoice",
      maxLineItemAmount: "Total should be between 0 and ",
      paymentAmountError: "Payment amount can't be more than due amount",
      deleteReceiptConfirmMessage:
        "Reconciled Receipts can't be deleted. Kindly un-reconcile before deleting a receipt.",
      invoiceWillBeUpdatedWithLatestDetails:
        "Invoice will be updated with latest details.",
      createPaymentNotAllowedInfoMsg:
        "A new payment can be created, only when no other payment is in process.",
      remittanceNote:
        "Remittance can't be generated for contacts without email IDs. Kindly update them from the contacts section.",
      generateRemittanceSuccessMessage: "Remittance generated successfully.",
      paymentsApprovedSuccessMessage: "Payment(s) approved successfully.",
      totalNote: "Updating the total will automatically recalculate the units.",
      noPaymentsRemittanceNote:
        "No payments with status Payment Processed to generate Remittance.",
      addInformationToDocument: "Add information to document",
      downloadNote:
        "The text entered below will reflect in the bottom left of the document. Kindly ensure the correctness of the information before download.",
      download: "Download",
      to: "To",
      from: "From",
      messageIsRequired: "Message is required",
      invaildMessageInputError:
        "Email message is not valid. Please check the template",
      cc: "CC",
      addInformationOnInstrument: "Additional Information on Instrument",
      emailContent: "Email Content",
      send: "Send",
      sent: "Sent",
      notSent: "Not Sent",
      sendMeCopy: "Send me a copy",
      preview: "Preview",
      instrumentNumberErrorMsg: "Instrument number in the email is incorrect",
      emailContentErrorMsg: "Email content cannot be blank.",
      emailSuccessMsg: "Email has been successfully sent.",
      emailNote: "will be attached with the email.",
      emailSeparationNote: "Separate multiple email IDs with a (,)",
      recipentEmailErrorMsg: "Recipient email ID is missing",
      invalidEmailsErrorMsg: "Invalid email ID(s)",
      noteLabel: "Note:",
      email: {
        step1: "Hi",
        step2: "Here is your",
        step3: `This is an autogenerated email. Kindly don’t reply to this email.

Thanks,
The SkySpecs Team`
      },
      paymentDate: "Payment Date",
      receiptDate: "Receipt date"
    },
    Utilisations: {
      createTemplate: "Create Template",
      templateName: "Template Name",
      updateTemplate: "Update Template",
      createTemplateFileTypeError: "Kindly import docx file(s) as specified.",
      name: "Name",
      currency: "Currency",
      templateFile: "Template File",
      replaceTemplateFile: "Replace Template File",
      documentPlaceholdersNote:
        "Please use <Amount> and <Date> in the template as placeholders.",
      replaceTemplateFileMsg:
        "This template will be replaced in Utilisation Requests that are New, Draft or Awaiting Approval. Approved requests will not be impacted.",
      previewAndSave: "Preview and Save",
      templateSavedSuccessfully: "Template saved successfully",
      templateCreatedSuccessfully: "Template created successfully",
      creationDate: "Creation Date",
      edit: "Edit",
      active: "Active",
      archived: "Archived",
      download: "Download",
      archive: "Archive",
      noActiveTemplates: "No active templates avaliable.",
      noArchivedTemplates: "No archived templates avaliable.",
      createTemplateFormNote:
        "Certain critical information can be inserted using placeholders. Placeholders available are <Amount> and <Date>. Kindly use the placeholders as needed in the template",
      archiveTemplate: "Archive Template",
      archiveComfirmPopUpNote:
        "Archiving a template will not impact approved utilisation requests. Utilisation requests in draft or awaiting approval state will be automatically voided.",
      utilisations: "Utilisations",
      utilisationRequestDateRange: "Date Range",
      templates: "Templates",
      utilisationRequests: "Utilisation Requests",
      utilisationTemplates: "Utilisation Templates",
      utilisationRequest: "Utilisation Request",
      noUtilisationRequests: "No utilisation requests available.",
      date: "Date",
      dateRange: "Date Range",
      requests: "Request(s)",
      netAmount: "Net Amount",
      reset: "Reset",
      createUtilisationRequest: "Create Utilisation Request",
      approveUtilisationRequest: "Approve Utilisation Request",
      approve: "Approve",
      attachSignedApproval: "Attach Signed Approval",
      approveUtilisationRequestfileTypeError:
        "Please upload the signed document only in .pdf format",
      createSuccessMsg: "Utilisation Request has been created successfully.",
      updateSuccessMsg: "Utilisation Request has been updated successfully",
      editRequest: "Edit Utilisation Request",
      utilisationRequestName: "Utilisation Request Name",
      description: "Description",
      utilisationRequestDescription: "Utilisation Request Description",
      selectUtilisationTemplate: "Select Utilisation Template",
      invoiceNumber: "Invoice Number",
      invoiceDate: "Invoice Date",
      contactName: "Contact Name",
      paymentAmount: "Payment Amount",
      amount: "Amount",
      infoMessage: "This is the currency of the selected template.",
      emptyTemplateInfoMessage: "Please select a template to see payments.",
      noPayments: "No payments available for this template.",
      totalOfSelectedPayments: "Total of selected payments",
      cancel: "Cancel",
      previewAndCreate: "Preview and Create",
      previewAndCreateModalHeading: "Preview and Create Utilisation Request",
      previewAndSaveModalHeading: "Preview and Save Utilisation Request",
      viewUtilisationRequest: "View Utilisation Request",
      create: "Create",
      save: "Save",
      view: "View",
      loading: "Loading",
      loadMore: "Load More"
    },
    Settings: {
      import: "Import"
    }
  },
  Groups: {
    noDataLabel: "You have no groups to view.",
    groups: "Groups",
    portfolio: "Portfolio",
    dashboard: "Dashboard",
    complianceAndRisk: "Compliance & Risk",
    settings: "Settings"
  },
  Portfolios: {
    portfolios: "Portfolios",
    portfolio: "Portfolio",
    assetName: "Entity Name",
    financials: "Financials",
    noPortfoliosFound: "No portfolios available.",
    name: "Name",
    dashboard: "Dashboard",
    organization: "Organization",
    assets: "Entities",
    asset: "Entity",
    createPortfolio: "Create Portfolio",
    editPortFolio: "Edit Portfolio",
    edit: "Edit",
    view: "View",
    portfolioSavedMsg: "Portfolio saved successfully.",
    totalSizeMw: "Total Size(MW)",
    sizeMw: "Size(MW)",
    delete: "Delete",
    remove: "Remove",
    country: "Country",
    supplier: "Supplier",
    percentageOwned: "% Owned",
    dataSourceFinancials: "Data Source Financials",
    dataSourceEnergyOutput: "Data Source Energy Output"
  },
  error: {
    backToHome: "Back to home",
    contactSupportMessage: "Oops! Something went wrong. Please contact support."
  },
  accounting: {
    accounting: "Accounting",
    bankAccounts: "Bank Accounts",
    importData: "Import Data",
    statementBalance: "Statement Balance",
    reconciledBalance: "Reconciled Balance",
    importBankStatement: "Import Bank Statement",
    viewTransactions: "View Transactions",
    connectToBankAccount: "Connect to Bank Account",
    refreshBankConnection: "Refresh Bank Connection",
    addBankAccount: "Add Bank Account",
    connectToBankAccountErrorMsg:
      "Connecting to Bank account has failed. Kindly try again.",
    syncBankStatement: "Sync Bank Statement",
    syncInProgress: "Sync in progress...",
    bankAccountTokenExpiryWarningMsg:
      "Connection to bank expires in 15 days. Kindly refresh.",
    configureBankAccount: "Configure Bank Account",
    viewBankAccount: "View Bank Account",
    configureBankAccountWarningMsg: "Kindly Configure Bank Account",
    country: "Country",
    selectCountry: "Select Country",
    sync: "Sync",
    syncDateRange: "From Date - To Date",
    toDate: "To Date",
    noBankAccounts: "No bank accounts available.",
    bankAccountTableNote:
      "<strong>Note:</strong> Statement Balance and Reconciled Balance will reflect after entity on-boarding.",
    bankAccount: "Bank Account",
    bankAccountName: "Bank Account Name",
    mostRecentTransaction: "Most recent transaction",
    date: "Date",
    monthYear: "Month/Year",
    paymentDate: "Payment Date",
    payeeName: "Payee Name",
    contactName: "Contact Name",
    paidOut: "Paid Out",
    paidIn: "Paid In",
    acceptedFiles: "Accepted file types: csv",
    downloadCsv: "Download CSV template",
    import: "Import",
    fileTypeError: "Kindly import CSV file(s) as specified.",
    noTransactions: "No transactions available.",
    importOpeningStatement: "Drag & drop bank statement",
    noOpeningBalances: "No opening balances available.",
    bankBalanceNote: "Bank balance as on",
    statementSuccess: "Bank statement imported successfully.",
    transactionStatus: "Transaction Status",
    transactionDateRange: "Transaction Date Range",
    importDateRange: "Import Date Range",
    status: "Status",
    transactions: "Transactions",
    transactionDate: "Transaction Date",
    view: "View Transaction",
    accounts: "Accounts",
    nonGLError:
      "Bank Balance information needs to be updated from Settings tab before importing Bank Statement.",
    Transactions: {
      from: "From",
      noTransactions: "No transactions available.",
      bankReconciliation: "Bank Reconciliation",
      transactions: "Transactions",
      to: "To",
      fileName: "File Name",
      import: "Import",
      format: "Format",
      transactionId: "Transaction ID",
      type: "Type",
      date: "Date",
      postingDate: "Posting Date",
      description: "Description",
      glName: "GL Name",
      netAmount: "Net Amount",
      importTransactions: "Import Transactions",
      selectPlatform: "Select Platform",
      importTransactionSuccessMsg: "Transactions were imported successfully.",
      transactionsFrom: "Transactions from",
      transactionDateFromTo: "Transaction From Date - To Date"
    },
    match: "Match",
    switch: "Switch",
    transfer: "Transfer",
    void: "Void",
    voidReason: "Void Reason",
    voidTransaction: "Void Transaction",
    returnTransactionToDraft: "Return Transaction To Draft",
    unreconcileTransaction: "UnReconcile Transaction",
    returnReason: "Return Reason",
    unreconcileReason: "Unreconcile Reason",
    deleteTransaction: "Delete Transaction",
    amount: "Amount",
    taxRate: "Tax Rate(%)",
    glName: "GL Name",
    budgetLineItem: "Budget Line Item",
    addNewTransaction: "Add New Transaction",
    transferToText: "Transfer to a different account",
    transferFromText: "Transfer from a different account",
    transferToOrFromOtherAccountTooltipMsg:
      "To Enable transfer to different accounts, set transfer to other bank accounts in chart of accounts to map system accounts",
    matchTextPaidOut: "Find & Select Matching Payments/Receipts",
    matchTextPaidIn: "Find & Select Matching Receipts/Payments",
    invoiceNumber: "Invoice No.",
    invoiceType: "Invoice Type",
    reference: "Reference",
    accountName: "Account Name",
    deleteModalTitle: "Delete Tranasaction Entry",
    deleteModalMessage: "Are you sure you want to delete this transaction?",
    deleteConfirmLabel: "Yes",
    deleteCancelLabel: "No",
    account: "Account",
    assetName: "Entity",
    approve: "Approve",
    retunToDraft: "Return To Draft",
    retunToDraftConfirmMessage: "The mapping information will be lost.",
    unreconcileConfirmMessage:
      "This action will reverse the reconciliation done. It will impact cash flow and detailed cash flow.",
    createTransaction: "Create Transaction",
    noPaymentsAreSelected: "No payments are selected",
    outBy: "Out by",
    cancelConfirmText:
      "Are you sure? Mapping of payments and created entries will be lost and Bank statement line item will be voided",
    sumofTransactions: "Sum of transactions",
    transferPayment: "Transfer Payment",
    paidOutTo: "Paid Out to",
    paidInFrom: "Paid In from",
    totalOfSelectedPayments: "Total of selected payments/receipts",
    totalOfSelectedReceipts: "Total of selected receipts/payments",
    totalOfPaymentsAndTransactions:
      "Total of payments/receipts and transactions",
    totalOfReceiptsAndTransactions:
      "Total of receipts/payments and transactions",
    noPaymentsAvailableText: "No payments/receipts are available for matching",
    noReceiptsAvailableText: "No receipts/payments are available for matching",
    payments: "Payments",
    choosePaymentsFileToUpload: "Choose payments file to upload",
    viewAssociatedBankStatement: "View Associated Bank Statement",
    Account: "Account",
    bankStatementImportedOn: "Bank Statement Imported on",
    deleteEntireStatement: "Delete entire statement",
    statementDeleteNote:
      "Reconciled and voided line items can't be deleted here.",
    noOtherBankAccountsExist: "Bank account is not available",
    description: "Description",
    type: "Type",
    transactionPaymentDate: "Transaction/Payment Date",
    budgetLineItemDescription: "Budget Line Item Description",
    matched: "Matched",
    created: "Created",
    Budgets: {
      budgets: "Budgets",
      noBudgets: "No budgets available.",
      budget: "Budget",
      budgetHistory: "Budget History",
      name: "Name",
      isDefault: "Is Default",
      inUse: "In Use",
      financialYear: "Financial Year",
      financialYearEnding: "Financial Year Ending",
      budgetDataFile: "Budget Data File",
      reImportingWarning: "Re importing budget will overwrite existing data.",
      file: "File"
    },
    statementBalanceMessage:
      "Statement Balance is the sum of bank balance at start of conversion period and the line items of imported bank statement",
    reconciledBalanceMessage:
      "Reconciled Balance is the latest available bank balance in balance sheet",
    transactionDateMessage:
      "Transaction date of the bank statement will be used for posting bank reconciliation",
    paymentOrReceipt: "Payment or Receipt",
    payment: "Payment",
    receipt: "Receipt",
    selectBankAccount: "Select Bank Account",
    noAccountSelected: "No account selected",
    noLineItemsAvailable: "No line items available"
  },
  Insights: {
    insights: "Insights",
    projects: "Projects",
    invoices: "Invoices",
    budgets: "Budgets",
    projectTimeline: "Project Timeline",
    dashboard: "Dashboard",
    eva: "EVA",
    tagging: "Tagging",
    revenueAutomation: "Revenue Automation",
    contingency: "Contingency",
    utilisation: "Utilisation",
    Projects: {
      EarnedValueAnalysis: {
        projectSpend: "Project Spend",
        reportingLineItem: "Reporting Line Item",
        invoiceNotPaid: "Unpaid",
        invoicePaid: "Paid",
        totalUnpaid: "Total Unpaid",
        totalPaid: "Total Paid",
        totalSpend: "Total Spend",
        budgetedSpend: "Budgeted Spend",
        variance: "Variance",
        variancePercent: "Variance %",
        budgetLineItem: "Budget Line Item",
        asset: "Entity",
        budget: "Budget",
        budgeted: "Budgeted",
        purchaseOrder: "Purchase Order",
        invoiceNumber: "Invoice Number",
        assetName: "Entity Name",
        contractType: "Contract Type",
        period: "Period",
        periodRange: "Invoice From Date - To Date",
        monthPeriodRange: "Invoice From Month - To Month",
        selectPeriodRange: "Date Range",
        selectMonthPeriodRange: "Month Range",
        generate: "Generate",
        noBudget: "No Budget",
        invoicingPeriod: "Invoicing Period",
        earnedValue: "Earned Value",
        plannedValue: "Planned Value",
        actualCost: "Actual Cost",
        costScheduleIndex: "Cost Schedule Index",
        schedulePerformanceIndex: "Schedule Performance Index",
        costPerformanceIndex: "Cost Performance Index",
        scheduleVariance: "Schedule Variance",
        costVariance: "Cost Variance",
        totalRemainingContingency: "Total Remaining Contingency",
        realisedContingency: "Realised Contingency",
        unRealisedContingency: "Unrealised Contingency",
        openingContingecy: "Opening Contingency",
        cost: "Cost",
        time: "Time (Months)",
        showDetailedView: "Show Detailed View",
        hideDetailedView: "Hide Detailed View"
      },
      Invoices: {
        projectInvoices: "Project Invoices",
        invoices: "Invoices",
        invoice: "Invoice",
        importSuccessMsg: "Invoices imported successfully.",
        importUsingCsv: "Import using CSV",
        importUsingAccountingSoftware: "Import using accounting software",
        noInvoices: "No invoices available.",
        supplier: "Supplier",
        invoiceNumber: "Invoice Number",
        invoiceDate: "Invoice Date",
        status: "Status",
        netAmount: "Net Amount",
        editProjectInvoice: "Edit Project Invoice",
        viewProjectInvoice: "View Project Invoice",
        noInvoiceDocumentsToDisplay: "No invoice documents available.",
        glCode: "GL Code:",
        baseCurrency: "Base currency",
        net: "Net",
        gross: "Gross",
        invoiceCurrency: "Invoice Currency",
        budgetLineItem: "Budget Line Item",
        selectBudgetLineItem: "Select Budget Line Item",
        assignBudgetLineItem: "Assign Budget Line Item",
        contract: "Contract",
        assignContract: "Assign Contract",
        selectContract: "Select Contract",
        milestoneValidationError1: "Total cannot exceed line item net amount",
        milestoneValidationError2: "Amount/Percentage cannot be empty or 0",
        variationValidationError: "Amount cannot be empty or 0",
        addMilestone: "Add Milestone",
        variationsOrContingencies: "Variations/Contingencies",
        addVariationsOrContingencies: "Add Variations/Contingencies",
        selectMilestone: "Select Milestone",
        description: "Description",
        milestone: "Milestone",
        amount: "Amount",
        percentage: "Percentage(%)",
        importInvoices: "Import Invoices",
        archiveReason: "Archive Reason",
        archiveInvoice: "Archive Invoice",
        retunToDraftReason: "Return to Draft Reason",
        returnInvoiceToDraft: "Return Invoice to Draft",
        comments: "Comments",
        attachments: "Attachments",
        commentsAndAttachments: "Comments And Attachments",
        submit: "Submit",
        submitting: "Submitting...",
        supplierName: "Supplier Name",
        invoiceFromDateToDate: "Invoice From Date - To Date",
        lineItem: "Line Item",
        glName: "GL Name",
        bliDescription: "BLI Description",
        glNameBliDescription: "GL Name/BLI Description",
        milestonesOrVariations: "Milestones/Variations",
        assignedAmount: "Assigned Amount",
        lineItemNetAmount: "Line Item Net Amount",
        uploadEditNote1:
          "Documents attached here will not reflect in original invoice.",
        uploadEditNote2:
          "The first document attached will be treated as invoice."
      },
      ProjectTimeline: {
        projectTimeline: "Project Timeline",
        plannedValue: "Planned Value",
        earnedValue: "Earned Value",
        cumulative: "Cumulative",
        plannedValueError: {
          step1: "Total of planned value",
          step2: "does not match with total contract value"
        },
        earnedValueError: {
          step1: "Total of earned value",
          step2: "does not match with total planned value"
        },
        selectContract: "Select Contract",
        addYear: "Add Year",
        noContractSelected: "No contract selected.",
        contractValue: "Contract Value"
      },
      Contingency: {
        label: "Contingency",
        realisedContingency: "Realised Contingency",
        unrealisedContingency: "Unrealised Contingency",
        total: "Total",
        openingBalance: "Opening Balance",
        openingBalanceInfoMessage:
          "Kindly provide the opening contingency balance. This will be used to calculate total remaining contingency. This cannot be changed later.",
        save: "Save",
        budgetLineItem: "Budget Line Item",
        glName: "GL Name",
        contract: "Contract",
        invoiceDate: "Invoice Date",
        description: "Description",
        amount: "Amount",
        date: "Date",
        addContingency: "Add Contingency"
      }
    },
    Tagging: {
      Dashboard: {
        dashboard: "Dashboard",
        taggingDashboard: "Tagging Dashboard",
        actual: "Actual",
        budgeted: "Budgeted",
        variance: "Variance",
        tagName: "Tag Name",
        varianceAmount: "Variance Amount",
        variancePercentage: "Variance %",
        invoiceLineItem: "Invoice Line Item",
        budgetLineItem: "Budget Line Item",
        reportingLineItem: "Reporting Line Item",
        supplier: "Supplier",
        glName: "GL Name",
        invoiceDate: "Invoice Date",
        netAmount: "Net Amount",
        taggedAmount: "Tagged Amount",
        search: "Search",
        searchPlaceHolder: "Enter search criteria (min 3 letters)",
        periodRange: "Invoice From Month - To Month",
        monthRange: "Month Range",
        cost: "Cost",
        monthly: "Monthly",
        cumulative: "Cumulative",
        tableView: "Table View",
        graphView: "Graph View"
      }
    },
    RevenueAutomation: {
      sumOfTurbines: "Sum of Turbines",
      calculatedRevenue: "Calculated Revenue",
      actualRevenue: "Actual Revenue",
      calcRevenueWithContractInfoMsg:
        "This shows the total calculated revenue of the selected Contract in the selected period.",
      actRevenueWithContractInfoMsg:
        "This shows the actuals revenue of the glcodes associated with the selected contract in the selected period as per income statement.",
      calcRevenueWithGlCodeInfoMsg:
        "This shows the total calculated revenue of the Glcode selected across contracts in the selected period.",
      actRevenueWithGlCodeInfoMsg:
        "This shows the actual revenue of the selected Glcode in the selected period as per income statement.",
      importContractedAvailabilityData: "Import Contracted Availability Data",
      importPowerGenerationForecastData:
        "Import Power Generation Forecast Data",
      importLineLosses: "Import Line Losses Data",
      importOtherLossesData: "Import Other Losses Data",
      selectFile: "Select File",
      powerGenerationReport: "Power Generation Report",
      configureDataSource: "Configure Data Source",
      configureScadaDataSource: "Configure SCADA Data Source",
      deviceIds: "Device IDs",
      revenueSchedule: "Revenue Schedule",
      setup: "Setup",
      contractedAvailability: "Contracted Availability",
      contractedAvailabilityWithBreak: "Contracted <br/>Availability",
      lineLosses: "Line Losses",
      otherLosses: "Other Losses",
      lossAdjustmentFactor: "Loss Adjustment Factor",
      source: "Source",
      importLossAdjustmentFactorData: "Import Loss Adjustment Factor data",
      constraint: "Constraint",
      importCpiData: "Import CPI Data",
      importConstraintData: "Import Constraint Data",
      curtailment: "Curtailment",
      scadaInKWh: "SCADA (kWh)",
      dlaf: "DLAF",
      tlaf: "TLAF",
      totalRevenue: "Total Revenue",
      consumerPriceIndex: "Consumer Price Index",
      addCpi: "Add CPI",
      updateCPi: "Update CPI",
      cpi: "CPI",
      view: "View",
      edit: "Edit",
      selectCPI: "Select CPI",
      cpiname: "CPI Name",
      cpiSaveSuccessMsg: "CPI added successfully.",
      cpiUpdateSuccessMsg: "CPI updated successfully.",
      cpiNameErrorMsg: "Only alphabets and numbers are allowed.",
      importCurtailmentData: "Import Curtailment Data",
      save: "Save",
      period: "Period",
      actualWithPercentage: "Actual %",
      actual: "Actual",
      interval: "Interval",
      percentage: " (%)",
      deviceId: "Device Id",
      totalkWh: "Total(kWh)",
      forecastWithPercentage: "Forecast %",
      forecast: "Forecast",
      day: "Day",
      night: "Night",
      noData: "No Data available.",
      noRecords: "No Data available. Please import data.",
      dataSavedMessage: "Data saved successfully.",
      dataImportedMsg: "Data imported successfully.",
      budget: "Budget %",
      glCode: "GL Code",
      contract: "Contract"
    }
  },
  Tasks: {
    all: "All",
    tasks: "Tasks",
    tasksDashboard: "Tasks Dashboard",
    tasksWorkflows: "Tasks Workflows",
    manual: "Manual",
    importSuccessMsg: "Task(s) are Imported/Updated successfully.",
    manualTasks: "Manual Tasks",
    manualTask: "Manual Task",
    repeating: "Repeating",
    repeatingTasks: "Repeating Tasks",
    period: "Period",
    name: "Name",
    taskName: "Task Name",
    taskId: "Task ID",
    me: "Me",
    assignedTo: "Assigned To",
    assignee: "Assignee(s)",
    status: "Status",
    dueDate: "Due Date",
    type: "Type",
    asset: "Entity",
    assetName: "Entity Name",
    source: "Source",
    reset: "Reset",
    export: "Export",
    startDates: "Start Dates",
    nextTaskDates: "Next Task Dates",
    noTasksAvailable: "No tasks available.",
    startDate: "Start Date",
    endDate: "End Date",
    nextTaskDate: "Next Task Date",
    repeatingTask: "Repeating Task",
    save: "Save",
    create: "Create",
    cancel: "Cancel",
    description: "Description",
    enterDescription: "Enter a description...",
    repeatDueDate: "Repeat Due Date",
    repeatStartDate: "Repeat Start Date",
    repeatThisTask: "Repeat this Task",
    repeatEndDate: "Repeat End Date",
    repeatThisTaskEvery: "Repeat this Task every",
    archiveTask: "Archive Task",
    submit: "Submit",
    submitting: "Submitting...",
    editTask: "Edit Task",
    viewTask: "View Task",
    createRepeatTask: "Create Repeating Task",
    createTask: "Create Task",
    markAs: "Mark as",
    showCompletedTasks: "Show Completed Tasks",
    saveTaskSuccessMsg: "Task saved successfully.",
    createTaskSuccessMsg: "Task created successfully.",
    deleteAttachment: "Delete Attachment",
    deleteAttachmentConfirmationMessage:
      "Are you sure you want to delete this attachment?",
    viewContract: "View Contract",
    attachments: "Attachment(s)",
    note: "Note: ",
    archiveModalNoteMessage:
      "Archiving a Repeating task does not impact the Manual tasks that are already created.",
    import: "Import",
    fileTypeError: "Kindly import CSV file(s) as specified.",
    Import: {
      title: "Import Tasks",
      Steps: {
        one: {
          heading: "Step 1. Download template",
          content:
            "Our standard template can be downloaded from the link below. Kindly input task details in the downloaded template as per fields in the file."
        },
        two: {
          heading: "Step 2. Import template"
        }
      },
      logFileMsg:
        "There are errors while uploading task file. Refer to the log file for details."
    }
  },
  Contracts: {
    tasks: "Tasks",
    taskName: "Task Name",
    description: "Description",
    assignTo: "Assign to",
    assignee: "Assignee(s)",
    repeatThisTask: "Repeat this Task",
    repeatThisTaskEvery: "Repeat this Task every",
    repeatDueDate: "Repeat Due Date",
    dueDate: "Due Date",
    repeatStartDate: "Repeat Start Date",
    repeatEndDate: "Repeat End Date",
    submit: "Submit",
    submitting: "Submitting...",
    addTask: "+ Add task",
    contracts: "Contracts",
    contractsCount: "Contract(s)",
    createContract: "Create Contract",
    editContract: "Edit Contract",
    enterDescription: "Enter a description...",
    cancelTaskConfirmMessage:
      "Are you sure you want to archive this repeating task?",
    cancelTaskConfirmTitle: "Archive Repeating Task",
    saveTasksSuccessMsg: "Task(s) saved successfully.",
    createContractSuccessMsg: "Contract created successfully.",
    updateContractSuccessMsg: "Contract updated successfully.",
    createMilestoneSuccessMsg: "Milestone created successfully.",
    updateMilestoneSuccessMsg: "Milestone updated successfully.",
    deleteMilestoneSuccessMsg: "Milestone deleted successfully.",
    name: "Name",
    phase: "Phase",
    counterparty: "Counterparty",
    dateRange: "Date Range",
    totalContractValue: "Total Contract Value",
    fixedPriceContract: "Fixed Price Contract?",
    type: "Type",
    startDate: "Start Date",
    endDate: "End Date",
    yes: "Yes",
    no: "No",
    milestones: "Milestones",
    contractMilestones: "Contract Milestones",
    fileTypeError: "Kindly import pdf file(s) as specified.",
    attachmentSizeError: "The size of the file uploaded is larger than 100MB.",
    documents: "Documents",
    contractDocuments: "Contract Documents",
    documentName: "Document Name",
    documentSaveSuccessMessage: "Document saved successfully",
    dateAdded: "Date Added",
    create: "Create",
    update: "Update",
    cancel: "Cancel",
    createMilestone: "Create Milestone",
    editMilestone: "Edit Milestone",
    deleteMilestone: "Delete Milestone",
    deleteMilestoneMessage: "Are you sure you want to delete this milestone?",
    status: "Status",
    assetName: "Entity Name",
    edit: "Edit",
    manageMilestones: "Manage Milestones",
    manageDocuments: "Manage Documents",
    noContracts: "No contracts available.",
    noDocuments: "No documents available.",
    noMilestones: "No milestones available.",
    reference: "Reference",
    description: "Description",
    amount: "Amount",
    lineItem: "Line Item",
    glNameBliDescription: "GL Name/BLI Description",
    contract: "Contract",
    voidContract: "Void Contract",
    voidAndCreateDraftContract: "Void Contract and Create Draft",
    milestonesVariations: "Milestones/Variations",
    assignedAmount: "Assigned Amount",
    lineItemNetAmount: "Line Item Net Amount",
    glName: "GL Name",
    netAmount: "Net Amount",
    bliDescription: "BLI Description",
    delete: "Delete",
    codify: "Codify",
    contractCodify: "Contract Codify",
    deleteDocumentConfirmMsg: "Are you sure you want to delete this document?",
    addDocuments: "Add Document(s)",
    attributes: "Attributes",
    save: "Save",
    property: "Property",
    value: "Value",
    downloadInProgress: "Downloading File...",
    General: {
      general: "General",
      generalSaveSuccessMessage: "General attribute(s) saved successfully"
    },
    Financial: {
      financial: "Financial",
      priceBlock: "Price block",
      delete: "Delete",
      priceBlockDeleteModalTitle: "Delete price block",
      priceBlockDeleteModalDescription: "Revenue calculation will be updated.",
      priceBlockName: "Name",
      addPriceBlock: "+ Add Price block",
      priceBlockSaveSuccessMessage: "Price block(s) saved successfully",
      price: "Price",
      gLCode: "GL Code",
      period: "Period",
      formula: "Formula"
    },
    Risk: {
      risk: "Risk",
      riskSaveSuccessMessage: "Risk attribute(s) saved successfully"
    }
  },
  ChartOfAccounts: {
    title: "Chart of Accounts",
    chartOfAccount: "Chart of Account",
    noChartOfAccounts: "No chart of accounts available.",
    noSystemAccounts: "No system accounts available.",
    mapSystemAccount: "Map System Accounts",
    importChartOfAccounts: "Import",
    glCode: "GL Code",
    glName: "GL Name",
    glType: "GL Type",
    fxRevaluation: "FX Revaluation",
    accountType: "Account Type",
    glSection: "GL Section",
    defaultTax: "Default Tax",
    ytd: "YTD",
    mtd: "MTD",
    nonInvoiceLabel: "Non Invoice",
    nonInvoiceTooltip: "Non Invoice",
    archiveSuccessMessage: "Account is archived successfully.",
    Imports: {
      title: "Import Chart of Accounts",
      stepLabel: "1. Lorem Ipsum",
      successMsg: "Chart of Accounts are imported successfully.",
      reImportingWarning:
        "Re importing chart of accounts will overwrite existing chart of accounts, system accounts, opening balances and resets conversion period.",
      reImportingOBWarning:
        "Re importing opening balances will replace existing opening balances and resets conversion period.",
      inCompleteImportMessage: "Chart of Accounts are not imported yet.",
      messageToappendClickhereLink: " to import Chart of Accounts ",
      Instructions: {
        title:
          "The Chart of Accounts import functionality allows the user to import the required GL codes and attributes for the entity.",
        first:
          "Download the csv template and prepare the data -  Using the built in csv template the user should populate the required fields. All fields are required."
      }
    },
    CreateAccount: {
      title: "Create Account",
      createChartOfAccount: "Create Chart of Account",
      addBankAccount: "Add Bank Account",
      editTitle: "Edit Account",
      successMsg: "New Account is created successfully.",
      editSuccessMsg: "Account is updated successfully.",
      nonInvoiceValidationMessage:
        "is mapped to system account and cannot be showed in non-invoice",
      mappedToGLAccount: "This GL Code is mapped to System Account",
      warningOnGLSectionChange:
        "Changing the GL Section will affect all reports for past and future dates",
      bankAccountGLSectionNote:
        "GL Section of Bank Account type cannot be changed.",
      inValidGlCodeMessage: "The GLCode is already in use"
    },
    SystemAccounts: {
      title: "System Accounts",
      toolTip: "System Account",
      successMsg: "System Accounts are successfully mapped to GL Accounts.",
      mappedAccountsLabel: " account(s) mapped.",
      journalsModificationInfo:
        "Entries to this category can be made through Journals"
    }
  },
  Journals: {
    unVerifiedContactsMessage:
      "This journal cannot be submitted for approval until the below contacts are verified.",
    journals: "Journals",
    transactions: "Transactions",
    manual: "Manual",
    manualJournals: "Manual Journals",
    manualJournal: "Manual Journal",
    repeating: "Repeating",
    loadMore: "Load More",
    noJournalsFound: "No journals available.",
    currency: "Currency",
    assetName: "Entity Name",
    status: "Status",
    statusWillBe: "Status will be",
    type: "Type",
    salesPeriod: "Sales Period",
    startDate: "Start Date",
    nextJournalDate: "Next Journal Date",
    endDate: "End Date",
    repeats: "Repeats",
    transactionDate: "Transaction Date",
    transactionDateFromTo: "Transaction From Date - To Date",
    startDateFromTo: "Start Dates",
    nextJournalDateFromTo: "Next Journal Dates",
    reset: "Reset",
    import: "Import",
    description: "Description",
    adjustment: "Adjustment",
    debit: "Debit",
    credit: "Credit",
    units: "Units",
    price: "Price",
    addNewJournalEntry: "Add New Journal Entry",
    newManualJournal: "New Manual Journal",
    addNewRepeatingJournalEntry: "Add New Repeating Journal Entry",
    date: "Date",
    journalType: "Journal Type",
    removeLineItem: "Remove Line Item",
    contact: "Contact",
    contactNotVerified: "Contact Not Verified.",
    supplier: "Supplier",
    budgetLineItem: "Budget Line Item",
    gLCode: "GL Code ",
    addANewLine: "Add a new line",
    total: "Total",
    pleaseSelectGLCode: "Please select GL Code",
    pleaseSelectContact: "Please select contact",
    outBy: "Total is out by",
    submitForApproval: "Submit for Approval",
    importSuccess: "Successfully imported",
    cancelJournalConfirmMessage:
      "Are you sure? The entries created will be deleted",
    reConfirmOnCancellingJournalMessage:
      "You have unsaved changes. You can save the journal and Cancel, Cancel the journal without saving, or continue editing.",
    saveAndCancel: "Save And Cancel Journal",
    cancelJournal: "Cancel Journal",
    returnToDraftJournalConfirmMessage: "Comments",
    cancelJournal: "Cancel Journal",
    returnToDraftJournal: "Return Journal to Draft",
    reverseJournal: "Reverse Journal",
    reversalDialogDesc: "Are you sure? Reverse Journal entries will be posted.",
    comments: "Comments",
    submit: "Submit",
    submitting: "Submitting...",
    options: "option(s)",
    selected: "selected",
    fileTypeError: "Kindly import CSV file(s) as specified.",
    asset: "Entity",
    submitForApprovalMessage:
      "Journal %desc% is submitted for approval. Total amount is",
    Repeating: {
      name: "Repeating Journal",
      repeatingJournals: "Repeating Journals",
      newRepeatingJournal: "New Repeating Journal",
      firstJournalDate: "First Journal Date",
      repeatJournalEvery: "Repeat this journal every",
      endDate: "End Date (Optional)",
      status: "Status of created journals will be :",
      cancelJournalConfirmTitle: "Repeating Journal Cancellation!",
      cancelJournalConfirmMessage:
        "Deleting a repeated journal stops all future occurrences. Are you sure you want to delete this repeating journal?",
      editRepeatingJournalConfirmMessage:
        "Editing a repeated journal updates all future occurrences. Are you sure you want to edit this repeating journal?",
      editRepeatingJournalJournal: "Confirm Edit Repeating Journal!",
      lastDateOfMonthSelectedToRepeatMonthly:
        "Last date of every month will be considered for journals"
    },
    Attachments: {
      title: "Attachments",
      addAttachment: "+ Add Attachment",
      noAttachments: "No Attachments.",
      dateAdded: "Date Added",
      action: "Action",
      confirmDeleteAttachmentMessage:
        "Are you sure you wish to delete the attachment?",
      attachmentDeleteSuccessMessage: "Attachment deleted successfully"
    },
    Import: {
      title: "Import Manual Journal",
      title2: "Import Journals",
      Steps: {
        one: {
          heading: "Step 1. Download manual journal template",
          heading2: "Step 1. Download journal template",
          content:
            "Our standard template can be downloaded from the link below."
        },
        two: {
          heading: "Step 2. Copy your manual journal to the template",
          heading2: "Step 2. Copy your journal to the template",
          content:
            "Kindly input your journal details in the downloaded template according to the fields in the file.",
          content2:
            "Input your journal details in the downloaded template according to the fields in the file.",
          overWriteLabel: "Overwrite previously imported journals"
        },
        three: {
          heading:
            "Step 3. Select Entity name and Import the updated template file",
          content: ""
        }
      },
      warning:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet ut enim dolor, justo, pharetra. Facilisis tempor lorem pellentesque donec a in quis. Molestie blandit eget commodo enim ipsum. Faucibus tincidunt amet maecenas aliquam fermentum nec.",
      selectAssetErrorText: "Please select an entity",
      reImportingWarning:
        "Reimporting opening journals will cause previously imported journals to be overwritten.",
      credit: "Credit",
      logFileMsg:
        "There are errors while uploading journals. Refer to the log file for details."
    }
  },
  Comments: {
    title: "History and Comments",
    addComment: "+Add Comment",
    hideAll: "Hide All",
    addCommentTitle: "Add Comment",
    editCommentTitle: "Edit Comment",
    showCommentsTitle: "Show history and comments",
    Info: {
      noHistory: "No History Yet.",
      noComments: "Nothing to Serve. Be the First to Comment"
    },
    Errors: {
      errorInGettingComments: "Error in getting Comments, Please try later!",
      enterComment: "Please Enter Comment.",
      unableToPostComment: "Error in Posting Comments, Please try later!"
    },
    Types: {
      All: "All",
      Comments: "Comments",
      History: "History"
    }
  },
  Contacts: {
    label: "Contacts",
    contact: "Contact",
    createNewContact: "Create Contact",
    successfulAssignContactsToAssetsMessage:
      "Contacts have been assigned to entities Successfully.",
    editContact: "Edit Contact",
    addContactToAsset: "Add contact to entities",
    notVerifiedInfoMessage:
      "Contact not verified. Instruments associated with an unverified contact cannot be submitted for approval.",
    supplier: "Supplier",
    customer: "Customer",
    address: "Address",
    contactPatternError:
      "Contact Name must contain only alpha numeric and special characters. Allowed special characters are &,-,_,.,', space, /, [, ], ( and ).",
    addressLine1: "Address Line 1",
    addressLine2: "Address Line 2",
    state: "State",
    areaCode: "Area Code",
    primaryContact: {
      person: "Contact Person",
      email: "Contact Email",
      phone: "Contact Phone"
    },
    exchangeRateInfo: "The exchange rate as on [date] is used",
    contactStatus: "Contact Status",
    contactDetails: "Contact Details",
    contactName: "Contact Name",
    type: "Type",
    contactPersonName: "Primary Contact",
    amountToBePaid: "You Owe",
    amountToReceived: "You're Owed",
    country: "Country",
    website: "Website",
    inValidWebsiteMessage: "Invalid website.",
    externalRefCode: "External Reference Code",
    attachments: "Attachments",
    financialDetails: "Financial Details",
    taxId: "Tax ID",
    defaultCurrency: "Default Currency",
    iban: "IBAN",
    invalidIban: "Invalid IBAN.",
    ibanShouldntContainSpace: "IBAN shouldn't contain spaces.",
    bic: "BIC",
    oin: "OIN",
    addAnotherAccount: "Add Account",
    bankAccountDetails: "Bank Account Details",
    bankName: "Bank Name",
    bankAccountNumber: "Bank Account No.",
    inValidAddressErrorMessage: "Fill out all of the Address fields.",
    externalRefCodeErrorMsg: "Maximum length Allowed is 10 characters",
    note: "Note:",
    addSupplierWarningText:
      "The newly created supplier needs to be verified before the associated purchase order can be approved.",
    duplicateAccount: "Duplicate Account",
    verify: "Verify",
    saveAndVerify: "Save & Verify",
    activityDetails: {
      openSalesTransactions: {
        title: "Open Sales Transactions",
        infoTitle: "You're Owed"
      },
      openPurchaseTransactions: {
        title: "Open Purchase Transactions",
        infoTitle: "You Owe",
        viewLinkTitle: "View Completed Payments",
        hideViewLinkTitle: "Hide Completed Payments"
      },
      completedPurchaseTransactions: {
        title: "Completed Purchase Transactions",
        infoTitle: "Payments"
      },
      purchaseOrders: {
        title: "Purchase orders",
        infoTitle: "Open Purchase Orders",
        viewLinkTitle: "View Completed Orders",
        hideViewLinkTitle: "Hide Completed Orders",
        infoMessage:
          "All the purchase orders that are approved but not yet invoiced are considered here. Purchase Order amount is total amount of Purchase Order minus invoiced amount."
      },
      completedPurchaseOrders: {
        title: "Completed orders",
        infoTitle: "Completed Purchase Orders"
      }
    },
    bankAccountName: "Bank Account Name",
    name: "Name",
    archiveContactMessage: "Are you sure you want to archive this contact?",
    noContacts: "No contacts available.",

    successfulMsg: "Contact created Successfully!",
    successfullyUpdatedMsg: "Contact updated Successfully!"
  },
  PostingPeriods: {
    postingPeriodRuleForAllInstruments:
      "Posting period depends on instrument date and available open period",
    postingPeriodMismatchMessage:
      "There is a mismatch between posting period and month of the instrument.",
    postingPeriod: "Posting Period",
    postingPeriodVoidMessage:
      "The instrument will be voided with posting period as ",
    postingPeriodUnReconcileMessage:
      "The instrument will be unreconciled with posting period as ",
    reopenedOrHasClosedPeriodsAfterMessageParts: [
      "There are closed periods after ",
      " or the period ",
      " was re-opened"
    ],
    missMatchReOpenedOrClosedPeriodsAfterMessageParts: [
      "There is a mismatch between posting period and month of the instrument. There are closed periods after ",
      " or the period ",
      " was re-opened"
    ],
    APARPostingPeriodMismatchMessage:
      "The periods of AP and AR modules are not in sync with each other. Kindly check the closing periods of the modules.",
    bankReconPostingPeriodMessage: "The posting period will be ",
    thePostingPeriodIs: "The posting period is "
  },
  interCompany: {
    glTypeShouldBeBalanceSheetForInterCompanyAccount:
      "GL Type should be Balance Sheet for Inter Company Account",
    interCompanyAsset: "Inter Company Entity",
    bankAccounts: "Bank Accounts",
    intercompanyAccounts: "Intercompany Accounts",
    notManagedOnPlatform: "Not managed on platform",
    interCompanyDirectDebitPaymentMessage:
      "Payment will move to paid state after saving.",
    interCompanyDirectNonDebitPaymentMessage:
      "Payment will move to paid state after approval."
  },
  Tagging: {
    tagging: "Tagging",
    insights: "Insights",
    attributes: "Attributes",
    editAttribute: "Edit Attribute",
    editTag: "Edit tag",
    deleteTag: "Delete tag",
    deleteTagMessage: "Are you sure you want to delete this tag?",
    name: "Name",
    values: "Values",
    assignedTo: "Assigned to",
    newCategory: "New Category",
    setCategoryName: "Set category name",
    attributeName: "Attribute name",
    attributeValue: "Attribute value",
    addValue: "Add value",
    attributeValueNoteMsg: "Values in use cannot be deleted.",
    createAttribute: "Create Attribute",
    assign: "Assign",
    assignedToEntitesSuccessMsg: "Category assigned to entities successfully.",
    assignedToEntitySuccessMsg: "Category assigned to entity successfully.",
    removedFromAssetSuccessMsg: "Category removed from entity successfully.",
    createAttributeSuccessMsg: "Attribute created successfully.",
    attributeValuesSuccessMsg: "Attribute value(s) saved successfully.",
    addCategorySuccessMsg: "Category added successfully.",
    categoryName: "Category Name",
    editCategoryName: "Edit Category Name",
    addNewAttribute: "Add New Attribute",
    addCategory: "Add Category",
    noCategories: "No category assigned to the entity.",
    noAttributes: "No Attributes available.",
    noAttributesToCreateTag: "No attributes available to create tags.",
    noSaveChanges: "No changes are available to save",
    invalidTagName: "Tag name should not be greater than 55 characters.",
    tagChangesRequired:
      "All the fields need to be filled to create/update active tag(s).",
    dashboard: "Dashboard",
    assetTagging: "Entity Tagging",
    taggingCategories: "Tagging Categories",
    tagSuccessMsg:
      "Tag(s) data saved successfully. This update will need to be applied to all invoices, budgets and will take a few mins.",
    notags: "No tags available",
    noBudgetlineitems: "No budget line items available",
    noBudgetSelected: "Select a budget to see associated budget line items",
    notAssignedToAssets: "This category is not assigned to any entities.",
    maxLengthLimit55: "Only 55 characters are allowed for this field.",
    minLengthLimit3: "Minimum of 3 characters are required.",
    create: "Create",
    deactivate: "Deactivate",
    tag: "Tag",
    categories: "Categories",
    category: "Category",
    searchByOrgOrAsset: "Search by Org. or Entity",
    invoices: "Invoices",
    tagName: "Tag Name",
    createTag: "Create Tag",
    status: "Status",
    budgets: {
      label: "Budget Line Items",
      budgetLineItemListing: "BudgetLineItem Listing",
      budgetLineItems: "Budget Line Item(s)",
      budgets: "Budgets",
      budget: "Budget",
      fyEnding: "FY Ending",
      budgetLineItem: "Budget Line Item",
      tagBudgetLineItem: "Tag Budget Line Item",
      glCode: "GL Code",
      contact: "Contact",
      amount: "Amount",
      tag: "Tag",
      category: "Category",
      usePercentage: "Use %",
      percentage: "%",
      addTag: "Add tag",
      budgeted: "Budgeted",
      remaining: "Remaining",
      noBLIDataAvailable: "No data available for this financial year",
      tagSavedSuccessMsg: "Tag(s) data saved successfully.",
      selectTag: "Select Tag",
      greaterTaggedAmountErrMsg:
        "Total tagged amount should not be greater than budgeted amount.",
      tags: "Tags",
      tagsStatusInfoMsg: "Inactive tags are in red and Active tags are in green"
    },
    Invoices: {
      label: "Invoices",
      invoice: "Invoice",
      importSuccessMsg: "Invoices imported successfully.",
      taggingInvoices: "Tagging Invoices",
      lineItemDescription: "Line Item Description",
      bliDescription: "BLI Description",
      importUsingCsv: "Import using CSV",
      importUsingAccountingSoftware: "Import using accounting software",
      noInvoices: "No invoices available.",
      supplier: "Supplier",
      invoiceNumber: "Invoice Number",
      invoiceDate: "Invoice Date",
      glDescription: "GL Description",
      glName: "GL Name",
      netAmount: "Net Amount",
      grossAmount: "Gross Amount",
      invocieAmount: "Invoice Amount",
      tags: "Tags",
      status: "Status",
      comments: "Comments",
      commentsAndAttachments: "Comments And Attachments",
      uploadEditNote1:
        "Documents attached here will not reflect in original invoice.",
      uploadEditNote2:
        "The first document attached will be treated as invoice and user will not be able to delete it.",
      supplier: "Supplier",
      returnToUntagged: "Return Invoice to Untagged",
      archiveReason: "Archive Reason",
      archiveInvoice: "Archive Invoice",
      category: "Category",
      assignCategory: "Assign Category",
      addCategory: "Add Category",
      addTag: "Add tag",
      selectTag: "Select Tag",
      selectCategory: "Select Category",
      tag: "Tag",
      amount: "Amount",
      percentage: "Percentage(%)",
      tagsNegativeAmountValidationError:
        "Total amount should be negative and cannot be lesser than the line item net amount.",
      tagsPositiveAmountValidationError:
        "Total amount should be positive and cannot exceed the line item net amount.",
      tagsValidationError2: "Amount/Percentage cannot be empty or 0",
      invoiceNumber: "Invoice Number",
      invoiceDate: "Invoice Date",
      glCode: "GL Code:",
      assignBudgetLineItem: "Assign Budget Line Item",
      budgetLineItem: "Budget Line Item",
      importInvoices: "Import Invoices",
      comments: "Comments",
      submit: "Submit",
      submitting: "Submitting...",
      supplierName: "Supplier Name"
    },
    reset: "Reset",
    save: "Save",
    cancel: "Cancel",
    categoryAssignToAssets: "Assign category to entities",
    atleaseOneValueShouldBeAdded: "At lease one value should be added"
  },
  FX: {
    fxRates: "FX Rates",
    description: {
      updatedValueOfEur: " updated the value of 1 Eur in ",
      addedValueOfEur: " added a value of 1 Eur in ",
      from: " from ",
      to: " to ",
      as: " as ",
      for: " for "
    },
    month: "Month",
    save: "Save",
    date: "Date",
    currency: "Currency",
    rate: "Rate",
    noteMsg: "The FX rates will be rounded off to 5 decimals.",
    title: "FX - 1 EUR",
    viewHistory: "View History",
    history: "History",
    saveSuccssMsg:
      "Data saved successfully. Changing FX rates will not impact transactions posted. Revaluation will need to be done to reflect updated FX rates."
  }
};
