import { sortObjectListByKey } from "./string";

/* Returns a sorted list of assets aggregated from all organization with no duplicates.
 *  Primarily used in get Assets and search Assets
 */
export function getSortedAssetListFromOrgs(
  organizations,
  orgId = undefined,
  includeNonGlAssets = true
) {
  const myAssets = [];
  if (orgId === undefined) {
    organizations?.forEach((organization) => {
      organization.organizationAssets.forEach((asset) => {
        myAssets.push({
          ...asset,
          orgId: organization.id,
          orgName: organization.name
        });
      });
    });
  } else {
    let organization = organizations.find((org) => org.id === parseInt(orgId));
    if (organization !== undefined) {
      organization.organizationAssets.forEach((asset) => {
        myAssets.push({
          ...asset,
          orgId: organization.id,
          orgName: organization.name
        });
      });
    }
  }

  // Remove duplicates since organizations can co-own assets
  const obj = {};
  myAssets.forEach((i) => (obj[i.id] = i));
  // Convert it back to array
  let data = [];
  for (const id in obj) {
    data.push(obj[id]);
  }

  //return only GL assets
  if (!includeNonGlAssets) {
    data = data.filter((asset) => asset.isFullService === true);
  }

  let sortedAssetList = sortObjectListByKey(
    data.map((i) => ({ ...i, value: i.id, label: i.formalBusinessName })),
    "formalBusinessName"
  );

  return sortedAssetList;
}

export const SITE_TECHNOLOGY_TYPES = {
  ONSHORE_WIND: "Onshore Wind",
  OFFSHORE_WIND: "Offshore Wind",
  SOLAR: "Solar",
  BATTERY: "Battery",
  BIOMASS: "Biomass",
  POWER: "Power"
};

export const ENTITY_TYPES = {
  SPV: "SPV",
  HOLDING: "Holding"
};

export const ASSET_CONFIGURATION_TYPES = {
  INTEGRATED: "Integrated",
  FULL_SERVICE: "Full Service"
};

export const ASSET_PHASES = {
  OPERATIONAL: "Operational",
  CONSTRUCTION: "Construction",
  DEVELOPMENT: "Development"
};

export const TIMEZONES = [
  { name: "(GMT -12:00) Eniwetok, Kwajalein", value: "-12:00" },
  { name: "(GMT -11:00) Midway Island, Samoa", value: "-11:00" },
  { name: "(GMT -10:00) Hawaii", value: "-10:00" },
  { name: "(GMT -9:30) Taiohae", value: "-09:50" },
  { name: "(GMT -9:00) Alaska", value: "-09:00" },
  { name: "(GMT -8:00) Pacific Time (US & Canada)", value: "-08:00" },
  { name: "(GMT -7:00) Mountain Time (US & Canada)", value: "-07:00" },
  {
    name: "(GMT -6:00) Central Time (US & Canada), Mexico City",
    value: "-06:00"
  },
  {
    name: "(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima",
    value: "-05:00"
  },
  { name: "(GMT -4:30) Caracas", value: "-04:50" },
  {
    name: "(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz",
    value: "-04:00"
  },
  { name: "(GMT -3:30) Newfoundland", value: "-03:50" },
  { name: "(GMT -3:00) Brazil, Buenos Aires, Georgetown", value: "-03:00" },
  { name: "(GMT -2:00) Mid-Atlantic", value: "-02:00" },
  { name: "(GMT -1:00) Azores, Cape Verde Islands", value: "-01:00" },
  {
    name: "(GMT) Western Europe Time, London, Lisbon, Casablanca",
    value: "+00:00"
  },
  { name: "(GMT +1:00) Brussels, Copenhagen, Madrid, Paris", value: "+01:00" },
  { name: "(GMT +2:00) Kaliningrad, South Africa", value: "+02:00" },
  {
    name: "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg",
    value: "+03:00"
  },
  { name: "(GMT +3:30) Tehran", value: "+03:50" },
  { name: "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi", value: "+04:00" },
  { name: "(GMT +4:30) Kabul", value: "+04:50" },
  {
    name: "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent",
    value: "+05:00"
  },
  { name: "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi", value: "+05:50" },
  { name: "(GMT +5:45) Kathmandu, Pokhara", value: "+05:75" },
  { name: "(GMT +6:00) Almaty, Dhaka, Colombo", value: "+06:00" },
  { name: "(GMT +6:30) Yangon, Mandalay", value: "+06:50" },
  { name: "(GMT +7:00) Bangkok, Hanoi, Jakarta", value: "+07:00" },
  { name: "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong", value: "+08:00" },
  { name: "(GMT +8:45) Eucla", value: "+08:75" },
  {
    name: "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk",
    value: "+09:00"
  },
  { name: "(GMT +9:30) Adelaide, Darwin", value: "+09:50" },
  {
    name: "(GMT +10:00) Eastern Australia, Guam, Vladivostok",
    value: "+10:00"
  },
  { name: "(GMT +10:30) Lord Howe Island", value: "+10:50" },
  {
    name: "(GMT +11:00) Magadan, Solomon Islands, New Caledonia",
    value: "+11:00"
  },
  { name: "(GMT +11:30) Norfolk Island", value: "+11:50" },
  {
    name: "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka",
    value: "+12:00"
  },
  { name: "(GMT +12:45) Chatham Islands", value: "+12:75" },
  { name: "(GMT +13:00) Apia, Nukualofa", value: "+13:00" },
  { name: "(GMT +14:00) Line Islands, Tokelau", value: "+14:00" }
];

export const YEAR_ENDS = [
  {
    label: "31 January",
    value: "01-31"
  },
  {
    label: "28 February",
    value: "02-28"
  },
  {
    label: "31 March",
    value: "03-31"
  },
  {
    label: "30 April",
    value: "04-30"
  },
  {
    label: "31 May",
    value: "05-31"
  },
  {
    label: "30 June",
    value: "06-30"
  },
  {
    label: "31 July",
    value: "07-31"
  },
  {
    label: "31 August",
    value: "08-31"
  },
  {
    label: "30 September",
    value: "09-30"
  },
  {
    label: "31 October",
    value: "10-31"
  },
  {
    label: "30 November",
    value: "11-30"
  },
  {
    label: "31 December",
    value: "12-31"
  }
];
