import React, { useContext } from "react";
import { useQueryClient, useMutation } from "react-query";
import {
  Modal,
  ModalBody,
  Alert,
  ModalFooter,
  Button,
  ModalHeader
} from "reactstrap";
import { Labels } from "../../../../../../constants/Constants";
import { InvoiceActions } from "../../../../../../constants/InvoiceActionsMap";
import PaymentStatuses from "../../../../../../constants/PaymentStatuses";
import PostingPeriodModules from "../../../../../../constants/PostingPeriodModules";
import QueryKeys from "../../../../../../constants/QueryKeys";
import PostingPeriodInfoMessage from "../../../../../../elements/PostingPeriodInfoMessage";
import useGetPostingPeriod from "../../../../../../hooks/useGetPostingPeriod";
import { serializeDate } from "../../../../../../utils/date";
import {
  voidAndCreateDraftInvoice,
  voidInvoice
} from "../../../../../../utils/http/endpoints";
import { httpRequest } from "../../../../../../utils/http/httpRequest";
import { SET_INVOICE_SAVED_MESSAGE } from "./actionsTypes";
import LoaderWithMessage from "../../../../../../elements/LoaderWithMessage";
import { nonBlockingHttpRequest } from "../../../../../../utils/http/nonBlockingHttpRequest";

export default function VoidActionWarningModal({
  isOpen,
  toggle,
  updateInvoice,
  assetId,
  isOnboardingComplete,
  invoiceId,
  isFormChanged,
  handleSaveAndAction,
  selectedOptionInSave,
  handleAction,
  comment,
  setComment,
  formErrors,
  setFormErrors,
  payments,
  invoice,
  isPCN,
  voidAndCreateDraftRef,
  currentActionNameRef,
  dispatch,
  setVoidAndCreateDraftSuccessMessage,
  actionType,
  isPoLinked,
  isPoAutomationEnabled
}) {
  const queryClient = useQueryClient();
  const postingPeriodQuery = useGetPostingPeriod({
    assetId,
    instrumentDate: serializeDate(new Date(invoice.invoiceDate)),
    module: PostingPeriodModules.AP
  });

  const putVoidReason = async () => {
    const request = isPoAutomationEnabled
      ? nonBlockingHttpRequest
      : httpRequest;
    return request({
      method: "put",
      url: voidAndCreateDraftRef.current
        ? voidAndCreateDraftInvoice({ assetId, invoiceId })
        : voidInvoice(assetId, invoiceId),
      data: {
        comment
      }
    }).then((res) => Promise.resolve(res?.data));
  };

  const mutation = useMutation(putVoidReason, {
    onSuccess: (data) => {
      if (setVoidAndCreateDraftSuccessMessage) {
        setVoidAndCreateDraftSuccessMessage(null);
      }
      if (voidAndCreateDraftRef.current) {
        if (dispatch) {
          dispatch({
            type: SET_INVOICE_SAVED_MESSAGE,
            payload: {
              userAction: InvoiceActions.voidCreateDraftInvoice,
              message: data
            }
          });
        }

        if (setVoidAndCreateDraftSuccessMessage) {
          setVoidAndCreateDraftSuccessMessage({
            ...data,
            assetId
          });
        }
      }

      toggle();
      updateInvoice();
      queryClient.invalidateQueries([
        QueryKeys.getInvoiceComments,
        +assetId,
        +invoiceId
      ]);
    },
    onError: (err) => {
      setFormErrors(err.data.errors);
    }
  });

  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (selectedOptionInSave === "Save & Action") {
      handleSaveAndAction();
      return;
    }
    mutation.mutate();
  };

  const getPopUpTitle = (currentActionNameRef, actionType) => {
    if (actionType) {
      if (actionType === InvoiceActions.voidPCN) {
        return Labels.Payments.Invoices.Details.creditNoteVoidActionWarnMessage;
      } else if (actionType === InvoiceActions.voidInvoice) {
        return Labels.Payments.Invoices.Details.invoiceVoidActionMessage;
      } else if (actionType === InvoiceActions.voidInvoice) {
        return Labels.Payments.Invoices.Details.invoiceVoidActionMessage;
      } else {
        if (isPCN) {
          return Labels.Payments.Invoices.Details
            .pcnVoidAndCreateDraftActionWarnMessage;
        } else {
          return Labels.Payments.Invoices.Details
            .invoiceVoidAndCreateDraftActionWarnMessage;
        }
      }
    } else if (!actionType) {
      if (currentActionNameRef.current === InvoiceActions.voidInvoice) {
        return Labels.Payments.Invoices.Details.invoiceVoidActionMessage;
      } else if (currentActionNameRef.current === InvoiceActions.voidPCN) {
        return Labels.Payments.Invoices.Details.creditNoteVoidActionWarnMessage;
      } else if (
        currentActionNameRef.current === InvoiceActions.voidCreateDraftInvoice
      ) {
        if (isPCN) {
          return Labels.Payments.Invoices.Details
            .pcnVoidAndCreateDraftActionWarnMessage;
        } else {
          return Labels.Payments.Invoices.Details
            .invoiceVoidAndCreateDraftActionWarnMessage;
        }
      }
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
        keyboard={false}
        fade={false}
        centered
      >
        <ModalHeader toggle={toggle}>
          <div>
            <h2 className="color-dark modal-title">
              {getPopUpTitle(currentActionNameRef, actionType)}
            </h2>
          </div>
        </ModalHeader>

        <ModalBody className="pt-0">
          <Alert
            color="danger"
            isOpen={formErrors?.length > 0}
            toggle={() => setFormErrors([])}
          >
            <ul className="error-list">
              {formErrors?.map((error, index) => {
                return <li key={index}>{error.errorMessage}</li>;
              })}
            </ul>
          </Alert>
          <div className="comments-box m-0 border-0 shadow-none p-0">
            <label htmlFor="comments">
              {Labels.Payments.Invoices.comments}
            </label>
            <textarea
              id="comments"
              className="form-control"
              rows={10}
              maxLength={512}
              value={comment}
              onChange={(event) => {
                setComment(event.target.value);
              }}
            ></textarea>
          </div>
          {isOnboardingComplete ? (
            <PostingPeriodInfoMessage
              postingPeriod={postingPeriodQuery.data?.postingPeriod}
              paymentsVoidMsg={
                (payments?.some(
                  (payment) =>
                    ![PaymentStatuses.VOIDED, PaymentStatuses.PAID].includes(
                      payment.status
                    )
                ) || invoice?.isPaymentInProcess
                  ? Labels.Payments.Invoices.Details
                      .invoiceVoidActionWarnWithActionPaymentMessage
                  : "") +
                (isPoLinked && isPoAutomationEnabled
                  ? Labels.Payments.Invoices.Details
                      .poLinkedInvoiceVoidNoteMessage
                  : "")
              }
            />
          ) : isPoLinked && isPoAutomationEnabled ? (
            <p>
              <span className="row-header-2">{Labels.CommonModals.note}</span>
              {Labels.Payments.Invoices.Details.poLinkedInvoiceVoidNoteMessage}
            </p>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={handleSubmit}
            disabled={mutation.isLoading}
          >
            {mutation.isLoading
              ? Labels.Payments.Invoices.submitting
              : Labels.Payments.Invoices.submit}
          </Button>
          <Button color="secondary" onClick={toggle}>
            {Labels.CommonModals.cancel}
          </Button>
        </ModalFooter>
      </Modal>
      {isPoAutomationEnabled && mutation.isLoading && (
        <LoaderWithMessage
          message={Labels.CommonModals.voidLoaderMessage}
          isOpen={mutation.isLoading}
        />
      )}
    </>
  );
}
