import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Alert,
  ModalHeader
} from "reactstrap";
import { useMutation } from "react-query";
import { Labels } from "../../../../../constants/Constants";
import { unreconcileLineItem } from "../../../../../utils/http/endpoints";
import { httpRequest } from "../../../../../utils/http/httpRequest";
import PostingPeriodInfoMessage from "../../../../../elements/PostingPeriodInfoMessage";
import useGetPostingPeriod from "../../../../../hooks/useGetPostingPeriod";
import modules from "../../../../../constants/PostingPeriodModules";
import { getBankReconPostingPeriodModule } from "./TransactionDetails/TransactionDetailProvider";
import { serializeDate } from "../../../../../utils/date";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import moment from "moment";

export default NiceModal.create(
  ({
    assetBankAccountStatementLineItemId,
    refreshTransactionsList,
    refreshTransactionData,
    currentPostingPeriod,
    isListView,
    transactionDetails,
    params
  }) => {
    const modal = useModal();
    const [comment, setComment] = useState();
    const [formErrors, setFormErrors] = useState([]);

    const arPostingPeriodQuery = useGetPostingPeriod({
      assetId: params.assetId,
      instrumentDate: transactionDetails?.date
        ? serializeDate(new Date(transactionDetails?.date))
        : null,
      module: modules.AR,
      enabled: isListView
    });

    const apPostingPeriodQuery = useGetPostingPeriod({
      assetId: params.assetId,
      instrumentDate: transactionDetails?.date
        ? serializeDate(new Date(transactionDetails?.date))
        : null,
      module: modules.AP,
      enabled: isListView
    });

    const getSelectedInstruments = () => {
      const selectedInstruments = new Set();

      if (transactionDetails?.transactions?.length) {
        selectedInstruments.add(modules.Manual);
      }

      if (!transactionDetails?.payments?.length) {
        return Array.from(selectedInstruments);
      }

      transactionDetails?.payments.forEach((instrument) => {
        const invoiceTypeToPostingPeriodModuleMap = new Map([
          ["SALES_INVOICE", modules.AR],
          ["SALES_CREDIT_NOTE", modules.AR],
          ["CONTRACT_FOR_DIFFERENCE", modules.AR],
          ["PURCHASE_INVOICE", modules.AP],
          ["PURCHASE_CREDIT_NOTE", modules.AP]
        ]);

        return selectedInstruments.add(
          invoiceTypeToPostingPeriodModuleMap.get(instrument.invoiceType)
        );
      });

      return Array.from(selectedInstruments);
    };

    const postingPeriod = {
      [modules.AP]: apPostingPeriodQuery.data?.postingPeriod || null,
      [modules.AR]: arPostingPeriodQuery.data?.postingPeriod || null
    }[
      getBankReconPostingPeriodModule({
        isPaidIn: transactionDetails?.amount > 0,
        selectedInstruments: getSelectedInstruments()
      })
    ];

    const isAPARPostingPeriodMismatched = () => {
      const selectedInstruments = getSelectedInstruments();
      if (
        selectedInstruments.includes(modules.AP) &&
        selectedInstruments.includes(modules.AR)
      ) {
        return (
          apPostingPeriodQuery.data?.postingPeriod !==
          arPostingPeriodQuery.data?.postingPeriod
        );
      } else {
        return false;
      }
    };

    const postVoidComment = async () => {
      return httpRequest({
        method: "post",
        url: unreconcileLineItem({
          assetBankAccountStatementLineItemId,
          ...params
        }),
        data: {
          comment
        }
      });
    };

    const mutation = useMutation(postVoidComment, {
      onSuccess: () => {
        modal.remove();
        if (refreshTransactionsList) {
          refreshTransactionsList();
        } else {
          refreshTransactionData(); // Refresh transaction line item data to show updated status value
        }
      },
      onError: (err) => {
        setFormErrors(err.data.errors);
      }
    });

    const handleSubmit = (evt) => {
      evt.preventDefault();
      mutation.mutate();
    };

    return (
      <Modal
        isOpen={modal.visible}
        toggle={modal.remove}
        backdrop="static"
        keyboard={false}
        fade={false}
        centered
      >
        <ModalHeader toggle={modal.remove}>
          <div>
            <h2 className="color-dark modal-title">
              {Labels.accounting.unreconcileTransaction}
            </h2>
          </div>
        </ModalHeader>

        <ModalBody className="mt-0 pt-0">
          {formErrors?.length > 0 ? (
            <Alert
              color="danger"
              className=""
              isOpen={formErrors?.length > 0}
              toggle={() => setFormErrors([])}
            >
              <ul className="error-list">
                {formErrors.map((error, index) => {
                  return <li key={index}>{error.errorMessage}</li>;
                })}
              </ul>
            </Alert>
          ) : null}
          <div className="comments-box m-0 border-0 shadow-none p-0">
            <label htmlFor="comments" className="border-bottom-0">
              {Labels.Assets.comments}
            </label>
            <textarea
              id="comments"
              className="form-control"
              rows={10}
              maxLength={512}
              value={comment}
              onChange={(event) => {
                setComment(event.target.value);
              }}
            ></textarea>
          </div>

          {transactionDetails?.transferToLineItem?.id ? (
            <PostingPeriodInfoMessage
              message={Labels.PostingPeriods.postingPeriodUnReconcileMessage}
              postingPeriod={moment(transactionDetails.date).format("MMM-YY")}
              transactionUnreconcileMsg={
                Labels.accounting.unreconcileConfirmMessage
              }
            />
          ) : isListView ? (
            <>
              {isAPARPostingPeriodMismatched() ? (
                <div className="mb-2">
                  <span className="body-primary color-negative">
                    {Labels.PostingPeriods.APARPostingPeriodMismatchMessage}
                  </span>
                </div>
              ) : (
                <PostingPeriodInfoMessage
                  message={
                    Labels.PostingPeriods.postingPeriodUnReconcileMessage
                  }
                  postingPeriod={postingPeriod}
                  transactionUnreconcileMsg={
                    Labels.accounting.unreconcileConfirmMessage
                  }
                />
              )}
            </>
          ) : (
            <PostingPeriodInfoMessage
              message={Labels.PostingPeriods.postingPeriodUnReconcileMessage}
              postingPeriod={currentPostingPeriod}
              transactionUnreconcileMsg={
                Labels.accounting.unreconcileConfirmMessage
              }
            />
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={handleSubmit}
            disabled={mutation.isLoading || isAPARPostingPeriodMismatched()}
          >
            {mutation.isLoading
              ? Labels.Payments.Invoices.submitting
              : Labels.Payments.Invoices.submit}
          </Button>
          <Button onClick={modal.remove} disabled={mutation.isLoading}>
            {Labels.CommonModals.cancel}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
);
