import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more.js";
import drilldown from "highcharts/modules/drilldown.js";
import React from "react";
import { getSymbolFromCurrencyCode } from "../../../utils/currency/currency";

highchartsMore(Highcharts);
drilldown(Highcharts);

Highcharts.setOptions({
  lang: {
    drillUpText: "Back to Main"
  }
});

export default function CashBridge({ isExpanded }) {
  /*let revenueSeriesData = [];
  revenueSeriesData.push({
    name: "Total Revenue (Budget)",
    y: 15135946.52,
    color: "#1A669D"
  });
  revenueData.forEach((obj) => {
    revenueSeriesData.push({
      name: obj.name,
      y: obj.amount,
      color: obj.amount >= 0 ? "#27ae60" : "#fd5e64"
    });
  });
  revenueSeriesData.push({
    name: "Total Revenue (Actual)",
    //y: 14538041.91,
    isSum: true,
    color: "#1A669D"
  });*/

  let chartOptions = {
    chart: {
      type: "waterfall",
      height: isExpanded ? 600 : 250,
      style: {
        fontFamily:
          'Inter, Lato, "Helvetica Neue", Arial, "Noto Sans", sans-serif'
      }
    },

    credits: false,

    title: {
      text: ""
    },

    xAxis: {
      type: "category"
    },

    yAxis: {
      title: {
        text: "EUR"
      }
    },

    legend: {
      enabled: false
    },

    tooltip: {
      pointFormat: `<b>${getSymbolFromCurrencyCode("EUR")} {point.y:,.2f}</b>`
    },

    series: [
      {
        upColor: "#27ae60",
        color: "#1A669D",
        name: "Main",
        type: "waterfall",
        /*data: [
          {
            name: "Cash Available for Distribution<br />(Budget)",
            y: 11193889.9303848,
            color: "#1A669D"
          },
          {
            name: "Revenue",
            y: -597904.609889917,
            drilldown: "revenue",
            color: "#fd5e64"
          },
          {
            name: "Operating Costs",
            y: 25132,
            color: "#27ae60"
          },
          {
            name: "Capital Costs",
            y: -124321,
            color: "#fd5e64"
          },
          {
            name: "Taxes",
            y: 0
          },
          {
            name: "Senior Debt Repayments",
            y: 0
          },
          {
            name: "Other",
            y: -15675,
            color: "#fd5e64"
          },
          {
            name: "Cash Available for Distribution<br />(Actual)",
            isSum: true,
            color: "#1A669D"
          }
        ],*/
        data: [
          {
            name: "Cash Available for Distribution<br />(Budget)",
            y: 0,
            color: "#1A669D"
          },
          {
            name: "Revenue",
            y: 0,
            //drilldown: "revenue",
            color: "#fd5e64"
          },
          {
            name: "Operating Costs",
            y: 0,
            color: "#27ae60"
          },
          {
            name: "Capital Costs",
            y: 0,
            color: "#fd5e64"
          },
          {
            name: "Taxes",
            y: 0
          },
          {
            name: "Senior Debt Repayments",
            y: 0
          },
          {
            name: "Other",
            y: 0,
            color: "#fd5e64"
          },
          {
            name: "Cash Available for Distribution<br />(Actual)",
            isSum: true,
            color: "#1A669D"
          }
        ],
        dataLabels: {
          enabled: true,
          inside: false,
          formatter: function () {
            return Highcharts.numberFormat(this.y / 1000, 0, ",") + "k";
          },
          style: {
            fontWeight: "bold"
          }
        },
        pointPadding: 0
      }
    ],
    drilldown: {
      activeAxisLabelStyle: {
        textDecoration: "none",
        fontWeight: "normal",
        color: "#666666"
      },
      drillUpButton: {
        position: {
          y: -10
        },
        color: "white",
        theme: {
          fill: "white",
          "stroke-width": 1,
          stroke: "#0094e1",
          r: 6,
          states: {
            hover: {
              fill: "#f3fafd"
            }
          }
        }
      },
      series: [
        /*{
          id: "revenue",
          type: "waterfall",
          data: revenueSeriesData
        }*/
      ]
    }
  };

  return (
    <React.Fragment>
      {/* <Row className="details-row">
                <Col sm="12" className="chart-container"> */}
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      {/* </Col>
            </Row> */}
    </React.Fragment>
  );
}
