import moment from "moment";
import { Labels } from "../../../../../../constants/Constants";
import { InvoiceActions } from "../../../../../../constants/InvoiceActionsMap";
import InvoiceStatuses from "../../../../../../constants/InvoiceStatuses";
// import InvoiceStatuses from "../../../../../constants/InvoiceStatuses";
import * as actionTypes from "./actionsTypes";

export default function invoiceReducer(state, { type, payload }) {
  // Util
  function updateLineItemValue(lineItemId, value) {
    return state.lineItems.map((lineItem) => {
      if (lineItem.lineItemId === lineItemId) {
        return {
          ...lineItem,
          ...value
        };
      }

      return lineItem;
    });
  }

  if (type === actionTypes.UPDATE_LINEITEMS_FROM_API_RESPONSE) {
    const { invoiceDetails } = payload;
    if (invoiceDetails.enriched === undefined) {
      // invoice details not yet fetched
      return state;
    }
    if (invoiceDetails) {
      return {
        ...state,
        // In case of single line item, keep it open/expand by default
        openedLineItemId: state.openedLineItemId
          ? state.openedLineItemId
          : invoiceDetails.invoiceBudgetLineItems.length === 1
          ? invoiceDetails.invoiceBudgetLineItems[0].id || "NOT_FILLED_YET"
          : null,
        isUpdateBudgetNeeded: false,
        lineItems: invoiceDetails.invoiceBudgetLineItems.map((lineItem) => {
          if (lineItem.id === null && !lineItem.glCodeLineItemData) {
            return {
              ...lineItem,
              ...lineItem.invoiceLineItem,
              apiErrors: [],
              invoiceLineItemId: lineItem.invoiceLineItem.id,
              isNew: true,
              lineItemId:
                invoiceDetails.invoiceBudgetLineItems.length === 1
                  ? "NOT_FILLED_YET"
                  : lineItem.id || lineItem.invoiceLineItem.id,
              budgetLineItem: null,
              poNumber: null,
              noBudgetAvailable: false,
              glAccountCode: null,
              budgetingOption: "budgetLineItem",
              poInvoiceScheduleId: null,
              poInvoiceLineItemId: null,
              prepaymentJournalSchedule:
                lineItem.prepaymentJournalSchedule || "MONTHLY",
              showAllAccruals: lineItem.showAllAccruals || false,
              openingBalance: lineItem.openingBalance || 0,
              journalPeriodRange: lineItem.invoicePeriodTo
                ? [
                    moment(lineItem.invoicePeriodFrom).toDate(),
                    moment(lineItem.invoicePeriodTo).toDate()
                  ]
                : [null, null],
              // If prepayment api returns error for selected journal period, disable save
              prepaymentError: false,
              totalAccruedAmount: "",
              totalAccruedAmountError: ""
            };
          }
          return {
            ...lineItem,
            apiErrors: [],
            poInvoiceScheduleId: lineItem?.purchaseOrder?.poInvoiceScheduleId,
            poInvoiceLineItemId: lineItem?.purchaseOrder?.poInvoiceLineItemId,
            prevPoInvoiceScheduleId:
              lineItem?.purchaseOrder?.poInvoiceScheduleId,
            prevPoInvoiceLineItemId:
              lineItem?.purchaseOrder?.poInvoiceLineItemId,
            poAvailableAmount: lineItem?.purchaseOrder?.poAvailableAmount,
            bcGrossAmount: lineItem.invoiceLineItem.bcGrossAmount,
            netAmount: lineItem.invoiceLineItem.netAmount,
            ...lineItem.invoiceLineItem, // description, accountCodes etc. comes from here
            lineItemId: lineItem.id,
            invoiceLineItemId: lineItem.invoiceLineItem.id,
            budgetingOption:
              lineItem.purchaseOrder === null
                ? lineItem.noBudgetAvailable
                  ? "noBudgetAvailable"
                  : "budgetLineItem"
                : "poNumber",
            prevOpexLineItem: lineItem.glCodeLineItemData.opexLineItem, // For calculating incurred with current invoice
            budgetLineItem:
              lineItem.glCodeLineItemData?.budgetLineItemId === null
                ? null
                : {
                    ...lineItem.glCodeLineItemData,
                    label:
                      lineItem.glCodeLineItemData.glCode +
                      " - " +
                      lineItem.glCodeLineItemData.budgetLineItemDescription,
                    value: lineItem.glCodeLineItemData.budgetLineItemId,
                    accountId: lineItem.glCodeLineItemData.glCodeId,
                    // In invoice get response this comes as "opexLineItem"
                    reportingLineDescription:
                      lineItem.glCodeLineItemData.opexLineItem,
                    budgetLineItem:
                      lineItem.glCodeLineItemData.budgetLineItemDescription
                    // opexLineItem: lineItem.glCodeLineItemData.opexLineItem
                  },
            poNumber:
              lineItem.purchaseOrder === null
                ? null
                : {
                    ...lineItem.purchaseOrder,
                    value: lineItem.purchaseOrder.id,
                    label:
                      lineItem.purchaseOrder.id +
                      " - " +
                      lineItem.purchaseOrder.poDescription
                  },
            persistedPoNumber: lineItem.purchaseOrder,
            noBudgetAvailable: lineItem.noBudgetAvailable,
            glAccountCode: {
              ...lineItem.glCodeLineItemData,
              label:
                lineItem.glCodeLineItemData.glCode +
                " - " +
                lineItem.glCodeLineItemData.glName,
              value: lineItem.glCodeLineItemData.glCodeId,
              // In invoice get response this comes as "opexLineItem"
              reportingLineDescription:
                lineItem.glCodeLineItemData.opexLineItem,
              description: lineItem.glCodeLineItemData.glCodeDescription
            },
            fromDate: moment(lineItem.invoicePeriodFrom).toDate(),
            toDate: moment(lineItem.invoicePeriodTo).toDate(),
            openingBalance: lineItem.openingBalance,
            prepaymentJournalSchedule:
              lineItem.prepaymentJournalSchedule || "MONTHLY",
            showAllAccruals: lineItem.showAllAccruals || false,
            journalPeriodRange: lineItem.invoicePeriodTo
              ? [
                  moment(lineItem.invoicePeriodFrom).toDate(),
                  moment(lineItem.invoicePeriodTo).toDate()
                ]
              : [null, null],
            // If prepayment api returns error for selected journal period, disable save
            prepaymentError: false,
            totalAccruedAmountError: "",
            totalAccruedAmount: lineItem.totalAccruedAmount || null
          };
        })
      };
    }
  }

  if (type === actionTypes.UPDATE_LINEITEM_DATA) {
    const { invoiceDetails } = payload;
    const invoiceLineItems = invoiceDetails.invoiceBudgetLineItems.map(
      (i) => i.invoiceLineItem
    );
    return {
      ...state,
      lineItems: state.lineItems.map((lineItem) => {
        const foundInvoiceLineItem = invoiceLineItems.find(
          (i) => i.id === lineItem.invoiceLineItemId
        );
        return {
          ...lineItem,
          ...foundInvoiceLineItem,
          invoiceLineItem: foundInvoiceLineItem
        };
      })
    };
  }

  if (type === actionTypes.CHANGE_SHOULD_UPDATE_BUDGET) {
    return {
      ...state,
      isUpdateBudgetNeeded: payload
    };
  }

  if (type === actionTypes.TOGGLE_PREPAYMENT_ERROR) {
    const { lineItemId, prepaymentError } = payload;

    return {
      ...state,
      lineItems: updateLineItemValue(lineItemId, {
        prepaymentError
      })
    };
  }

  if (type === actionTypes.UPDATED_BUDGET_LINE_ITEM) {
    const { lineItemId, budgetLineItem } = payload;
    return {
      ...state,
      lineItems: updateLineItemValue(lineItemId, {
        budgetLineItem
      })
    };
  }

  if (type === actionTypes.UPDATE_PREPAYMENT_JOURNAL_SCHEDULE) {
    const { lineItemId, prepaymentJournalSchedule } = payload;

    return {
      ...state,
      lineItems: updateLineItemValue(lineItemId, {
        prepaymentJournalSchedule: prepaymentJournalSchedule
      })
    };
  }

  if (type === actionTypes.UPDATED_PO_NUMBER) {
    const { lineItemId, poNumber } = payload;
    return {
      ...state,
      lineItems: updateLineItemValue(lineItemId, { poNumber })
    };
  }

  if (type === actionTypes.CHANGE_PO_INVOICE_SCHEDULED) {
    const { lineItemId, poInvoiceScheduleId, poInvoiceLineItemId } = payload;
    return {
      ...state,
      lineItems: updateLineItemValue(lineItemId, {
        poInvoiceScheduleId,
        poInvoiceLineItemId
      })
    };
  }

  if (type === actionTypes.UPDATE_PO_AVAILABLE_AMOUNT) {
    const { lineItemId, poAvailableAmount } = payload;
    return {
      ...state,
      lineItems: updateLineItemValue(lineItemId, { poAvailableAmount })
    };
  }

  if (type === actionTypes.UPDATED_GL_ACCOUNT_CODE) {
    const { lineItemId, glAccountCode } = payload;
    return {
      ...state,
      lineItems: updateLineItemValue(lineItemId, { glAccountCode })
    };
  }

  if (type === actionTypes.UPDATE_LINEITEM_ACCRUED_AMOUNT) {
    const { lineItemId, totalAccruedAmount, totalAccruedAmountError } = payload;
    return {
      ...state,
      lineItems: updateLineItemValue(lineItemId, {
        totalAccruedAmount,
        totalAccruedAmountError
      })
    };
  }

  if (type === actionTypes.UPDATE_LINEITEM_OPENING_BALANCE) {
    const { lineItemId, openingBalance } = payload;
    return {
      ...state,
      lineItems: updateLineItemValue(lineItemId, {
        openingBalance
      })
    };
  }

  if (type === actionTypes.LINE_ITEM_API_ERRORED) {
    const { lineItemId, apiErrors } = payload;
    return {
      ...state,
      lineItems: updateLineItemValue(lineItemId, { apiErrors })
    };
  }

  if (type === actionTypes.UPDATE_BUDGETING_OPTION) {
    const { lineItemId, budgetingOption } = payload;
    return {
      ...state,
      lineItems: updateLineItemValue(lineItemId, { budgetingOption })
    };
  }

  if (type === actionTypes.UPDATE_JOURNAL_RANGE) {
    const { lineItemId, journalPeriodRange } = payload;
    return {
      ...state,
      lineItems: updateLineItemValue(lineItemId, {
        journalPeriodRange
      })
    };
  }

  if (
    type ===
    actionTypes.UPDATE_WITHHOLDING_TAXES_FROM_TAX_LIST_API_AND_INVOICE_DATA
  ) {
    return {
      ...state,
      withholdingTaxes: payload.whtTaxList.map((taxItem) => ({
        id: taxItem.id,
        isSelected:
          payload.invoiceDetails.withholdingTaxes.findIndex(
            (i) => i.id === taxItem.id
          ) === -1
            ? false
            : true,
        percentage: {
          value: payload.invoiceDetails.withholdingTaxes.find(
            (i) => i.id === taxItem.id
          )
            ? payload.invoiceDetails.withholdingTaxes.find(
                (i) => i.id === taxItem.id
              ).percentage
            : taxItem.taxRate,
          validationErrors: ""
        },
        name: taxItem.taxName
      }))
    };
  }

  if (type === actionTypes.WITHHOLDING_TAXES_UPDATE) {
    const withholdingTaxes = [...state.withholdingTaxes];
    const foundTaxItem = withholdingTaxes.find((i) => i.id === payload.id);
    foundTaxItem.percentage = {
      value: payload.percentage.value,
      validationErrors: payload.percentage.validationErrors
    };
    foundTaxItem.isSelected = payload.isSelected;
    return {
      ...state,
      withholdingTaxes
    };
  }

  if (type === actionTypes.OTHER_DEDUCTIONS_UPDATE) {
    return {
      ...state,
      otherDeductions: {
        ...state.otherDeductions,
        ...payload
      }
    };
  }

  if (type === actionTypes.TOGGLE_LINEITEM_CARD) {
    if (payload.lineItemId === state.openedLineItemId) {
      return {
        ...state,
        openedLineItemId: ""
      };
    }
    return {
      ...state,
      openedLineItemId: payload.lineItemId
    };
  }

  if (type === actionTypes.SET_INVOICE_SAVED_MESSAGE) {
    const { userAction, message } = payload;
    let invoiceSavedMessage = "";
    if (userAction === InvoiceStatuses.AWAITING_APPROVAL) {
      invoiceSavedMessage =
        Labels.Payments.Invoices.Details.InvoiceSavedSendForApprovalMessage;
    } else if (userAction === InvoiceStatuses.AWAITING_PAYMENT) {
      invoiceSavedMessage =
        Labels.Payments.Invoices.Details.InvoiceSavedAndApprovedMessage;
    } else if (userAction === InvoiceActions.voidCreateDraftInvoice) {
      invoiceSavedMessage = message;
    } else {
      invoiceSavedMessage =
        Labels.Payments.Invoices.Details.InvoiceSavedMessage;
    }

    return {
      ...state,
      invoiceSavedMessage
    };
  }

  if (type === actionTypes.RESET_INVOICE_SAVED_MESSAGE) {
    return {
      ...state,
      invoiceSavedMessage: ""
    };
  }

  return state;
}
