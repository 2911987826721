import React from "react";
import { Labels } from "../constants/Constants";

const PostingPeriodInfoMessage = ({
  postingPeriod,
  paymentsVoidMsg,
  transactionUnreconcileMsg,
  message = Labels.PostingPeriods.postingPeriodVoidMessage // Primarily this info message is shown for void action, so if no message is given then show the message of void action
}) => {
  return (
    <div>
      <p className="mt-2 pt-1">
        <span className="row-header-2">{Labels.CommonModals.note}</span>
        {message}
        {postingPeriod ? postingPeriod + ". " : "-"}
        {paymentsVoidMsg || ""}
        {transactionUnreconcileMsg || ""}
      </p>
    </div>
  );
};

export default PostingPeriodInfoMessage;
