import React, {useState} from "react";
import {
    Alert,
    Button,
    Col,
    Input,
    InputGroup,
    InputGroupText,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import {Labels} from "../../../../../constants/Constants";
import {useMutation, useQueryClient} from "react-query";
import {httpRequest} from "../../../../../utils/http/httpRequest";
import {bankAccountDetails} from "../../../../../utils/http/endpoints";
import QueryKeys from "../../../../../constants/QueryKeys";
import {BIC, IBAN} from "ibankit";
import DisplayAlerts from "../../../../../elements/DisplayAlerts";
import useGetAssetById from "../../../../../hooks/useGetAssetById";
import {SelectBox} from "../../../../../elements/select/Select";
import NiceModal, {useModal} from "@ebay/nice-modal-react";

export default NiceModal.create(
  ({ bankAcData, assetId, isFormReadOnly, navigationContext }) => {
    const modal = useModal();
    const countries = [
      { id: "Ireland", value: "Ireland", label: "Ireland" },
      {
        id: "Italy",
        value: "Italy",
        label: "Italy"
      },
      { id: "Spain", value: "Spain", label: "Spain" },
      { id: "UK", value: "UK", label: "UK" },
      { id: "Portugal", value: "Portugal", label: "Portugal" }
    ];
    const [country, setCountry] = useState(() => {
      if (bankAcData.country !== null) {
        return countries
          .filter((eachCountry) => eachCountry.value === bankAcData?.country)
          .pop();
      } else {
        return null;
      }
    });
    const [currency, setCurrency] = useState(null);
    const [isFormFieldTouched, setIsFormFieldTouched] = useState(false);
    const assetDataQuery = useGetAssetById({
      assetId: assetId,
      isRecentlyViewedBoolean:
        navigationContext.pageContext === "Under An Asset",
      onSuccess: (data) => {
        if (bankAcData.currency) {
          setCurrency(
            data?.currenciesAvailableForUse?.find(
              (i) => i.value === bankAcData.currency
            )
          );
        } else {
          setCurrency(
            data.currenciesAvailableForUse.find(
              (i) => i.value === data.currency
            )
          );
        }
      }
    });
    const [bankAc, setBankAc] = useState({
      ...bankAcData,
      iban: bankAcData.iban || "",
      bic: bankAcData.bic || "",
      oin: bankAcData.oin || "",
      openingStatementBalance: bankAcData.openingStatementBalance || 0,
      touched: {
        bic: false,
        oin: false,
        iban: false,
        openingStatementBalance: false
      }, // to know if user touched this field
      errors: {
        bic: "",
        oin: "",
        iban: "",
        openingStatementBalance: ""
      }
    });

    const queryClient = useQueryClient();
    const mutation = useMutation(
      () => {
        return httpRequest({
          method: "put",
          data: {
            country: country.value,
            bankAccountCode: bankAc.bankAccountCode,
            iban: bankAc.iban,
            bic: bankAc.bic,
            oin: bankAc.oin,
            currency: currency.value,
            openingStatementBalance: bankAc.openingStatementBalance || 0
          },
          url: bankAccountDetails(assetId)
        })
          .then((res) => Promise.resolve(res.data))
          .catch((err) => Promise.reject(err.data.errors));
      },
      {
        onSuccess: () => {
          queryClient.removeQueries(QueryKeys.getBankAccounts);
          modal.remove();
        }
      }
    );

    const handleChange = (event) => {
      event.target.value = event.target.value.replace(/\s/g, "");
      event.target.checkValidity();
      const value = event.target.value.replace(/\s/g, "");

      if (
        (event.target.name === "iban" && value && !IBAN.isValid(value)) ||
        (event.target.name === "bic" && value && !BIC.isValid(value)) ||
        (event.target.name === "oin" && event.target.validity.patternMismatch)
      ) {
        event.target.setCustomValidity(
          Labels.Assets.Settings[event.target.name + "Error"]
        );
      } else {
        event.target.setCustomValidity("");
      }

      setBankAc({
        ...bankAc,
        [event.target.name]: value.replace(/\s/g, ""),
        touched: {
          ...bankAc.touched,
          [event.target.name]: true
        },
        errors: {
          ...bankAc.errors,
          [event.target.name]: event.target.validationMessage
        }
      });
      setIsFormFieldTouched(true);
    };

    const isFormValid = () => {
      // Errors should be empty and values should be not empty
      return (
        !bankAc.errors.bic &&
        !bankAc.errors.oin &&
        !bankAc.errors.iban &&
        bankAc.bic &&
        bankAc.oin &&
        bankAc.iban &&
        country &&
        currency
      );
    };

    return (
      <div>
        <Modal
          isOpen={modal.visible}
          toggle={modal.remove}
          backdrop="static"
          keyboard={false}
          centered
        >
          <ModalHeader className="pb-0" toggle={modal.remove}>
            <h2 className="color-dark modal-title">
              {isFormReadOnly
                ? Labels.accounting.viewBankAccount
                : Labels.accounting.configureBankAccount}
            </h2>
          </ModalHeader>
          <ModalBody className="pt-0">
            <fieldset disabled={isFormReadOnly}>
              {mutation.isSuccess ? (
                <Alert color="success">
                  {Labels.Assets.Settings.bankACSavedMessage}
                </Alert>
              ) : null}
              <Row className="mt-3">
                <Col sm={12} md={6} className="row-header-2">
                  {Labels.accounting.country}
                </Col>
                <Col className="mt-1 mt-md-0 d-flex flex-column">
                  <div>
                    <SelectBox
                      options={countries}
                      value={country}
                      onChange={(value) => {
                        setCountry(value);
                        setIsFormFieldTouched(true);
                      }}
                      placeholder={Labels.accounting.selectCountry}
                      isDisabled={isFormReadOnly}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm={12} md={6} className="row-header-2">
                  {Labels.Assets.Settings.oin}
                </Col>
                <Col className="mt-1 mt-md-0 d-flex flex-column">
                  <div>
                    <Input
                      type="text"
                      name="oin"
                      placeholder="OIN"
                      required={bankAc.touched.oin}
                      pattern="^[0-9A-Za-z]+$"
                      onChange={handleChange}
                      value={bankAc.oin}
                    />
                  </div>
                  <div>
                    <span className="text-danger error-message error-message">
                      {bankAc.errors.oin}
                    </span>
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm={12} md={6} className="row-header-2">
                  {Labels.Assets.Settings.iban}
                </Col>
                <Col className="mt-1 mt-md-0 d-flex flex-column">
                  <div>
                    <Input
                      type="text"
                      name="iban"
                      required={bankAc.touched.iban}
                      placeholder="IBAN"
                      onChange={handleChange}
                      value={bankAc.iban}
                    />
                  </div>
                  <div>
                    <span className="text-danger error-message text-wrap">
                      {bankAc.errors.iban}
                    </span>
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm={12} md={6} className="row-header-2">
                  {Labels.Assets.Settings.bic}
                </Col>
                <Col className="mt-1 mt-md-0 d-flex flex-column">
                  <div>
                    <Input
                      type="text"
                      name="bic"
                      placeholder="BIC"
                      required={bankAc.touched.bic}
                      onChange={handleChange}
                      value={bankAc.bic}
                    />
                  </div>
                  <div>
                    <span className="text-danger error-message text-wrap">
                      {bankAc.errors.bic}
                    </span>
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm={12} md={6} className="row-header-2">
                  {Labels.Assets.Settings.currency}
                </Col>
                <Col className="mt-1 mt-md-0 d-flex flex-column">
                  <div>
                    <SelectBox
                      options={assetDataQuery.data?.currenciesAvailableForUse}
                      isLoading={assetDataQuery.isLoading}
                      value={currency}
                      onChange={(value) => {
                        setCurrency(value);
                        setIsFormFieldTouched(true);
                      }}
                      placeholder={Labels.Assets.Settings.currency}
                      isDisabled={
                        assetDataQuery.isLoading ||
                        bankAcData?.currency ||
                        isFormReadOnly
                      }
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm={12} md={6} className="row-header-2">
                  {Labels.Assets.Settings.openingStatementBalance}
                </Col>
                <Col className="mt-1 mt-md-0 d-flex flex-column">
                  {bankAcData.isImported ? (
                    <>
                      <div>
                        <InputGroup className="input-group-text-currency">
                          <InputGroupText className="body-primary left-bordered-label">
                            {currency?.symbol || "-"}
                          </InputGroupText>
                          <Input
                            type="number"
                            name={"openingStatementBalance"}
                            placeholder={
                              Labels.Assets.Settings.openingStatementBalance
                            }
                            required={bankAc.touched.openingStatementBalance}
                            onChange={handleChange}
                            disabled={bankAcData?.isConfigured}
                            value={bankAc.openingStatementBalance}
                            min={0}
                            step={0.01}
                            className="form-control "
                          />
                        </InputGroup>
                      </div>
                      <div>
                        <span className="text-danger error-message text-wrap">
                          {bankAc.errors.openingStatementBalance}
                        </span>
                      </div>
                    </>
                  ) : (
                    <div className="text-right body-primary color-text-primary">
                      <span>{currency?.symbol || ""}0</span>
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  {mutation.isError ? (
                    <DisplayAlerts alerts={mutation.error} type="danger" />
                  ) : null}
                </Col>
              </Row>
            </fieldset>
          </ModalBody>
          <ModalFooter>
            {!isFormReadOnly && (
              <Row className="no-gutters">
                <Col sm={12} md="auto" className="mr-2">
                  <Button
                    color="primary"
                    className="btn btn-primary"
                    type="button"
                    disabled={
                      mutation.isLoading ||
                      !isFormValid() ||
                      !isFormFieldTouched
                    }
                    onClick={() => mutation.mutate()}
                  >
                    {Labels.CommonModals.save}
                  </Button>
                </Col>
                <Col sm={12} md="auto">
                  <Button
                    color="secondary"
                    onClick={modal.remove}
                    className="btn btn-secondary"
                    type="button"
                  >
                    {Labels.CommonModals.cancel}
                  </Button>
                </Col>
              </Row>
            )}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
);
