import Handsontable from "handsontable";
import React, { useState, useEffect, useRef } from "react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import {
  Modal,
  ModalBody,
  Row,
  Col,
  Label,
  Alert,
  ModalHeader,
  FormGroup,
  Input
} from "reactstrap";
import { HotColumn, HotTable } from "@handsontable/react";
import { Labels } from "../../../../constants/Constants";
import IncurredAmountsColumn from "./IncurredAmountsColumn";
import DisplayAlerts from "../../../../elements/DisplayAlerts";
import { httpRequest } from "../../../../utils/http/httpRequest";
import { viewBudgetData } from "../../../../utils/http/endpoints";
import { SelectBox as Select } from "../../../../elements/select/Select";
import { formatAmountWithThousandSeparatorsToPrecision } from "../../../../utils/number";
import IncurredAmountsDrilldownRoutingColumn from "./IncurredAmountsDrilldownRoutingColumn";

export const budgetPeriods = [
  {
    label: Labels.Assets.PurchaseOrders.ytd,
    value: Labels.Assets.PurchaseOrders.ytd
  },
  {
    label: Labels.Assets.PurchaseOrders.mtd,
    value: Labels.Assets.PurchaseOrders.mtd
  }
];

export default NiceModal.create(({ orgId, assetId, poId, path, invoices }) => {
  const modal = useModal();
  const [errors, setErrors] = useState([]);
  const [period, setPeriod] = useState(budgetPeriods[0]);
  const [tableData, setTableData] = useState(null);
  const [isMainTableHidden, setIsMainTableHidden] = useState(false);
  const [columDataProp, setColumDataProp] = useState(""); // for main table 1st column data
  const [amountDrilldownData, setAmountDrilldownData] = useState([]);
  const [amountDrilldownTitle, setAmountDrilldownTitle] = useState("");
  const hideColumnsRef = useRef(null);
  const hotTableComponentRef = useRef(null);
  const [isDetailedViewEnabled, setIsDetailedViewEnabled] = useState(false);

  const reportDataSchema = {
    lineItemDescription: null,
    reportingLineItemDescription: null,
    budgetLineItemDescription: null,
    glCode: null,
    isBudgetContracted: null,
    amount: null,
    availableAmountYTD: null,
    availableAmountMTD: null,
    budgetAmountYTD: null,
    budgetAmountMTD: null,
    incurredOpeningBalance: null,
    approvedPOsAmountYTD: null,
    approvedPOsAmountMTD: null,
    invoicedAmountYTD: null,
    invoicedAmountMTD: null,
    bankReconTransactionsAmountYTD: null,
    bankReconTransactionsAmountMTD: null,
    totalConsumedAmountYTD: null,
    totalConsumedAmountMTD: null,
    isLineItemOverBudget: true
  };

  const amountDrilldownSchema = {
    poId: null,
    invoiceId: null,
    transactionId: null,
    poNumber: null,
    invoiceNumber: null,
    date: null,
    incurredAmount: null
  };

  const hideColumnsSwitchChange = (event) => {
    setIsDetailedViewEnabled(event.target.checked);
    const plugin =
      hotTableComponentRef.current.hotInstance.getPlugin("hiddenColumns");
    if (event.target.checked) {
      plugin.showColumns([2, 3, 4, 5]);
    } else {
      plugin.hideColumns([2, 3, 4, 5]);
    }
    setTimeout(() => {
      const data = hotTableComponentRef.current.hotInstance.getSourceData();
      hotTableComponentRef.current.hotInstance.updateData(data);
    }, 50);
  };

  useEffect(() => {
    const invoiceScheduleList = invoices.map((invoice) => {
      return {
        id: invoice.id,
        lineItems: invoice.lineItems
          .map((lineItem) => {
            return !!lineItem.budgetLineItem &&
              !!lineItem.netAmount &&
              !!lineItem.productDescription
              ? {
                  budgetLineItemDescription:
                    lineItem.budgetLineItem.budgetLineItem,
                  budgetLineItemId: lineItem.budgetLineItem.id,
                  glAccountId: lineItem.budgetLineItem.accountId,
                  glCodeDescription: lineItem.budgetLineItem.glCodeDescription,
                  id: lineItem.id,
                  netAmount: parseFloat(lineItem.netAmount),
                  productDescription: lineItem.productDescription
                }
              : !!lineItem.glCode &&
                !!lineItem.netAmount &&
                !!lineItem.productDescription
              ? {
                  budgetLineItemDescription: null,
                  budgetLineItemId: null,
                  glAccountId: lineItem.glCode.id,
                  glCodeDescription: lineItem.glCode.glName,
                  id: lineItem.id,
                  netAmount: parseFloat(lineItem.netAmount),
                  productDescription: lineItem.productDescription
                }
              : null;
          })
          .filter((lineItem) => !!lineItem)
      };
    });

    httpRequest({
      method: "post",
      url: viewBudgetData(assetId, poId),
      data: { invoiceScheduleList }
    })
      .then((response) => {
        if (response.status === 200 && response.data.length > 0) {
          const lineItemdsData = response.data.reduce(
            (accu, currObj, index) => {
              const updatedLineItemsData = currObj.poLineItemBudgetViewList.map(
                (lineItem) => {
                  return {
                    ...lineItem,
                    invoiceId: `Invoice ${index + 1}`
                  };
                }
              );
              return accu.concat(updatedLineItemsData);
            },
            []
          );

          const tableData = lineItemdsData.reduce((accu, currObj) => {
            const isDuplicateInvoiceId = !!accu.find(
              (obj) => obj.invoiceId === currObj.invoiceId
            )?.invoiceId;

            const isDuplicateLineItemData = !!accu.find(
              (obj) => obj.id === currObj.id
            )?.lineItemDescription;

            const updatedObj = {
              ...currObj,
              updatedLineItemDescription: isDuplicateLineItemData
                ? ""
                : currObj.lineItemDescription,
              updatedReportingLineItemDescription: isDuplicateLineItemData
                ? ""
                : currObj.reportingLineItemDescription,
              updatedInvoiceId: isDuplicateInvoiceId ? "" : currObj.invoiceId,
              glCode: currObj.glCode + " - " + currObj.glName,
              budgetLineItemDescription: !!currObj.budgetLineItemDescription
                ? currObj.budgetLineItemDescription
                : "-",
              isBudgetContracted: !!currObj.budgetLineItemDescription
                ? currObj.isBudgetContracted
                  ? "Contracted"
                  : "Budgeted"
                : "-"
            };

            return accu.concat(updatedObj);
          }, []);
          setTableData(tableData);
        }
      })
      .catch((error) => {
        setErrors(errors.concat(error.data.errors));
        setTableData([]);
      });
  }, []);

  const formatAmounts = (value, handleNegativeAmounts) => {
    if (typeof value === "number") {
      return formatAmountWithThousandSeparatorsToPrecision(
        value,
        handleNegativeAmounts
      );
    } else {
      return value;
    }
  };

  function numericValueRenderer(
    instance,
    td,
    row,
    col,
    prop,
    value,
    cellProperties
  ) {
    Handsontable.renderers.NumericRenderer.apply(this, arguments);
    td.innerHTML = formatAmounts(value, true);
  }
  Handsontable.renderers.registerRenderer(
    "numericValueRenderer",
    numericValueRenderer
  );

  function cellRenderer(row, col) {
    const cellProperties = {};

    if (col > 8) {
      this.instance.setCellMeta(
        row,
        col,
        "className",
        "color-text-primary fw-500"
      );
    }

    const rowsCount = this.instance.countRows();
    if (col >= 0 && col < 3) {
      if (
        row === 0 &&
        !!this.instance.getDataAtCell(row, col) &&
        !this.instance.getDataAtCell(row + 1, col)
      ) {
        this.instance.setCellMeta(row, col, "className", "border-bottom-0");
      } else if (!!this.instance.getDataAtCell(row + 1, col)) {
      } else if (
        row !== rowsCount - 1 &&
        (!this.instance.getDataAtCell(row + 1, col) ||
          !this.instance.getDataAtCell(row, col))
      ) {
        this.instance.setCellMeta(row, col, "className", "border-bottom-0");
      }
    }

    if (
      [
        "amount",
        "incurredOpeningBalance",
        "budgetAmountYTD",
        "budgetAmountMTD",
        "availableAmountYTD",
        "availableAmountMTD",
        "totalConsumedAmountYTD",
        "totalConsumedAmountMTD"
      ].includes(this.instance.colToProp(col))
    ) {
      cellProperties.renderer = "numericValueRenderer";
    }

    return cellProperties;
  }

  function amountDrilldownCellRenderer(row, col) {
    const cellProperties = {};
    if (this.instance.colToProp(col) === "incurredAmount") {
      cellProperties.renderer = "numericValueRenderer";
    }
    return cellProperties;
  }

  const getColumnTooltipContent = (columnName) => {
    switch (true) {
      case columnName === Labels.Assets.PurchaseOrders.lineItemAmount:
        return Labels.Assets.PurchaseOrders.lineItemAmountInfoMsg;
      case columnName === Labels.Assets.PurchaseOrders.budgeted:
        return {
          title: Labels.Assets.PurchaseOrders.budgetedInfoMsg,
          formulaText: "(A)"
        };
      case columnName === Labels.Assets.PurchaseOrders.incurredOpeningBalance:
        return {
          title: Labels.Assets.PurchaseOrders.incurredOpeningBalanceInfoMsg,
          formulaText: "(B)"
        };
      case columnName === Labels.Assets.PurchaseOrders.approvedPOs &&
        period.value === Labels.Assets.PurchaseOrders.ytd:
        return {
          title: Labels.Assets.PurchaseOrders.approvedPOsYtdInfoMsg,
          formulaText: "(C)"
        };
      case columnName === Labels.Assets.PurchaseOrders.approvedPOs &&
        period.value === Labels.Assets.PurchaseOrders.mtd:
        return {
          title: Labels.Assets.PurchaseOrders.approvedPOsMtdInfoMsg,
          formulaText: "(C)"
        };
      case columnName === Labels.Assets.PurchaseOrders.approvedPurchases &&
        period.value === Labels.Assets.PurchaseOrders.ytd:
        return {
          title: Labels.Assets.PurchaseOrders.approvedPurchasesYtdInfoMsg,
          formulaText: "(D)"
        };
      case columnName === Labels.Assets.PurchaseOrders.approvedPurchases &&
        period.value === Labels.Assets.PurchaseOrders.mtd:
        return {
          title: Labels.Assets.PurchaseOrders.approvedPurchasesMtdInfoMsg,
          formulaText: "(D)"
        };
      case columnName ===
        Labels.Assets.PurchaseOrders.bankReconciliationTransactions &&
        period.value === Labels.Assets.PurchaseOrders.ytd:
        return {
          title:
            Labels.Assets.PurchaseOrders
              .bankReconciliationTransactionsYtdInfoMsg,
          formulaText: "(E)"
        };
      case columnName ===
        Labels.Assets.PurchaseOrders.bankReconciliationTransactions &&
        period.value === Labels.Assets.PurchaseOrders.mtd:
        return {
          title:
            Labels.Assets.PurchaseOrders
              .bankReconciliationTransactionsMtdInfoMsg,
          formulaText: "(E)"
        };
      case columnName === Labels.Assets.PurchaseOrders.totalConsumed:
        return {
          title: Labels.Assets.PurchaseOrders.totalConsumedInfoMsg,
          formulaText: "(F) = (B+C+D+E)"
        };
      case columnName === Labels.Assets.PurchaseOrders.available:
        return {
          title: "",
          formulaText: " = (A-F)"
        };
    }
  };

  function handleAfterGetColHeader(col, TH) {
    if (col === 7) {
      TH.className += " budgeted-column";
    } else if (col > 7 && col < 12) {
      TH.className += " incurred-column";
    } else if (col === 12) {
      TH.className += " total-consumed-column";
    } else if (col === 13) {
      TH.className += " available-column";
    }

    let colHeader = TH.querySelector(".colHeader");
    if (
      [
        Labels.Assets.PurchaseOrders.budgeted,
        Labels.Assets.PurchaseOrders.incurredOpeningBalance,
        Labels.Assets.PurchaseOrders.approvedPOs,
        Labels.Assets.PurchaseOrders.approvedPurchases,
        Labels.Assets.PurchaseOrders.bankReconciliationTransactions,
        Labels.Assets.PurchaseOrders.totalConsumed,
        Labels.Assets.PurchaseOrders.available
      ].includes(TH.textContent)
    ) {
      colHeader.innerHTML += `${
        getColumnTooltipContent(TH.textContent).formulaText
      } <i class="fas fa-info-circle color-primary py-1" title="${
        getColumnTooltipContent(TH.textContent).title
      }"></i>`;
      TH.className += " text-right";
    } else if (
      [Labels.Assets.PurchaseOrders.lineItemAmount].includes(TH.textContent)
    ) {
      colHeader.innerHTML += ` <i class="fas fa-info-circle color-primary" title="${getColumnTooltipContent(
        TH.textContent
      )}"></i>`;
      TH.className += " text-right";
    }
  }

  return (
    <Modal
      isOpen={modal.visible}
      className={
        !isMainTableHidden ? "m-5 mb-0" : "d-flex justify-content-center mt-5"
      }
      style={!isMainTableHidden ? { maxWidth: "unset" } : null}
      size={isMainTableHidden ? "lg" : null}
      backdrop="static"
      keyboard={false}
    >
      <ModalHeader className="pb-0" toggle={modal.remove}>
        <div>
          <h2 className="color-dark modal-title">
            {!isMainTableHidden ? "Budget" : amountDrilldownTitle}
          </h2>
        </div>
      </ModalHeader>
      <ModalBody className="py-2 purchase-order-budget-table">
        <DisplayAlerts className="mt-0" type="danger" alerts={errors} />
        {errors.length === 0 &&
        Array.isArray(tableData) &&
        !isMainTableHidden ? (
          <Row className="mb-3">
            <Col xs={6} md className="d-flex">
              <Label
                for="period"
                className="row-header-2 font-weight-bold my-auto mr-2"
              >
                {Labels.Assets.PurchaseOrders.period}
              </Label>
              <div>
                <Select
                  className="period-select-box z-index-1030"
                  onChange={(option) => {
                    setPeriod(option);
                  }}
                  value={period}
                  options={budgetPeriods}
                />
              </div>
            </Col>
            <Col xs={6} className="d-flex justify-content-end">
              <FormGroup check className="custom-control custom-switch my-auto">
                <Input
                  type="checkbox"
                  id="switch1"
                  className="custom-control-input"
                  innerRef={hideColumnsRef}
                  checked={isDetailedViewEnabled}
                  onChange={hideColumnsSwitchChange}
                />
                <Label check for="switch1" className="custom-control-label">
                  {Labels.Assets.PurchaseOrders.viewDetails}
                </Label>
              </FormGroup>
            </Col>
          </Row>
        ) : null}
        {errors.length === 0 &&
        Array.isArray(tableData) &&
        tableData.length > 0 &&
        !isMainTableHidden ? (
          <div className="pb-3">
            <HotTable
              data={tableData}
              ref={hotTableComponentRef}
              dataSchema={reportDataSchema}
              height={"auto"}
              hiddenColumns={{
                columns: isDetailedViewEnabled ? [] : [2, 3, 4, 5],
                indicators: false
              }}
              stretchH={"all"}
              fillHandle={false}
              autoRowSize={false}
              cells={cellRenderer}
              autoColumnSize={false}
              manualColumnResize={false}
              disableVisualSelection={true}
              className="purchase-order-budget-table"
              afterGetColHeader={handleAfterGetColHeader}
              licenseKey={process.env.REACT_APP_HOT_TABLE_LICENSE_KEY}
            >
              <HotColumn
                title={Labels.Assets.PurchaseOrders.estimatedInvoice}
                data="updatedInvoiceId"
                readOnly={true}
                width={130}
              />

              <HotColumn
                title={Labels.Assets.PurchaseOrders.lineItemDescription}
                data="updatedLineItemDescription"
                readOnly={true}
                width={180}
              />

              <HotColumn
                title={Labels.Assets.PurchaseOrders.reportingLineItem}
                data="updatedReportingLineItemDescription"
                readOnly={true}
                width={180}
              />

              <HotColumn
                title={Labels.Assets.PurchaseOrders.budgetLineItem}
                data="budgetLineItemDescription"
                readOnly={true}
                width={180}
              />
              <HotColumn
                title={Labels.Assets.PurchaseOrders.glName}
                data="glCode"
                readOnly={true}
                width={180}
              />

              <HotColumn
                title={Labels.Assets.PurchaseOrders.contractedOrBudgted}
                data="isBudgetContracted"
                readOnly={true}
                width={130}
              />

              <HotColumn
                title={Labels.Assets.PurchaseOrders.lineItemAmount}
                data="amount"
                type="numeric"
                readOnly={true}
                width={130}
              />

              <HotColumn
                title={Labels.Assets.PurchaseOrders.budgeted}
                data={
                  period.value === Labels.Assets.PurchaseOrders.ytd
                    ? "budgetAmountYTD"
                    : "budgetAmountMTD"
                }
                readOnly={true}
                type="numeric"
                width={130}
              />

              <HotColumn
                title={Labels.Assets.PurchaseOrders.incurredOpeningBalance}
                data="incurredOpeningBalance"
                readOnly={true}
                type="numeric"
                width={130}
              />

              <HotColumn
                title={Labels.Assets.PurchaseOrders.approvedPOs}
                data={
                  period.value === Labels.Assets.PurchaseOrders.ytd
                    ? "approvedPOsAmountYTD"
                    : "approvedPOsAmountMTD"
                }
                type="numeric"
                readOnly={true}
                width={130}
              >
                <IncurredAmountsColumn
                  setIsMainTableHidden={setIsMainTableHidden}
                  setAmountDrilldownData={setAmountDrilldownData}
                  setColumDataProp={setColumDataProp}
                  setAmountDrilldownTitle={setAmountDrilldownTitle}
                  hot-renderer
                />
              </HotColumn>

              <HotColumn
                title={Labels.Assets.PurchaseOrders.approvedPurchases}
                data={
                  period.value === Labels.Assets.PurchaseOrders.ytd
                    ? "invoicedAmountYTD"
                    : "invoicedAmountMTD"
                }
                readOnly={true}
                type="numeric"
                width={130}
              >
                <IncurredAmountsColumn
                  setIsMainTableHidden={setIsMainTableHidden}
                  setAmountDrilldownData={setAmountDrilldownData}
                  setColumDataProp={setColumDataProp}
                  setAmountDrilldownTitle={setAmountDrilldownTitle}
                  hot-renderer
                />
              </HotColumn>

              <HotColumn
                title={
                  Labels.Assets.PurchaseOrders.bankReconciliationTransactions
                }
                data={
                  period.value === Labels.Assets.PurchaseOrders.ytd
                    ? "bankReconTransactionsAmountYTD"
                    : "bankReconTransactionsAmountMTD"
                }
                readOnly={true}
                type="numeric"
                width={130}
              >
                <IncurredAmountsColumn
                  setIsMainTableHidden={setIsMainTableHidden}
                  setAmountDrilldownData={setAmountDrilldownData}
                  setColumDataProp={setColumDataProp}
                  setAmountDrilldownTitle={setAmountDrilldownTitle}
                  hot-renderer
                />
              </HotColumn>

              <HotColumn
                title={Labels.Assets.PurchaseOrders.totalConsumed}
                data={
                  period.value === Labels.Assets.PurchaseOrders.ytd
                    ? "totalConsumedAmountYTD"
                    : "totalConsumedAmountMTD"
                }
                readOnly={true}
                type="numeric"
                width={130}
              />

              <HotColumn
                title={Labels.Assets.PurchaseOrders.available}
                data={
                  period.value === Labels.Assets.PurchaseOrders.ytd
                    ? "availableAmountYTD"
                    : "availableAmountMTD"
                }
                readOnly={true}
                type="numeric"
                width={130}
              />
            </HotTable>
          </div>
        ) : null}
        {isMainTableHidden ? (
          <div className="mb-2">
            <a
              href="#"
              className="back-link"
              onClick={(event) => {
                event.preventDefault();
                setIsMainTableHidden(false);
              }}
            >
              &nbsp;&nbsp;
              {Labels.CommonModals.back}
            </a>
          </div>
        ) : null}
        {isMainTableHidden && amountDrilldownData.length === 0 ? (
          <Alert
            color="info"
            className="mt-2"
            isOpen={amountDrilldownData.length === 0}
          >
            {Labels.Assets.PurchaseOrders.noDetailsAvailable}
          </Alert>
        ) : null}
        {isMainTableHidden && amountDrilldownData.length > 0 ? (
          <div>
            <HotTable
              data={amountDrilldownData}
              dataSchema={amountDrilldownSchema}
              width={"635"}
              stretchH={"last"}
              height={"80vh"}
              fillHandle={false}
              autoRowSize={false}
              autoColumnSize={false}
              manualColumnResize={false}
              disableVisualSelection={true}
              cells={amountDrilldownCellRenderer}
              afterGetColHeader={handleAfterGetColHeader}
              className="purchase-order-budget-amount-table mb-2"
              licenseKey={process.env.REACT_APP_HOT_TABLE_LICENSE_KEY}
            >
              <HotColumn
                title={
                  columDataProp === "poNumber"
                    ? Labels.Assets.PurchaseOrders.purchaseOrderNumber
                    : columDataProp === "invoiceNumber"
                    ? Labels.Assets.PurchaseOrders.invoiceNumber
                    : Labels.Assets.PurchaseOrders.transactionDate
                }
                type="text"
                data={columDataProp}
                readOnly={true}
                width={450}
              >
                <IncurredAmountsDrilldownRoutingColumn
                  orgId={orgId}
                  assetId={assetId}
                  path={path}
                  hot-renderer
                />
              </HotColumn>

              <HotColumn
                title={Labels.Assets.PurchaseOrders.incurredAmount}
                type="numeric"
                data="incurredAmount"
                readOnly={true}
              />
            </HotTable>
          </div>
        ) : null}
      </ModalBody>
    </Modal>
  );
});
