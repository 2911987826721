import moment from "moment";
import Handsontable from "handsontable";
import { Modal, ModalHeader, ModalBody, Row, Col } from "reactstrap";
import { HotColumn, HotTable } from "@handsontable/react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Labels } from "../../../../../constants/Constants";
import { getSymbolFromCurrencyCode } from "../../../../../utils/currency/currency";
import { formatAmountWithThousandSeparatorsToPrecision } from "../../../../../utils/number";
import { toReadableString } from "../../../../../utils/string";

export default NiceModal.create(({ data, orgId, assetId }) => {
  // Use a hook to manage the modal state
  const modal = useModal();

  const drillDownSchema = {
    date: null,
    fxRate: null,
    balanceAmount: null,
    fxRevaluation: null,
    runningTotal: null
  };

  let readOnlySetting = {
    readOnly: true
  };

  Handsontable.renderers.registerRenderer(
    "dateValueRenderer",
    dateValueRenderer
  );
  // console.log(data[0].incomeStatementFxDrilldownLineItems);

  function dateValueRenderer(
    instance,
    td,
    row,
    col,
    prop,
    value,
    cellProperties
  ) {
    Handsontable.renderers.DateRenderer.apply(this, arguments);
    if (value !== null) {
      td.innerHTML = moment(value).format("DD MMM YYYY");
    } else {
      td.innerHTML = "";
    }
  }

  function prepaymentsDrillDownRenderer(row, col) {
    const cellProperties = {};
    if (this.instance.colToProp(col) !== "date") {
      cellProperties.renderer = "dateValueRenderer";
    }

    if (this.instance.colToProp(col) !== "date") {
      cellProperties.renderer = "numericValueRenderer";
    }
    return cellProperties;
  }

  const formatAmounts = (value, handleNegativeAmounts) => {
    if (typeof value === "number") {
      return formatAmountWithThousandSeparatorsToPrecision(
        value,
        handleNegativeAmounts
      );
    } else {
      return value;
    }
  };

  function numericValueRenderer(
    instance,
    td,
    row,
    col,
    prop,
    value,
    cellProperties
  ) {
    Handsontable.renderers.NumericRenderer.apply(this, arguments);
    td.innerHTML = formatAmounts(value, true);
  }
  Handsontable.renderers.registerRenderer(
    "numericValueRenderer",
    numericValueRenderer
  );

  let url;

  if (
    ["Purchase Invoice", "Purchase Credit Note"].includes(
      toReadableString(data.instrumentType)
    )
  ) {
    url = `#/org/${orgId}/assets/asset/${assetId}/payruns/invoices/${data.invoiceId}`;
  } else if (
    ["Sales Invoice", "Sales Credit Note", "Contract For Difference"].includes(
      toReadableString(data.instrumentType)
    )
  ) {
    url = `#/org/${orgId}/assets/asset/${assetId}/payruns/sales-invoices/view/${assetId}/${data.invoiceId} `;
  }

  return (
    <Modal
      centered
      keyboard={false}
      backdrop="static"
      fade={false}
      size="xl"
      isOpen={modal.visible}
      toggle={modal.remove}
    >
      <ModalHeader toggle={modal.remove}>
        <h2 className="color-dark modal-title">
          {Labels.Assets.IncomeStatement.summary}
        </h2>
      </ModalHeader>
      <ModalBody className="mt-0 pt-0">
        <div>
          <Row>
            <Col md={4}>
              <div className="mb-3">
                <span className="column-header-1">
                  {Labels.Assets.IncomeStatement.instrumentType}:{" "}
                </span>
                <span className="column-header-2">
                  {toReadableString(data.instrumentType)}
                </span>
              </div>
              <div className="mb-3">
                <span className="column-header-1">
                  {Labels.Assets.IncomeStatement.extRefNo}:{" "}
                </span>

                <span className="column-header-2 cursor-pointer">
                  <a href={url} target={"_blank"} className="link-primary">
                    {data.instrumentRefNo}
                  </a>
                </span>
              </div>
            </Col>
            <Col md={4}>
              <div className="mb-3">
                <span className="column-header-1">
                  {Labels.Assets.IncomeStatement.instrumentDate}:
                </span>
                <span className="column-header-2">{data.invoiceDate}</span>
              </div>
              <div className="mb-3">
                <span className="column-header-1">
                  {Labels.Assets.IncomeStatement.instrumentApproved}:{" "}
                </span>

                <span className="column-header-2">
                  {data.instrumentApprovedDate || "-"}
                </span>
              </div>
            </Col>
            <Col md={4}>
              <div className="mb-3">
                <span className="column-header-1">
                  {Labels.Assets.IncomeStatement.instrumentAmount}:{" "}
                </span>

                <span className="column-header-2">
                  {`${getSymbolFromCurrencyCode(data.instrumentCurrency)}${
                    data.instrumentCurrencyAmount
                  } / ${getSymbolFromCurrencyCode(data.assetCurrency)}${
                    data.baseCurrencyAmount
                  }`}
                </span>
              </div>
              {/* <div className="mb-3">
                <span className="column-header-1">
                  {Labels.Assets.IncomeStatement.instrumentAmount}
                </span>
                :{" "}
                <span className="column-header-2">
                  {getSymbolFromCurrencyCode(data.assetCurrency)}
                  {data.bcAmount}
                </span>
              </div> */}
            </Col>
          </Row>
          <HotTable
            className="unrealised-amount-drilldown-table"
            data={data.incomeStatementFxDrilldownLineItems}
            dataSchema={drillDownSchema}
            fillHandle={false}
            stretchH={"last"}
            height={"600"}
            disableVisualSelection={false}
            manualColumnResize={false}
            licenseKey={process.env.REACT_APP_HOT_TABLE_LICENSE_KEY}
            cells={prepaymentsDrillDownRenderer}
          >
            <HotColumn
              title={Labels.Assets.IncomeStatement.date}
              data="date"
              type="date"
              placeholder={Labels.Assets.IncomeStatement.date}
              settings={readOnlySetting}
              width={235}
            />
            <HotColumn
              title={Labels.Assets.IncomeStatement.fxRate}
              data="fxRate"
              type="numeric"
              settings={readOnlySetting}
              width={200}
            />
            <HotColumn
              title={`${
                Labels.Assets.IncomeStatement.unpaidBalance
              }(${getSymbolFromCurrencyCode(data.assetCurrency)})`}
              data="balanceAmount"
              type="numeric"
              placeholder={Labels.Assets.IncomeStatement.balance}
              settings={readOnlySetting}
              width={235}
            />
            <HotColumn
              title={Labels.Assets.IncomeStatement.fxRevaluation}
              data="fxRevaluation"
              type="numeric"
              settings={readOnlySetting}
              width={200}
            />

            <HotColumn
              title={Labels.Assets.IncomeStatement.runningTotal}
              data="runningTotal"
              type="numeric"
              settings={readOnlySetting}
              width={200}
            />
          </HotTable>
        </div>
      </ModalBody>
    </Modal>
  );
});
