import React from "react";
import { Badge } from "reactstrap";
import { Labels } from "../../../../constants/Constants";
import { formatAmountWithThousandSeparatorsToPrecision } from "../../../../utils/number";
import { getSymbolFromCurrencyCode } from "../../../../utils/currency/currency";
import PurchaseOrderStatuses from "../../../../constants/PurchaseOrderStatuses";
import Tooltip from "../../../../elements/ToolTips/Tooltip";
export class ContactRoutedFrom {
  static orgs = `orgs`;
  static allAssets = `allassets`;
  static assets = `assets`;
  static isOrgsRoute(route) {
    return this.orgs === route;
  }
  static isAllAssetsRoute(route) {
    return this.allAssets === route;
  }
  static isAssetsRoute(route) {
    return this.assets === route;
  }
}

export const ContactStatuses = {
  ACTIVE: { label: "Active", value: "ACTIVE" },
  IN_ACTIVE: { label: "In Active", value: "IN_ACTIVE" }
};

export const CONTACT_TYPES = {
  SUPPLIER: "SUPPLIER",
  CUSTOMER: "CUSTOMER",
  BOTH: "BOTH"
};

export class BankAccountDetails {
  country = null;
  iban = null;
  bic = null;
  bankAccountName = null;
  bankName = null;
  bankAccountNumber = null;
  status = ContactStatuses.ACTIVE.value;
  id = null;
}

export const ContactsListClasses = {
  header: {
    name: "mb-0 d-flex flex-column justify-content-end align-items-start",
    contactPersonName:
      "mb-0 d-flex flex-column justify-content-end align-items-center",
    contactEmail:
      "mb-0 d-flex flex-column justify-content-end align-items-center",
    type: "mb-0 d-flex flex-column justify-content-end align-items-center",
    amountToBePaid:
      "mb-0 d-flex flex-column justify-content-end align-items-end",
    amountToBeReceived:
      "mb-0 d-flex flex-column justify-content-end align-items-end"
  },

  data: {
    name: "d-flex flex-column justify-content-end align-items-start",
    contactPersonName: "text-block-primary text-center",
    contactEmail: "text-block-primary text-center",
    type: "text-block-primary d-flex flex-column justify-content-end align-items-center",
    amountToBePaid: "text-block-primary text-right pr-2",
    amountToBeReceived: "text-block-primary text-right pr-2"
  }
};

export const ContactsActivityClasses = {
  header: {
    assetName:
      "label mb-0 d-flex flex-column justify-content-end align-items-start",
    invoiceNumber:
      "label mb-0 d-flex flex-column justify-content-start align-items-start",
    type: "label mb-0 d-flex flex-column justify-content-center align-items-center",
    status:
      "label mb-0 d-flex flex-column justify-content-end align-items-center",
    invoiceDate:
      "label mb-0 d-flex flex-column justify-content-end align-items-center",
    dueAmount:
      "label mb-0 d-flex flex-column justify-content-end align-items-end",
    grossAmount:
      "label mb-0 d-flex flex-column justify-content-end align-items-end"
  },
  data: {
    assetName:
      "mb-0 d-flex flex-column justify-content-end align-items-start py-2",
    invoiceNumber:
      "mb-0 d-flex flex-column justify-content-start align-items-start py-2",
    type: "mb-0 d-flex flex-column justify-content-center align-items-center py-2",
    status: "mb-0 d-flex flex-column justify-content-end align-items-center",
    invoiceDate:
      "mb-0 d-flex flex-column justify-content-end align-items-center py-2",
    dueAmount:
      "label mb-0 d-flex flex-column justify-content-end align-items-end py-2",
    grossAmount:
      "label mb-0 d-flex flex-column justify-content-end align-items-end py-2"
  }
};

export const ContactsActivityPODetailsClasses = {
  header: {
    assetName:
      "label mb-0 d-flex flex-column justify-content-end align-items-start col-2",
    poNumber:
      "label mb-0 d-flex flex-column justify-content-start align-items-start col-1",
    poApprovedDate:
      "label mb-0 d-flex flex-column justify-content-end align-items-center col-1 px-0",
    poStatus:
      "label mb-0 d-flex flex-column justify-content-end align-items-center col-2",
    receivedAmount:
      "label mb-0 d-flex flex-column justify-content-end align-items-end col-2",
    invoicedAmount:
      "label mb-0 d-flex flex-column justify-content-end align-items-end col-2",
    poAmount:
      "label mb-0 d-inline-flex justify-content-end align-items-center align-content-end"
  },
  data: {
    assetName:
      "m-0 d-flex flex-column justify-content-end align-items-start col-2",
    poNumber:
      "m-0 d-flex flex-column justify-content-start align-items-start col-1",
    poApprovedDate:
      "m-0 d-flex flex-column justify-content-end align-items-center col-1 px-0",
    poStatus:
      "m-0 d-flex flex-column justify-content-end align-items-center col-2",
    receivedAmount:
      "label m-0 d-flex flex-column justify-content-end align-items-end col-2",
    invoicedAmount:
      "label m-0 d-flex flex-column justify-content-end align-items-end col-2",
    poAmount:
      "label m-0 d-inline-flex justify-content-end align-items-center align-content-end"
  }
};

export const ContactActions = {
  viewAndEdit: "Edit"
  //archive: "Archive"
};

export const ContactListTableHeaders = [
  { key: "name", value: Labels.Contacts.contactName, sortEnabled: true },
  { key: "type", value: Labels.Contacts.type, sortEnabled: false },
  {
    key: "contactPersonName",
    value: Labels.Contacts.contactPersonName,
    sortEnabled: true
  },
  {
    key: "contactEmail",
    value: Labels.Contacts.primaryContact.email,
    sortEnabled: false
  },
  {
    key: "amountToBePaid",
    value: Labels.Contacts.amountToBePaid,
    sortEnabled: true,
    hasInfoIcon: false
  },
  {
    key: "amountToBeReceived",
    value: Labels.Contacts.amountToReceived,
    sortEnabled: true,
    hasInfoIcon: false
  }
];

export const getFormattedAmount = (amount, currency = "EUR", id) => {
  const val =
    getSymbolFromCurrencyCode(currency) +
    "" +
    formatAmountWithThousandSeparatorsToPrecision(amount);
  return (
    <>
      <span id={id} className={`${amount < 0 ? "text-danger" : ""}`}>
        {val}
      </span>
      {id && <Tooltip target={id}>{val}</Tooltip>}
    </>
  );
};

export const ContactActivityDetailsHeaders = [
  { key: "assetName", value: "Entity Name", formatter: (val) => val },
  { key: "invoiceNumber", value: "Ref No.", formatter: (val) => val },
  { key: "type", value: "Type", formatter: (val) => val },
  { key: "status", value: "Status", formatter: (val) => val },
  { key: "dueAmount", value: "Due Amount", formatter: getFormattedAmount },
  { key: "grossAmount", value: "Gross Amount", formatter: getFormattedAmount }
];

export const ContactActivityPODetailsHeaders = [
  { key: "assetName", value: "Entity Name", formatter: (val) => val },
  { key: "poNumber", value: "PO No.", formatter: (val) => val },
  { key: "poApprovedDate", value: "Date", formatter: (val) => val },
  {
    key: "poStatus",
    value: "Status",
    formatter: (val) => <Badge className={getStatusColor(val)}>{val}</Badge>
  },
  {
    key: "receivedAmount",
    value: "Received Amount",
    formatter: getFormattedAmount
  },
  {
    key: "invoicedAmount",
    value: "Invoiced Amount",
    formatter: getFormattedAmount
  },
  { key: "poAmount", value: "PO Amount", formatter: getFormattedAmount }
];

const getStatusColor = (status) => {
  switch (status) {
    case PurchaseOrderStatuses.DRAFT:
      return "badge-info";
    case PurchaseOrderStatuses.AWAITING_APPROVAL:
      return "badge-lime";
    case PurchaseOrderStatuses.CANCELLED:
      return "badge-danger";
    case PurchaseOrderStatuses.VOIDED:
      return "badge-disabled";
    case PurchaseOrderStatuses.AWAITING_GRN:
    case PurchaseOrderStatuses.INVOICED:
    case PurchaseOrderStatuses.RECEIPTED:
      return "badge-success";
    default:
      return "badge-info";
  }
};

export const UPDATING_CONTACT_FROM = {
  CONTACT_DETAILS: "CONTACT_DETAILS",
  FIN_DETAILS: "FIN_DETAILS"
};

export const ContactDataModel = {
  name: null,
  type: "",
  externalReferenceCode: null,
  contactPersonName: null,
  contactEmail: null,
  contactPhone: null,
  websiteUrl: null,
  taxId: null,
  defaultCurrencyCode: null,
  assetIds: [],
  status: ContactStatuses.ACTIVE.value,
  address: {
    addressLine1: null,
    addressLine2: null,
    country: null,
    state: null,
    pincode: null
  },
  bankAccountDetails: [
    {
      id: undefined,
      country: null,
      iban: null,
      bic: null,
      bankAccountName: null,
      bankName: null,
      bankAccountNumber: null,
      status: ContactStatuses.ACTIVE.value
    }
  ]
};
