import React, {useState} from "react";
import {Alert, Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Labels, Privileges} from "../../../../../constants/Constants";
import {SelectBox} from "../../../../../elements/select/Select";
import {assignGLCodesToFixedAssetClass} from "../../../../../utils/http/endpoints";
import {useMutation} from "react-query";
import {httpRequest} from "../../../../../utils/http/httpRequest";
import {MultiSelect} from "../../../../../elements/multiselect/MultiSelect";
import NiceModal, {useModal} from "@ebay/nice-modal-react";
import Auth from "../../../../../auth/Auth";
import Tooltip from "../../../../../elements/ToolTips/Tooltip";

export default NiceModal.create(
  ({
    assetId,
    assetClass,
    plCodeQuery,
    balanceSheetCodeQuery,
    glCodeQuery,
    refreshAssetClassesList,
    setIsGlCodeAssignedMsgVisible,
    setIsGlCodeUpdateMsgVisible
  }) => {
    const modal = useModal();
    const [plCodeId, setPlCodeId] = useState();
    const [balanceSheetCodeId, setBalanceSheetCodeId] = useState();
    const [glCodeIds, setGlCodeIds] = useState([]);
    const [apiErrors, setApiErrors] = useState([]);

    const balanceSheetCodeOptions = balanceSheetCodeQuery?.data?.map(
      (account) => {
        const data = { ...account };
        return {
          label: data.label,
          value: data.value
        };
      }
    );

    const plCodeOptions = plCodeQuery?.data?.map((account) => {
      const data = { ...account };
      return {
        label: data.label,
        value: data.value
      };
    });

    const glCodeOptions = glCodeQuery?.data?.map((account) => {
      const data = { ...account };
      return {
        label: data.label,
        value: data.value
      };
    });

    const PLCodeHandler = (option) => {
      setPlCodeId(option);
    };

    const balanceSheetCodeHandler = (option) => {
      setBalanceSheetCodeId(option);
    };

    const glCodeHandler = (values) => {
      setGlCodeIds(values);
    };

    const assignGLCodeMutation = useMutation((data) => {
      httpRequest({
        method: "put",
        url: assignGLCodesToFixedAssetClass(
          assetId,
          assetClass.id,
          data.plCodeId,
          data.balanceSheetCodeId,
          data.glAccountIds
        )
      })
        .then((res) => {
          refreshAssetClassesList();
          assetClass.plCode && assetClass.balanceSheetCode
            ? setIsGlCodeUpdateMsgVisible(true)
            : setIsGlCodeAssignedMsgVisible(true);
          modal.remove();
          document.body.scrollIntoView({ behavior: "smooth", block: "start" });
        })
        .catch((error) => {
          if (typeof error == "string") {
            setApiErrors([{ errorMessage: error }]);
          } else {
            setApiErrors(error.data?.errors);
          }
        });
    });

    const handleAssign = () => {
      let data = {
        balanceSheetCodeId:
          balanceSheetCodeId?.value ||
          balanceSheetCodeOptions.filter((option) =>
            option.label.includes(assetClass.balanceSheetCode)
          )[0].value,
        plCodeId:
          plCodeId?.value ||
          plCodeOptions.filter((option) =>
            option.label.includes(assetClass.plCode)
          )[0].value,
        glAccountIds:
          glCodeIds.length > 0
            ? glCodeIds.map((option) => option.value)
            : assetClass.glAccountIds
      };
      setIsGlCodeAssignedMsgVisible(false);
      setApiErrors([]);
      assignGLCodeMutation.mutate(data);
    };

    const handleCancel = () => {
      modal.remove();
      setApiErrors([]);
    };

    return (
      <div>
        <Modal
          centered
          isOpen={modal.visible}
          backdrop="static"
          keyboard={false}
        >
          <ModalHeader className="mb-0 pb-0" toggle={modal.remove}>
            <div>
              <h2 className="color-dark modal-title">
                {Labels.Organisations.fixedAssetRegister.assignGLCodes}
              </h2>
            </div>
          </ModalHeader>
          <Alert
            color="danger"
            className="mt-3 mb-0 mx-3"
            isOpen={apiErrors?.length > 0}
            toggle={() => setApiErrors([])}
          >
            <ul className="error-list">
              {apiErrors?.map((error, index) => {
                return <li key={index}>{error.errorMessage}</li>;
              })}
            </ul>
          </Alert>
          <ModalBody className="pt-0">
            <Row className="mt-3">
              <Col sm={12} md={6} className="row-header-2 my-auto">
                <span style={{ color: "fv-primary" }}>
                  {
                    Labels.Organisations.fixedAssetRegister
                      .accumulatedDepreciationBalanceSheetCode
                  }
                </span>
              </Col>
              <Col className="mt-1 mt-md-0 d-flex flex-column">
                <div>
                  <SelectBox
                    options={balanceSheetCodeOptions}
                    onChange={balanceSheetCodeHandler}
                    defaultValue={
                      balanceSheetCodeOptions?.filter((option) =>
                        option.label?.includes(assetClass.balanceSheetCode)
                      ) || null
                    }
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col
                sm={12}
                md={6}
                className="row-header-2 my-auto"
                color="primary"
              >
                <span>
                  {
                    Labels.Organisations.fixedAssetRegister
                      .depreciationExpensePLCode
                  }
                </span>
              </Col>
              <Col className="mt-1 mt-md-0 d-flex flex-column">
                <div>
                  <SelectBox
                    options={plCodeOptions}
                    onChange={PLCodeHandler}
                    defaultValue={
                      plCodeOptions?.filter((option) =>
                        option.label?.includes(assetClass.plCode)
                      ) || null
                    }
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col
                sm={12}
                md={6}
                className="row-header-2 my-auto"
                color="primary"
              >
                <span>
                  {Labels.Organisations.fixedAssetRegister.grossBlockCodes}
                  <>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      id="glCodes-tooltip"
                      className="ml-1"
                    />
                    <Tooltip target="glCodes-tooltip">
                      {
                        Labels.Organisations.fixedAssetRegister
                          .grossBlockCodesTooltip
                      }
                    </Tooltip>
                  </>
                </span>
              </Col>
              <Col className="mt-1 mt-md-0 d-flex flex-column">
                <div>
                  <MultiSelect
                    isMulti
                    hasSelectAllOption={true}
                    hideSelectedOptions={false}
                    closeMenuOnSelect={true}
                    className="react-multi-select"
                    options={glCodeOptions || []}
                    onChange={glCodeHandler}
                    value={
                      glCodeIds.length > 0
                        ? glCodeIds
                        : glCodeOptions?.filter((option) =>
                            assetClass.glAccountIds?.includes(option.value)
                          )
                    }
                  />
                </div>
              </Col>
            </Row>
            {(assetClass.plCode || assetClass.balanceSheetCode) && (
              <div className="mt-3">
                <p>
                  <span className="row-header-2">
                    {Labels.CommonModals.note}
                  </span>
                  {Labels.Organisations.fixedAssetRegister.editNote}
                </p>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Row className="no-gutters">
              <Col sm={12} md="auto" className="mr-2">
                {Auth.getUserPrivileges().includes(
                  Privileges.ASSIGN_GL_CODES_TO_FIXED_ASSET_CLASS
                ) && (
                  <Button
                    color="primary"
                    className="btn btn-primary"
                    type="button"
                    onClick={handleAssign}
                    disabled={
                      assetClass.plCode &&
                      assetClass.balanceSheetCode &&
                      assetClass.glAccountIds
                        ? !(
                            plCodeId ||
                            balanceSheetCodeId ||
                            glCodeIds.length > 0
                          )
                        : !(
                            plCodeId &&
                            balanceSheetCodeId &&
                            glCodeIds.length > 0
                          )
                    }
                  >
                    {assetClass.plCode && assetClass.balanceSheetCode
                      ? Labels.Organisations.fixedAssetRegister.update
                      : Labels.Organisations.fixedAssetRegister.assign}
                  </Button>
                )}
              </Col>
              <Col sm={12} md="auto">
                <Button
                  color="secondary"
                  onClick={handleCancel}
                  className="btn btn-secondary"
                  type="button"
                >
                  {Labels.Organisations.fixedAssetRegister.cancel}
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
);
