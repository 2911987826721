import React, { useState } from "react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Container,
  Alert
} from "reactstrap";
import { Labels, Privileges } from "../../../../constants/Constants";
import { useMutation, useQueryClient } from "react-query";
import { httpRequest } from "../../../../utils/http/httpRequest";
import { purchaseOrderEmailTemplate } from "./PurchaseOrderEmailTemplate";
import { sendPODocumentToSupplier } from "../../../../utils/http/endpoints";
import QueryKeys from "../../../../constants/QueryKeys";
import Auth from "../../../../auth/Auth";
import { emailRegexp } from "../../../../constants/regexp";

export default NiceModal.create(
  ({ poDetails, assetId, setEmailSuccessMessage, refreshPOData }) => {
    const modal = useModal();
    const [apiErrors, setApiErrors] = useState([]);
    const [sendMeCopy, setSendMeCopy] = useState(false);
    const [toAddressesInput, setToAddressesInput] = useState(
      poDetails.supplierEmail || ""
    );
    const [ccAddressesInput, setCcAddressesInput] = useState("");
    const [isCcInputFieldTouched, setIsCcInputFieldTouched] = useState(false);
    const queryClient = useQueryClient();
    const [isEmailContentErrorVisible, setIsEmailContentErrorVisible] =
      useState(false);
    const [emailContent, setEmailContent] = useState(
      purchaseOrderEmailTemplate(
        poDetails.supplierName,
        poDetails.purchaseOrderNumber
      )
    );

    const emailContentHandler = (event) => {
      if (event.target.value.length === 0) {
        setIsEmailContentErrorVisible(true);
      } else if (event.target.value.length > 0) {
        setIsEmailContentErrorVisible(false);
      }
      setEmailContent(event.target.value);
    };

    const sendMeCopyHandler = () => {
      setSendMeCopy(!sendMeCopy);
    };

    const isCcInputEmailsValid = () => {
      return isCcInputFieldTouched && ccAddressesInput.length > 0
        ? ccAddressesInput.split(",").every((email) => emailRegexp.test(email))
        : true;
    };

    const isToEmailsValid = () =>
      toAddressesInput.length > 0
        ? toAddressesInput.split(",").every((email) => emailRegexp.test(email))
        : true;

    const handleKeyPress = (event) => {
      var keyCode = event.keyCode || event.which;
      // to block the user  entering space character
      if (keyCode === 32) {
        event.preventDefault();
      }
    };

    const shoudleSendButtonBeDisabled = () => {
      return isCcInputFieldTouched && ccAddressesInput.length > 0
        ? toAddressesInput.split(",").length > 0 &&
            toAddressesInput
              .split(",")
              .every((email) => emailRegexp.test(email)) &&
            ccAddressesInput
              .split(",")
              .every((email) => emailRegexp.test(email))
        : toAddressesInput.split(",").length > 0 &&
            toAddressesInput
              .split(",")
              .every((email) => emailRegexp.test(email));
    };

    const handleSendStatement = () => {
      const toAddressesList = toAddressesInput.split(",");

      const ccAddressesList =
        ccAddressesInput.length > 0 ? ccAddressesInput.split(",") : [];

      let data = {
        sendToList: toAddressesList,
        sendCCList: ccAddressesList,
        emailContent: emailContent || poDetails.pdfEmailContent,
        sendCopyToUser: sendMeCopy
      };
      sendStatementMutation.mutate(data);
    };

    const sendStatementMutation = useMutation({
      mutationKey: [QueryKeys.PurchaseOrderSendStatement, +assetId],
      mutationFn: (data) => {
        return httpRequest({
          method: "post",
          url: sendPODocumentToSupplier(assetId, poDetails.id),
          data: data
        })
          .then((res) => Promise.resolve(res.data))
          .catch((error) => Promise.reject(error));
      },
      onSuccess: (data) => {
        modal.resolve();
        modal.remove();
        queryClient.invalidateQueries([QueryKeys.getInvoiceHistoryAndComments]);
        refreshPOData();
        setEmailSuccessMessage(Labels.Assets.PurchaseOrders.emailSuccessMsg);
        sendStatementMutation.reset();
      },
      onError: (error) => {
        if (typeof error == "string") {
          setApiErrors([{ errorMessage: error }]);
        } else {
          setApiErrors(error.data.errors);
        }
      }
    });

    return (
      <Modal
        keyboard={false}
        backdrop="static"
        centered
        fade={false}
        isOpen={modal.visible}
        toggle={modal.remove}
        size="lg"
      >
        <div className="modal-header">
          <h2 className="color-dark modal-title">
            {Labels.CommonModals.email}
          </h2>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={modal.remove}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody className="py-0">
          <Alert
            color="danger"
            className="mt-3 mb-0 mx-auto"
            isOpen={apiErrors?.length > 0}
            toggle={() => setApiErrors([])}
          >
            <ul className="error-list">
              {apiErrors?.map((error, index) => {
                return <li key={index}>{error.errorMessage}</li>;
              })}
            </ul>
          </Alert>
          <div>
            <Container>
              <Row className="d-flex justify-content-between align-items-center">
                <Col
                  md={5}
                  className="align-self-start m-0 p-0"
                  htmlFor="to-email"
                >
                  <label className="mt-0">
                    {Labels.Assets.PurchaseOrders.to}
                  </label>
                </Col>
                <Col md={7} className="pr-0">
                  <input
                    type="text"
                    onChange={(event) =>
                      setToAddressesInput(event.target.value)
                    }
                    onKeyPress={handleKeyPress}
                    value={toAddressesInput}
                    placeholder="Enter recipent email"
                  />
                  {toAddressesInput.length === 0 ? (
                    <span className="error-message">
                      {Labels.Assets.PurchaseOrders.recipentEmailErrorMsg}
                    </span>
                  ) : null}
                  {isToEmailsValid() ? (
                    <div className="text-block-primary mt-2 mb-0">
                      {Labels.Assets.PurchaseOrders.emailSeparationNote}
                    </div>
                  ) : (
                    <span className="error-message">
                      {Labels.Assets.PurchaseOrders.invalidEmailsErrorMsg}
                    </span>
                  )}
                </Col>
              </Row>
              <Row className="d-flex justify-content-between align-items-center pt-3">
                <Col
                  md={5}
                  className="align-self-start m-0 p-0"
                  htmlFor="from-email"
                >
                  <label className="mt-0">
                    {Labels.Assets.PurchaseOrders.cc}
                  </label>
                </Col>
                <Col md={7} className="pr-0">
                  <input
                    type="text"
                    onKeyPress={handleKeyPress}
                    onChange={(event) => {
                      setIsCcInputFieldTouched(true);
                      setCcAddressesInput(event.target.value);
                    }}
                    value={ccAddressesInput}
                  />

                  {isCcInputEmailsValid() ? (
                    <label className="text-block-primary mt-2 mb-0">
                      {Labels.Assets.PurchaseOrders.emailSeparationNote}
                    </label>
                  ) : (
                    <span className="error-message">
                      {Labels.Assets.PurchaseOrders.invalidEmailsErrorMsg}
                    </span>
                  )}
                </Col>
              </Row>
              <Row className="d-flex justify-content-between align-items-center pt-3">
                <Col md={5} className="m-0 p-0" htmlFor="from-email">
                  <label className="my-auto">
                    {Labels.Assets.PurchaseOrders.from}
                  </label>
                </Col>
                <Col md={7} className="d-flex">
                  <label className="m-0 p-0">
                    {poDetails.applicationEmailAddress}
                  </label>
                </Col>
              </Row>
              <Row className="d-flex justify-content-between align-items-center pt-3">
                <Col md={5} className="align-self-start pl-0">
                  <label className="m-0 p-0" htmlFor="email-body">
                    {Labels.Assets.PurchaseOrders.emailContent}
                  </label>
                </Col>
                <Col className="pr-0">
                  <textarea
                    type="textarea"
                    className="form-control p-2 m-0 p-2 m-0"
                    cols="50"
                    rows="10"
                    defaultValue={poDetails.pdfEmailContent || emailContent}
                    onChange={emailContentHandler}
                  />

                  {isEmailContentErrorVisible || !emailContent ? (
                    <span className="error-message">
                      {Labels.Assets.PurchaseOrders.emailContentErrorMsg}
                    </span>
                  ) : null}
                  <div className="m-0 p-0 mt-2 d-flex align-items-center">
                    <input
                      type="checkbox"
                      id="sendCopyToUser"
                      className="m-0 p-0 mr-1"
                      onChange={sendMeCopyHandler}
                      checked={sendMeCopy}
                    />
                    <label className="body-primary color-text-secondary my-auto">
                      {Labels.Assets.PurchaseOrders.sendMeCopy}
                    </label>
                  </div>
                </Col>
              </Row>
              <Row>
                <p className="my-3">
                  <span className="row-header-2">
                    {Labels.CommonModals.note}
                  </span>
                  {Labels.Assets.PurchaseOrders.emailNote}
                </p>
              </Row>
            </Container>
          </div>
        </ModalBody>
        <ModalFooter className="py-2">
          {Auth.getUserPrivileges().includes(
            Privileges.SEND_PURCHASE_ORDER_PDF
          ) ? (
            <Button
              color="primary"
              onClick={handleSendStatement}
              disabled={
                !shoudleSendButtonBeDisabled() ||
                sendStatementMutation.isLoading ||
                isEmailContentErrorVisible ||
                !emailContent
              }
            >
              {Labels.Assets.PurchaseOrders.send}
            </Button>
          ) : null}
          <Button color="secondary" onClick={modal.remove}>
            {Labels.CommonModals.cancel}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
);
