import React, { useState, useReducer } from "react";
import { createContext } from "react";
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch
} from "react-router-dom";
import transactionDetailReducer, {
  InitialState
} from "./transactionDetailReducer";
import useQueryParams from "../../../../../../hooks/useQueryParams";
import { useQuery, useQueryClient } from "react-query";
import { getBankStatementLineItem } from "../../../../../../utils/http/endpoints";
import { httpRequest } from "../../../../../../utils/http/httpRequest";
import { convertToFloat } from "../../../../../../utils/number";
import {
  reset,
  updateTransactionStatus,
  updateTransferToBankAccountId
} from "./transactionDetailsActionCreator";
import { Labels } from "../../../../../../constants/Constants";
import useToggle from "../../../../../../hooks/useToggle";
import useGetBudgetLineItemsList from "../../../../../../hooks/useGetBudgetLineItemsList";
import moment from "moment";
import useGetTaxList from "../../../../../../hooks/useGetTaxList";
import useGetGLCodesList from "../../../../../../hooks/useGetGLCodesList";
import { SalesInvoiceTypes } from "../../../../../../constants/SalesInvoiceTypes";
import useGetContactList from "../../../../../../hooks/useGetContactList";
import useGetPostingPeriod from "../../../../../../hooks/useGetPostingPeriod";
import { serializeDate } from "../../../../../../utils/date";
import modules from "../../../../../../constants/PostingPeriodModules";
import TransactionStatuses from "../../../../../../constants/TransactionStatuses";
import useGetPaidOutOrInBankStatementLineItems from "./useGetPaidOutOrInBankStatementLineItems";

export const initialState = new InitialState();

export const instrumentToInvoiceTypeMap = new Map([
  ["SALES_INVOICE", "Sales Invoice"],
  ["SALES_CREDIT_NOTE", "Sales Credit Note"],
  ["CONTRACT_FOR_DIFFERENCE", "Contract for Difference"],
  ["PURCHASE_INVOICE", "Purchase Invoice"],
  ["PURCHASE_CREDIT_NOTE", "Purchase Credit Note"]
]);

export const TransactionDetailContext = createContext({
  transactionDetails: {},
  state: initialState,
  dispatch: () => {},
  formData: {}
});

const TransactionDetailContextProvider = ({ children }) => {
  const queryParams = useQueryParams();
  const location = useLocation();
  const [isTransfer, toggleMatchAndTransfer] = useToggle(
    location.state?.isTransfer
  );

  const params = useParams();
  const { assetId, assetBankAccountId, assetBankAccountStatementLineItemId } =
    params;
  const [formData, dispatch] = useReducer(
    transactionDetailReducer,
    new InitialState()
  );
  const glCodeListQuery = useGetGLCodesList({
    assetId,
    systemAccountsToInclude: {
      includeFXGainLossSystemAcc: true,
      includeVATControlSystemAcc: true,
      includeInterCompanyAccounts: true
    },
    includeAllSystemAccounts: false
  });
  const contactsQuery = useGetContactList({
    assetId,
    types: []
  });
  const isFieldTouched = (fieldName) => {
    // fields are in pascale care & Lables are capitalized
    // so converting both to lower cases for comparison
    const touchedFormFieldsSet = new Set();
    formData.touchedFormFields.forEach((field) => {
      touchedFormFieldsSet.add(field.toLowerCase());
    });
    return touchedFormFieldsSet.has(fieldName.toLowerCase());
  };

  const [sortAndFilterQueryParams] = useState(() => {
    const params = new URLSearchParams(queryParams.toString());
    params.delete("isMatch");
    params.delete("isTransfer");
    return params.toString();
  });

  const history = useHistory();

  const getInvoiceUrl = ({ assetId, invoiceId, invoiceType, orgId }) => {
    let url = "";
    if (assetId === undefined && orgId !== undefined) {
      url = `/#/orgs/org/${orgId}/payruns/invoices/${assetId}/${invoiceId}`;
    } else if (assetId !== undefined && orgId !== undefined) {
      url = `/#/org/${orgId}/assets/asset/${assetId}/payruns/invoices/${invoiceId}`;
    } else {
      url = `/#/allassets/payments/invoices/${assetId}/${invoiceId}`;
    }

    return [
      SalesInvoiceTypes.SALES_CREDIT_NOTE,
      SalesInvoiceTypes.SALES_INVOICE,
      SalesInvoiceTypes.CONTRACT_FOR_DIFFERENCE
    ].includes(invoiceType)
      ? url.replace(
          /invoices.*/gi,
          `sales-invoices/view/${assetId}/${invoiceId}?type=${invoiceType}`
        )
      : url;
  };

  const transactionDetailsQuery = useQuery({
    queryKey: [
      "transactionDetailsByLineItemId",
      +assetId,
      +assetBankAccountId,
      +assetBankAccountStatementLineItemId,
      sortAndFilterQueryParams
    ],
    select: (data) => ({
      ...data,
      payments: data.payments.map((payment, index) => ({
        ...payment,
        invoiceTypeReadableString: instrumentToInvoiceTypeMap.get(
          payment.invoiceType
        ),
        invoiceUrl: getInvoiceUrl({
          assetId: assetId,
          invoiceId: payment.invoiceId,
          invoiceType: instrumentToInvoiceTypeMap.get(payment.invoiceType),
          orgId: params.orgId
        })
      }))
    }),
    queryFn: () => {
      return httpRequest({
        method: "get",
        url:
          getBankStatementLineItem(
            assetId,
            assetBankAccountId,
            assetBankAccountStatementLineItemId
          ) +
          "?" +
          sortAndFilterQueryParams
      })
        .then((response) => {
          dispatch(updateTransactionStatus(response.data.status));
          return Promise.resolve(response.data);
        })
        .catch((error) => Promise.reject(error.data.errors));
    },
    onError: (error) => {
      if (error[0].errorCode === "400") {
        if (getLinkedTransactionData(Labels.CommonModals.next).isDisabled) {
          history.goBack();
        } else {
          history.replace(
            getLinkedTransactionData(Labels.CommonModals.next).to
          );
        }
      }
    },
    onSuccess: (data) => {
      dispatch(reset());

      // Select transfer bank account by default if only 1 account is available in other bank accounts
      if (
        !data.transferToBankAccountId && // Make sure tranfer account is empty before
        data.otherBankAccountsForAsset?.length === 1
      ) {
        dispatch(
          updateTransferToBankAccountId(
            data.otherBankAccountsForAsset[0].assetBankAccountId,
            {
              isUserAction: false
            } // Do not mark form as changed, for this action
          )
        );
      }
    }
  });

  const bankStatementLineItemsQuery = useGetPaidOutOrInBankStatementLineItems({
    assetBankAccountId: formData.transferToBankAccountId,
    assetId,
    isPaidIn: transactionDetailsQuery.data?.amount > 0,
    enabled: !!(
      transactionDetailsQuery.data && formData.transferToBankAccountId
    )
  });

  const taxCodesListQuery = useGetTaxList({
    assetId,
    status: "ACTIVE"
  });

  const getSelectedInstruments = () => {
    const selectedInstruments = new Set();

    const selectedPayments =
      TransactionStatuses.DRAFT === transactionDetailsQuery.data?.status
        ? formData.selectedPayments
        : transactionDetailsQuery.data?.payments;
    const transactionLineItems =
      TransactionStatuses.DRAFT === transactionDetailsQuery.data?.status
        ? formData.transactionLineItems
        : transactionDetailsQuery.data?.transactions;

    if (transactionLineItems?.length) {
      selectedInstruments.add(modules.Manual);
    }

    if (!selectedPayments?.length) {
      return Array.from(selectedInstruments);
    }

    selectedPayments.forEach((instrument) => {
      const invoiceTypeToPostingPeriodModuleMap = new Map([
        ["SALES_INVOICE", modules.AR],
        ["SALES_CREDIT_NOTE", modules.AR],
        ["CONTRACT_FOR_DIFFERENCE", modules.AR],
        ["PURCHASE_INVOICE", modules.AP],
        ["PURCHASE_CREDIT_NOTE", modules.AP]
      ]);

      return selectedInstruments.add(
        invoiceTypeToPostingPeriodModuleMap.get(instrument.invoiceType)
      );
    });

    return Array.from(selectedInstruments);
  };

  const arPostingPeriodQuery = useGetPostingPeriod({
    assetId: assetId,
    instrumentDate: transactionDetailsQuery.data?.date
      ? serializeDate(new Date(transactionDetailsQuery.data?.date))
      : null,
    module: modules.AR
  });

  const apPostingPeriodQuery = useGetPostingPeriod({
    assetId: assetId,
    instrumentDate: transactionDetailsQuery.data?.date
      ? serializeDate(new Date(transactionDetailsQuery.data?.date))
      : null,
    module: modules.AP
  });

  const currentPostingPeriod = {
    [modules.AP]: apPostingPeriodQuery.data?.postingPeriod || null,
    [modules.AR]: arPostingPeriodQuery.data?.postingPeriod || null
  }[
    getBankReconPostingPeriodModule({
      isPaidIn: transactionDetailsQuery.data?.amount > 0,
      selectedInstruments: getSelectedInstruments()
    })
  ];

  const isAPARPostingPeriodMismatched = () => {
    const selectedInstruments = getSelectedInstruments();
    if (
      selectedInstruments.includes(modules.AP) &&
      selectedInstruments.includes(modules.AR)
    ) {
      return (
        apPostingPeriodQuery.data?.postingPeriod !==
        arPostingPeriodQuery.data?.postingPeriod
      );
    } else {
      return false;
    }
  };

  const { url } = useRouteMatch();
  const getLinkedTransactionData = (direction = "") => {
    const transactionDetails = transactionDetailsQuery.data;
    return {
      to: {
        pathname: url.replace(
          "transactions/" + transactionDetails?.id,
          "transactions/" +
            transactionDetails?.[direction.toLowerCase()]
              ?.assetBankAccountStatementLineItemId
        ),
        search: location.search
      },
      isDisabled: !!(
        !transactionDetails || !transactionDetails?.[direction.toLowerCase()]
      )
    };
  };

  const budgetLineItemListQuery = useGetBudgetLineItemsList({
    assetId,
    month: moment(transactionDetailsQuery.data?.date).format("M"),
    financialYear: moment(transactionDetailsQuery.data?.date).format("Y"),
    systemAccountsToInclude: {
      includeFXGainLossSystemAcc: true,
      includeVATControlSystemAcc: true
    },
    includeAllSystemAccounts: false
  });

  const queryClient = useQueryClient();
  const refreshTransactionData = () => {
    queryClient.invalidateQueries("transactionDetailsByLineItemId");
  };

  // Always +ve
  const getSumOfSelectedPayments = () => {
    const sumOfSelectedPayments = transactionDetailsQuery.isSuccess
      ? transactionDetailsQuery.data.payments
          .filter(
            (i) =>
              formData.selectedPayments.findIndex(
                (selectedPayment) =>
                  selectedPayment.id === i.id && selectedPayment.type === i.type
              ) !== -1
          )
          .map((i) => i.amount)
          .reduce((accumlator, previousValue) => accumlator + previousValue, 0)
      : 0;

    return +sumOfSelectedPayments.toFixed(2);
  };

  // Can be -ve or +ve
  const getSumOfUserCreatedTransactions = () => {
    const sumOfUserCreatedTransactions = formData.transactionLineItems
      .map((i) => convertToFloat(i.amount))
      .reduce((accumlator, previousValue) => accumlator + previousValue, 0);

    return +sumOfUserCreatedTransactions.toFixed(2);
  };

  const getSumOfMatchedPaymentsAndTransactions = () => {
    const sumOfSelectedPayments = getSumOfSelectedPayments();
    const sumOfUserCreatedTransactions = getSumOfUserCreatedTransactions();
    return +(sumOfSelectedPayments + sumOfUserCreatedTransactions).toFixed(2);
  };

  const getSumOfPaidOutAmountAndTransactionsForFX = () => {
    const lineItemAmount = Math.abs(transactionDetailsQuery.data?.amount); // +ve
    const sumOfUserCreatedTransactions = getSumOfUserCreatedTransactions() * -1;
    return +(lineItemAmount + sumOfUserCreatedTransactions).toFixed(2);
  };

  const calculateOutByAmount = () => {
    let outByAmount = 0;
    const lineItemAmount = Math.abs(transactionDetailsQuery.data?.amount); // +ve
    const sumOfPayments = getSumOfSelectedPayments(); // +ve
    const sumofTransactions = getSumOfUserCreatedTransactions(); // +ve, -ve, 0

    // Out by amout <-> new transaction amount, will automatically becomes -ve only when sum of payments exceeds line item amount

    outByAmount = lineItemAmount - sumOfPayments - sumofTransactions;

    return +outByAmount.toFixed(2);
  };

  const outByAmount = calculateOutByAmount();

  return (
    <TransactionDetailContext.Provider
      value={{
        transactionDetailsQuery,
        getSumOfMatchedPaymentsAndTransactions,
        getSumOfPaidOutAmountAndTransactionsForFX,
        budgetLineItemListQuery,
        taxCodesListQuery,
        contactsQuery,
        outByAmount,
        glCodeListQuery,
        getSumOfSelectedPayments,
        getSumOfUserCreatedTransactions,
        refreshTransactionData,
        formData,
        isFieldTouched,
        dispatch,
        getLinkedTransactionData,
        isTransfer,
        toggleMatchAndTransfer,
        currentPostingPeriod,
        isAPARPostingPeriodMismatched,
        bankStatementLineItemsQuery
      }}
    >
      {children}
    </TransactionDetailContext.Provider>
  );
};

export function getBankReconPostingPeriodModule({
  selectedInstruments = [],
  isPaidIn = false
}) {
  if (selectedInstruments.length === 0) {
    return null;
  }

  // If any module can be used, AP is used.
  const moduleSelectionRules = {
    [[modules.Manual, modules.AP, modules.AR].sort()]: modules.AP,
    [[modules.Manual, modules.AP].sort()]: modules.AP,
    [[modules.Manual, modules.AR].sort()]: modules.AR,
    [[modules.Manual].sort()]: isPaidIn ? modules.AR : modules.AP,
    [[modules.AP, modules.AR].sort()]: modules.AP,
    [[modules.AP].sort()]: modules.AP,
    [[modules.AR].sort()]: modules.AR
  };

  return moduleSelectionRules[selectedInstruments.sort()];
}

export default TransactionDetailContextProvider;
